/* eslint-disable import/prefer-default-export */

// PRoducts Category
const productCategory = {
    "1": "businessCentral",
    "2": "sales",
    "3": "marketing",
    "4": "customerService"
  };



//  modifies the products category object
export const modifyProductsByCategory = (products) => {
    return products.map(item => productCategory[item])
  }