/* eslint-disable no-unused-vars */
/* eslint-disable prefer-regex-literals */
/* eslint-disable no-useless-escape */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-shadow */
/* eslint-disable prefer-const */
/* eslint-disable no-plusplus */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/alt-text */
import axios from 'axios';
import { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Button,
  Container,
  Grid,
  Typography,
  Stack,
  TextField,
  Checkbox,
  IconButton,
  Fade,
  Select,
  MenuItem,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import Tab from '@mui/material/Tab';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import ReactGA from 'react-ga';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import InputBase from '@mui/material/InputBase';
import { Link } from 'react-scroll';
import { useMediaQuery } from 'react-responsive';
import { connect, useSelector, useDispatch } from 'react-redux';
import 'animate.css';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { makeStyles, withStyles } from '@mui/styles';
import styled from '@emotion/styled';
import { Formik } from 'formik';
import * as yup from 'yup';
import publicIp from 'public-ip';
import bluetick from '../assets/bluetick.svg';
import arrow from '../assets/arrow.svg';
import { setStepsStatus } from '../redux/home/home.actions';
// import { useLinkedIn } from 'react-linkedin-login-oauth2';
import Navbar from '../Components/Navbar/NavbarTab';
import BannerImage from '../assets/logo-overlay.png';
import iconlight from '../assets/iconlight.svg';
import icondark from '../assets/icondark.svg';
import infoicon from '../assets/infoicon.svg';
import saveSupport from '../redux/support/support.actions';
import pricingCode from '../redux/pricingCode/pricingCode.actions';
import saveGeoLocation from '../redux/geoLocation/geoLocation.actions';
import saveSupportPackage from '../redux/supportPackage/supportPackage.actions';
import CallingCode from '../Components/CallingCode';
import saveProfileInfo from '../redux/profile/profile.actions';
import SaveForLater from '../Components/SaveForLater';
import exit from '../assets/exit.svg';
import catapultLogo from '../assets/catapult-mobile.svg';
import { modifyProductsByCategory } from '../helpers';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}));

const icon = {
  position: 'fixed',
  cursor: 'pointer',
  right: '20px',
  top: '15px',
  color: '#323232',
  opacity: '50%',
};

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    // maxWidth: "234px",
    display: 'flex',
    alignItems: 'center',
    height: '50px',
    color: '#FFFFFF',
    fontFamily: 'DinDIsplayProRegular',
    backgroundColor: '#00313E',
    fontSize: '12px',
  },
});

const CssTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#CCCCCC',
        fontFamily: 'DinDIsplayProRegular',
      },
      '&:hover fieldset': {
        borderColor: '#CCCCCC',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#CCCCCC',
      },
      '& input': {
        fontFamily: 'DinDIsplayProRegular',
        fontSize: '15px',
        color: '#00313E',
        height: '14px',
        width: '100%',
      },
    },
  },
})(TextField);

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  MuiAccordionroot: {
    '&.MuiAccordion-root': {
      boxShadow: 'none',
      borderTop: '1px solid #F4F4F4',
    },
    '&.MuiAccordionDetails-root': {
      paddingLeft: '16px',
      paddingRight: '16px',
    },
    '&.MuiAccordionSummary-content Mui-expanded': {
      margin: 0,
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        '&.Mui-selected': {
          color: '#00313E',
        },
      },
    },
  },
}));

const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 170,
    },
  },
};

const Support = ({ stepsStatus, profileInfo, selectedPackage, code, saveForLaterCode }) => {
  const isLandscape = useMediaQuery({ query: `(max-height: 500px)` });

  const isSmallMobile = useMediaQuery({ query: `(max-width: 768px)` });

  const isIpad = useMediaQuery({ query: `(max-width: 1024px)` });

  const isMobile = useMediaQuery({ query: `(max-width: 812px)` });

  const classes = useStyles();

  const dispatch = useDispatch();

  let formRef = useRef();

  const supportDivRef = useRef();

  const { state } = useLocation();

  const modalRef = useRef(null);

  const id = localStorage.getItem('clientId');

  const location = useLocation();

  const reduxData = useSelector((s) => s);

  const navigate = useNavigate();

  // state for navbar responsiveness
  const [navbar, setNavbar] = useState(false);

  // modal open & close state
  const [open, setOpen] = useState(false);

  // enable and disable//
  const [show, setShow] = useState(false);

  const [showError, setShowError] = useState(false);

  const [selectPlan, setSelectPlan] = useState(selectedPackage?.supportPackage);

  const [hoveredPlan, setHoveredPlan] = useState(selectedPackage?.supportPackage);

  const [loading, setLoading] = useState(false);

  const [check, setCheck] = useState(false);

  const [isChecked, setIsChecked] = useState(false);

  const [openToolTip, setOpeToolTip] = useState(false);

  const handleTooltipOpen = () => {
    setOpeToolTip(!openToolTip);
  };

  const [value, setValue] = useState('2');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeDemo = (e) => {
    setIsChecked(!isChecked);
  };

  const handleCancel = () => {
    navigate('/pricing');
  };

  const initialValues = {
    firstName: profileInfo?.firstName,
    lastName: profileInfo?.lastName,
    email: profileInfo?.email,
    companyName: profileInfo?.companyName,
    phoneNo: profileInfo?.phoneNo,
    countryCode: profileInfo?.countryCode ? profileInfo?.countryCode : '+1',
    followUp: profileInfo?.followUp,
  };

  const nameRegexp = RegExp(/^[a-zA-Z\ ]*$/);

  const phoneRegExp =
    /^(1?)(-| ?)(\()?([0-9]{3})(\)|-| |\)-|\) )?([0-9]{3})(-| )?([0-9]{4}|[0-9]{4})$/;

  const validationSchema = yup.object().shape({
    firstName: yup
      .string()
      .trim()
      .matches(nameRegexp, 'Please enter a valid name')
      .min(2, 'Too Short')
      .max(50, 'Too Long')
      .required('Please enter first name.'),
    lastName: yup
      .string()
      .trim()
      .min(2, 'Too Short')
      .matches(nameRegexp, 'Please enter a valid name')
      .max(50, 'Too Long')
      .required('Please enter last name.'),
    email: yup
      .string()
      .trim()
      .required('Please enter an email.')
      .email('Please enter a valid email'),
    companyName: yup
      .string()
      .trim()
      .min(2, 'Too Short')
      .max(50, 'Too Long')
      .required('Please enter company name.'),
    phoneNo: yup.string().matches(phoneRegExp, 'Please enter as 111-222-3333 or 1234567890'),
  });

  const { product } = useSelector((s) => s);
  const ifProductsExist = product.selectedProducts.length > 0 ? product.selectedProducts : [];
  // const [selectedProducts, setProducts] = useState(ifProductsExist);

  const handlePostMessage = async (event) => {
    if (event?.data?.type === 'code') {
      setLoading(true);
      const { code } = event.data;
      const redirectUrl = `${process.env.REACT_APP_LINKEDIN_URL}/support`;

      axios
        .post(`${process.env.REACT_APP_BASE_URL}/api/getLinkedinUserProfileDetails`, {
          code,
          redirectUrl,
        })

        .then((res) => {
          if (res.status === 200) {
            formRef?.current?.setFieldValue('firstName', res.data.data.userProfile.firstName);
            formRef?.current?.setFieldValue('lastName', res.data.data.userProfile.lastName);
            formRef?.current?.setFieldValue('email', res.data.data.userProfile.email);
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  const addProfileInfo = (values) => {
    values.followUp = isChecked;
    setLoading(true);
    // navigate('/pricing')
    const body = {
      selectedProducts: modifyProductsByCategory(reduxData.product.selectedProducts),
      businessCentral: reduxData.businessCentral.businessCentral,
      sales: reduxData.sales.sales,
      marketing: reduxData.marketing.marketing,
      customerService: reduxData.customerService.customerService,
      endUsers: reduxData.endUsers,
      profileDetails: values,
      supportPackage: selectPlan,
      userLocation: reduxData.saveGeoLocation.geoLocation,
      // userCode: saveForLaterCode,
      lastVistedRoute: {
        stepsStatus: reduxData.home.stepsStatus,
        subStepsStatus: reduxData.home.subStepsStatus,
        route: location.pathname,
      },
      analyticsPayload: {
        clientId: id,
      },
    };
    if (saveForLaterCode) {
      body.userCode = saveForLaterCode;
    } else if (code) {
      body.userCode = code;
    } else {
      body.userCode = '';
    }
    saveProfileInfo(values);
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/api/calculatePricing`, body)

      .then((res) => {
        if (res?.data?.message === 'Pricing calculated successfully') {
          const response = res?.data;
          dispatch(saveSupport(response));
          dispatch(saveProfileInfo(response.data.profileDetails));
          dispatch(saveSupportPackage(selectPlan));
          dispatch(pricingCode(res?.data?.data?.code));
          navigate('/pricing');
          const updateStep = stepsStatus;
          updateStep[3].support = { active: false, completed: true };
          dispatch(setStepsStatus(updateStep));
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        setShowError(true);
      });
  };

  const handleSave = () => {
    setLoading(true);
    const body = {
      selectedProducts: modifyProductsByCategory(reduxData.product.selectedProducts),
      businessCentral: reduxData.businessCentral.businessCentral,
      sales: reduxData.sales.sales,
      marketing: reduxData.marketing.marketing,
      customerService: reduxData.customerService.customerService,
      endUsers: reduxData.endUsers,
      profileDetails: {
        companyName: reduxData.profile.profile.companyName,
        countryCode: reduxData.profile.profile.countryCode,
        email: reduxData.profile.profile.email,
        firstName: reduxData.profile.profile.firstName,
        followUp: reduxData.profile.profile.followUp,
        lastName: reduxData.profile.profile.lastName,
        phoneNo: reduxData.profile.profile.phoneNo,
      },
      userLocation: reduxData.saveGeoLocation.geoLocation,
      supportPackage: selectPlan,
      userCode: saveForLaterCode,
      lastVistedRoute: {
        stepsStatus: reduxData.home.stepsStatus,
        subStepsStatus: reduxData.home.subStepsStatus,
        route: location.pathname,
      },
      analyticsPayload: {
        clientId: id,
      },
    };

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/api/calculatePricing`, body)

      .then((res) => {
        if (res?.data?.message === 'Pricing calculated successfully') {
          const response = res?.data;
          dispatch(saveSupport(response));
          dispatch(saveSupportPackage(selectPlan));
          dispatch(pricingCode(res?.data?.data?.code));
          navigate('/pricing');
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        setShowError(true);
      });
  };
  const getCodeFromWindowURL = (url) => {
    const popupWindowURL = new URL(url);
    return popupWindowURL.searchParams.get('code');
  };

  const handleNext = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setIsChecked(false);
  };

  document.documentElement.style.setProperty('--animate-duration', '3s');

  const changePosition = () => {
    if (window.scrollY >= 400) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  // const { linkedInLogin } = useLinkedIn({
  //   clientId: '78ahugwtlani98',
  //   redirectUri: `${window.location.href}`, // for Next.js, you can use `${typeof window === 'object' && window.location.origin}/linkedin`
  //   onSuccess: (code) => {
  //     console.log('linkedIn',code);
  //   },
  //   onError: (error) => {
  //     console.log('linkedInError',error);
  //   },
  // });

  window.addEventListener('scroll', changePosition);

  const showPopup = () => {
    const clientId = '78mmuqz3bvhr50';
    // const redirectUrl = 'http://localhost:3000/support';
    const redirectUrl = `${process.env.REACT_APP_LINKEDIN_URL}/support`;
    let oauthUrl = 'https://www.linkedin.com/oauth/v2/authorization?response_type=code';
    const scope = 'r_liteprofile%20r_emailaddress';
    const state = '123456';

    oauthUrl = `${oauthUrl}&client_id=${clientId}&scope=${scope}&state=${state}&redirect_uri=${redirectUrl}`;
    const width = 450;
    const height = 730;
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;
    window.open(
      oauthUrl,
      'Linkedin',
      `menubar=no,location=no,resizable=no,scrollbars=no,status=no, width=${width}, height= ${height} , top= ${top} , left= ${left}`
    );
  };

  const hyperSupport = [
    {
      name: 'HelpDesk Support ',
      arrow: <img src={arrow} />,
      tick1: '',
      tick1Desc: '10 cases/year',
      tick2: '',
      tick2Desc: '30 cases/year',
      tick3: '',
      tick3Desc: 'Unlimited cases',
      desc: 'Resolve unplanned glitches and troubleshoot issues with fast access to our team of experts.',
    },
    {
      name: 'Upgrade Management & New Capabilities Showcase',
      arrow: <img src={arrow} />,
      tick1: <img src={bluetick} />,
      tick1Desc: '',
      tick2: <img src={bluetick} />,
      tick2Desc: '',
      tick3: <img src={bluetick} />,
      tick3Desc: '',
      desc: 'Learn about new capabilities and have them tested and deployed without disrupting your workflows.',
    },
    {
      name: 'Solution Admin & Monitoring',
      arrow: <img src={arrow} />,
      tick1: <img src={bluetick} />,
      tick1Desc: '',
      tick2: <img src={bluetick} />,
      tick2Desc: '',
      tick3: <img src={bluetick} />,
      tick3Desc: '',
      desc: 'Make sure your solution is always optimized with regular monitoring, user management, and scheduled system audits.',
    },
    {
      name: 'Data Backup & Recovery',
      arrow: <img src={arrow} />,
      tick1: <img src={bluetick} />,
      tick1Desc: '',
      tick2: <img src={bluetick} />,
      tick2Desc: '',
      tick3: <img src={bluetick} />,
      tick3Desc: '',
      // tick1: '',
      desc: 'Secure your data with ongoing back-up management and rapid recovery response in the event of data loss.',
    },
    {
      name: 'Monthly Support Reviews',
      arrow: <img src={arrow} />,
      tick1: <img src={bluetick} />,
      tick1Desc: '',
      tick2: <img src={bluetick} />,
      tick2Desc: '',
      tick3: <img src={bluetick} />,
      tick3Desc: '',
      desc: 'Get the most out of your support service with monthly evaluation check-ins. ',
    },
    {
      name: 'Annual Solution Review ',
      arrow: <img src={arrow} />,
      tick1: <img src={bluetick} />,
      tick1Desc: '',
      tick2: <img src={bluetick} />,
      tick2Desc: '',
      tick3: <img src={bluetick} />,
      tick3Desc: '',
      desc: 'Effectively scale your solution as your business needs grow with solution reviews by our consultants.',
    },
    {
      name: 'Training',
      arrow: <img src={arrow} />,
      tick1: '',
      tick2: '',
      tick2Desc: '4 sessions/year',
      tick3: '',
      tick3Desc: '12 sessions/year',
      desc: 'Train new team members on your solution and workflows or bring your current team up to speed on new features for quick adoption and maximum productivity. ',
    },
    {
      name: 'ClickLearn Training Content Portal',
      arrow: <img src={arrow} />,
      tick1: '',
      tick2: <img src={bluetick} />,
      tick2Desc: '*',
      tick3: '',
      tick3Desc: 'Training content updated twice annually',
      desc: 'Access a full library of interactive training content documentation, including video guides to maintain platform efficiency.',
      additionalDesc:
        '*ClickLearn licensing and content management available for an additional fee',
    },
    {
      name: 'Enhancement Planning Sessions ',
      arrow: <img src={arrow} />,
      tick1: '',
      tick2: '',
      tick3: <img src={bluetick} />,
      tick3Desc: '',
      desc: 'Work with our Hypercare support experts to discover and implement new process enhancements. ',
    },
  ];

  const hyperSupportLaunch = [
    {
      name: 'HelpDesk Support ',
      arrow: <img src={arrow} />,
      tick1: '',
      tick1Desc: '10 cases/year',
      desc: 'Resolve unplanned glitches and troubleshoot issues with fast access to our team of experts.',
    },
    {
      name: 'Upgrade Management & New Capabilities Showcase',
      arrow: <img src={arrow} />,
      tick1: <img src={bluetick} />,
      desc: 'Learn about new capabilities and have them tested and deployed without disrupting your workflows.',
    },
    {
      name: 'Solution Admin & Monitoring',
      arrow: <img src={arrow} />,
      tick1: <img src={bluetick} />,
      desc: 'Make sure your solution is always optimized with regular monitoring, user management, and scheduled system audits.',
    },
    {
      name: 'Data Backup & Recovery',
      arrow: <img src={arrow} />,
      tick1: <img src={bluetick} />,
      desc: 'Secure your data with ongoing back-up management and rapid recovery response in the event of data loss.',
    },
    {
      name: 'Monthly Support Reviews',
      arrow: <img src={arrow} />,
      tick1: <img src={bluetick} />,
      desc: 'Get the most out of your support service with monthly evaluation check-ins. ',
    },
    {
      name: 'Annual Solution Review ',
      arrow: <img src={arrow} />,
      tick1: <img src={bluetick} />,
      desc: 'Effectively scale your solution as your business needs grow with solution reviews by our consultants.',
    },
    {
      name: 'Training',
      arrow: <img src={arrow} />,
      tick1: '',
      desc: 'Train new team members on your solution and workflows or bring your current team up to speed on new features for quick adoption and maximum productivity. ',
    },
    {
      name: 'ClickLearn Training Content Portal',
      arrow: <img src={arrow} />,
      tick1: '',
      desc: `Access a full library of interactive training content documentation, including video guides to maintain platform efficiency.`,
      additionalDesc: `*ClickLearn licensing and content management available for an additional fee`,
    },
    {
      name: 'Enhancement Planning Sessions ',
      arrow: <img src={arrow} />,
      tick1: '',
      desc: 'Work with our Hypercare support experts to discover and implement new process enhancements. ',
    },
  ];
  const hyperSupportOptimize = [
    {
      name: 'HelpDesk Support',
      arrow: <img src={arrow} />,
      tick1: '',
      tick1Desc: '30 cases/year',
      desc: 'Resolve unplanned glitches and troubleshoot issues with fast access to our team of experts.',
    },
    {
      name: 'Upgrade Management & New Capabilities Showcase',
      arrow: <img src={arrow} />,
      tick1: <img src={bluetick} />,
      desc: 'Learn about new capabilities and have them tested and deployed without disrupting your workflows.',
    },
    {
      name: 'Solution Admin & Monitoring',
      arrow: <img src={arrow} />,
      tick1: <img src={bluetick} />,
      desc: 'Make sure your solution is always optimized with regular monitoring, user management, and scheduled system audits.',
    },
    {
      name: 'Data Backup & Recovery',
      arrow: <img src={arrow} />,
      tick1: <img src={bluetick} />,
      desc: 'Secure your data with ongoing back-up management and rapid recovery response in the event of data loss.',
    },
    {
      name: 'Monthly Support Reviews',
      arrow: <img src={arrow} />,
      tick1: <img src={bluetick} />,
      desc: 'Get the most out of your support service with monthly evaluation check-ins. ',
    },
    {
      name: 'Annual Solution Review ',
      arrow: <img src={arrow} />,
      tick1: <img src={bluetick} />,
      desc: 'Effectively scale your solution as your business needs grow with solution reviews by our consultants.',
    },
    {
      name: 'Training',
      arrow: <img src={arrow} />,
      tick1: '',
      tick1Desc: '4 sessions/year',
      desc: 'Train new team members on your solution and workflows or bring your current team up to speed on new features for quick adoption and maximum productivity. ',
    },
    {
      name: 'ClickLearn Training Content Portal',
      arrow: <img src={arrow} />,
      tick1: <img src={bluetick} />,
      tick1Desc: '*',
      desc: `Access a full library of interactive training content documentation, including video guides to maintain platform efficiency.`,
      additionalDesc: `*ClickLearn licensing and content management available for an additional fee`,
    },
    {
      name: 'Enhancement Planning Sessions ',
      arrow: <img src={arrow} />,
      tick1: '',
      desc: 'Work with our Hypercare support experts to discover and implement new process enhancements. ',
    },
  ];

  const hyperSupportExtend = [
    {
      name: 'HelpDesk Support ',
      arrow: <img src={arrow} />,
      tick1: '',
      tick1Desc: 'Unlimited cases',
      desc: 'Resolve unplanned glitches and troubleshoot issues with fast access to our team of experts.',
    },
    {
      name: 'Upgrade Management & New Capabilities Showcase',
      arrow: <img src={arrow} />,
      tick1: <img src={bluetick} />,
      desc: 'Learn about new capabilities and have them tested and deployed without disrupting your workflows.',
    },
    {
      name: 'Solution Admin & Monitoring',
      arrow: <img src={arrow} />,
      tick1: <img src={bluetick} />,
      desc: 'Make sure your solution is always optimized with regular monitoring, user management, and scheduled system audits.',
    },
    {
      name: 'Data Backup & Recovery',
      arrow: <img src={arrow} />,
      tick1: <img src={bluetick} />,
      desc: 'Secure your data with ongoing back-up management and rapid recovery response in the event of data loss.',
    },
    {
      name: 'Monthly Support Reviews',
      arrow: <img src={arrow} />,
      tick1: <img src={bluetick} />,
      desc: 'Get the most out of your support service with monthly evaluation check-ins. ',
    },
    {
      name: 'Annual Solution Review ',
      arrow: <img src={arrow} />,
      tick1: <img src={bluetick} />,
      desc: 'Effectively scale your solution as your business needs grow with solution reviews by our consultants.',
    },
    {
      name: 'Training',
      arrow: <img src={arrow} />,
      tick1: '',
      tick1Desc: '12 sessions/year',
      desc: 'Train new team members on your solution and workflows or bring your current team up to speed on new features for quick adoption and maximum productivity. ',
    },
    {
      name: 'ClickLearn Training Content Portal',
      arrow: <img src={arrow} />,
      tick1: '',
      tick1Desc: 'Training content updated twice annually',
      desc: `Access a full library of interactive training content documentation, including video guides to maintain platform efficiency.`,
      additionalDesc: `*ClickLearn licensing and content management available for an additional fee`,
    },
    {
      name: 'Enhancement Planning Sessions ',
      arrow: <img src={arrow} />,
      tick1: <img src={bluetick} />,
      desc: 'Work with our Hypercare support experts to discover and implement new process enhancements. ',
    },
  ];

  const packageHeading = [
    {
      name: 'Launch',
    },
    {
      name: 'Optimize',
    },
    {
      name: 'Extend',
    },
  ];

  const scrollToRef = (ref) => {
    if (isSmallMobile) {
      window.scrollTo(0, ref.current.offsetTop - 200);
    } else {
      window.scrollTo(0, ref.current.offsetTop - 100);
    }
  };

  useEffect(() => {
    if (state?.focus) {
      switch (state?.focus) {
        case 'supportRoute':
          scrollToRef(supportDivRef);
          break;

        default:
          break;
      }
    } else if (isSmallMobile) {
      scrollToRef(supportDivRef);
      // window.scrollToRef(0, ref.current.offsetTop - 120);
    } else {
      // window.scrollToRef(0, ref.current.offsetTop - 100);
      scrollToRef(supportDivRef);
    }
  }, [state?.focus]);

  useEffect(() => {
    if (selectPlan) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [selectPlan]);

  useEffect(() => {
    if (window.opener && window.opener !== window) {
      const code = getCodeFromWindowURL(window.location.href);
      // eslint-disable-next-line object-shorthand
      window.opener.postMessage({ type: 'code', code: code }, '*');
      window.close();
    }
    window.addEventListener('message', handlePostMessage);

    ReactGA.initialize('UA-74792081-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
    console.log(window.location.pathname, 'window.location.pathname');
    console.log(window.location.search, 'window.location.search');
  }, []);
  return (
    <div>
      <Backdrop sx={{ color: '#fff', zIndex: 35000 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Link to="navbar" spy smooth>
        <section
          style={{
            backgroundImage: `linear-gradient(90deg, #61C250, #4EC35D, #00C37C, #00C0A4, #00BBC7, #00B3DA, #00ACE0, #00A9E0)`,
            height: isMobile ? '80vh' : '100%',
          }}
        >
          <div
            style={{
              backgroundImage: `url(${BannerImage})`,
              height: '100%',
              backgroundPosition: 'top',
              backgroundRepeat: 'no-repeat',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {location.pathname === '/pricing' || location.pathname === '/product' ? (
              <img
                src={isMobile ? catapultLogo : exit}
                style={{
                  cursor: 'pointer',
                  height: isMobile ? '47px' : '68px',
                  width: isMobile ? '100%' : '230px',
                  zIndex: 999,
                  position: 'fixed',
                  right: isMobile ? '' : '0px',
                  top: '-5px',
                }}
                onClick={() => window.location.replace('https://www.catapulterp.com/')}
              />
            ) : (
              <img
                src={isMobile ? catapultLogo : exit}
                style={{
                  cursor: 'pointer',
                  height: isMobile ? '47px' : '68px',
                  width: isMobile ? '100%' : '230px',
                  zIndex: 999,
                  position: 'fixed',
                  right: isMobile ? '' : '0px',
                  top: '-5px',
                }}
                onClick={() => {
                  modalRef.current();
                  setCheck(true);
                }}
              />
            )}
            <Container
              fixed
              maxWidth="md"
              className="animate__animated animate__fadeIn"
              sx={{
                height: '450px',
              }}
            >
              <Grid
                item
                xs={12}
                sx={{
                  textAlign: 'center',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Stack
                  direction="column"
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    paddingTop: isMobile ? '47px' : '',
                  }}
                >
                  <Typography
                    variant="string"
                    gutterBottom
                    component="div"
                    sx={{
                      fontFamily: 'DinDIsplayProRegular',
                      fontStyle: 'italic',
                      color: '#FFFFFF',
                    }}
                  >
                    Pricing
                  </Typography>
                  <Typography
                    variant="h3"
                    gutterBottom
                    component="div"
                    className="font-face-prelo-black"
                    sx={{
                      fontFamily: 'PreloSlabW04Black',
                      color: '#FFFFFF',
                      fontSize: isMobile ? '33px' : '48px',
                      marginBottom: isMobile ? '40px' : '',
                    }}
                  >
                    Take the guesswork out of your ERP & CRM solution budgeting.
                  </Typography>
                  <Typography
                    variant="caption"
                    gutterBottom
                    component="div"
                    mb={5}
                    sx={{
                      fontFamily: 'PreloSlabW04Light',
                      color: '#FFFFFF',
                      fontSize: isMobile ? '18px' : '20px',
                      lineHeight: 1.5,
                      paddingX: isMobile ? 2 : 10,
                      marginBottom: isMobile ? '60px' : '40px',
                    }}
                  >
                    Customize and price your Dynamics 365 solution using our powerful online scoping
                    and budgeting tools.
                  </Typography>
                </Stack>
              </Grid>
            </Container>
          </div>
        </section>
      </Link>

      <div style={{ height: isMobile ? '' : '120px' }} id="navbar">
        <Navbar navbar={navbar} />
      </div>
      <div
        style={{
          backgroundColor: '#FFFFFF',
          marginBottom: isMobile ? '0px' : '100px',
          marginTop: isMobile ? '40px' : '',
        }}
        ref={supportDivRef}
      >
        <Container>
          {isMobile ? (
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{
                width: '100%',
                marginBottom: '20px',
              }}
            >
              <Typography
                variant="string"
                gutterBottom
                component="div"
                sx={{
                  fontFamily: 'PreloSlabW04SemiBold',
                  color: '#00313E',
                  fontSize: '22px',
                }}
              >
                Please choose a Support package <br /> for your Dynamics 365 solution
              </Typography>
            </Stack>
          ) : (
            ''
          )}
          <Grid container spacing={isMobile ? 0 : 3} justifyContent="start" md={12} mx={0}>
            {isMobile ? (
              <Grid
                item
                xs={12}
                sx={{
                  border: '1px solid #F4F4F4',
                  borderRadius: '5px',
                }}
              >
                <TabContext value={value}>
                  <Box
                    sx={{
                      borderBottom: 1,
                      borderColor: 'divider',
                      color: '#00313E',
                      display: 'flex',
                      justifyContent: 'center',
                      height: '60px',
                    }}
                  >
                    <TabList
                      TabIndicatorProps={{
                        style: {
                          backgroundColor: '#00313E',
                          fontSize: '16px',
                        },
                      }}
                      onChange={handleChange}
                      aria-label="lab API tabs example"
                    >
                      <Tab
                        label={
                          <span
                            style={{
                              fontSize: '16px',
                              fontFamily: 'PreloSlabW04Medium',
                              textTransform: 'none',
                            }}
                          >
                            Launch
                          </span>
                        }
                        value="1"
                      />
                      <Tab
                        label={
                          <span
                            style={{
                              fontSize: '16px',
                              fontFamily: 'PreloSlabW04Medium',
                              textTransform: 'none',
                            }}
                          >
                            Optimize
                          </span>
                        }
                        value="2"
                      />
                      <Tab
                        label={
                          <span
                            style={{
                              fontSize: '16px',
                              fontFamily: 'PreloSlabW04Medium',
                              textTransform: 'none',
                            }}
                          >
                            Extend
                          </span>
                        }
                        value="3"
                      />
                    </TabList>
                  </Box>
                  <TabPanel value="1">
                    {hyperSupportLaunch.map((item) => (
                      <Accordion
                        classes={{ root: classes.MuiAccordionroot }}
                        sx={{
                          width: '100%',
                        }}
                      >
                        <AccordionSummary
                          sx={{ padding: '0px' }}
                          aria-controls="panel2a-content"
                          id="panel2a-header"
                        >
                          <Grid item xs={8}>
                            <Typography
                              variant="body2"
                              component="div"
                              // my={1}
                              sx={{
                                fontFamily: 'PreloSlabW04Medium',
                                color: '#00313E',
                                fontSize: '15px',
                                width: '100%',
                                height: '40px',
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              {item.name}
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Typography
                              variant="body2"
                              component="div"
                              // my={1}
                              sx={{
                                fontFamily: 'DinDIsplayProBold',
                                color: '#00313E',
                                fontSize: '15px',
                                display: 'flex',
                                alignItems: 'center',
                                height: '50px',
                                justifyContent: 'center',
                              }}
                            >
                              {item.arrow}
                            </Typography>
                          </Grid>

                          <Grid item xs={2}>
                            {item?.tick1 && (
                              <Typography
                                variant="body2"
                                component="div"
                                // my={1}
                                sx={{
                                  fontFamily: 'DinDIsplayProBold',
                                  color: '#00313E',
                                  fontSize: '12px',
                                  display: 'flex',
                                  alignItems: 'center',
                                  height: '50px',
                                  justifyContent: 'center',
                                }}
                              >
                                {item.tick1}
                              </Typography>
                            )}
                            {item?.tick1Desc && (
                              <Typography
                                variant="body2"
                                component="div"
                                // my={1}
                                sx={{
                                  fontFamily: 'DinDIsplayProBold',
                                  color: '#00313E',
                                  fontSize: '15px',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  height: '50px',
                                  textAlign: 'center',
                                }}
                              >
                                {item?.tick1Desc}
                              </Typography>
                            )}
                          </Grid>
                        </AccordionSummary>
                        <AccordionDetails
                          classes={{ root: classes.MuiAccordionDetails }}
                          sx={{
                            fontFamily: 'DinDIsplayProRegular',
                            color: '#00313E',
                            fontSize: '15px',
                            padding: '0px',
                            width: '100%',
                          }}
                        >
                          {item.desc}
                          {item?.additionalDesc && (
                            <Typography
                              variant="string"
                              gutterBottom
                              component="div"
                              sx={{
                                fontFamily: 'DinDIsplayProRegular',
                                color: '#747678',
                                fontSize: '13px',
                              }}
                            >
                              {item?.additionalDesc}
                            </Typography>
                          )}
                        </AccordionDetails>
                      </Accordion>
                    ))}
                    <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Grid item xs={12}>
                        <Stack
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                          sx={{
                            borderTop: '1px solid #F4F4F4',
                            borderBottom: '0px',
                            height: '80px',
                            width: '100%',
                          }}
                        >
                          <Typography
                            variant="string"
                            gutterBottom
                            component="div"
                            sx={{
                              fontFamily: 'DinDIsplayProRegular',
                              color: '#747678',
                              fontSize: '12px',
                            }}
                          >
                            Custom support packages available. Talk to your advisor.
                          </Typography>
                        </Stack>
                      </Grid>

                      <Grid item xs={12}>
                        <Stack
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                          sx={{
                            height: '80px',
                            width: '100%',
                          }}
                        >
                          <Button
                            onMouseEnter={() => {
                              setHoveredPlan(1);
                            }}
                            onMouseLeave={() => {
                              setHoveredPlan('');
                            }}
                            variant="primary"
                            sx={{
                              borderRadius: '15px',
                              border: '1px solid #CFCFCF',
                              height: '50px',
                              width: '140px',
                              fontFamily: 'DinDIsplayProBold',
                              color: selectPlan === 1 || hoveredPlan === 1 ? '#FFFFFF' : '#00313E',
                              background: selectPlan === 1 ? '#00A8E1' : '',
                              '&:hover': {
                                background: selectPlan === 1 ? '#00A8E1' : '#34B233',
                                color: '#FFFFFF',
                              },
                            }}
                            value={selectPlan?.value}
                            onClick={() => {
                              setSelectPlan(1);
                            }}
                          >
                            {selectPlan === 1 ? 'Selected' : 'Choose Plan'}
                          </Button>
                        </Stack>
                      </Grid>
                    </Grid>
                  </TabPanel>
                  <TabPanel value="2">
                    {hyperSupportOptimize.map((item) => (
                      <Accordion
                        classes={{ root: classes.MuiAccordionroot }}
                        sx={{ width: '100%' }}
                      >
                        <AccordionSummary
                          sx={{ padding: '0px' }}
                          aria-controls="panel2a-content"
                          id="panel2a-header"
                        >
                          <Grid item xs={8}>
                            <Typography
                              variant="body2"
                              component="div"
                              sx={{
                                fontFamily: 'PreloSlabW04Medium',
                                color: '#00313E',
                                fontSize: '15px',
                                width: '100%',
                                height: '40px',
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              {item.name}
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Typography
                              variant="body2"
                              component="div"
                              // my={1}
                              sx={{
                                fontFamily: 'DinDIsplayProBold',
                                color: '#00313E',
                                fontSize: '15px',
                                display: 'flex',
                                alignItems: 'center',
                                height: '50px',
                                justifyContent: 'center',
                              }}
                            >
                              {item.arrow}
                            </Typography>
                          </Grid>

                          <Grid item xs={2}>
                            {item.tick1 && (
                              <Typography
                                variant="body2"
                                component="div"
                                // my={1}
                                sx={{
                                  fontFamily: 'DinDIsplayProBold',
                                  color: '#00313E',
                                  fontSize: '12px',
                                  display: 'flex',
                                  alignItems: 'center',
                                  height: '50px',
                                  justifyContent: 'center',
                                  textAlign: 'center',
                                }}
                              >
                                {item.tick1}
                                {item?.tick1Desc && item?.tick1Desc === '*' && (
                                  <span style={{ marginLeft: '5px' }}>*</span>
                                )}
                              </Typography>
                            )}
                            {item?.tick1Desc && item?.tick1Desc !== '*' && (
                              <Typography
                                variant="body2"
                                component="div"
                                // my={1}
                                sx={{
                                  fontFamily: 'DinDIsplayProBold',
                                  color: '#00313E',
                                  fontSize: '15px',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  textAlign: 'center',
                                }}
                              >
                                {item?.tick1Desc}
                              </Typography>
                            )}
                          </Grid>
                        </AccordionSummary>
                        <AccordionDetails
                          classes={{ root: classes.MuiAccordionDetails }}
                          sx={{
                            fontFamily: 'DinDIsplayProRegular',
                            color: '#00313E',
                            fontSize: '15px',
                            padding: '0px',
                            width: '100%',
                          }}
                        >
                          {item.desc}
                          {item?.additionalDesc && (
                            <Typography
                              variant="string"
                              gutterBottom
                              component="div"
                              sx={{
                                fontFamily: 'DinDIsplayProRegular',
                                color: '#747678',
                                fontSize: '13px',
                              }}
                            >
                              {item?.additionalDesc}
                            </Typography>
                          )}
                        </AccordionDetails>
                      </Accordion>
                    ))}
                    <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Grid item xs={12}>
                        <Stack
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                          sx={{
                            borderTop: '1px solid #F4F4F4',
                            borderBottom: '0px',
                            height: '80px',
                            width: '100%',
                          }}
                        >
                          <Typography
                            variant="string"
                            gutterBottom
                            component="div"
                            sx={{
                              fontFamily: 'DinDIsplayProRegular',
                              color: '#747678',
                              fontSize: '12px',
                            }}
                          >
                            Custom support packages available. Talk to your advisor.
                          </Typography>
                        </Stack>
                      </Grid>

                      <Grid item xs={12}>
                        <Stack
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                          sx={{
                            borderTop: '1px solid #F4F4F4',
                            // borderBottom: "1px solid #F4F4F4",
                            height: '80px',
                            width: '100%',
                          }}
                        >
                          <Button
                            onMouseEnter={() => {
                              setHoveredPlan(2);
                            }}
                            onMouseLeave={() => {
                              setHoveredPlan('');
                            }}
                            onClick={() => setSelectPlan(2)}
                            variant="primary"
                            sx={{
                              borderRadius: '15px',
                              border: '1px solid #CFCFCF',
                              height: '50px',
                              width: '140px',
                              fontFamily: 'DinDIsplayProBold',
                              color: selectPlan === 2 || hoveredPlan === 2 ? '#FFFFFF' : '#00313E',
                              background: selectPlan === 2 ? '#00A8E1' : '',
                              '&:hover': {
                                background: selectPlan === 2 ? '#00A8E1' : '#34B233',
                                color: '#FFFFFF',
                              },
                            }}
                          >
                            {selectPlan === 2 ? 'Selected' : 'Choose Plan'}
                          </Button>
                        </Stack>
                      </Grid>
                    </Grid>
                  </TabPanel>
                  <TabPanel value="3">
                    {hyperSupportExtend.map((item) => (
                      <Accordion
                        classes={{ root: classes.MuiAccordionroot }}
                        sx={{ width: '100%' }}
                      >
                        <AccordionSummary
                          sx={{ padding: '0px' }}
                          aria-controls="panel2a-content"
                          id="panel2a-header"
                        >
                          <Grid item xs={8}>
                            <Typography
                              variant="body2"
                              component="div"
                              // my={1}
                              sx={{
                                fontFamily: 'PreloSlabW04Medium',
                                color: '#00313E',
                                fontSize: '15px',
                                width: '100%',
                                height: '40px',
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              {item.name}
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Typography
                              variant="body2"
                              component="div"
                              // my={1}
                              sx={{
                                fontFamily: 'DinDIsplayProBold',
                                color: '#00313E',
                                fontSize: '15px',
                                display: 'flex',
                                alignItems: 'center',
                                height: '50px',
                                justifyContent: 'center',
                              }}
                            >
                              {item.arrow}
                            </Typography>
                          </Grid>

                          <Grid item xs={2}>
                            {item.tick1 && (
                              <Typography
                                variant="body2"
                                component="div"
                                // my={1}
                                sx={{
                                  fontFamily: 'DinDIsplayProBold',
                                  color: '#00313E',
                                  fontSize: '12px',
                                  display: 'flex',
                                  alignItems: 'center',
                                  height: '50px',
                                  justifyContent: 'center',
                                  textAlign: 'center',
                                }}
                              >
                                {item.tick1}
                              </Typography>
                            )}
                            {item?.tick1Desc && (
                              <Typography
                                variant="body2"
                                component="div"
                                // my={1}
                                sx={{
                                  fontFamily: 'DinDIsplayProBold',
                                  color: '#00313E',
                                  fontSize: '15px',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  textAlign: 'center',
                                }}
                              >
                                {item?.tick1Desc}
                              </Typography>
                            )}
                          </Grid>
                        </AccordionSummary>
                        <AccordionDetails
                          classes={{ root: classes.MuiAccordionDetails }}
                          sx={{
                            fontFamily: 'DinDIsplayProRegular',
                            color: '#00313E',
                            fontSize: '15px',
                            padding: '0px',
                            width: '100%',
                          }}
                        >
                          {item.desc}
                          {item?.additionalDesc && (
                            <Typography
                              variant="string"
                              gutterBottom
                              component="div"
                              sx={{
                                fontFamily: 'DinDIsplayProRegular',
                                color: '#747678',
                                fontSize: '13px',
                              }}
                            >
                              {item?.additionalDesc}
                            </Typography>
                          )}
                        </AccordionDetails>
                      </Accordion>
                    ))}
                    <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Grid item xs={12}>
                        <Stack
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                          sx={{
                            borderTop: '1px solid #F4F4F4',
                            borderBottom: '0px',
                            height: '80px',
                            width: '100%',
                          }}
                        >
                          <Typography
                            variant="string"
                            gutterBottom
                            component="div"
                            sx={{
                              fontFamily: 'DinDIsplayProRegular',
                              color: '#747678',
                              fontSize: '12px',
                            }}
                          >
                            Custom support packages available. Talk to your advisor.
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={12}>
                        <Stack
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                          sx={{
                            borderTop: '1px solid #F4F4F4',
                            // borderBottom: "1px solid #F4F4F4",
                            height: '80px',
                            width: '100%',
                          }}
                        >
                          <Button
                            variant="primary"
                            onMouseEnter={() => {
                              setHoveredPlan(3);
                            }}
                            onMouseLeave={() => {
                              setHoveredPlan('');
                            }}
                            onClick={() => setSelectPlan(3)}
                            className="btn-choose-plan"
                            sx={{
                              borderRadius: '15px',
                              border: '1px solid #CFCFCF',
                              height: '50px',
                              width: '140px',
                              fontFamily: 'DinDIsplayProBold',
                              color: selectPlan === 3 || hoveredPlan === 3 ? '#FFFFFF' : '#00313E',
                              background: selectPlan === 3 ? '#00A8E1' : '',
                              '&:hover': {
                                background: selectPlan === 3 ? '#00A8E1' : '#34B233',
                                color: '#FFFFFF',
                              },
                            }}
                          >
                            {selectPlan === 3 ? 'Selected' : 'Choose Plan'}
                          </Button>
                        </Stack>
                      </Grid>
                    </Grid>
                  </TabPanel>
                </TabContext>
              </Grid>
            ) : (
              <div style={{ display: 'flex', width: '100%' }}>
                <Grid item xs={12}>
                  <Grid item xs={12} sx={{ display: 'flex' }}>
                    <Stack
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      sx={{
                        // borderTop: "1px solid #F4F4F4",
                        // borderBottom: "0px",
                        height: '101px',
                        width: '100%',
                        paddingLeft: '16px',
                      }}
                    >
                      <Typography
                        variant="string"
                        gutterBottom
                        component="div"
                        sx={{
                          fontFamily: 'PreloSlabW04SemiBold',
                          color: '#00313E',
                          fontSize: '24px',
                        }}
                      >
                        Please choose a Support package <br /> for your Dynamics 365 solution
                        {/* Choose a Hypercare
                        <br /> support package */}
                      </Typography>
                    </Stack>
                    {packageHeading.map((item) => (
                      <Grid item xs={4}>
                        <Stack
                          direction="row"
                          justifyContent="center"
                          alignItems="flex-end"
                          sx={{
                            // borderTop: "1px solid #F4F4F4",
                            borderBottom: '0px',
                            height: '100px',
                            width: '100%',
                          }}
                        >
                          <Typography
                            variant="string"
                            gutterBottom
                            component="div"
                            sx={{
                              fontFamily: 'PreloSlabW04Medium',
                              color: '#00313E',
                              fontSize: '20px',
                              paddingBottom: '10px',
                            }}
                          >
                            {item.name}
                          </Typography>
                        </Stack>
                      </Grid>
                    ))}
                  </Grid>
                  {hyperSupport.map((item) => (
                    <Accordion classes={{ root: classes.MuiAccordionroot }} sx={{ width: '100%' }}>
                      <AccordionSummary aria-controls="panel2a-content" id="panel2a-header">
                        <Grid item xs={4}>
                          <Typography
                            variant="body2"
                            component="div"
                            // my={1}
                            sx={{
                              fontFamily: 'PreloSlabW04Medium',
                              color: '#00313E',
                              fontSize: '15px',
                              width: '100%',
                              height: '40px',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            {item.name}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography
                            variant="body2"
                            component="div"
                            // my={1}
                            sx={{
                              fontFamily: 'DinDIsplayProBold',
                              color: '#00313E',
                              fontSize: '15px',
                              display: 'flex',
                              alignItems: 'center',
                              height: '50px',
                              justifyContent: 'center',
                            }}
                          >
                            {item.arrow}
                          </Typography>
                        </Grid>

                        <Grid item xs={2}>
                          {item?.tick1 && (
                            <Typography
                              variant="body2"
                              component="div"
                              // my={1}
                              sx={{
                                fontFamily: 'DinDIsplayProBold',
                                color: '#00313E',
                                fontSize: '15px',
                                display: 'flex',
                                alignItems: 'center',
                                height: '50px',
                                justifyContent: 'center',
                              }}
                            >
                              {item.tick1}
                            </Typography>
                          )}
                          {item?.tick1Desc && (
                            <Typography
                              variant="body2"
                              component="div"
                              // my={1}
                              sx={{
                                fontFamily: 'DinDIsplayProBold',
                                color: '#00313E',
                                fontSize: '12px',
                                display: 'flex',
                                alignItems: 'center',
                                height: '50px',
                                justifyContent: 'center',
                              }}
                            >
                              {item?.tick1Desc}
                            </Typography>
                          )}
                        </Grid>
                        <Grid item xs={2}>
                          {item?.tick2 && (
                            <Typography
                              variant="body2"
                              component="div"
                              // my={1}
                              sx={{
                                fontFamily: 'DinDIsplayProBold',
                                color: '#00313E',
                                fontSize: '15px',
                                display: 'flex',
                                alignItems: 'center',
                                height: '50px',
                                justifyContent: 'center',
                              }}
                            >
                              {item.tick2}{' '}
                              {item?.tick2Desc && item?.tick2Desc === '*' && (
                                <span style={{ marginLeft: '5px' }}>*</span>
                              )}
                            </Typography>
                          )}
                          {item?.tick2Desc && item?.tick2Desc !== '*' && (
                            <Typography
                              variant="body2"
                              component="div"
                              // my={1}
                              sx={{
                                fontFamily: 'DinDIsplayProBold',
                                color: '#00313E',
                                fontSize: '12px',
                                display: 'flex',
                                alignItems: 'center',
                                height: '50px',
                                justifyContent: 'center',
                              }}
                            >
                              {item?.tick2Desc}
                            </Typography>
                          )}
                        </Grid>
                        <Grid item xs={2}>
                          {item?.tick3 && (
                            <Typography
                              variant="body2"
                              component="div"
                              // my={1}
                              sx={{
                                fontFamily: 'DinDIsplayProBold',
                                color: '#00313E',
                                fontSize: '15px',
                                display: 'flex',
                                alignItems: 'center',
                                height: '50px',
                                justifyContent: 'center',
                              }}
                            >
                              {item.tick3}
                            </Typography>
                          )}
                          {item?.tick3Desc && (
                            <Typography
                              variant="body2"
                              component="div"
                              // my={1}
                              sx={{
                                fontFamily: 'DinDIsplayProBold',
                                color: '#00313E',
                                fontSize: '12px',
                                display: 'flex',
                                alignItems: 'center',
                                height: '50px',
                                justifyContent: 'center',
                                textAlign: 'center',
                              }}
                            >
                              {item?.tick3Desc}
                            </Typography>
                          )}
                        </Grid>
                      </AccordionSummary>
                      <AccordionDetails
                        classes={{ root: classes.MuiAccordionDetails }}
                        sx={{
                          fontFamily: 'DinDIsplayProRegular',
                          color: '#00313E',
                          fontSize: '15px',
                          // paddingLeft: "25px",
                          paddingRight: '16px',
                          paddingTop: '0px',
                          paddingBottom: '0px',
                          width: '415px',
                          whiteSpace: 'pre-line',
                        }}
                      >
                        {item.desc}
                        {item?.additionalDesc && (
                          <Typography
                            variant="string"
                            gutterBottom
                            component="div"
                            sx={{
                              fontFamily: 'DinDIsplayProRegular',
                              color: '#747678',
                              fontSize: '13px',
                            }}
                          >
                            {item?.additionalDesc}
                          </Typography>
                        )}
                      </AccordionDetails>
                    </Accordion>
                  ))}

                  <Grid item xs={12} sx={{ display: 'flex' }}>
                    <Grid item xs={6}>
                      <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        sx={{
                          borderTop: '1px solid #F4F4F4',
                          borderBottom: '0px',
                          height: '80px',
                          width: '100%',
                          paddingLeft: '16px',
                        }}
                      >
                        <Typography
                          variant="string"
                          gutterBottom
                          component="div"
                          sx={{
                            fontFamily: 'DinDIsplayProRegular',
                            color: '#747678',
                            fontSize: '13px',
                          }}
                        >
                          Custom support packages available. Talk to your advisor.
                        </Typography>
                      </Stack>
                    </Grid>

                    <Grid item xs={2}>
                      <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                          borderTop: '1px solid #F4F4F4',
                          // borderBottom: "1px solid #F4F4F4",
                          height: '80px',
                          width: '100%',
                        }}
                      >
                        <Button
                          onMouseEnter={() => {
                            setHoveredPlan(1);
                          }}
                          onMouseLeave={() => {
                            setHoveredPlan('');
                          }}
                          variant="primary"
                          sx={{
                            borderRadius: '15px',
                            border: '1px solid #CFCFCF',
                            height: '50px',
                            width: '140px',
                            margin: '10px',
                            fontFamily: 'DinDIsplayProBold',
                            color: selectPlan === 1 || hoveredPlan === 1 ? '#FFFFFF' : '#00313E',
                            background: selectPlan === 1 ? '#00A8E1' : '',
                            '&:hover': {
                              background: selectPlan === 1 ? '#00A8E1' : '#34B233',
                              color: '#FFFFFF',
                            },
                          }}
                          value={selectPlan?.value}
                          onClick={() => {
                            setSelectPlan(1);
                          }}
                        >
                          {selectPlan === 1 ? 'Selected' : 'Choose Plan'}
                        </Button>
                      </Stack>
                    </Grid>
                    <Grid item xs={2}>
                      <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                          borderTop: '1px solid #F4F4F4',
                          // borderBottom: "1px solid #F4F4F4",
                          height: '80px',
                          width: '100%',
                        }}
                      >
                        <Button
                          onMouseEnter={() => {
                            setHoveredPlan(2);
                          }}
                          onMouseLeave={() => {
                            setHoveredPlan('');
                          }}
                          onClick={() => setSelectPlan(2)}
                          variant="primary"
                          sx={{
                            borderRadius: '15px',
                            border: '1px solid #CFCFCF',
                            height: '50px',
                            width: '140px',
                            margin: '10px',
                            fontFamily: 'DinDIsplayProBold',
                            color: selectPlan === 2 || hoveredPlan === 2 ? '#FFFFFF' : '#00313E',
                            background: selectPlan === 2 ? '#00A8E1' : '',
                            '&:hover': {
                              background: selectPlan === 2 ? '#00A8E1' : '#34B233',
                              color: '#FFFFFF',
                            },
                          }}
                        >
                          {selectPlan === 2 ? 'Selected' : 'Choose Plan'}
                        </Button>
                      </Stack>
                    </Grid>
                    <Grid item xs={2}>
                      <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                          borderTop: '1px solid #F4F4F4',
                          height: '80px',
                          width: '100%',
                        }}
                      >
                        <Button
                          variant="primary"
                          onMouseEnter={() => {
                            setHoveredPlan(3);
                          }}
                          onMouseLeave={() => {
                            setHoveredPlan('');
                          }}
                          onClick={() => setSelectPlan(3)}
                          className="btn-choose-plan"
                          sx={{
                            borderRadius: '15px',
                            border: '1px solid #CFCFCF',
                            height: '50px',
                            width: '140px',
                            margin: '10px',
                            fontFamily: 'DinDIsplayProBold',
                            color: selectPlan === 3 || hoveredPlan === 3 ? '#FFFFFF' : '#00313E',
                            background: selectPlan === 3 ? '#00A8E1' : '',
                            '&:hover': {
                              background: selectPlan === 3 ? '#00A8E1' : '#34B233',
                              color: '#FFFFFF',
                            },
                          }}
                        >
                          {selectPlan === 3 ? 'Selected' : 'Choose Plan'}
                        </Button>
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            )}
            {/* -------------------------------------END------------ */}
          </Grid>
        </Container>
      </div>

      {state?.focus ? (
        <Grid
          item
          xs={12}
          mt={2}
          mb={isMobile ? 2 : 0}
          // md={3}
          sx={{
            display: 'flex',
            justifyContent: isMobile ? 'center' : 'flex-end',
            alignItems: 'center',
            position: isMobile || isIpad ? '' : 'fixed',
            right: '25px',
            bottom: '25px',
            width: '100%',
            height: isMobile ? '110px' : '',
            paddingLeft: 0,
            zIndex: isMobile ? 0 : -1000,
          }}
        >
          <Button
            variant="primary"
            sx={{
              borderRadius: 1,
              border: '1px solid #CFCFCF',
              height: '60px',
              width: '180px',
              marginRight: '15px',
              marginBottom: isMobile || isIpad ? '' : '50px',
            }}
            onClick={handleCancel}
          >
            <Typography variant="button" sx={{ fontFamily: 'DinDIsplayProBold', color: '#00313E' }}>
              Cancel
            </Typography>
          </Button>

          <Button
            variant="contained"
            color="neutral"
            disabled={!show}
            sx={{
              borderRadius: 1,
              backgroundColor: '#00313E',
              height: '60px',
              width: '130px',
              marginBottom: isMobile || isIpad ? '' : '50px',
            }}
            onClick={() => handleSave()}
          >
            <Typography variant="button" sx={{ fontFamily: 'DinDIsplayProBold' }}>
              Save
            </Typography>
          </Button>
        </Grid>
      ) : (
        <Grid
          item
          xs={12}
          mt={2}
          mb={isMobile ? 2 : 0}
          // md={3}
          sx={{
            display: 'flex',
            justifyContent: isMobile ? 'center' : 'flex-end',
            alignItems: 'center',
            position: isMobile || isIpad ? '' : 'fixed',
            right: '25px',
            bottom: '25px',
            width: '100%',
            height: isMobile ? '110px' : '',
            paddingLeft: 0,
            zIndex: isMobile ? 0 : -1000,
          }}
        >
          <Button
            variant="primary"
            sx={{
              borderRadius: 1,
              border: '1px solid #CFCFCF',
              height: '60px',
              width: '180px',
              marginRight: '15px',
              marginBottom: isMobile || isIpad ? '' : '50px',
            }}
            onClick={() => {
              modalRef.current();
            }}
          >
            <Typography variant="button" sx={{ fontFamily: 'DinDIsplayProBold', color: '#00313E' }}>
              Save for later
            </Typography>
          </Button>

          <Button
            variant="contained"
            color="neutral"
            disabled={!show}
            sx={{
              borderRadius: 1,
              backgroundColor: '#00313E',
              height: '60px',
              width: '130px',
              marginBottom: isMobile || isIpad ? '' : '50px',
            }}
            onClick={() => handleNext()}
          >
            <Typography variant="button" sx={{ fontFamily: 'DinDIsplayProBold' }}>
              Next
            </Typography>
          </Button>
        </Grid>
      )}

      {/* Start modal------------------------------------------------------------------------------------ */}
      <Modal open={open}>
        <Box
          sx={{
            overflowY: 'scroll',
            position: 'absolute',
            top: '50%',
            left: '50%',
            overflow: 'auto',
            transform: 'translate(-50%, -50%)',
            // width: isMobile ? "80%" : '450px',
            // height: isMobile ? '65%' : '88%',
            width: isMobile || isLandscape ? '326px' : '450px',
            height: isMobile || isLandscape ? '65%' : '562px',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}
        >
          <CloseIcon sx={icon} onClick={() => handleClose()} />
          <Typography
            sx={{
              fontFamily: 'PreloSlabW04Bold',
              color: '#00313E',
              fontSize: '26px',
              textAlign: 'center',
            }}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            Contact Info
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{
              mt: 1,
              fontFamily: 'PreloSlabW04Light',
              color: '#00313E',
              fontSize: '15px',
              textAlign: 'center',
            }}
          >
            Please complete your profile using one of the options below and receive your custom
            subscription quote right away.
          </Typography>

          <Button
            variant="primary"
            onClick={showPopup}
            startIcon={<LinkedInIcon sx={{ color: '#0A66C2' }} />}
            sx={{
              mt: 1,
              mb: 1,
              borderRadius: 1,
              backgroundColor: '#F3F7FC',
              padding: '15px 30px',
              width: '100%',
              color: '#00313E',
            }}
          >
            <Typography
              variant="button"
              sx={{ fontFamily: 'DinDIsplayProBold', fontSize: '15px', textTransform: 'none' }}
            >
              Linkedin Sign In
            </Typography>
          </Button>
          <Grid item sx={{ textAlign: 'center' }}>
            <Typography
              variant="body"
              sx={{
                // mt: 5,
                fontFamily: 'PreloSlabW04Light',
                color: '#00313E',
                textTransform: 'none',
                fontSize: '18px',
              }}
            >
              or
            </Typography>
          </Grid>

          <Grid Container display="flex" mt={1}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              innerRef={formRef}
              onSubmit={addProfileInfo}
            >
              {({ values, handleChange, handleSubmit, errors, touched }) => (
                <div className={classes.root}>
                  <Grid
                    item
                    sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}
                  >
                    <CssTextField
                      className={classes.margin}
                      sx={{ width: '48%' }}
                      variant="outlined"
                      id="custom-css-outlined-input"
                      placeholder="First Name"
                      error={touched.firstName && errors.firstName}
                      defaultValue={values.firstName}
                      value={values.firstName}
                      onChange={handleChange('firstName')}
                      helperText={touched.firstName && errors.firstName}
                    />
                    <CssTextField
                      className={classes.margin}
                      sx={{ width: '48%' }}
                      variant="outlined"
                      id="custom-css-outlined-input"
                      placeholder="Last Name"
                      error={touched.lastName && errors.lastName}
                      defaultValue={values.lastName}
                      value={values.lastName}
                      onChange={handleChange('lastName')}
                      helperText={touched.lastName && errors.lastName}
                    />
                  </Grid>
                  {/* <Grid item>

                  </Grid> */}

                  <Grid item mt={3} sx={{ width: '100%' }}>
                    <CssTextField
                      className={classes.margin}
                      variant="outlined"
                      fullWidth
                      id="custom-css-outlined-input"
                      placeholder="Work Email Address"
                      error={touched.email && errors.email}
                      defaultValue={values.email}
                      value={values.email}
                      onChange={handleChange('email')}
                      helperText={touched.email && errors.email}
                    />
                  </Grid>

                  <Grid
                    item
                    mt={3}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '100%',
                      height: '48px',
                      fontFamily: 'DinDIsplayProRegular',
                    }}
                  >
                    <Select
                      labelId="demo-customized-select-label"
                      id="demo-customized-select"
                      value={values.countryCode}
                      onChange={handleChange('countryCode')}
                      options={CallingCode}
                      sx={{ width: '20%' }}
                      input={<BootstrapInput />}
                      MenuProps={MenuProps}
                    >
                      {CallingCode.map((item) => (
                        <MenuItem key={item.dialCode} value={item.dialCode}>
                          {item.dialCode}
                        </MenuItem>
                      ))}
                    </Select>

                    <CssTextField
                      sx={{ width: '75%' }}
                      className={classes.margin}
                      // type="number"
                      variant="outlined"
                      fullWidth
                      id="custom-css-outlined-input"
                      placeholder="Phone Number"
                      error={touched.phoneNo && errors.phoneNo}
                      defaultValue={values.phoneNo}
                      value={values.phoneNo}
                      onChange={handleChange('phoneNo')}
                      helperText={touched.phoneNo && errors.phoneNo}
                      inputProps={{ maxLength: 12 }}
                    />
                  </Grid>
                  <Grid item mt={3} sx={{ width: '100%' }}>
                    <CssTextField
                      className={classes.margin}
                      variant="outlined"
                      fullWidth
                      id="custom-css-outlined-input"
                      placeholder="Company Name"
                      error={touched.companyName && errors.companyName}
                      defaultValue={values.companyName}
                      value={values.companyName}
                      onChange={handleChange('companyName')}
                      helperText={touched.companyName && errors.companyName}
                    />
                  </Grid>

                  <Grid item mt={2} sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <>
                      <Checkbox
                        size="small"
                        style={{ margin: 0, padding: 0 }}
                        onChange={(e) => handleChangeDemo(e.target.value)}
                        checked={isChecked}
                        icon={<img src={iconlight} />}
                        checkedIcon={<img src={icondark} />}
                      />
                      <Typography
                        variant="body"
                        sx={{
                          fontFamily: 'PreloSlabW04Light',
                          color: '#00313E',
                          textTransform: 'none',
                          fontSize: isMobile ? '11px' : '14px',
                          textAlign: isMobile ? 'left' : 'center',
                          marginLeft: '5px',
                        }}
                      >
                        Please follow up with me to arrange a tailored solution demo.
                      </Typography>

                      {isMobile || isIpad ? (
                        <CustomTooltip
                          placement="top-end"
                          TransitionComponent={Fade}
                          TransitionProps={{ timeout: 1000 }}
                          open={openToolTip}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          title="We need this information to ensure that the quote is shared safely and securely with you."
                        >
                          <IconButton>
                            <img src={infoicon} onClick={handleTooltipOpen} />
                          </IconButton>
                        </CustomTooltip>
                      ) : (
                        <CustomTooltip
                          placement="top-end"
                          TransitionComponent={Fade}
                          TransitionProps={{ timeout: 1000 }}
                          title="We need this information to ensure that the quote is shared safely and securely with you."
                        >
                          <IconButton>
                            <img src={infoicon} />
                          </IconButton>
                        </CustomTooltip>
                      )}
                    </>
                  </Grid>

                  <Button
                    variant="contained"
                    color="neutral"
                    sx={{
                      borderRadius: 1,
                      backgroundColor: '#00313E',
                      width: '100%',
                      mt: 2,
                      mb: 1,
                      padding: '15px 30px',
                    }}
                    onClick={handleSubmit}
                  >
                    <Typography
                      variant="button"
                      sx={{
                        fontFamily: 'DinDIsplayProBold',
                        fontSize: '15px',
                        textTransform: 'none',
                      }}
                    >
                      Get Pricing
                    </Typography>
                  </Button>
                </div>
              )}
            </Formik>
          </Grid>
        </Box>
      </Modal>
      {/* End modal */}
      <SaveForLater check={check} modalRef={modalRef} selectPlan={selectPlan} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    stepsStatus: state?.home?.stepsStatus,
    profileInfo: state?.profile?.profile,
    selectedPackage: state?.saveSupportPackage,
    code: state?.pricingCode?.code,
    saveForLaterCode: state?.saveLater?.code,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveSupport: (payload) => dispatch(saveSupport(payload)),
    pricingCode: (payload) => dispatch(pricingCode(payload)),
    saveGeoLocation: (payload) => dispatch(saveGeoLocation(payload)),
    setStepsStatus: (payload) => dispatch(setStepsStatus(payload)),
    saveProfileInfo: (payload) => dispatch(saveProfileInfo(payload)),
    saveSupportPackage: (payload) => dispatch(saveSupportPackage(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Support);
