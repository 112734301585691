/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useMediaQuery } from 'react-responsive';
import InputBase from "@mui/material/InputBase";
import { styled } from '@mui/system';
import { Container, InputAdornment, Stack, Snackbar, Alert, Backdrop, CircularProgress } from '@mui/material';
import publicIp from 'public-ip';
import 'animate.css';
import axios from 'axios';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { makeStyles } from '@mui/styles';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import BannerImage from '../assets/logo-overlay.png';
import MobileBanner from '../assets/mobile-banner.png'
import exit from '../assets/exit.svg'
import catapultLogo from '../assets/catapult-mobile.svg'
import saveGeoLocation from '../redux/geoLocation/geoLocation.actions';


// redux
import {
  increaseCounter,
  decreaseCounter,
  setQuoteType,
  setStepsStatus,
  setSubStepStatus
} from '../redux/home/home.actions';
import { setSelectedProducts } from '../redux/product/product.actions';
import saveBusinessCentral from '../redux/businessCentral/businessCentral.actions';
import saveSupport from '../redux/support/support.actions';
import saveSales from '../redux/sales/sales.actions'
import saveLater from '../redux/saveForLater/saveForLater.actions';
import saveCustomerService from '../redux/customerService/customerService.actions';
import saveMarketing from '../redux/marketing/marketing.actions';
import saveEndUsers from '../redux/endUser/endUsers.actions';
import saveSupportPackage from '../redux/supportPackage/supportPackage.actions';
import saveProfileInfo from '../redux/profile/profile.actions'
import { RESET_PRODUCT } from '../redux/product/product.type';
import { RESET_SAVEBUSINESSCENTRAL } from '../redux/businessCentral/businessCentral.types';
import { RESET_SAVECUSTOMERSERVICE } from '../redux/customerService/customerService.types';
import { RESET_SAVESALES } from '../redux/sales/sales.types';
import { RESET_SAVEMARKETING } from '../redux/marketing/marketing.types';
import { RESET_SAVEENDUSERS } from '../redux/endUser/endUsers.types';
import { RESET_SAVESUPPORT } from '../redux/support/support.types';
import { RESET_SET_PROFILE } from '../redux/profile/profile.type';
import { RESET_SAVELATER } from '../redux/saveForLater/saveForLater.types';
import { RESET_PRICINGCODE } from '../redux/pricingCode/pricingCode.types';
import { RESET_SAVESUPPORTPACKAGE } from '../redux/supportPackage/supportPackage.types';
import { RESET_SAVEGEOLOCATION } from '../redux/geoLocation/geoLocation.types';
import { RESET_SAVEREQUESTDEMO } from '../redux/requestDemo/requestDemo.types';
import { RESET_STEPSOFMODULES, RESET_UPDATE_SUB_STEPS_STATUS } from '../redux/home/home.types';
// import { modifyProductsArray } from '../helpers';

const useStyles = makeStyles({
  btnSeconday: {
    border: '1px solid #FFFFFF !important',
  },
});

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),

  },
  "& .MuiInputBase-input": {
    color: "white",
    borderRadius: 4,
    position: "relative",
    backgroundColor: "none",
    border: "1px solid #ced4da",
    fontSize: 14,
    height: "45px",
    width: "253px",
    textAlign: "center",

    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow"
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "DinDIsplayProBold",
    ].join(","),

  }
}));

const Home = ({ home, subStepsStatus, stepsStatus, userLocation }) => {


  const { userCode } = useParams()

  const classes = useStyles();

  const location = useLocation();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [inputCode, setInputCode] = useState('')

  const [loading, setLoading] = useState(false)

  const [errorShow, setErrorShow] = useState('')

  const [open, setOpen] = useState(false);

  const isMobile = useMediaQuery({ query: `(max-width: 590px)` });

  const isIpad = useMediaQuery({ query: `(max-width: 900px)` });

  const resetReducersList = [RESET_PRODUCT, RESET_SAVEBUSINESSCENTRAL, RESET_SAVECUSTOMERSERVICE, RESET_SAVESALES, RESET_SAVEMARKETING, RESET_SAVEENDUSERS, RESET_SAVESUPPORT, RESET_SET_PROFILE, RESET_SAVELATER, RESET_PRICINGCODE, RESET_SAVESUPPORTPACKAGE, RESET_SAVEGEOLOCATION, RESET_SAVEREQUESTDEMO, RESET_STEPSOFMODULES, RESET_UPDATE_SUB_STEPS_STATUS];

  document.documentElement.style.setProperty('--animate-duration', '3s');

  // grabbing the user location using the api and storing it in redux as GeoLocation

  const geoLocation = async (IP) => {
    let locationName = ""
    const ip = await publicIp.v4()
    const url = `https://api.ipstack.com/${ip}?access_key=0a55b97ca8be2073722a2b21643f6c2a`
    try {
      const response = await axios.get(url);
      dispatch(saveGeoLocation(response?.data?.country_name))
      locationName = response?.data?.country_name
    } catch (error) {
      console.error(error);
    }
    return locationName
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  // Reset Redux Store
  const resetStoreReducers = () => {
    resetReducersList.forEach(type => {
      dispatch({ type })
    });
  }
  const navigateProducts = () => {
    resetStoreReducers();
    geoLocation();
    navigate('/product')
  }

  const modifyProductsArray = (products) => {
    const newArray = []
    for (let i = 0; i < products.length; i++) {
      if (products[i] === "businessCentral") {
        newArray.push("1")
      }
      else if (products[i] === "sales") {
        newArray.push("2")
      }
      else if (products[i] === "marketing") {
        newArray.push("3")
      }
      else if (products[i] === "customerService") {
        newArray.push("4")
      }
    }
    return newArray;
  }

  const getCodeData = async (pricingCode) => {
    setLoading(true)
    const countryName = await geoLocation();
    const body = {
      code: pricingCode,
      userLocation: countryName,
    }
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/getDataByCode`, body)
      dispatch(setSelectedProducts(modifyProductsArray(response?.data?.data?.saveForLaterData?.selectedProducts)))
      dispatch(setStepsStatus(response?.data?.data?.saveForLaterData?.lastVistedRoute.stepsStatus))
      dispatch(setSubStepStatus(response?.data?.data?.saveForLaterData?.lastVistedRoute.subStepsStatus))
      dispatch(saveLater(response?.data?.data?.saveForLaterData?.code))
      dispatch(saveBusinessCentral(response?.data?.data?.saveForLaterData?.businessCentral))
      dispatch(saveSales(response?.data?.data?.saveForLaterData?.sales))
      dispatch(saveMarketing(response?.data?.data?.saveForLaterData?.marketing))
      dispatch(saveCustomerService(response?.data?.data?.saveForLaterData?.customerService))
      dispatch(saveEndUsers(response?.data?.data?.saveForLaterData?.endUsers))
      dispatch(saveSupport(response?.data))
      dispatch(saveSupportPackage(response?.data?.data?.saveForLaterData?.supportPackage))
      dispatch(saveProfileInfo(response?.data?.data?.profileDetails))
      if (response?.data?.data?.saveForLaterData?.quoteCompleted === true) {
        navigate('/pricing');
        const updateStep = stepsStatus;
        updateStep[0].product = { 'active': false, 'completed': true }
        updateStep[1].businessDetails = { 'active': false, 'completed': true }
        updateStep[2].endUsers = { 'active': false, 'completed': true }
        updateStep[3].support = { 'active': false, 'completed': true }
        dispatch(setStepsStatus(updateStep))
      }
      else {
        navigate(`${response?.data?.data?.saveForLaterData?.lastVistedRoute.route}`)
      }
    } catch (error) {
      setLoading(false)
      if (error.response.status === 404) {
        setErrorShow("Please enter code!")
        setOpen(true);
      }
      else if (error.response.status === 400) {

        setErrorShow("Invalid code!")
        setOpen(true);
        if (userCode) {
          setTimeout(() => {
            window.location.replace('/')
          }, 1000);
        }
      }
      else {
        setErrorShow("Something went wrong!")
        setOpen(true);
      }
    }
  }

  const getUserDataByUrl = () => {
    getCodeData(userCode)
  }

  const getUserData = () => {
    getCodeData(inputCode)
  }

  useEffect(() => {
    if (location.pathname === `/${userCode}`) {
      getUserDataByUrl()
    }
  }, [])

  return (
    <section
      style={{
        backgroundImage: `linear-gradient(90deg, #61C250, #4EC35D, #00C37C, #00C0A4, #00BBC7, #00B3DA, #00ACE0, #00A9E0)`,
        height: isMobile ? '' : '100vh',
        // padding: 2,
        overflow: "auto"
      }}
    >
      <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          {errorShow}
        </Alert>
      </Snackbar>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>


      <div
        style={{
          backgroundImage: `url(${isMobile ? MobileBanner : BannerImage})`,
          height: isMobile || isIpad ? '100vh' : '99vh',
          backgroundPosition: 'top',
          backgroundRepeat: 'no-repeat',
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <img src={isMobile ? catapultLogo : exit}
          style={{
            cursor: "pointer",
            position: "absolute",
            height: isMobile ? '47px' : "68px",
            width: isMobile ? '100%' : "230px",
            right: isMobile ? '' : "0px",
            top: "-5px"
          }}
          onClick={() =>
            window.open("https://www.catapulterp.com/", "_blank")
          }
        />
        <Container fixed maxWidth="md" sx={{ mt: 8 }} className="animate__animated animate__fadeIn">
          <Grid container direction="column" spacing={4}>
            <Grid
              item
              xs={12}
              sx={{
                textAlign: 'center',
              }}
              className="animate_animated animate_fadeIn"

            >
              <Stack direction="column">
                <Typography
                  variant="string"
                  gutterBottom
                  component="div"
                  sx={{
                    fontFamily: 'DinDIsplayProRegular',
                    fontStyle: 'italic',
                    color: '#FFFFFF',
                  }}
                >
                  Pricing
                </Typography>
                <Typography
                  variant="h3"
                  gutterBottom
                  component="div"
                  className="font-face-prelo-black"
                  sx={{ fontFamily: 'PreloSlabW04Black', color: '#FFFFFF', fontSize: isMobile ? '34px' : '48px', }}
                >
                  Take the guesswork out of your ERP & CRM solution budgeting.
                </Typography>
                <Typography
                  variant="caption"
                  gutterBottom
                  component="div"
                  sx={{
                    fontFamily: 'PreloSlabW04Light',
                    color: '#FFFFFF',
                    fontSize: isMobile ? '18px' : '20px',
                    lineHeight: 1.5,
                    paddingX: isMobile ? 0 : 10,
                  }}
                >
                  Build and price your Dynamics 365 solution in less than 10 minutes using our powerful online scoping and budgeting tool.
                </Typography>
              </Stack>
            </Grid>
            <Grid
              item
              xs="auto"
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              onClick={() => {
              }}
            >
              <Button
                variant="contained"
                color="neutral"
                sx={{
                  borderRadius: 1,
                  padding: '14px 46px',
                }}
                onClick={() => navigateProducts()}
              >
                <Typography variant="button" sx={{ fontFamily: 'DinDIsplayProBold', fontSize: "16px" }}>
                  Click here to begin
                </Typography>
              </Button>
            </Grid>
            <Grid
              item
              xs="auto"
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <div className={classes.root}>

                <Grid item
                  sx={{
                    width: "100%",
                    height: "60px",
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}>

                  <BootstrapInput
                    id="bootstrap-input"
                    placeholder="CONTINUE/VIEW A QUOTE"
                    onChange={(e) => setInputCode(e.target.value)}
                    endAdornment={
                      <InputAdornment
                        onClick={getUserData}
                      >
                        <ArrowCircleRightIcon
                          fontSize="large"
                          sx={{
                            cursor: 'pointer',
                            color: '#FFFFFF',
                            opacity: 0.5,
                            position: "absolute",
                            right: "5px"

                          }}
                        />
                      </InputAdornment>
                    }
                    inputProps={{ maxLength: 6 }} />
                </Grid>

              </div>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ height: "100px" }}>
            <Stack direction="row" justifyContent="center" alignItems="flex-end" sx={{ height: "100%", }}>
              <Link
                component="button"
                variant="body2"
                sx={{
                  color: 'white',
                  textDecorationColor: 'white',
                  fontFamily: 'DinDIsplayProRegular',
                  fontSize: '20px',
                  paddingBottom: isMobile ? '40px' : '0px',
                }}
                onClick={() => {
                  window.open("/faq", "_blank")
                }}
              >
                FAQs
              </Link>
            </Stack>
          </Grid>
        </Container>
      </div>
    </section >
  );
};

const mapStateToProps = (state) => {
  return {
    home: state.home,
    stepsStatus: state.home.stepsStatus,
    subStepsStatus: state.home.subStepsStatus,
    userLocation: state.saveGeoLocation.geoLocation,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    increaseCounter: (payload) => dispatch(increaseCounter(payload)),
    decreaseCounter: () => dispatch(decreaseCounter()),
    setQuoteType: (payload) => dispatch(setQuoteType(payload)),
    saveGeoLocation: (payload) => dispatch(saveGeoLocation(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
