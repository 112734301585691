import SAVEBUSINESSCENTRAL from './businessCentral.types';

const saveBusinessCentral = (payload) => {
    return {

        type: SAVEBUSINESSCENTRAL,
        payload
    };

};

export default saveBusinessCentral