/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Button, Container, Grid, Typography, Stack, IconButton, Fade } from '@mui/material';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { Link } from 'react-scroll';
import ReactGA from 'react-ga';
import styled from '@emotion/styled';
import { useLocation, useNavigate } from 'react-router-dom';
import { makeStyles, withStyles } from '@mui/styles';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import infoicon from '../assets/infoicon.svg';
import { setStepsStatus, setSubStepStatus } from '../redux/home/home.actions';
import BannerImage from '../assets/logo-overlay.png';
import 'animate.css';
import conditionalRoute from '../Routes/ConditionalRoutes';
import conditionalRouteEndUser from '../Routes/ConditionalRouteEndUser';
import Navbar from '../Components/Navbar/NavbarTab';
import ProductBar from '../Components/ProductBar/ProductBar';
import blackcircle from '../assets/blackcircle.svg';
import tickblack from '../assets/tickblack.svg';
import saveSales from '../redux/sales/sales.actions'
import saveSupport from '../redux/support/support.actions';
import SaveForLater from '../Components/SaveForLater';
import exit from '../assets/exit.svg'
import catapultLogo from '../assets/catapult-mobile.svg'
import { modifyProductsByCategory } from '../helpers';



const CssTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#F2F4F5",
        fontFamily: "DinDIsplayProRegular"
      },
      "&:hover fieldset": {
        borderColor: "#F2F4F5"
      },
      "&.Mui-focused fieldset": {
        borderColor: "#F2F4F5"
      },
      "& input": {
        fontFamily: "DinDIsplayProRegular",
        fontSize: "15px",
        color: "#00313E",
        opacity: 0.5,
        height: "15px",
        background: "#F2F4F5"
      }
    },

  }
})(TextField);

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "260px",
    padding: "10px",
    display: "flex",
    alignItems: "center",
    height: "50px",
    color: "#FFFFFF",
    fontFamily: "DinDIsplayProRegular",
    backgroundColor: "#00313E",
    fontSize: "12px"
  }
});


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
}));

const Sales = ({ sales, stepsStatus, code, hyperPackage, saveForLaterCode }) => {

  const isMobile = useMediaQuery({ query: `(max-width: 640px)` });

  const isSmallMobile = useMediaQuery({ query: `(max-width: 768px)` });

  const isSmallDevice = useMediaQuery({ query: `(max-width: 390px)` });

  const isXtraSmallDevice = useMediaQuery({ query: `(max-height: 667px)` });

  const isIpad = useMediaQuery({ query: `(max-width: 900px)` });

  const id = localStorage.getItem("clientId");

  const classes = useStyles();

  const dispatch = useDispatch();

  const modalRef = useRef(null)

  const { product } = useSelector((s) => s);

  const reduxData = useSelector((s) => s);

  const navigate = useNavigate();

  const location = useLocation();

  const { state } = useLocation();

  // state for navbar responsiveness
  const [navbar, setNavbar] = useState(false);

  // enable and disable//
  const [show, setShow] = useState(false);

  const [salesManagement, setSalesManagement] = useState(sales?.salesManagement);

  const [check, setCheck] = useState(false);

  const [salesQuote, setSalesQuotes] = useState(sales?.salesQuote);

  const [loading, setLoading] = useState(false);

  const leadsDivRef = useRef()

  const quotesDivRef = useRef()

  const ifProductsExist = product.selectedProducts.length > 0 ? product.selectedProducts : [];

  const [selectedProducts, setProducts] = useState(ifProductsExist);

  const [otherDesc, setOtherDesc] = useState('')

  const [openToolTip, setOpenToolTip] = useState(false);

  const [openToolTipOne, setOpenToolTipOne] = useState(false);

  const [openToolTipTwo, setOpenToolTipTwo] = useState(false);


  const handleTooltipOpen = () => {
    setOpenToolTip(!openToolTip);
    setTimeout(() => {
      setOpenToolTip(false);
    }, 3000);
  };

  const handleTooltipOpenOne = () => {
    setOpenToolTipOne(!openToolTipOne);
    setTimeout(() => {
      setOpenToolTipOne(false);
    }, 3000);
  };

  const handleTooltipOpenTwo = () => {
    setOpenToolTipTwo(!openToolTipTwo);
    setTimeout(() => {
      setOpenToolTipTwo(false);
    }, 3000);
  };


  const handleChangeSalesManagement = (field, label, value) => {
    setTimeout(() => {
      window.scrollBy({
        top: isMobile || isXtraSmallDevice || isSmallDevice ? 290 : 500,
        behavior: 'smooth',
      });
    }, 800);

    setSalesManagement({ ...salesManagement, [field]: { "label": label, "value": parseInt(value, 10) } });
  };
  const handleChangeSalesQuotes = (field, label, value) => {
    setTimeout(() => {
      window.scrollBy({
        top: isMobile || isXtraSmallDevice || isSmallDevice ? 300 : 500,
        behavior: 'smooth',
      });
    }, 800);
    setSalesQuotes({ ...salesQuote, [field]: { "label": label, "value": parseInt(value, 10) } });
  };

  const handleSaveData = () => {

    const data = {
      "salesManagement": {
        "websitesLeads": {
          "label": salesManagement?.websitesLeads?.label,
          "value": salesManagement?.websitesLeads?.value
        },
        "emailLeads": {
          "label": salesManagement?.emailLeads?.label,
          "value": salesManagement?.emailLeads?.value
        },
        "leadLists": {
          "label": salesManagement?.leadLists?.label,
          "value": salesManagement?.leadLists?.value
        },

        "other": {
          "label": salesManagement?.other?.label,
          "value": salesManagement?.other?.value
        },

        "otherDescription": {
          "label": "otherDescription",
          "value": otherDesc
        },

        "leadRouting": {
          "label": salesManagement?.leadRouting?.label,
          "value": salesManagement?.leadRouting?.value
        },
        "leadScoring": {
          "label": salesManagement?.leadScoring?.label,
          "value": salesManagement?.leadScoring?.value
        },
        "buSalesFunnels": {
          "label": salesManagement?.buSalesFunnels?.label,
          "value": salesManagement?.buSalesFunnels?.value
        },
        "salesProcesses": {
          "label": salesManagement?.salesProcesses?.label,
          "value": salesManagement?.salesProcesses?.value
        },
        "collabrativeSales": {
          "label": salesManagement?.collabrativeSales?.label,
          "value": salesManagement?.collabrativeSales?.value
        },
        "salesTargetTracking": {
          "label": salesManagement?.salesTargetTracking?.label,
          "value": salesManagement?.salesTargetTracking?.value
        }
      },
      "salesQuote": {
        "quotes": {
          "label": salesQuote?.quotes?.label,
          "value": salesQuote?.quotes?.value
        },

        "eSignatureIntegration": {
          "label": salesQuote?.eSignatureIntegration?.label,
          "value": salesQuote?.eSignatureIntegration?.value
        },
        "quoteType": {
          "label": salesQuote?.quoteType?.label,
          "value": salesQuote?.quoteType?.value
        },
        "noCatalogPriceLists": {
          "label": salesQuote?.noCatalogPriceLists?.label,
          "value": salesQuote?.noCatalogPriceLists?.value
        },
        "productBundles": {
          "label": salesQuote?.productBundles?.label,
          "value": salesQuote?.productBundles?.value
        },

      }
    }

    dispatch(saveSales(data));
  }

  const handleNext = () => {

    const data = {
      "salesManagement": {
        "websitesLeads": {
          "label": salesManagement?.websitesLeads?.label,
          "value": salesManagement?.websitesLeads?.value
        },
        "emailLeads": {
          "label": salesManagement?.emailLeads?.label,
          "value": salesManagement?.emailLeads?.value
        },
        "leadLists": {
          "label": salesManagement?.leadLists?.label,
          "value": salesManagement?.leadLists?.value
        },

        "other": {
          "label": salesManagement?.other?.label,
          "value": salesManagement?.other?.value
        },

        "otherDescription": {
          "label": "otherDescription",
          "value": otherDesc
        },

        "leadRouting": {
          "label": salesManagement?.leadRouting?.label,
          "value": salesManagement?.leadRouting?.value
        },
        "leadScoring": {
          "label": salesManagement?.leadScoring?.label,
          "value": salesManagement?.leadScoring?.value
        },
        "buSalesFunnels": {
          "label": salesManagement?.buSalesFunnels?.label,
          "value": salesManagement?.buSalesFunnels?.value
        },
        "salesProcesses": {
          "label": salesManagement?.salesProcesses?.label,
          "value": salesManagement?.salesProcesses?.value
        },
        "collabrativeSales": {
          "label": salesManagement?.collabrativeSales?.label,
          "value": salesManagement?.collabrativeSales?.value
        },
        "salesTargetTracking": {
          "label": salesManagement?.salesTargetTracking?.label,
          "value": salesManagement?.salesTargetTracking?.value
        }
      },
      "salesQuote": {
        "quotes": {
          "label": salesQuote?.quotes?.label,
          "value": salesQuote?.quotes?.value
        },

        "eSignatureIntegration": {
          "label": salesQuote?.eSignatureIntegration?.label,
          "value": salesQuote?.eSignatureIntegration?.value
        },
        "quoteType": {
          "label": salesQuote?.quoteType?.label,
          "value": salesQuote?.quoteType?.value
        },
        "noCatalogPriceLists": {
          "label": salesQuote?.noCatalogPriceLists?.label,
          "value": salesQuote?.noCatalogPriceLists?.value
        },
        "productBundles": {
          "label": salesQuote?.productBundles?.label,
          "value": salesQuote?.productBundles?.value
        },

      }
    }

    if (selectedProducts[selectedProducts.length - 1] === '2') {
      const updateStep = stepsStatus;
      updateStep[1].businessDetails = { 'active': false, 'completed': true }
      dispatch(setStepsStatus(updateStep))
    }
    dispatch(setSubStepStatus({ bdSales: { active: false, completed: true } }));
    dispatch(saveSales(data));
    const nextIndex = selectedProducts.indexOf('2') + 1 || Infinity;
    if (selectedProducts.at(nextIndex)) {
      navigate(`/business-details/${conditionalRoute(selectedProducts.at(nextIndex))}`);
    }
    else {
      navigate(`/end-users/${conditionalRouteEndUser(selectedProducts.at(0))}`);
    }
  };

  const handleSave = () => {
    setLoading(true)

    const data = {
      "salesManagement": {
        "websitesLeads": {
          "label": salesManagement?.websitesLeads?.label,
          "value": salesManagement?.websitesLeads?.value === null ? 0 : salesManagement?.websitesLeads?.value
        },
        "emailLeads": {
          "label": salesManagement?.emailLeads?.label,
          "value": salesManagement?.emailLeads?.value === null ? 0 : salesManagement?.emailLeads?.value
        },
        "leadLists": {
          "label": salesManagement?.leadLists?.label,
          "value": salesManagement?.leadLists?.value === null ? 0 : salesManagement?.leadLists?.value
        },

        "other": {
          "label": salesManagement?.other?.label,
          "value": salesManagement?.other?.value === null ? 0 : salesManagement?.other?.value
        },

        "otherDescription": {
          "label": "otherDescription",
          "value": salesManagement?.otherDescription?.value === null ? '' : salesManagement?.otherDescription?.value
        },

        "leadRouting": {
          "label": salesManagement?.leadRouting?.label,
          "value": salesManagement?.leadRouting?.value === null ? 0 : salesManagement?.leadRouting?.value
        },
        "leadScoring": {
          "label": salesManagement?.leadScoring?.label,
          "value": salesManagement?.leadScoring?.value === null ? 0 : salesManagement?.leadScoring?.value
        },
        "buSalesFunnels": {
          "label": salesManagement?.buSalesFunnels?.label,
          "value": salesManagement?.buSalesFunnels?.value === null ? 0 : salesManagement?.buSalesFunnels?.value
        },
        "salesProcesses": {
          "label": salesManagement?.salesProcesses?.label,
          "value": salesManagement?.salesProcesses?.value === null ? 0 : salesManagement?.salesProcesses?.value
        },
        "collabrativeSales": {
          "label": salesManagement?.collabrativeSales?.label,
          "value": salesManagement?.collabrativeSales?.value === null ? 0 : salesManagement?.collabrativeSales?.value
        },
        "salesTargetTracking": {
          "label": salesManagement?.salesTargetTracking?.label,
          "value": salesManagement?.salesTargetTracking?.value === null ? 0 : salesManagement?.salesTargetTracking?.value
        }
      },
      "salesQuote": {
        "quotes": {
          "label": salesQuote?.quotes?.label,
          "value": salesQuote?.quotes?.value === null ? 0 : salesQuote?.quotes?.value
        },

        "eSignatureIntegration": {
          "label": salesQuote?.eSignatureIntegration?.label,
          "value": salesQuote?.eSignatureIntegration?.value === null ? 0 : salesQuote?.eSignatureIntegration?.value
        },
        "quoteType": {
          "label": salesQuote?.quoteType?.label,
          "value": salesQuote?.quoteType?.value === null ? 0 : salesQuote?.quoteType?.value
        },
        "noCatalogPriceLists": {
          "label": salesQuote?.noCatalogPriceLists?.label,
          "value": salesQuote?.noCatalogPriceLists?.value === null ? 0 : salesQuote?.noCatalogPriceLists?.value
        },
        "productBundles": {
          "label": salesQuote?.productBundles?.label,
          "value": salesQuote?.productBundles?.value === null ? 0 : salesQuote?.productBundles?.value
        },

      }
    }

    const profileData = {
      "firstName": reduxData?.profile?.profile?.firstName,
      "lastName": reduxData?.profile?.profile?.lastName,
      "email": reduxData?.profile?.profile?.email,
      "countryCode": reduxData?.profile?.profile?.countryCode,
      "phoneNo": reduxData?.profile?.profile?.phoneNo,
      "companyName": reduxData?.profile?.profile?.companyName,
      "followUp": reduxData?.profile?.profile?.followUp
    }
    const body = {
      selectedProducts: modifyProductsByCategory(reduxData.product.selectedProducts),
      businessCentral: reduxData?.businessCentral?.businessCentral,
      sales: data,
      marketing: reduxData?.marketing?.marketing,
      customerService: reduxData?.customerService.customerService,
      endUsers: reduxData?.endUsers,
      profileDetails: profileData,
      supportPackage: hyperPackage,
      // userCode: saveForLaterCode,
      userLocation: reduxData.saveGeoLocation.geoLocation,
      analyticsPayload: {
        clientId: id
      }
    }
    if (saveForLaterCode) {
      body.userCode = saveForLaterCode
    }
    else if (code) {
      body.userCode = code
    }
    else {
      body.userCode = ""
    }
    axios.post(`${process.env.REACT_APP_BASE_URL}/api/calculatePricing`, body)
      .then(res => {
        if (res.data.message === 'Pricing calculated successfully') {
          setLoading(false)
          const response = res.data;
          dispatch(saveSales(data));
          dispatch(saveSupport(response))
          navigate('/pricing');
        }
      })
      .catch((error) => {
        setLoading(false)
      });
  }

  const handleCancel = () => {
    navigate('/pricing');
  }

  document.documentElement.style.setProperty('--animate-duration', '3s');

  const changePosition = () => {
    if (window.scrollY >= 400) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener('scroll', changePosition);

  const buttonFour = [
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    { label: '3+', value: 3 },
  ];

  const buttonTen = [
    { label: '1', value: 1 },
    { label: '2 to 3', value: 2 },
    { label: '3+', value: 3 },
  ];


  const buttonNine = [
    { label: 'Manual Input', value: 1 },
    { label: 'Price list', value: 2 },
  ];

  useEffect(() => {
    ReactGA.initialize('UA-74792081-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
    console.log(window.location.pathname, "window.location.pathname")
    console.log(window.location.search, "window.location.search")
  }, [])


  useEffect(() => {

    if (salesQuote?.eSignatureIntegration.value === 0 || salesQuote?.eSignatureIntegration.value === 1) {
      setShow(true)
    }

    else if (otherDesc === '') {
      setShow(false);
    }

    else if (otherDesc !== '') {
      setShow(true);
    }

    else {
      setShow(false)
    }

  }, [salesQuote])

  const scrollToRef = (ref) => {
    if (isSmallMobile) {
      window.scrollTo(0, ref.current.offsetTop - 260);
    }
    else {
      window.scrollTo(0, ref.current.offsetTop - 150);
    }
  }

  useEffect(() => {
    if (state?.focus) {
      switch (state?.focus) {
        case 'leads':
          scrollToRef(leadsDivRef)
          break;

        case 'quotes':
          scrollToRef(quotesDivRef)
          break;

        default:
          break;
      }
    }
    else if (isSmallMobile) {
      scrollToRef(leadsDivRef);
      // window.scrollToRef(0, ref.current.offsetTop - 120);
    }
    else {
      // window.scrollToRef(0, ref.current.offsetTop - 100);
      scrollToRef(leadsDivRef);
    }
  }, [state?.focus])


  return (
    <div>
      <Link to="navbar" spy smooth>
        <section
          style={{
            backgroundImage: `linear-gradient(90deg, #61C250, #4EC35D, #00C37C, #00C0A4, #00BBC7, #00B3DA, #00ACE0, #00A9E0)`,
            height: isMobile ? '80vh' : "100%",
            // padding: 2,
          }}
        >
          <div
            style={{
              backgroundImage: `url(${BannerImage})`,
              height: '100%',
              backgroundPosition: 'top',
              backgroundRepeat: 'no-repeat',
              display: "flex",
              alignItems: "center"
            }}
          >
            {location.pathname === "/pricing" || location.pathname === "/product" ?
              (
                <img src={isMobile ? catapultLogo : exit}
                  style={{
                    cursor: "pointer",
                    height: isMobile ? '47px' : "68px",
                    width: isMobile ? '100%' : "230px",
                    zIndex: 999,
                    position: "fixed",
                    right: isMobile ? '' : "0px",
                    top: "-5px"
                  }}
                  onClick={() => window.location.replace('https://www.catapulterp.com/')}
                />
              ) : (
                <img src={isMobile ? catapultLogo : exit}
                  style={{
                    cursor: "pointer",
                    height: isMobile ? '47px' : "68px",
                    width: isMobile ? '100%' : "230px",
                    zIndex: 999,
                    position: "fixed",
                    right: isMobile ? '' : "0px",
                    top: "-5px"
                  }}
                  onClick={() => {
                    modalRef.current()
                    setCheck(true)
                  }}
                />
              )
            }
            <Container fixed maxWidth="md" className="animate__animated animate__fadeIn"
              sx={{
                height: "450px",
              }}
            >
              <Grid item xs={12}
                sx={{
                  textAlign: 'center',
                  height: "100%",
                  display: "flex",
                  justifyContent: "center"
                }}>
                <Stack direction="column"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: isMobile ? '47px' : ''
                  }}>
                  <Typography
                    variant="string"
                    gutterBottom
                    component="div"
                    sx={{
                      fontFamily: 'DinDIsplayProRegular',
                      fontStyle: 'italic',
                      color: '#FFFFFF',
                    }}
                  >
                    Pricing
                  </Typography>
                  <Typography
                    variant="h3"
                    gutterBottom
                    component="div"
                    className="font-face-prelo-black"
                    sx={{
                      fontFamily: 'PreloSlabW04Black',
                      color: '#FFFFFF',
                      fontSize: isMobile ? '33px' : '48px',
                      marginBottom: isMobile ? "40px" : ''
                    }}
                  >
                    Take the guesswork out of your ERP & CRM solution budgeting.
                  </Typography>
                  <Typography
                    variant="caption"
                    gutterBottom
                    component="div"
                    mb={5}
                    sx={{
                      fontFamily: 'PreloSlabW04Light',
                      color: '#FFFFFF',
                      fontSize: isMobile ? '18px' : '20px',
                      lineHeight: 1.5,
                      paddingX: isMobile ? 2 : 10,
                      marginBottom: isMobile ? "60px" : '40px'
                    }}
                  >
                    Customize and price your Dynamics 365 solution using our powerful online
                    scoping and budgeting tools.
                  </Typography>
                </Stack>
              </Grid>
            </Container>
          </div>
        </section>
      </Link>
      <div
        style={{ height: isMobile ? '' : '120px' }}
        id="navbar">
        <Navbar navbar={navbar} />
        <ProductBar navbar={navbar} />
      </div>

      <Container sx={{ py: 4, height: 'auto', display: "inherit", marginBottom: isMobile ? "100px" : '', minHeight: "800px" }}>
        <Grid container spacing={isMobile ? 3 : 2} justifyContent="start" md={12} sx={{ paddingBottom: isMobile ? "100px" : "", paddingTop: "10px" }}>
          {/* -------------------------Question 1 ----------------------------------------------------*/}
          <Grid item xs={12} md={10} className="animate__animated animate__fadeIn" ref={leadsDivRef}>
            <Typography
              variant="body1"
              sx={{
                color: '#00313E',
                fontFamily: 'PreloSlabW04SemiBold',
                fontSize: isMobile ? '19px' : '22px',
              }}
              gutterBottom
              component="div"
            >
              First, please describe where your business leads come from:
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={8}
            mb={1}
            sx={{ display: 'flex', flexWrap: 'wrap' }}
            className="animate__animated animate__fadeIn"
          >
            <Button
              variant="myPrimary"
              sx={{
                backgroundColor: salesManagement?.websitesLeads?.value !== 1 ? 'white' : '#00313E',
                width: '250px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                height: '80px',
                borderRadius: '15px',
                border: '1px solid #00313E',
                marginRight: '18px',
                marginBottom: '10px',
                marginTop: '10px',
                paddingTop: 1,

                '&:hover': {
                  backgroundColor: salesManagement?.websitesLeads?.value !== 1 ? 'white' : '#00313E',
                },
              }}
              value={salesManagement?.websitesLeads?.value}
              onClick={() => {
                handleChangeSalesManagement("websitesLeads", salesManagement?.websitesLeads?.value === 1 ? "No" : "Yes", salesManagement?.websitesLeads?.value === 1 ? 0 : 1)

              }}
            >
              <Typography
                variant="button"
                display="block"
                align="left"
                sx={{
                  color: salesManagement?.websitesLeads?.value !== 1 ? '#00313E' : '#FFFFFF',
                  fontFamily: 'DinDIsplayProBold',
                  textTransform: 'none',
                  fontSize: isMobile ? '14px' : '18px',
                }}
              >
                Website forms
              </Typography>
              {salesManagement?.websitesLeads?.value !== 1 ? <img src={blackcircle} /> : <img src={tickblack} />}
            </Button>
            <Button
              variant="myPrimary"
              sx={{
                backgroundColor: salesManagement?.emailLeads?.value !== 1 ? 'white' : '#00313E',
                width: '250px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                height: '80px',
                borderRadius: '15px',
                border: '1px solid #00313E',
                marginRight: '18px',
                marginBottom: '10px',
                marginTop: '10px',
                paddingTop: 1,

                '&:hover': {
                  backgroundColor: salesManagement?.emailLeads?.value !== 1 ? 'white' : '#00313E',
                },
              }}
              value={salesManagement?.emailLeads?.value}
              onClick={() => {
                handleChangeSalesManagement("emailLeads", salesManagement?.emailLeads?.value === 1 ? "No" : "Yes", salesManagement?.emailLeads?.value === 1 ? 0 : 1)
              }}
            >
              <Typography
                variant="button"
                display="block"
                align="left"
                sx={{
                  color: salesManagement?.emailLeads?.value !== 1 ? '#00313E' : '#FFFFFF',
                  fontFamily: 'DinDIsplayProBold',
                  textTransform: 'none',
                  fontSize: isMobile ? '14px' : '18px',
                }}
              >
                Email or phone inquiries
              </Typography>
              {salesManagement?.emailLeads?.value !== 1 ? <img src={blackcircle} /> : <img src={tickblack} />}
            </Button>
            <Button
              variant="myPrimary"
              sx={{
                backgroundColor: salesManagement?.leadLists?.value !== 1 ? 'white' : '#00313E',
                width: '250px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                height: '80px',
                borderRadius: '15px',
                border: '1px solid #00313E',
                marginRight: '18px',
                marginBottom: '10px',
                marginTop: '10px',
                paddingTop: 1,

                '&:hover': {
                  backgroundColor: salesManagement?.leadLists?.value !== 1 ? 'white' : '#00313E',
                },
              }}
              value={salesManagement?.leadLists?.value}
              onClick={() => {
                handleChangeSalesManagement("leadLists", salesManagement?.leadLists?.value === 1 ? "No" : "Yes", salesManagement?.leadLists?.value === 1 ? 0 : 1)
              }}
            >
              <Typography
                variant="button"
                display="block"
                align="left"
                sx={{
                  color: salesManagement?.leadLists?.value !== 1 ? '#00313E' : '#FFFFFF',
                  fontFamily: 'DinDIsplayProBold',
                  textTransform: 'none',
                  fontSize: isMobile ? '14px' : '18px',
                }}
              >
                Purchased/imported lists
              </Typography>
              {salesManagement?.leadLists?.value !== 1 ? <img src={blackcircle} /> : <img src={tickblack} />}
            </Button>
            <Button
              variant="myPrimary"
              sx={{
                backgroundColor: salesManagement?.other?.value !== 1 ? 'white' : '#00313E',
                width: '250px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                height: '80px',
                borderRadius: '15px',
                border: '1px solid #00313E',
                marginRight: '18px',
                marginBottom: '10px',
                marginTop: '10px',
                paddingTop: 1,

                '&:hover': {
                  backgroundColor: salesManagement?.other?.value !== 1 ? 'white' : '#00313E',
                },
              }}
              value={salesManagement?.other?.value}
              onClick={() => {
                handleChangeSalesManagement("other", salesManagement?.other?.value === 1 ? "No" : "Yes", salesManagement?.other?.value === 1 ? 0 : 1)
              }}
            >
              <Typography
                variant="button"
                display="block"
                align="left"
                sx={{
                  color: salesManagement?.other?.value !== 1 ? '#00313E' : '#FFFFFF',
                  fontFamily: 'DinDIsplayProBold',
                  textTransform: 'none',
                  fontSize: isMobile ? '14px' : '18px',
                }}
              >
                Other
              </Typography>
              {salesManagement?.other?.value !== 1 ? <img src={blackcircle} /> : <img src={tickblack} />}
            </Button>

            {
              salesManagement?.other?.value ? (
                <Grid item mt={2} sx={{ width: "518px" }}>
                  <CssTextField
                    className={classes.margin}
                    variant="outlined"
                    fullWidth
                    error={salesManagement?.otherDescription.value === ""}
                    helperText={salesManagement?.otherDescription.value === "" ? 'Empty field!' : ' '}
                    id="custom-css-outlined-input"
                    placeholder='Please describe'
                    defaultValue={sales?.salesManagement?.otherDescription.value}
                    onChange={(e) => setOtherDesc(e.target.value)}
                  />

                </Grid>
              ) : (
                ''
              )
            }

          </Grid>

          {/* -------------------------Question 2 ----------------------------------------------------*/}

          {salesManagement?.websitesLeads?.value || salesManagement?.emailLeads?.value || salesManagement?.leadLists?.value || otherDesc !== '' ? (
            <>
              <Grid item xs={12} md={9} className="animate__animated animate__fadeIn" sx={{ display: "flex" }}>
                <Typography
                  variant="body1"
                  sx={{
                    color: '#00313E',
                    fontFamily: 'PreloSlabW04SemiBold',
                    fontSize: isMobile ? '19px' : '22px',
                    // paddingTop: "60px"
                  }}
                  gutterBottom
                  component="div"
                >
                  Do you require lead routing based on business rules?
                </Typography>
                {
                  isMobile || isIpad ? (
                    <CustomTooltip placement="top-end"
                      sx={{ padding: "0px 0px 4px 10px" }}
                      TransitionComponent={Fade}
                      TransitionProps={{ timeout: 1000 }}
                      open={openToolTip}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      title="D365 can route sales leads to designated people or teams when specific criteria are met, e.g., product, service or region.">
                      <IconButton>
                        <img src={infoicon}
                          onClick={handleTooltipOpen}
                        />
                      </IconButton>
                    </CustomTooltip>
                  ) : (
                    <CustomTooltip placement="right"
                      sx={{ padding: "0px 0px 4px 10px" }}
                      TransitionComponent={Fade}
                      TransitionProps={{ timeout: 1000 }}
                      title="D365 can route sales leads to designated people or teams when specific criteria are met, e.g., product, service or region.">
                      <IconButton>
                        <img src={infoicon}
                        />
                      </IconButton>
                    </CustomTooltip>
                  )
                }

              </Grid>
              <Grid
                item
                xs={12}
                md={9}
                mb={2}
                sx={{ display: 'flex' }}
                className="animate__animated animate__fadeIn"
              >
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    onChange={(event) =>
                      handleChangeSalesManagement('leadRouting', event.target.value === "0" ? "No" : "Yes", event.target.value)
                    }
                    sx={{
                      color: '#00313E',
                    }}
                  >
                    <FormControlLabel
                      value={1}
                      control={
                        <Radio
                          checked={salesManagement?.leadRouting?.value === 1}
                          size="large"
                          sx={{
                            color: '#00313E',
                            '&.Mui-checked': {
                              color: '#00313E',
                            },
                          }}
                        />
                      }
                      label="Yes"
                    />
                    <FormControlLabel
                      value={0}
                      control={
                        <Radio
                          checked={salesManagement?.leadRouting?.value === 0}
                          size="large"
                          sx={{
                            color: '#00313E',
                            '&.Mui-checked': {
                              color: '#00313E',
                            },
                          }}
                        />
                      }
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </>
          ) : (
            ''
          )}


          {/* -------------------------Question 3 ----------------------------------------------------*/}

          {salesManagement?.leadRouting?.value === 1 || salesManagement?.leadRouting?.value === 0 ? (
            <>
              <Grid item xs={12} md={9} className="animate__animated animate__fadeIn" sx={{ display: "flex" }}>
                <Typography
                  variant="body1"
                  sx={{
                    color: '#00313E',
                    fontFamily: 'PreloSlabW04SemiBold',
                    fontSize: isMobile ? '19px' : '22px',
                    // paddingTop: "60px"
                  }}
                  gutterBottom
                  component="div"
                >
                  Do you require lead scoring?
                </Typography>
                {
                  isIpad || isMobile ?
                    (<CustomTooltip placement="top-end"
                      sx={{ padding: "0px 0px 4px 10px" }}
                      TransitionComponent={Fade}
                      TransitionProps={{ timeout: 1000 }}
                      open={openToolTipOne}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      title="D365 can score leads based on specified business criteria to help sales people focus on the best quality deals.">
                      <IconButton>
                        <img src={infoicon}
                          onClick={handleTooltipOpenOne}
                        />
                      </IconButton>
                    </CustomTooltip>)
                    : (
                      <CustomTooltip placement="right"
                        sx={{ padding: "0px 0px 4px 10px" }}
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 1000 }}
                        title="D365 can score leads based on specified business criteria to help sales people focus on the best quality deals.">
                        <IconButton>
                          <img src={infoicon}
                          />
                        </IconButton>
                      </CustomTooltip>

                    )
                }
              </Grid>
              <Grid
                item
                xs={12}
                md={9}
                mb={2}
                sx={{ display: 'flex' }}
                className="animate__animated animate__fadeIn"
              >
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    onChange={(event) =>
                      handleChangeSalesManagement('leadScoring', event.target.value === "0" ? "No" : "Yes", event.target.value)
                    }
                    sx={{
                      color: '#00313E',
                    }}
                  >
                    <FormControlLabel
                      value={1}
                      control={
                        <Radio
                          checked={salesManagement?.leadScoring?.value === 1}
                          size="large"
                          sx={{
                            color: '#00313E',
                            '&.Mui-checked': {
                              color: '#00313E',
                            },
                          }}
                        />
                      }
                      label="Yes"
                    />
                    <FormControlLabel
                      value={0}
                      control={
                        <Radio
                          checked={salesManagement?.leadScoring?.value === 0}
                          size="large"
                          sx={{
                            color: '#00313E',
                            '&.Mui-checked': {
                              color: '#00313E',
                            },
                          }}
                        />
                      }
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </>
          ) : (
            ''
          )}
          {/* -------------------------Question 4 ----------------------------------------------------*/}
          {salesManagement?.leadScoring?.value === 1 || salesManagement?.leadScoring?.value === 0 ? (
            <>
              <Grid item xs={12} md={9} className="animate__animated animate__fadeIn">
                <div style={{ display: "flex" }}>
                  <Typography
                    variant="body1"
                    sx={{
                      color: '#00313E',
                      fontFamily: 'PreloSlabW04SemiBold',
                      fontSize: isMobile ? '19px' : '22px',
                      marginBottom: '10px',
                      // paddingTop: "60px"
                    }}
                    gutterBottom
                    component="div"
                  >
                    How many distinct sales funnels does the business need to manage?
                  </Typography>

                  {
                    isMobile || isIpad ? (
                      <CustomTooltip placement="top-end"
                        sx={{ padding: "0px 0px 4px 10px" }}
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 1000 }}
                        open={openToolTipTwo}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        title="Different opportunity types can be grouped into a single sales funnel. Separate divisions or business units may require distinct funnels for their deal flow.">
                        <IconButton>
                          <img src={infoicon}
                            onClick={handleTooltipOpenTwo}
                          />
                        </IconButton>
                      </CustomTooltip>
                    ) : (
                      <CustomTooltip placement="right"
                        sx={{ padding: "0px 0px 4px 10px" }}
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 1000 }}
                        title="Different opportunity types can be grouped into a single sales funnel. Separate divisions or business units may require distinct funnels for their deal flow.">
                        <IconButton>
                          <img src={infoicon}
                          />
                        </IconButton>
                      </CustomTooltip>
                    )
                  }
                </div>
                <Typography
                  variant="body1"
                  sx={{
                    color: '#00313E',
                    fontFamily: 'DinDIsplayProRegular',
                    fontSize: '14px',
                  }}
                  gutterBottom
                  component="div"
                >
                  Include any part of the business (e.g., department, division, region) that manages
                  a separate sales team and funnel.
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={9}
                mb={1}
                sx={{
                  display: 'flex',
                  flexWrap: isMobile ? 'wrap' : ''
                }}
                className="animate__animated animate__fadeIn"
              >
                {buttonFour?.map((item) => (
                  <Button
                    variant="myPrimary"
                    sx={{
                      backgroundColor:
                        salesManagement?.buSalesFunnels?.value !== item.value ? 'white' : '#00313E',
                      width: isMobile ? '150px' : '180px',
                      height: '60px',
                      borderRadius: '15px',
                      border: '1px solid #00313E',
                      marginRight: isMobile ? '18px' : '40px',
                      marginBottom: '10px',
                      marginTop: '10px',

                      '&:hover': {
                        backgroundColor:
                          salesManagement?.buSalesFunnels?.value !== item.value
                            ? 'white'
                            : '#00313E',
                      },
                    }}
                    value={item.label}
                    onClick={() => {
                      handleChangeSalesManagement('buSalesFunnels', item.label, item.value);
                    }}
                  >
                    <Typography
                      variant="button"
                      display="block"
                      sx={{
                        color:
                          salesManagement?.buSalesFunnels?.value !== item.value
                            ? '#00313E'
                            : '#FFFFFF',
                        fontFamily: 'DinDIsplayProBold',
                        textTransform: 'none',
                        fontSize: isMobile ? '14px' : '18px',
                      }}
                    >
                      {item.label}
                    </Typography>
                  </Button>
                ))}
              </Grid>
            </>
          ) : (
            ''
          )}

          {/* -------------------------Question 5 ----------------------------------------------------*/}

          {salesManagement?.buSalesFunnels?.value ? (
            <>
              <Grid item xs={12} md={9} className="animate__animated animate__fadeIn">
                <Typography
                  variant="body1"
                  sx={{
                    color: '#00313E',
                    fontFamily: 'PreloSlabW04SemiBold',
                    fontSize: isMobile ? '19px' : '22px',
                    marginBottom: '10px',
                    // paddingTop: "60px"
                  }}
                  gutterBottom
                  component="div"
                >
                  How many distinct sales processes are there?
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    color: '#00313E',
                    fontFamily: 'DinDIsplayProRegular',
                    fontSize: '14px',
                  }}
                  gutterBottom
                  component="div"
                >
                  Include any product or service lines that have unique processes, stages or
                  workflows
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={9}
                mb={1}
                sx={{
                  display: 'flex',
                  flexWrap: isMobile ? 'wrap' : ''
                }}
                className="animate__animated animate__fadeIn"
              >
                {buttonFour?.map((item) => (
                  <Button
                    variant="myPrimary"
                    sx={{
                      backgroundColor:
                        salesManagement?.salesProcesses?.value !== item.value ? 'white' : '#00313E',
                      width: isMobile ? '150px' : '180px',
                      height: '60px',
                      borderRadius: '15px',
                      border: '1px solid #00313E',
                      marginRight: isMobile ? '18px' : '40px',
                      marginBottom: '10px',
                      marginTop: '10px',

                      '&:hover': {
                        backgroundColor:
                          salesManagement?.salesProcesses?.value !== item.value
                            ? 'white'
                            : '#00313E',
                      },
                    }}
                    value={item.label}
                    onClick={() => {
                      handleChangeSalesManagement('salesProcesses', item.label, item.value);
                    }}
                  >
                    <Typography
                      variant="button"
                      display="block"
                      sx={{
                        color:
                          salesManagement?.salesProcesses?.value !== item.value
                            ? '#00313E'
                            : '#FFFFFF',
                        fontFamily: 'DinDIsplayProBold',
                        textTransform: 'none',
                        fontSize: isMobile ? '14px' : '18px',
                      }}
                    >
                      {item.label}
                    </Typography>
                  </Button>
                ))}
              </Grid>
            </>
          ) : (
            ''
          )}

          {/* -------------------------Question 6 ----------------------------------------------------*/}

          {salesManagement?.salesProcesses?.value ? (
            <>
              <Grid item xs={12} md={9} className="animate__animated animate__fadeIn">
                <Typography
                  variant="body1"
                  sx={{
                    color: '#00313E',
                    fontFamily: 'PreloSlabW04SemiBold',
                    fontSize: isMobile ? '19px' : '22px',
                    // paddingTop: "60px"
                  }}
                  gutterBottom
                  component="div"
                >
                  Do sales opportunities typically require multiple people to collaborate?
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={9}
                mb={2}
                sx={{ display: 'flex' }}
                className="animate__animated animate__fadeIn"
              >
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    onChange={(event) =>
                      handleChangeSalesManagement('collabrativeSales', event.target.value === "0" ? "No" : "Yes", event.target.value)}
                    sx={{
                      color: '#00313E',
                    }}
                  >
                    <FormControlLabel
                      value={1}
                      control={
                        <Radio
                          checked={salesManagement?.collabrativeSales?.value === 1}
                          size="large"
                          sx={{
                            color: '#00313E',
                            '&.Mui-checked': {
                              color: '#00313E',
                            },
                          }}
                        />
                      }
                      label="Yes"
                    />
                    <FormControlLabel
                      value={0}
                      control={
                        <Radio
                          checked={salesManagement?.collabrativeSales?.value === 0}
                          size="large"
                          sx={{
                            color: '#00313E',
                            '&.Mui-checked': {
                              color: '#00313E',
                            },
                          }}
                        />
                      }
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </>
          ) : (
            ''
          )}

          {/* -------------------------Question  7----------------------------------------------------*/}

          {salesManagement?.collabrativeSales.value === 1 || salesManagement?.collabrativeSales.value === 0 ? (
            <>
              <Grid item xs={12} md={9} className="animate__animated animate__fadeIn">
                <Typography
                  variant="body1"
                  sx={{
                    color: '#00313E',
                    fontFamily: 'PreloSlabW04SemiBold',
                    fontSize: isMobile ? '19px' : '22px',
                    // paddingTop: "60px"
                  }}
                  gutterBottom
                  component="div"
                >
                  Do you require sales target or quota tracking?
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={9}
                mb={2}
                sx={{ display: 'flex' }}
                className="animate__animated animate__fadeIn"
              >
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    onChange={(event) => handleChangeSalesManagement('salesTargetTracking', event.target.value === "0" ? "No" : "Yes", event.target.value)
                    }
                    sx={{
                      color: '#00313E',
                    }}
                  >
                    <FormControlLabel
                      value={1}
                      control={
                        <Radio
                          checked={salesManagement?.salesTargetTracking?.value === 1}
                          size="large"
                          sx={{
                            color: '#00313E',
                            '&.Mui-checked': {
                              color: '#00313E',
                            },
                          }}
                        />
                      }
                      label="Yes"
                    />
                    <FormControlLabel
                      value={0}
                      control={
                        <Radio
                          checked={salesManagement?.salesTargetTracking?.value === 0}
                          size="large"
                          sx={{
                            color: '#00313E',
                            '&.Mui-checked': {
                              color: '#00313E',
                            },
                          }}
                        />
                      }
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </>
          ) : (
            ''
          )}

          {/* -------------------------Question 8 ----------------------------------------------------*/}

          {salesManagement?.salesTargetTracking.value === 1 || salesManagement?.salesTargetTracking.value === 0 ? (
            <>
              <Grid item xs={12} md={9} className="animate__animated animate__fadeIn" ref={quotesDivRef}>
                <Typography
                  variant="body1"
                  sx={{
                    color: '#00313E',
                    fontFamily: 'PreloSlabW04SemiBold',
                    fontSize: isMobile ? '19px' : '22px',
                    // paddingTop: "60px"
                  }}
                  gutterBottom
                  component="div"
                >
                  Do you provide sales quotes?
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={9}
                mb={2}
                sx={{ display: 'flex' }}
                className="animate__animated animate__fadeIn"
              >
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    onChange={(event) => { handleChangeSalesQuotes('quotes', event.target.value === "0" ? "No" : "Yes", event.target.value); }
                    }
                    sx={{
                      color: '#00313E',
                    }}
                  >
                    <FormControlLabel
                      value={1}
                      control={
                        <Radio
                          checked={salesQuote?.quotes?.value === 1}
                          size="large"
                          sx={{
                            color: '#00313E',
                            '&.Mui-checked': {
                              color: '#00313E',
                            },
                          }}
                        />
                      }
                      label="Yes"
                    />
                    <FormControlLabel
                      value={0}
                      control={
                        <Radio
                          checked={salesQuote?.quotes?.value === 0}
                          size="large"

                          sx={{
                            color: '#00313E',
                            '&.Mui-checked': {
                              color: '#00313E',
                            },
                          }}
                        />
                      }
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </>
          ) : (
            ''
          )}


          {salesQuote?.quotes?.value === 1 ? (

            <>
              {/* -------------------------Question 9 ----------------------------------------------------*/}
              {salesQuote?.quotes?.value ? (
                <>
                  <Grid item xs={12} md={9} className="animate__animated animate__fadeIn">
                    <Typography
                      variant="body1"
                      sx={{
                        color: '#00313E',
                        fontFamily: 'PreloSlabW04SemiBold',
                        fontSize: isMobile ? '19px' : '22px',
                      }}
                      gutterBottom
                      component="div"
                    >
                      Do you want to create quotes from a price list in D365 or enter manually from another source?
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={9}
                    mb={1}
                    sx={{
                      display: 'flex',
                      flexWrap: isMobile ? 'wrap' : ''
                    }}
                    className="animate__animated animate__fadeIn"
                  >
                    {buttonNine?.map((item) => (
                      <Button
                        variant="myPrimary"
                        sx={{
                          backgroundColor:
                            salesQuote?.quoteType?.value !== item.value ? 'white' : '#00313E',
                          width: isMobile ? '150px' : '180px',
                          height: '60px',
                          borderRadius: '15px',
                          border: '1px solid #00313E',
                          marginRight: isMobile ? '18px' : '40px',
                          marginBottom: '10px',
                          marginTop: '10px',

                          '&:hover': {
                            backgroundColor:
                              salesQuote?.quoteType?.value !== item.value ? 'white' : '#00313E',
                          },
                        }}
                        value={item.label}
                        onClick={() => {
                          handleChangeSalesQuotes('quoteType', item.label, item.value);
                        }}
                      >
                        <Typography
                          variant="button"
                          display="block"
                          sx={{
                            color: salesQuote?.quoteType?.value !== item.value ? '#00313E' : '#FFFFFF',
                            fontFamily: 'DinDIsplayProBold',
                            textTransform: 'none',
                            fontSize: isMobile ? '14px' : '18px',
                          }}
                        >
                          {item.label}
                        </Typography>
                      </Button>
                    ))}
                  </Grid>
                </>
              ) : (
                ''
              )}

              {/* -------------------------Question 10     ----------------------------------------------------*/}

              {salesQuote?.quoteType?.value === 2 ? (
                <>
                  <Grid item xs={12} md={9} className="animate__animated animate__fadeIn">
                    <Typography
                      variant="body1"
                      sx={{
                        color: '#00313E',
                        fontFamily: 'PreloSlabW04SemiBold',
                        fontSize: isMobile ? '17px' : '20px',
                      }}
                      gutterBottom
                      component="div"
                    >
                      How many unique price lists are there?
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={9}
                    mb={1}
                    sx={{
                      display: 'flex',
                      flexWrap: isMobile ? 'wrap' : ''
                    }}
                    className="animate__animated animate__fadeIn"
                  >
                    {buttonTen?.map((item) => (
                      <Button
                        variant="myPrimary"
                        sx={{
                          backgroundColor:
                            salesQuote?.noCatalogPriceLists?.value !== item.value
                              ? 'white'
                              : '#00313E',
                          width: isMobile ? '150px' : '180px',
                          height: '60px',
                          borderRadius: '15px',
                          border: '1px solid #00313E',
                          marginRight: isMobile ? '18px' : '40px',
                          marginBottom: '10px',
                          marginTop: '10px',
                          '&:hover': {
                            backgroundColor:
                              salesQuote?.noCatalogPriceLists?.value !== item.value
                                ? 'white'
                                : '#00313E',
                          },
                        }}
                        value={item.label}
                        onClick={() => {
                          handleChangeSalesQuotes('noCatalogPriceLists', item.label, item.value);
                        }}
                      >
                        <Typography
                          variant="button"
                          display="block"
                          sx={{
                            color:
                              salesQuote?.noCatalogPriceLists?.value !== item.value
                                ? '#00313E'
                                : '#FFFFFF',
                            fontFamily: 'DinDIsplayProBold',
                            textTransform: 'none',
                            fontSize: isMobile ? '14px' : '18px',
                          }}
                        >
                          {item.label}
                        </Typography>
                      </Button>
                    ))}
                  </Grid>
                </>
              ) : (
                ''
              )}

              {salesQuote?.noCatalogPriceLists?.value && salesQuote?.quoteType?.value === 2 ? (
                <>
                  <Grid item xs={12} md={9} className="animate__animated animate__fadeIn">
                    <Typography
                      variant="body1"
                      sx={{
                        color: '#00313E',
                        fontFamily: 'PreloSlabW04SemiBold',
                        fontSize: isMobile ? '17px' : '20px',
                      }}
                      gutterBottom
                      component="div"
                    >
                      Do you offer product bundles or kits?
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={9}
                    mb={2}
                    sx={{ display: 'flex' }}
                    className="animate__animated animate__fadeIn"
                  >
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                        onChange={(event) =>
                          handleChangeSalesQuotes(
                            'productBundles',
                            event.target.value === "0" ? "No" : "Yes", event.target.value
                          )
                        }
                        sx={{
                          color: '#00313E',
                        }}
                      >
                        <FormControlLabel
                          value={1}
                          control={
                            <Radio
                              checked={salesQuote?.productBundles?.value === 1}
                              size="large"
                              sx={{
                                color: '#00313E',
                                '&.Mui-checked': {
                                  color: '#00313E',
                                },
                              }}
                            />
                          }
                          label="Yes"
                        />
                        <FormControlLabel
                          value={0}
                          control={
                            <Radio
                              checked={salesQuote?.productBundles?.value === 0}
                              size="large"
                              sx={{
                                color: '#00313E',
                                '&.Mui-checked': {
                                  color: '#00313E',
                                },
                              }}
                            />
                          }
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </>
              ) : (
                ''
              )}


            </>

          ) : (
            ''
          )}

          {
            salesQuote?.quotes?.value === 0 || salesQuote?.quoteType?.value === 1 || salesQuote?.productBundles?.value === 0 || salesQuote?.productBundles?.value === 1 ? (
              <>
                <Grid item xs={12} md={9} className="animate__animated animate__fadeIn">
                  <Typography
                    variant="body1"
                    sx={{
                      color: '#00313E',
                      fontFamily: 'PreloSlabW04SemiBold',
                      fontSize: isMobile ? '19px' : '22px',
                      // paddingTop: "60px"
                    }}
                    gutterBottom
                    component="div"
                  >
                    Do you require integration with a digital signature platform for quote acceptance (e.g., DocuSign, Pandadoc, or HelloSign)?
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={9}
                  mb={2}
                  sx={{ display: 'flex' }}
                  className="animate__animated animate__fadeIn"
                >
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="radio-buttons-group"
                      onChange={(event) => { handleChangeSalesQuotes('eSignatureIntegration', event.target.value === "0" ? "No" : "Yes", event.target.value); }
                      }
                      sx={{
                        color: '#00313E',
                      }}
                    >
                      <FormControlLabel
                        value={1}
                        control={
                          <Radio
                            checked={salesQuote?.eSignatureIntegration?.value === 1}
                            size="large"
                            sx={{
                              color: '#00313E',
                              '&.Mui-checked': {
                                color: '#00313E',
                              },
                            }}
                          />
                        }
                        label="Yes"
                      />
                      <FormControlLabel
                        value={0}
                        control={
                          <Radio
                            checked={salesQuote?.eSignatureIntegration?.value === 0}
                            size="large"
                            sx={{
                              color: '#00313E',
                              '&.Mui-checked': {
                                color: '#00313E',
                              },
                            }}
                          />
                        }
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </>

            ) : (
              ''
            )}
          {/* .....................................Grid End............................................... */}
        </Grid>
      </Container>

      <div
        style={{
          background: isMobile ? "white" : "",
          height: isMobile ? "100px" : "70px",
          position: isMobile ? "fixed" : '',
          bottom: "0px",
          width: "100%"
        }}>

        {state?.focus ? (
          <Grid
            item
            xs={12}
            md={3}
            direction="row"
            sx={{
              display: "flex",
              justifyContent: isMobile ? "center" : "flex-end",
              alignItems: "center",
              position: isMobile || isIpad ? '' : 'fixed',
              right: '25px',
              bottom: '25px',
              width: '100%',
              paddingLeft: 0,
              height: isMobile ? "100%" : '120px'
            }}
          >
            <Button
              variant="primary"
              sx={{
                borderRadius: 1,
                border: '1px solid #CFCFCF',
                height: "60px",
                width: "180px",
                marginRight: "15px",

              }}
              onClick={handleCancel}
            >
              <Typography
                variant="button"
                sx={{ fontFamily: 'DinDIsplayProBold', color: '#00313E' }}
              >
                Cancel
              </Typography>
            </Button>

            <LoadingButton
              variant="contained"
              color="neutral"
              loading={loading}
              loadingPosition="end"
              sx={{
                borderRadius: 1,
                backgroundColor: '#00313E',
                height: "60px",
                width: "130px"
              }}
              onClick={() => handleSave()}
            >
              <Typography variant="button" sx={{ fontFamily: 'DinDIsplayProBold' }}>
                Save
              </Typography>
            </LoadingButton>
          </Grid>
        ) : <Grid
          item
          xs={12}
          md={3}
          direction="row"
          sx={{
            display: "flex",
            justifyContent: isMobile ? "center" : "flex-end",
            alignItems: "center",
            position: isMobile || isIpad ? '' : 'fixed',
            right: '25px',
            bottom: '25px',
            width: '100%',
            paddingLeft: 0,
            height: isMobile ? "100%" : '120px'
          }}
        >
          <Button
            variant="primary"
            sx={{
              borderRadius: 1,
              border: '1px solid #CFCFCF',
              height: "60px",
              width: "180px",
              marginRight: "15px",

            }}
            onClick={() => {
              modalRef.current()
              handleSaveData()
            }}
          >
            <Typography
              variant="button"
              sx={{ fontFamily: 'DinDIsplayProBold', color: '#00313E' }}
            >
              Save for later
            </Typography>
          </Button>

          <Button
            variant="contained"
            color="neutral"
            disabled={!show}
            sx={{
              borderRadius: 1,
              backgroundColor: '#00313E',
              height: "60px",
              width: "130px"
            }}
            onClick={() => handleNext()}
          >
            <Typography variant="button" sx={{ fontFamily: 'DinDIsplayProBold' }}>
              Next
            </Typography>
          </Button>
        </Grid>}
      </div>
      <SaveForLater check={check} modalRef={modalRef} />
    </div >
  );
};

const mapStateToProps = (state) => {
  return {
    sales: state.sales.sales,
    stepsStatus: state.home.stepsStatus,
    code: state.pricingCode.code,
    hyperPackage: state.saveSupportPackage.supportPackage,
    saveForLaterCode: state?.saveLater?.code,

  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveSales: (payload) => dispatch(saveSales(payload)),
    setStepsStatus: (payload) => dispatch(setStepsStatus(payload)),

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sales);

