/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/alt-text */
import { makeStyles } from "@mui/styles";
import { Accordion, AccordionDetails, AccordionSummary, Grid, Stack, Typography } from '@mui/material'
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import { connect } from "react-redux";
import edit from '../assets/pen.svg';
import { setStepsStatus } from '../redux/home/home.actions';
import saveSupportPackage from '../redux/supportPackage/supportPackage.actions'
import saveCustomerService from '../redux/customerService/customerService.actions';
import saveBusinessCentral from '../redux/businessCentral/businessCentral.actions';
import saveMarketing from '../redux/marketing/marketing.actions';
import saveSales from '../redux/sales/sales.actions'
import saveEndUsers from '../redux/endUser/endUsers.actions';


const useStyles = makeStyles((theme) => ({
    MuiAccordionroot: {
        "&.MuiAccordion-root": {
            boxShadow: "none",
            borderTop: "1px solid #F4F4F4"
        },
        '& .MuiAccordionSummary-content': {
            paddingLeft: "0px",
            paddingRight: "0px",
        },
    }

}));

const Configuration = ({ support, businessCentral, sales, marketing, customerService, product, endUsers }) => {

    const classes = useStyles();

    const navigate = useNavigate();

    const isMobile = useMediaQuery({ query: `(max-width: 640px)` });

    const isIpad = useMediaQuery({ query: `(max-width: 1024px)` });

    const endUsersProduct = product

    const users = endUsers

    const financialAccounting = [
        { name: "Bank Accounts", value: `${businessCentral?.bcFinanceFoundation?.bankAccounts?.label === 'bankAccounts' || '' ? "NA" : businessCentral?.bcFinanceFoundation?.bankAccounts?.label}` },
        { name: "Functional Currencies", value: `${businessCentral?.bcFinanceFoundation?.functionalCurrencies?.label === 'functionalCurrencies' || '' ? "NA" : businessCentral?.bcFinanceFoundation?.functionalCurrencies?.label}` },
        { name: "Customer Count", value: `${businessCentral?.bcFinanceFoundation?.customerCount?.label === 'customerCount' || '' ? "NA" : businessCentral?.bcFinanceFoundation?.customerCount?.label}` },
        { name: "Vendor Count", value: `${businessCentral?.bcFinanceFoundation?.vendorCount?.label === 'vendorCount' || '' ? "NA" : businessCentral?.bcFinanceFoundation?.vendorCount?.label}` },
        { name: "PO Count", value: `${businessCentral?.bcFinanceFoundation?.poCount?.label === 'poCount' || '' ? "NA" : businessCentral?.bcFinanceFoundation?.poCount?.label}` },
    ]
    const multicompany = [
        { name: "Multi company", value: `${businessCentral?.bcMulticompany?.multicompany?.label === 'multicompany' || '' ? "NA" : businessCentral?.bcMulticompany?.multicompany?.label}` },
        businessCentral?.bcMulticompany?.multicompany?.label === 'More than one company or legal entity' && { name: "Entity Count", value: `${businessCentral?.bcMulticompany?.entityCount?.label === 'entityCount' || '' ? "NA" : businessCentral?.bcMulticompany?.entityCount?.label}` },
        businessCentral?.bcMulticompany?.multicompany?.label === 'More than one company or legal entity' && { name: "Unique COA", value: `${businessCentral?.bcMulticompany?.uniqueCOA?.label === 'uniqueCOA' || '' ? "NA" : businessCentral?.bcMulticompany?.uniqueCOA?.label}` },
        businessCentral?.bcMulticompany?.multicompany?.label === 'More than one company or legal entity' && { name: "Intercompany Required", value: `${businessCentral?.bcMulticompany?.intercompanyRequired?.label === 'intercompanyRequired' || '' ? "NA" : businessCentral?.bcMulticompany?.intercompanyRequired?.label}` },
        businessCentral?.bcMulticompany?.multicompany?.label === 'More than one company or legal entity' && { name: "Consolidation Required", value: `${businessCentral?.bcMulticompany?.consolidationRequired?.label === 'consolidationRequired' || '' ? "NA" : businessCentral?.bcMulticompany?.consolidationRequired?.label}` },
        businessCentral?.bcMulticompany?.multicompany?.label === 'More than one company or legal entity' || businessCentral?.bcMulticompany?.consolidationRequired?.label === "No" && { name: "No Consolidation", value: `${businessCentral?.bcMulticompany?.noConsolidations?.label === 'noConsolidations' || '' ? "NA" : businessCentral?.bcMulticompany?.noConsolidations?.label}` },

    ]
    const fixedAssets = [
        { name: "Fixed Assets", value: `${businessCentral?.bcFixedAssets?.fixedAssets?.label === 'fixedAssets' || '' ? "NA" : businessCentral?.bcFixedAssets?.fixedAssets?.label}` },
        businessCentral?.bcFixedAssets?.fixedAssets?.label === 'Yes' && { name: "No Fixed Assets", value: `${businessCentral?.bcFixedAssets?.noFixedAssets?.label === 'noFixedAssets' || '' ? "NA" : businessCentral?.bcFixedAssets?.noFixedAssets?.label}` },
        businessCentral?.bcFixedAssets?.fixedAssets?.label === 'Yes' && { name: "FA Components", value: `${businessCentral?.bcFixedAssets?.faComponents?.label === 'faComponents' || '' ? "NA" : businessCentral?.bcFixedAssets?.faComponents?.label}` },
    ]

    const salesOrders = [
        { name: "Sales Orders", value: `${businessCentral?.bcSalesOrder?.salesOrders?.label === 'salesOrders' || '' ? "NA" : businessCentral?.bcSalesOrder?.salesOrders?.label}` },
        businessCentral?.bcSalesOrder?.salesOrders?.label === 'Yes' && { name: "Sales Orders Count", value: `${businessCentral?.bcSalesOrder?.soCount?.label === 'soCount' || '' ? "NA" : businessCentral?.bcSalesOrder?.soCount?.label}` },
    ]
    const jobs = [
        { name: "Jobs", value: `${businessCentral?.bcJobs?.jobs?.label === 'jobs' || '' ? "NA" : businessCentral?.bcJobs?.jobs?.label}` },
        businessCentral?.bcJobs?.jobs?.label === 'Yes' && { name: "Billable Jobs", value: `${businessCentral?.bcJobs?.billableJobs?.label === 'billableJobs' || '' ? "NA" : businessCentral?.bcJobs?.billableJobs?.label}` },
    ]

    const bcInventory = [
        { name: "Inventory Items", value: `${businessCentral?.bcInventory?.inventoryItems?.label === 'inventoryItems' || '' ? "NA" : businessCentral?.bcInventory?.inventoryItems?.label}` },
        businessCentral?.bcInventory?.inventoryItems?.label === 'Yes' && { name: "No Sku", value: `${businessCentral?.bcInventory?.noSku?.label === 'noSku' || '' ? "NA" : businessCentral?.bcInventory?.noSku?.label}` },
        businessCentral?.bcInventory?.inventoryItems?.label === 'Yes' && { name: "Lot Tracking", value: `${businessCentral?.bcInventory?.lotTracking?.label === 'lotTracking' || '' ? "NA" : businessCentral?.bcInventory?.lotTracking?.label}` },
        businessCentral?.bcInventory?.inventoryItems?.label === 'Yes' && { name: "No Warehouses", value: `${businessCentral?.bcInventory?.noWarehouses?.label === 'noWarehouses' || '' ? "NA" : businessCentral?.bcInventory?.noWarehouses?.label}` },
        businessCentral?.bcInventory?.inventoryItems?.label === 'Yes' && { name: "Warehouse Logistics", value: `${businessCentral?.bcInventory?.warehouseLogistics?.label === 'warehouseLogistics' || '' ? "NA" : businessCentral?.bcInventory?.warehouseLogistics?.label}` },
    ]

    const bcAssemblyManagement = [
        { name: "Assembly Management", value: `${businessCentral?.bcAssemblyManagement?.assemblyMgmt?.label === 'assemblyMgmt' || '' ? "NA" : businessCentral?.bcAssemblyManagement?.assemblyMgmt?.label}` },
        businessCentral?.bcAssemblyManagement?.assemblyMgmt?.label === 'Yes' && { name: "No Aboms", value: `${businessCentral?.bcAssemblyManagement?.noAboms?.label === 'noAboms' || '' ? "NA" : businessCentral?.bcAssemblyManagement?.noAboms?.label}` },
        businessCentral?.bcAssemblyManagement?.assemblyMgmt?.label === 'Yes' && { name: "Edi", value: `${businessCentral?.bcAssemblyManagement?.edi?.label === 'edi' || '' ? "NA" : businessCentral?.bcAssemblyManagement?.edi?.label}` },
    ]

    const bcManufacturing = [
        { name: "Manufacturing", value: `${businessCentral?.bcManufacturing?.manufacturing?.label === 'manufacturing' || '' ? "NA" : businessCentral?.bcManufacturing?.manufacturing?.label}` },
        businessCentral?.bcManufacturing?.manufacturing?.label === 'Yes' && { name: "Routings", value: `${businessCentral?.bcManufacturing?.routings?.label === 'routings' || '' ? "NA" : businessCentral?.bcManufacturing?.routings?.label}` },
        businessCentral?.bcManufacturing?.manufacturing?.label === 'Yes' && { name: "Work Centers", value: `${businessCentral?.bcManufacturing?.workCenters?.label === 'workCenters' || '' ? "NA" : businessCentral?.bcManufacturing?.workCenters?.label}` },
        businessCentral?.bcManufacturing?.manufacturing?.label === 'Yes' && { name: "Prod Locations", value: `${businessCentral?.bcManufacturing?.prodLocations?.label === 'prodLocations' || '' ? "NA" : businessCentral?.bcManufacturing?.prodLocations?.label}` },
        businessCentral?.bcManufacturing?.manufacturing?.label === 'Yes' && { name: "MBoms", value: `${businessCentral?.bcManufacturing?.mBoms?.label === 'mBoms' || '' ? "NA" : businessCentral?.bcManufacturing?.mBoms?.label}` },
        businessCentral?.bcManufacturing?.manufacturing?.label === 'Yes' && { name: "EdiM", value: `${businessCentral?.bcManufacturing?.ediM?.label === 'ediM' || '' ? "NA" : businessCentral?.bcManufacturing?.ediM?.label}` },
    ]

    const salesQuoteData = [
        { name: "Sales Quote", value: `${sales?.salesQuote?.quotes?.label === 'quotes' || '' ? "NA" : sales?.salesQuote?.quotes?.label}` },
        sales?.salesQuote?.quotes?.label === 'Yes' && { name: "E Signature Integration", value: `${sales?.salesQuote?.eSignatureIntegration?.label === 'eSignatureIntegration' || '' ? "NA" : sales?.salesQuote?.eSignatureIntegration?.label}` },
        sales?.salesQuote?.quotes?.label === 'Yes' && { name: "Quote Type", value: `${sales?.salesQuote?.quoteType?.label === 'quoteType' || '' ? "NA" : sales?.salesQuote?.quoteType?.label}` },
        sales?.salesQuote?.quotes?.label === 'Yes' || sales?.salesQuote?.quoteType?.label === 'Price list' && { name: "No Catalog Price Lists", value: `${sales?.salesQuote?.noCatalogPriceLists?.label === 'noCatalogPriceLists' || '' ? "NA" : sales?.salesQuote?.noCatalogPriceLists?.label}` },
        sales?.salesQuote?.quotes?.label === 'Yes' || sales?.salesQuote?.quoteType?.label === 'Price list' && { name: "Product Bundles", value: `${sales?.salesQuote?.productBundles?.label === 'productBundles' || '' ? "NA" : sales?.salesQuote?.productBundles?.label}` },
    ]

    const salesLeadsData = [
        { name: "Websites Leads", value: `${sales?.salesManagement?.websitesLeads?.label === 'websitesLeads' || '' ? "NA" : sales?.salesManagement?.websitesLeads?.label}` },
        { name: "Email Leads", value: `${sales?.salesManagement?.emailLeads?.label === 'emailLeads' || '' ? "NA" : sales?.salesManagement?.emailLeads?.label}` },
        { name: "Lead Lists", value: `${sales?.salesManagement?.leadLists?.label === "leadLists" || '' ? "NA" : sales?.salesManagement?.leadLists?.label}` },
        { name: "Other", value: `${sales?.salesManagement?.other?.label === 'other' || '' ? "NA" : sales?.salesManagement?.other?.label}` },
        { name: "Other Description", value: `${sales?.salesManagement?.otherDescription?.value === 'otherDescription' || '' ? "NA" : sales?.salesManagement?.otherDescription?.value}` },
        { name: "Lead Routing", value: `${sales?.salesManagement?.leadRouting?.label === 'leadRouting' || '' ? "NA" : sales?.salesManagement?.leadRouting?.label}` },
        { name: "Lead Scoring", value: `${sales?.salesManagement?.leadScoring?.label === 'leadScoring' || '' ? "NA" : sales?.salesManagement?.leadScoring?.label}` },
        { name: "Sales Funnels", value: `${sales?.salesManagement?.buSalesFunnels?.label === 'buSalesFunnels' || '' ? "NA" : sales?.salesManagement?.buSalesFunnels?.label}` },
        { name: "Sales Processes", value: `${sales?.salesManagement?.salesProcesses?.label === 'salesProcesses' || '' ? "NA" : sales?.salesManagement?.salesProcesses?.label}` },
        { name: "Collabrative Sales", value: `${sales.salesManagement.collabrativeSales.label === 'collabrativeSales' || '' ? "NA" : sales.salesManagement.collabrativeSales.label}` },
        { name: "Sales Target Tracking", value: `${sales?.salesManagement?.salesTargetTracking?.label === 'salesTargetTracking' || '' ? "NA" : sales?.salesManagement?.salesTargetTracking?.label}` },

    ]

    const customerData = [
        { name: "Unique Service Processess", value: `${customerService?.customerServiceData?.uniqueServiceProcesses.label === 'uniqueServiceProcesses' || '' ? "NA" : customerService?.customerServiceData?.uniqueServiceProcesses.label}` },
        { name: "Customer Self Serve Portal", value: `${customerService?.customerServiceData?.customerSelfServePortal.label === 'customerSelfServePortal' || '' ? "NA" : customerService?.customerServiceData?.customerSelfServePortal.label}` },
        { name: "Work Orders", value: `${customerService?.customerServiceData?.workOrders.label === 'workOrders' || '' ? "NA" : customerService?.customerServiceData?.workOrders.label}` },
        customerService?.customerServiceData?.workOrders.label === 'Yes' && { name: "Work Order Routing", value: `${customerService?.customerServiceData?.workOrderRouting.label === 'workOrderRouting' || '' ? "NA" : customerService?.customerServiceData?.workOrderRouting.label}` },
        { name: "Support Contracts", value: `${customerService?.customerServiceData?.supportContracts.label === 'supportContracts' || '' ? "NA" : customerService?.customerServiceData?.supportContracts.label}` },
        { name: "Self Serve KBs", value: `${customerService?.customerServiceData?.selfserveKBs.label === 'selfserveKBs' || '' ? "NA" : customerService?.customerServiceData?.selfserveKBs.label}` },
    ]

    const marketingData = [
        { name: "Unique Templates", value: `${marketing?.marketingData?.uniqueTemplates?.label === 'uniqueTemplates' || '' ? "NA" : marketing?.marketingData?.uniqueTemplates?.label}` },
        { name: "Nurture Campaigns", value: `${marketing?.marketingData?.nurtureCampaigns?.label === 'nurtureCampaigns' || '' ? "NA" : marketing?.marketingData?.nurtureCampaigns?.label}` },
        marketing?.marketingData?.nurtureCampaigns?.label === 'Yes' && { name: "Campaign Specific Landing Pages", value: `${marketing?.marketingData?.campaignSpecificLandingPages?.label === 'campaignSpecificLandingPages' || '' ? "NA" : marketing?.marketingData?.campaignSpecificLandingPages?.label}` },
        marketing?.marketingData?.campaignSpecificLandingPages?.label === 'Yes' && { name: "Landing Pages", value: `${marketing?.marketingData?.landingPages?.label === 'landingPages' || '' ? "NA" : marketing?.marketingData?.landingPages?.label}` },
        { name: "Surveys", value: `${marketing?.marketingData?.surveys?.label === 'surveys' || '' ? "NA" : marketing?.marketingData?.surveys?.label}` },
        { name: "Event Registration", value: `${marketing?.marketingData?.eventRegistration?.label === 'eventRegistration' || '' ? "NA" : marketing?.marketingData?.eventRegistration?.label}` },
    ]

    const endUsersBusinessCentral = [
        { name: "Full Users", value: endUsers?.bcFullUsers },
        { name: "Team Users", value: endUsers?.bcTeamUsers },
    ]

    const endUsersSales = [
        { name: "Full Users", value: endUsers?.salesFullUsers },
        { name: "Team Users", value: endUsers?.salesTeamUsers },
    ]
    const endUsersCustomerService = [
        { name: "Full Users", value: endUsers?.serviceFullUsers },
        { name: "Team Users", value: endUsers?.serviceTeamUsers },
    ]

    const supportPackage = [
        { name: "Launch", value: endUsers?.serviceFullUsers },
        { name: "Optimize", value: endUsers?.serviceTeamUsers },
        { name: "Extend", value: endUsers?.serviceTeamUsers },
    ]
    const supportLaunch = [
        { name: 'HelpDesk Support', value: "10 cases/year" },
        { name: 'Upgrade Management & New Capabilities Showcase ', value: "Yes" },
        { name: 'Solution Admin & Monitoring', value: "Yes" },
        { name: 'Data Backup & Recovery', value: "Yes" },
        { name: 'Monthly Support Reviews', value: "Yes" },
        { name: 'Annual Solution Review', value: "Yes" },
    ]

    const supportOptimise = [
        { name: 'HelpDesk Support', value: "30 cases/year" },
        { name: 'Upgrade Management & New Capabilities Showcase ', value: "Yes" },
        { name: 'Solution Admin & Monitoring', value: "Yes" },
        { name: 'Data Backup & Recovery', value: "Yes" },
        { name: 'Monthly Support Reviews', value: "Yes" },
        { name: 'Annual Solution Review', value: "Yes" },
        { name: 'Training', value: "4 sessions/year" },
        {name: 'ClickLearn Training Content Portal', value: "Yes*"}
    ]

    const supportExtend = [
        { name: 'HelpDesk Support', value: "Unlimited cases" },
        { name: 'Upgrade Management & New Capabilities Showcase ', value: "Yes" },
        { name: 'Solution Admin & Monitoring', value: "Yes" },
        { name: 'Data Backup & Recovery', value: "Yes" },
        { name: 'Monthly Support Reviews', value: "Yes" },
        { name: 'Annual Solution Review', value: "Yes" },
        { name: 'Training', value: "12 sessions/year" },
        {name: 'ClickLearn Training Content Portal', value: "Training content updated twice annually"},
        {name: 'Enhancement Planning Sessions ', value: 'Yes'}
    ]



    return (
        <div>
            <Grid item xs={12} sx={{ textAlign: 'center', borderTop: "1px solid  #F4F4F4", borderBottom: "1px solid  #F4F4F4" }}>
                <Accordion classes={{
                    root: classes.MuiAccordionroot
                }}>
                    <AccordionSummary
                        sx={{ padding: "0px" }}
                        expandIcon={<ExpandMoreIcon sx={{ color: '#00313E' }} />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography
                            className={classes.heading}
                            variant="body2"
                            component="div"
                            my={1}
                            sx={{
                                fontFamily: 'PreloSlabW04Medium',
                                color: '#00313E',
                                fontSize: isMobile ? "16px" : '18px',
                                width: "100%",
                                textAlign: "left"
                            }}
                        >
                            Review/Change Business Details
                        </Typography>
                    </AccordionSummary>
                    {product.includes('1') && <>
                        <AccordionDetails sx={{ padding: "0px 0px 0px 0px" }}>
                            <Typography
                                variant="body2"
                                component="div"
                                my={1}
                                sx={{
                                    fontFamily: 'PreloSlabW04Bold',
                                    color: '#00313E',
                                    fontSize: isMobile ? "16px" : '18px',
                                    width: "100%",
                                    textAlign: "left"
                                }}
                            >
                                Dynamics 365 Business Central
                            </Typography>
                        </AccordionDetails>
                        {/* ----------------------------------Central---------------------------------------------------------- */}
                        <Accordion classes={{ root: classes.MuiAccordionroot }}  >
                            <AccordionSummary
                                sx={{ padding: "0px" }}
                                expandIcon={<ExpandMoreIcon sx={{ color: '#00313E' }} />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <Typography
                                    variant="body2"
                                    component="div"
                                    sx={{
                                        fontFamily: 'PreloSlabW04Light',
                                        color: '#00313E',
                                        fontSize: isMobile ? "14px" : '18px',
                                        width: isMobile ? "68%" : "30%",
                                        display: "flex",
                                        justifyContent: "space-between"
                                    }}
                                >
                                    Financial Accounting
                                    <span style={{ marginLeft: "10px" }}><img src={edit} onClick={() => navigate('/business-details/business-central/', { state: { focus: 'financialAccounting' } })} /></span>
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={{ padding: "0px 0px 0px 0px" }}>
                                {financialAccounting.map((item) => (
                                    <Stack
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        key={item}
                                        my={2}
                                        spacing={1}>
                                        <Typography
                                            variant="body2"

                                            component="div"
                                            sx={{
                                                fontFamily: 'PreloSlabW04Medium',
                                                color: '#00313E',
                                                fontSize: isMobile ? "12px" : '16px',
                                                textAlign: "center"
                                            }}
                                        >
                                            {item.name}
                                        </Typography>

                                        <Typography
                                            variant="body2"

                                            component="div"
                                            sx={{
                                                fontFamily: 'PreloSlabW04Medium',
                                                color: '#00313E',
                                                fontSize: isMobile ? "12px" : '16px',
                                                textAlign: "center"
                                            }}
                                        >
                                            {item.value}
                                        </Typography>

                                    </Stack>
                                )
                                )}

                            </AccordionDetails>
                        </Accordion>
                        <Accordion classes={{ root: classes.MuiAccordionroot }}  >
                            <AccordionSummary
                                sx={{ padding: "0px" }}
                                expandIcon={<ExpandMoreIcon sx={{ color: '#00313E' }} />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <Typography
                                    variant="body2"
                                    component="div"
                                    sx={{
                                        fontFamily: 'PreloSlabW04Light',
                                        color: '#00313E',
                                        fontSize: isMobile ? "14px" : '18px',
                                        width: isMobile ? "68%" : "30%",
                                        display: "flex",
                                        justifyContent: "space-between"
                                    }}
                                >
                                    Multi Company
                                    <span style={{ marginLeft: "10px" }}>
                                        <img src={edit}
                                            onClick={() => navigate('/business-details/business-central', { state: { focus: 'multiCompany' } })} /></span>
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails
                                sx={{
                                    padding: "10px 0px 10px 0px",
                                    height: businessCentral?.bcMulticompany?.multicompany?.label === 'More than one company or legal entity' ? '' : '40px'
                                }}>
                                {multicompany.map((item) => (
                                    <Stack
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        key={item}
                                        my={2}
                                        spacing={1}>
                                        <Typography
                                            variant="body2"

                                            component="div"
                                            sx={{
                                                fontFamily: 'PreloSlabW04Medium',
                                                color: '#00313E',
                                                fontSize: isMobile ? "12px" : '16px',
                                                textAlign: "center"
                                            }}
                                        >
                                            {item.name}
                                        </Typography>

                                        <Typography
                                            variant="body2"

                                            component="div"
                                            sx={{
                                                fontFamily: 'PreloSlabW04Medium',
                                                color: '#00313E',
                                                fontSize: isMobile ? "12px" : '16px',
                                                textAlign: "center"
                                            }}
                                        >
                                            {item?.value}
                                        </Typography>

                                    </Stack>
                                )

                                )}

                            </AccordionDetails>
                        </Accordion>
                        <Accordion classes={{ root: classes.MuiAccordionroot }}  >
                            <AccordionSummary
                                sx={{ padding: "0px" }}
                                expandIcon={<ExpandMoreIcon sx={{ color: '#00313E' }} />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <Typography
                                    variant="body2"
                                    component="div"
                                    sx={{
                                        fontFamily: 'PreloSlabW04Light',
                                        color: '#00313E',
                                        fontSize: isMobile ? "14px" : '18px',
                                        width: isMobile ? "68%" : "30%",
                                        display: "flex",
                                        justifyContent: "space-between"
                                    }}
                                >
                                    Fixed Assets
                                    <span style={{ marginLeft: "10px" }}><img src={edit} onClick={() => navigate('/business-details/business-central', { state: { focus: 'fixedAssets' } })} /></span>
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={{
                                padding: "10px 0px 10px 0px",
                                height: businessCentral?.bcFixedAssets?.fixedAssets?.label === 'Yes' ? "" : "40px"
                            }}>
                                {fixedAssets.map((item) => (
                                    <Stack
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        key={item}
                                        my={2}
                                        spacing={1}>
                                        <Typography
                                            variant="body2"

                                            component="div"
                                            sx={{
                                                fontFamily: 'PreloSlabW04Medium',
                                                color: '#00313E',
                                                fontSize: isMobile ? "12px" : '16px',
                                                textAlign: "center"
                                            }}
                                        >
                                            {item.name}
                                        </Typography>

                                        <Typography
                                            variant="body2"

                                            component="div"
                                            sx={{
                                                fontFamily: 'PreloSlabW04Medium',
                                                color: '#00313E',
                                                fontSize: isMobile ? "12px" : '16px',
                                                textAlign: "center"
                                            }}
                                        >
                                            {item.value}
                                        </Typography>

                                    </Stack>
                                )

                                )}

                            </AccordionDetails>
                        </Accordion>
                        <Accordion classes={{ root: classes.MuiAccordionroot }}  >
                            <AccordionSummary
                                sx={{ padding: "0px" }}
                                expandIcon={<ExpandMoreIcon sx={{ color: '#00313E' }} />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <Typography
                                    variant="body2"
                                    component="div"
                                    sx={{
                                        fontFamily: 'PreloSlabW04Light',
                                        color: '#00313E',
                                        fontSize: isMobile ? "14px" : '18px',
                                        width: isMobile ? "68%" : "30%",
                                        display: "flex",
                                        justifyContent: "space-between"
                                    }}
                                >
                                    Sales Orders
                                    <span style={{ marginLeft: "10px" }}><img src={edit} onClick={() => navigate('/business-details/business-central', { state: { focus: 'salesOrders' } })} /></span>
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={{
                                padding: "0px 0px 0px 0px",
                                height: businessCentral?.bcSalesOrder?.salesOrders?.label === 'Yes' ? '' : "40px"
                            }}>
                                {salesOrders.map((item) => (
                                    <Stack
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        key={item}
                                        my={2}
                                        spacing={1}>
                                        <Typography
                                            variant="body2"

                                            component="div"
                                            sx={{
                                                fontFamily: 'PreloSlabW04Medium',
                                                color: '#00313E',
                                                fontSize: isMobile ? "12px" : '16px',
                                                textAlign: "center"
                                            }}
                                        >
                                            {item.name}
                                        </Typography>

                                        <Typography
                                            variant="body2"

                                            component="div"
                                            sx={{
                                                fontFamily: 'PreloSlabW04Medium',
                                                color: '#00313E',
                                                fontSize: isMobile ? "12px" : '16px',
                                                textAlign: "center"
                                            }}
                                        >
                                            {item.value}
                                        </Typography>

                                    </Stack>
                                )

                                )}

                            </AccordionDetails>
                        </Accordion>
                        <Accordion classes={{ root: classes.MuiAccordionroot }}  >
                            <AccordionSummary
                                sx={{ padding: "0px" }}
                                expandIcon={<ExpandMoreIcon sx={{ color: '#00313E' }} />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <Typography
                                    variant="body2"
                                    component="div"
                                    sx={{
                                        fontFamily: 'PreloSlabW04Light',
                                        color: '#00313E',
                                        fontSize: isMobile ? "14px" : '18px',
                                        width: isMobile ? "68%" : "30%",
                                        display: "flex",
                                        justifyContent: "space-between"
                                    }}
                                >
                                    Jobs
                                    <span style={{ marginLeft: "10px" }}><img src={edit} onClick={() => navigate('/business-details/business-central', { state: { focus: 'jobs' } })} /></span>
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={{
                                padding: "0px 0px 0px 0px",
                                height: businessCentral?.bcJobs?.jobs?.label === 'Yes' ? '' : '40px'
                            }}>
                                {jobs.map((item) => (
                                    <Stack
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        key={item}
                                        my={2}
                                        spacing={1}>
                                        <Typography
                                            variant="body2"

                                            component="div"
                                            sx={{
                                                fontFamily: 'PreloSlabW04Medium',
                                                color: '#00313E',
                                                fontSize: isMobile ? "12px" : '16px',
                                                textAlign: "center"
                                            }}
                                        >
                                            {item.name}
                                        </Typography>

                                        <Typography
                                            variant="body2"

                                            component="div"
                                            sx={{
                                                fontFamily: 'PreloSlabW04Medium',
                                                color: '#00313E',
                                                fontSize: isMobile ? "12px" : '16px',
                                                textAlign: "center"
                                            }}
                                        >
                                            {item.value}
                                        </Typography>

                                    </Stack>
                                )

                                )}

                            </AccordionDetails>
                        </Accordion>
                        <Accordion classes={{ root: classes.MuiAccordionroot }}  >
                            <AccordionSummary
                                sx={{ padding: "0px" }}
                                expandIcon={<ExpandMoreIcon sx={{ color: '#00313E' }} />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <Typography
                                    variant="body2"
                                    component="div"
                                    sx={{
                                        fontFamily: 'PreloSlabW04Light',
                                        color: '#00313E',
                                        fontSize: isMobile ? "14px" : '18px',
                                        width: isMobile ? "68%" : "30%",
                                        display: "flex",
                                        justifyContent: "space-between"
                                    }}
                                >
                                    Inventory
                                    <span style={{ marginLeft: "10px" }}><img src={edit} onClick={() => navigate('/business-details/business-central', { state: { focus: 'inventory' } })} /></span>
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={{
                                padding: "10px 0px 10px 0px",
                                height: businessCentral?.bcInventory?.inventoryItems?.label === 'Yes' ? '' : "40px"
                            }}>
                                {bcInventory.map((item) => (
                                    <Stack
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        key={item}
                                        my={2}
                                        spacing={1}>
                                        <Typography
                                            variant="body2"

                                            component="div"
                                            sx={{
                                                fontFamily: 'PreloSlabW04Medium',
                                                color: '#00313E',
                                                fontSize: isMobile ? "12px" : '16px',
                                                textAlign: "center"
                                            }}
                                        >
                                            {item.name}
                                        </Typography>

                                        <Typography
                                            variant="body2"

                                            component="div"
                                            sx={{
                                                fontFamily: 'PreloSlabW04Medium',
                                                color: '#00313E',
                                                fontSize: isMobile ? "12px" : '16px',
                                                textAlign: "center"
                                            }}
                                        >
                                            {item.value}
                                        </Typography>

                                    </Stack>
                                )

                                )}

                            </AccordionDetails>
                        </Accordion>
                        <Accordion classes={{ root: classes.MuiAccordionroot }}  >
                            <AccordionSummary
                                sx={{ padding: "0px" }}
                                expandIcon={<ExpandMoreIcon sx={{ color: '#00313E' }} />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <Typography
                                    variant="body2"
                                    component="div"
                                    sx={{
                                        fontFamily: 'PreloSlabW04Light',
                                        color: '#00313E',
                                        fontSize: isMobile ? "14px" : '18px',
                                        width: isMobile ? "68%" : "30%",
                                        display: "flex",
                                        justifyContent: "space-between"
                                    }}
                                >
                                    Assembly Management
                                    <span style={{ marginLeft: "10px" }}><img src={edit}
                                        onClick={() => navigate('/business-details/business-central', { state: { focus: 'assembly' } })}
                                    />
                                    </span>
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={{
                                padding: "10px 0px 10px 0px",
                                height: businessCentral?.bcAssemblyManagement?.assemblyMgmt?.label === 'Yes' ? '' : "40px",
                            }}>
                                {bcAssemblyManagement.map((item) => (
                                    <Stack
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        key={item}
                                        my={2}
                                        spacing={1}>
                                        <Typography
                                            variant="body2"

                                            component="div"
                                            sx={{
                                                fontFamily: 'PreloSlabW04Medium',
                                                color: '#00313E',
                                                fontSize: isMobile ? "12px" : '16px',
                                                textAlign: "center"
                                            }}
                                        >
                                            {item.name}
                                        </Typography>

                                        <Typography
                                            variant="body2"

                                            component="div"
                                            sx={{
                                                fontFamily: 'PreloSlabW04Medium',
                                                color: '#00313E',
                                                fontSize: isMobile ? "12px" : '16px',
                                                textAlign: "center"
                                            }}
                                        >
                                            {item.value}
                                        </Typography>

                                    </Stack>
                                )

                                )}

                            </AccordionDetails>
                        </Accordion>
                        <Accordion classes={{ root: classes.MuiAccordionroot }}  >
                            <AccordionSummary
                                sx={{ padding: "0px" }}
                                expandIcon={<ExpandMoreIcon sx={{ color: '#00313E' }} />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <Typography
                                    variant="body2"
                                    component="div"
                                    sx={{
                                        fontFamily: 'PreloSlabW04Light',
                                        color: '#00313E',
                                        fontSize: isMobile ? "14px" : '18px',
                                        width: isMobile ? "68%" : "30%",
                                        display: "flex",
                                        justifyContent: "space-between"
                                    }}
                                >
                                    Manufacturing
                                    <span style={{ marginLeft: "10px" }}><img src={edit} onClick={() => navigate('/business-details/business-central', { state: { focus: 'manufacturing' } })} /></span>
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={{
                                padding: "10px 0px 10px 0px",
                                height: businessCentral?.bcManufacturing?.manufacturing?.label === 'Yes' ? '' : '40px',
                            }}>
                                {bcManufacturing.map((item) => (
                                    <Stack
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        key={item}
                                        my={2}
                                        spacing={1}>
                                        <Typography
                                            variant="body2"

                                            component="div"
                                            sx={{
                                                fontFamily: 'PreloSlabW04Medium',
                                                color: '#00313E',
                                                fontSize: isMobile ? "12px" : '16px',
                                                textAlign: "center"
                                            }}
                                        >
                                            {item.name}
                                        </Typography>

                                        <Typography
                                            variant="body2"

                                            component="div"
                                            sx={{
                                                fontFamily: 'PreloSlabW04Medium',
                                                color: '#00313E',
                                                fontSize: isMobile ? "12px" : '16px',
                                                textAlign: "center"
                                            }}
                                        >
                                            {item.value}
                                        </Typography>

                                    </Stack>
                                )

                                )}

                            </AccordionDetails>
                        </Accordion>
                    </>}
                    {/* ---------------------- --------------------------------------Sales------------------------------------- */}
                    {product.includes('2') && <>
                        <AccordionDetails sx={{ padding: "0px 0px 0px 0px" }}>
                            <Typography
                                variant="body2"
                                component="div"
                                my={1}
                                sx={{
                                    fontFamily: 'PreloSlabW04Bold',
                                    color: '#00313E',
                                    fontSize: isMobile ? "16px" : '18px',
                                    width: "100%",
                                    textAlign: "left"
                                }}
                            >
                                Dynamics 365 Sales
                            </Typography>
                        </AccordionDetails>
                        <Accordion classes={{ root: classes.MuiAccordionroot }}  >
                            <AccordionSummary
                                sx={{ padding: "0px" }}
                                expandIcon={<ExpandMoreIcon sx={{ color: '#00313E' }} />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <Typography
                                    variant="body2"
                                    component="div"
                                    sx={{
                                        fontFamily: 'PreloSlabW04Light',
                                        color: '#00313E',
                                        fontSize: isMobile ? "14px" : '18px',
                                        width: isMobile ? "68%" : "30%",
                                        display: "flex",
                                        justifyContent: "space-between"
                                    }}
                                >
                                    Leads & Opportunities
                                    <span style={{ marginLeft: "10px" }}>
                                        <img src={edit}
                                            onClick={() => navigate('/business-details/sales',
                                                { state: { focus: 'leads' } })} /></span>
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={{ padding: "0px 0px 0px 0px" }}>
                                {salesLeadsData.map((item) => (
                                    <Stack
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        key={item}
                                        my={2}
                                        spacing={1}>
                                        <Typography
                                            variant="body2"

                                            component="div"
                                            sx={{
                                                fontFamily: 'PreloSlabW04Medium',
                                                color: '#00313E',
                                                fontSize: isMobile ? "12px" : '16px',
                                                textAlign: "center"
                                            }}
                                        >
                                            {item.name}
                                        </Typography>

                                        <Typography
                                            variant="body2"

                                            component="div"
                                            sx={{
                                                fontFamily: 'PreloSlabW04Medium',
                                                color: '#00313E',
                                                fontSize: isMobile ? "12px" : '16px',
                                                textAlign: "center"
                                            }}
                                        >
                                            {item.value ? item.value : "NA"}
                                        </Typography>

                                    </Stack>
                                )

                                )}

                            </AccordionDetails>
                        </Accordion>
                        <Accordion classes={{ root: classes.MuiAccordionroot }}  >
                            <AccordionSummary
                                sx={{ padding: "0px" }}
                                expandIcon={<ExpandMoreIcon sx={{ color: '#00313E' }} />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <Typography
                                    variant="body2"
                                    component="div"
                                    sx={{
                                        fontFamily: 'PreloSlabW04Light',
                                        color: '#00313E',
                                        fontSize: isMobile ? "14px" : '18px',
                                        width: isMobile ? "68%" : "30%",
                                        display: "flex",
                                        justifyContent: "space-between"
                                    }}
                                >
                                    Quotes
                                    <span style={{ marginLeft: "10px" }}><img src={edit} onClick={() => navigate('/business-details/sales', { state: { focus: 'quotes' } })} /></span>
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={{
                                padding: "10px 0px 10px 0px",
                                height: sales?.salesQuote?.quotes?.label === 'Yes' ? '' : '40px'
                            }}>
                                {salesQuoteData.map((item) => (
                                    <Stack
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        key={item}
                                        my={2}
                                        spacing={1}>
                                        <Typography
                                            variant="body2"

                                            component="div"
                                            sx={{
                                                fontFamily: 'PreloSlabW04Medium',
                                                color: '#00313E',
                                                fontSize: isMobile ? "12px" : '16px',
                                                textAlign: "center"
                                            }}
                                        >
                                            {item.name}
                                        </Typography>

                                        <Typography
                                            variant="body2"

                                            component="div"
                                            sx={{
                                                fontFamily: 'PreloSlabW04Medium',
                                                color: '#00313E',
                                                fontSize: isMobile ? "12px" : '16px',
                                                textAlign: "center"
                                            }}
                                        >
                                            {item.value}
                                        </Typography>

                                    </Stack>
                                ))}

                            </AccordionDetails>
                        </Accordion>
                    </>}
                    {
                        product.includes('3') && <>
                            <AccordionDetails sx={{ padding: "0px 0px 0px 0px" }}>
                                <Typography
                                    variant="body2"
                                    component="div"
                                    my={1}
                                    sx={{
                                        fontFamily: 'PreloSlabW04Bold',
                                        color: '#00313E',
                                        fontSize: isMobile ? "16px" : '18px',
                                        width: "100%",
                                        textAlign: "left"
                                    }}
                                >
                                    Dynamics 365 Marketing
                                </Typography>
                            </AccordionDetails>
                            <Accordion classes={{ root: classes.MuiAccordionroot }}  >
                                <AccordionSummary
                                    sx={{ padding: "0px" }}
                                    expandIcon={<ExpandMoreIcon sx={{ color: '#00313E' }} />}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                >
                                    <Typography
                                        variant="body2"
                                        component="div"
                                        sx={{
                                            fontFamily: 'PreloSlabW04Light',
                                            color: '#00313E',
                                            fontSize: isMobile ? "14px" : '18px',
                                            width: isMobile ? "68%" : "30%",
                                            display: "flex",
                                            justifyContent: "space-between"
                                        }}
                                    >
                                        D365 Marketing
                                        <span style={{ marginLeft: "10px" }}><img src={edit} onClick={() => navigate('/business-details/marketing', { state: { focus: 'marketing' } })} /></span>
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails sx={{ padding: "0px 0px 0px 0px" }}>
                                    {marketingData.map((item) => (
                                        <Stack
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            key={item}
                                            my={2}
                                            spacing={1}>
                                            <Typography
                                                variant="body2"

                                                component="div"
                                                sx={{
                                                    fontFamily: 'PreloSlabW04Medium',
                                                    color: '#00313E',
                                                    fontSize: isMobile ? "12px" : '16px',
                                                    textAlign: "center"
                                                }}
                                            >
                                                {item.name}
                                            </Typography>

                                            <Typography
                                                variant="body2"

                                                component="div"
                                                sx={{
                                                    fontFamily: 'PreloSlabW04Medium',
                                                    color: '#00313E',
                                                    fontSize: isMobile ? "12px" : '16px',
                                                    textAlign: "center"
                                                }}
                                            >
                                                {item.value}
                                            </Typography>

                                        </Stack>
                                    ))}
                                </AccordionDetails>
                            </Accordion>
                        </>
                    }
                    {product.includes('4') && <>
                        <AccordionDetails sx={{ padding: "0px 0px 0px 0px" }}>
                            <Typography
                                variant="body2"
                                component="div"
                                my={1}
                                sx={{
                                    fontFamily: 'PreloSlabW04Bold',
                                    color: '#00313E',
                                    fontSize: isMobile ? "16px" : '18px',
                                    width: "100%",
                                    textAlign: "left"
                                }}
                            >
                                Dynamics 365 Customer Service
                            </Typography>

                        </AccordionDetails>
                        <Accordion classes={{ root: classes.MuiAccordionroot }}  >
                            <AccordionSummary
                                sx={{ padding: "0px" }}
                                expandIcon={<ExpandMoreIcon
                                    sx={{ color: '#00313E' }}
                                />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <Typography
                                    variant="body2"
                                    component="div"
                                    sx={{
                                        fontFamily: 'PreloSlabW04Light',
                                        color: '#00313E',
                                        fontSize: isMobile ? "14px" : '18px',
                                        width: isMobile ? "68%" : "30%",
                                        display: "flex",
                                        justifyContent: "space-between"
                                    }}
                                >   D365 Customer Service
                                    <span style={{ marginLeft: "10px" }}><img src={edit} onClick={() => navigate('/business-details/customer-service', { state: { focus: 'customerService' } })} /></span>
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={{ padding: "0px 0px 0px 0px" }}>

                                {customerData.map((item) => (
                                    <Stack
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        key={item}
                                        my={2}
                                        spacing={1}>
                                        <Typography
                                            variant="body2"

                                            component="div"
                                            sx={{
                                                fontFamily: 'PreloSlabW04Medium',
                                                color: '#00313E',
                                                fontSize: isMobile ? "12px" : '16px',
                                                textAlign: "center"
                                            }}
                                        >
                                            {item.name}
                                        </Typography>

                                        <Typography
                                            variant="body2"

                                            component="div"
                                            sx={{
                                                fontFamily: 'PreloSlabW04Medium',
                                                color: '#00313E',
                                                fontSize: isMobile ? "12px" : '16px',
                                                textAlign: "center"
                                            }}
                                        >
                                            {item.value}
                                        </Typography>

                                    </Stack>
                                ))}

                            </AccordionDetails>
                        </Accordion>

                    </>}


                    {/* ---------------------------------------------End Users---------------------------------------------- */}

                </Accordion>

                <Accordion classes={{
                    root: classes.MuiAccordionroot
                }}>


                    <AccordionSummary
                        sx={{ padding: "0px" }}
                        expandIcon={<ExpandMoreIcon sx={{ color: '#00313E' }} />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography
                            className={classes.heading}
                            variant="body2"
                            component="div"
                            my={1}
                            sx={{
                                fontFamily: 'PreloSlabW04Medium',
                                color: '#00313E',
                                fontSize: isMobile ? "16px" : '18px',
                                width: "100%",
                                textAlign: "left"
                            }}
                        >
                            Review/Change Users
                        </Typography>
                    </AccordionSummary>

                    {endUsersProduct.includes('1') &&

                        <Accordion classes={{ root: classes.MuiAccordionroot }}  >
                            <AccordionSummary
                                sx={{ padding: "0px" }}
                                expandIcon={<ExpandMoreIcon
                                    sx={{ color: '#00313E' }}
                                />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <Typography
                                    variant="body2"
                                    component="div"
                                    sx={{
                                        fontFamily: 'PreloSlabW04Light',
                                        color: '#00313E',
                                        fontSize: isMobile ? "14px" : '18px',
                                        width: isMobile ? "68%" : "30%",
                                        display: "flex",
                                        justifyContent: "space-between"
                                    }}
                                >   D365 Business Central
                                    <span style={{ marginLeft: "10px" }}><img src={edit} onClick={() => navigate('/end-users/business-central', { state: { focus: 'businessCentral' } })} /></span>
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={{ padding: "0px 0px 0px 0px" }}>

                                {endUsersBusinessCentral.map((item) => (
                                    <Stack
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        key={item}
                                        my={2}
                                        spacing={1}>
                                        <Typography
                                            variant="body2"

                                            component="div"
                                            sx={{
                                                fontFamily: 'PreloSlabW04Medium',
                                                color: '#00313E',
                                                fontSize: isMobile ? "12px" : '16px',
                                                textAlign: "center"
                                            }}
                                        >
                                            {item.name}
                                        </Typography>

                                        <Typography
                                            variant="body2"

                                            component="div"
                                            sx={{
                                                fontFamily: 'PreloSlabW04Medium',
                                                color: '#00313E',
                                                fontSize: isMobile ? "12px" : '16px',
                                                textAlign: "center"
                                            }}
                                        >
                                            {item.value}
                                        </Typography>
                                    </Stack>
                                ))}
                            </AccordionDetails>
                        </Accordion>
                    }

                    {endUsersProduct.includes('2') &&
                        <Accordion classes={{ root: classes.MuiAccordionroot }}  >
                            <AccordionSummary
                                sx={{ padding: "0px" }}
                                expandIcon={<ExpandMoreIcon
                                    sx={{ color: '#00313E' }}
                                />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <Typography
                                    variant="body2"
                                    component="div"
                                    sx={{
                                        fontFamily: 'PreloSlabW04Light',
                                        color: '#00313E',
                                        fontSize: isMobile ? "14px" : '18px',
                                        width: isMobile ? "68%" : "30%",
                                        display: "flex",
                                        justifyContent: "space-between"
                                    }}
                                >   D365 Sales
                                    <span style={{ marginLeft: "10px" }}><img src={edit} onClick={() => navigate('/end-users/sales', { state: { focus: 'leads' } })} /></span>
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={{ padding: "0px 0px 0px 0px" }}>
                                {endUsersSales.map((item) => (
                                    <Stack
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        key={item}
                                        my={2}
                                        spacing={1}>
                                        <Typography
                                            variant="body2"

                                            component="div"
                                            sx={{
                                                fontFamily: 'PreloSlabW04Medium',
                                                color: '#00313E',
                                                fontSize: isMobile ? "12px" : '16px',
                                                textAlign: "center"
                                            }}
                                        >
                                            {item.name}
                                        </Typography>

                                        <Typography
                                            variant="body2"

                                            component="div"
                                            sx={{
                                                fontFamily: 'PreloSlabW04Medium',
                                                color: '#00313E',
                                                fontSize: isMobile ? "12px" : '16px',
                                                textAlign: "center"
                                            }}
                                        >
                                            {item.value}
                                        </Typography>

                                    </Stack>
                                ))}
                            </AccordionDetails>
                        </Accordion>
                    }

                    {endUsersProduct.includes('4') &&
                        <Accordion classes={{ root: classes.MuiAccordionroot }}  >
                            <AccordionSummary
                                sx={{ padding: "0px" }}
                                expandIcon={<ExpandMoreIcon
                                    sx={{ color: '#00313E' }}
                                />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <Typography
                                    variant="body2"
                                    component="div"
                                    sx={{
                                        fontFamily: 'PreloSlabW04Light',
                                        color: '#00313E',
                                        fontSize: isMobile ? "14px" : '18px',
                                        width: isMobile ? "68%" : "30%",
                                        display: "flex",
                                        justifyContent: "space-between"
                                    }}
                                >   D365 Customer Service
                                    <span style={{ marginLeft: "10px" }}><img src={edit} onClick={() => navigate('/end-users/customer-service', { state: { focus: 'customerService' } })} /></span>
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={{ padding: "0px 0px 0px 0px" }}>
                                {endUsersCustomerService.map((item) => (
                                    <Stack
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        key={item}
                                        my={2}
                                        spacing={1}>
                                        <Typography
                                            variant="body2"

                                            component="div"
                                            sx={{
                                                fontFamily: 'PreloSlabW04Medium',
                                                color: '#00313E',
                                                fontSize: isMobile ? "12px" : '16px',
                                                textAlign: "center"
                                            }}
                                        >
                                            {item.name}
                                        </Typography>

                                        <Typography
                                            variant="body2"

                                            component="div"
                                            sx={{
                                                fontFamily: 'PreloSlabW04Medium',
                                                color: '#00313E',
                                                fontSize: isMobile ? "12px" : '16px',
                                                textAlign: "center"
                                            }}
                                        >
                                            {item.value}
                                        </Typography>

                                    </Stack>
                                ))}
                            </AccordionDetails>
                        </Accordion>
                    }
                </Accordion>



                <Accordion classes={{
                    root: classes.MuiAccordionroot
                }}>


                    <AccordionSummary
                        sx={{ padding: "0px" }}
                        expandIcon={<ExpandMoreIcon sx={{ color: '#00313E' }} />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography
                            className={classes.heading}
                            variant="body2"
                            component="div"
                            my={1}
                            sx={{
                                fontFamily: 'PreloSlabW04Medium',
                                color: '#00313E',
                                fontSize: isMobile ? "16px" : '18px',
                                width: "100%",
                                textAlign: "left"
                            }}
                        >
                            Review/Change Support Plan
                        </Typography>
                    </AccordionSummary>
                    <Accordion classes={{ root: classes.MuiAccordionroot }}  >
                        <AccordionSummary
                            sx={{ padding: "0px" }}
                            expandIcon={<ExpandMoreIcon
                                sx={{ color: '#00313E' }}
                            />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography
                                variant="body2"
                                component="div"
                                sx={{
                                    fontFamily: 'PreloSlabW04Light',
                                    color: '#00313E',
                                    fontSize: isMobile ? "14px" : '18px',
                                    width: isMobile ? "68%" : "30%",
                                    display: "flex",
                                    justifyContent: "space-between"
                                }}
                            >
                                {support === 1 ? "Launch" : ''}
                                {support === 2 ? "Optimize" : ''}
                                {support === 3 ? "Extend" : ''}
                                <span style={{ marginLeft: "10px" }}><img src={edit} onClick={() => navigate('/support', { state: { focus: 'supportRoute' } })} /></span>
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ padding: "0px 0px 0px 0px" }}>

                            {support === 1 ? (
                                supportLaunch.map((item) => (
                                    <Stack
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        my={2}
                                        spacing={1} >
                                        <Typography
                                            variant="body2"

                                            component="div"
                                            sx={{
                                                fontFamily: 'PreloSlabW04Medium',
                                                color: '#00313E',
                                                fontSize: isMobile ? "12px" : '16px',
                                                textAlign: "center"
                                            }}
                                        >
                                            {item.name}
                                        </Typography>

                                        <Typography
                                            variant="body2"

                                            component="div"
                                            sx={{
                                                fontFamily: 'PreloSlabW04Medium',
                                                color: '#00313E',
                                                fontSize: isMobile ? "12px" : '16px',
                                                textAlign: "center"
                                            }}
                                        >
                                            {item.value}
                                        </Typography>

                                    </Stack>
                                ))
                            ) : ''}

                            {support === 2 ? (
                                supportOptimise.map((item) => (
                                    <Stack
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        my={2}
                                        spacing={1} >
                                        <Typography
                                            variant="body2"

                                            component="div"
                                            sx={{
                                                fontFamily: 'PreloSlabW04Medium',
                                                color: '#00313E',
                                                fontSize: isMobile ? "12px" : '16px',
                                                textAlign: "center"
                                            }}
                                        >
                                            {item.name}
                                        </Typography>

                                        <Typography
                                            variant="body2"

                                            component="div"
                                            sx={{
                                                fontFamily: 'PreloSlabW04Medium',
                                                color: '#00313E',
                                                fontSize: isMobile ? "12px" : '16px',
                                                textAlign: "center"
                                            }}
                                        >
                                            {item.value}
                                        </Typography>

                                    </Stack>
                                ))
                            ) : ''}

                            {support === 3 ? (
                                supportExtend.map((item) => (
                                    <Stack
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        my={2}
                                        spacing={1} >
                                        <Typography
                                            variant="body2"

                                            component="div"
                                            sx={{
                                                fontFamily: 'PreloSlabW04Medium',
                                                color: '#00313E',
                                                fontSize: isMobile ? "12px" : '16px',
                                                textAlign: "center"
                                            }}
                                        >
                                            {item.name}
                                        </Typography>

                                        <Typography
                                            variant="body2"

                                            component="div"
                                            sx={{
                                                fontFamily: 'PreloSlabW04Medium',
                                                color: '#00313E',
                                                fontSize: isMobile ? "12px" : '16px',
                                                textAlign: "center"
                                            }}
                                        >
                                            {item.value}
                                        </Typography>

                                    </Stack>
                                ))
                            ) : ''}


                        </AccordionDetails>
                    </Accordion>

                </Accordion>

            </Grid>

        </div >
    )
}

const mapStateToProps = (state) => {
    return {
        stepsStatus: state.home.stepsStatus,
        businessCentral: state.businessCentral.businessCentral,
        sales: state.sales.sales,
        marketing: state.marketing.marketing,
        customerService: state.customerService.customerService,
        product: state.product.selectedProducts,
        endUsers: state.endUsers,
        support: state.saveSupportPackage.supportPackage
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setStepsStatus: (payload) => dispatch(setStepsStatus(payload)),
        saveBusinessCentral: (payload) => dispatch(saveBusinessCentral(payload)),
        saveCustomerService: (payload) => dispatch(saveCustomerService(payload)),
        saveMarketing: (payload) => dispatch(saveMarketing(payload)),
        saveSales: (payload) => dispatch(saveSales(payload)),
        saveEndUsers: (payload) => dispatch(saveEndUsers(payload))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Configuration);