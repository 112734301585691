import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/styles';
import './fonts/PFDinDisplayPro-Bold.ttf';
import './fonts/PFDinDisplayPro-Regular.ttf';
import './fonts/Prelo-Slab-W04-Black.ttf';
import './fonts/Prelo-Slab-W04-Bold.ttf';
import './fonts/Prelo-Slab-W04-Light.ttf';
import './fonts/Prelo-Slab-W04-Semi-Bold.ttf';
import './fonts/Prelo-Slab-W04-Medium.ttf';

// REDUX
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './redux/store';

import App from './App';
import reportWebVitals from './reportWebVitals';
import theme from './theme/customTheme';

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <React.StrictMode>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </BrowserRouter>
      </React.StrictMode>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
