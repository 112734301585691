import PRICINGCODE from './pricingCode.types';

const pricingCode = (payload) => {
    // console.log('payload',payload)
    return {

        type: PRICINGCODE,
        payload
    };

};

export default pricingCode
