import SAVESUPPORTPACKAGE, { RESET_SAVESUPPORTPACKAGE } from './supportPackage.types';

const INITIAL_STATE = {
supportPackage: null
};

const reducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case SAVESUPPORTPACKAGE:
      // console.log('action.payload', action.payload);
      return {
        ...state,
        supportPackage: action.payload
      };

      case RESET_SAVESUPPORTPACKAGE:
        return INITIAL_STATE
    default:
      return state;
  }
};

export default reducer;
