import SAVEENDUSERS from './endUsers.types';

const saveEndUser = (payload) => {
    // console.log('payload',payload)
    return {

        type: SAVEENDUSERS,
        payload
    };

};

export default saveEndUser