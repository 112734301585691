import SAVESUPPORTPACKAGE from './supportPackage.types';

const saveSupportPackage = (payload) => {
    // console.log('payload',payload)
    return {

        type: SAVESUPPORTPACKAGE,
        payload
    };

};

export default saveSupportPackage
