import { RESET_PRODUCT, SELECTPRODUCTS } from './product.type';
// import { SELECTPRODUCTS } from './product.type';

const INITIAL_STATE = {

    selectedProducts: [],
    productsConfigs: []
};

const reducer = (state = INITIAL_STATE, action = {}) => {

    switch (action.type) {

        case SELECTPRODUCTS:
            // console.log('action.payload',action.payload)
            return {

                ...state, selectedProducts: action.payload

            };
        case RESET_PRODUCT: 
                return INITIAL_STATE

            default: return state;

            
    }

};

export default reducer;