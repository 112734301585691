import SAVECUSTOMERSERVICE from './customerService.types';

const saveCustomerService = (payload) => {
  // console.log('payload', payload);
  return {
    type: SAVECUSTOMERSERVICE,
    payload,
  };
};

export default saveCustomerService;
