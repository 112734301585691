const conditionalRouteEndUser = (value) => {
  switch (value) {
    case '1':
      return 'business-central';
    case '2':
      return 'sales';

    case '4':
      return 'customer-service';

    default:
      return 'customer-service';
  }
};

export default conditionalRouteEndUser;
