/* eslint-disable no-param-reassign */
/* eslint-disable prefer-regex-literals */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useState, useRef } from 'react';
import { Button, Container, Grid, Typography, Stack } from '@mui/material';
import axios from 'axios'
import LoadingButton from '@mui/lab/LoadingButton';
import { Link } from 'react-scroll';
import TextField from '@mui/material/TextField';
import ReactGA from 'react-ga';
import { useMediaQuery } from 'react-responsive';
import { useLocation, useNavigate } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import { setStepsStatus, setSubStepStatus } from '../redux/home/home.actions';
import BannerImage from '../assets/logo-overlay.png';
import saveSupport from '../redux/support/support.actions';
import 'animate.css';
import Navbar from '../Components/Navbar/NavbarTab';
import CustomizedSlider from '../Components/Custom/CustomizedSlider';
import ProductBarEndUsers from '../Components/ProductBarEndUsers/ProductBarEndUsers';
import saveEndUsers from '../redux/endUser/endUsers.actions';
import SaveForLater from '../Components/SaveForLater';
import exit from '../assets/exit.svg'
import catapultLogo from '../assets/catapult-mobile.svg'
import { modifyProductsByCategory } from '../helpers';

const EndUserBusinessCentral = ({ endUsers, stepsStatus, saveForLaterCode, code }) => {

  const isSmallMobile = useMediaQuery({ query: `(max-width: 768px)` });

  const isIpad = useMediaQuery({ query: `(max-width: 1024px)` });

  const isMobile = useMediaQuery({ query: `(max-width: 812px)` });

  const id = localStorage.getItem("clientId");

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const modalRef = useRef(null)

  const location = useLocation();

  const endUserBusinessCentralDivRef = useRef()

  const { state } = useLocation();

  const { product } = useSelector((s) => s);

  const ifExist = endUsers || 0;

  const [bcFullUsers, setBcFullUsers] = useState(ifExist.bcFullUsers === 0 ? "" : ifExist.bcFullUsers);

  const [bcTeamUsers, setBcTeamUsers] = useState(ifExist.bcTeamUsers);

  const [loading, setLoading] = useState(false);

  const [show, setShow] = useState(false);

  const [check, setCheck] = useState(false);

  const ifProductsExist = product.selectedProducts.length > 0 ? product.selectedProducts : [];
  const [selectedProducts, setProducts] = useState(ifProductsExist);

  const getValue = (e, v) => {
    setBcFullUsers(v);
  };

  const getValueTwo = (e, v) => {
    setBcTeamUsers(v);
  };

  const [errorTeamUsers, setErrorTeamUsers] = useState(false)

  const [errorFullUsers, setErrorFullUsers] = useState(false)

  const errorHandleFullUsers = (e) => {
    const regex = new RegExp('^[0-9]+$')
    setBcFullUsers(e.target.value);

    if (regex.test(e.target.value) && e.target.value <= 50 && e.target.value >= 1) {
      setErrorFullUsers(false)
      setBcFullUsers(e.target.value);
    }

    else {
      setErrorFullUsers(true)
    }
  }

  const errorHandleTeamUsers = (e) => {
    const regex = new RegExp('^[0-9]+$')
    setBcTeamUsers(e.target.value);

    if (regex.test(e.target.value) && e.target.value <= 100) {
      setErrorTeamUsers(false)
      setBcTeamUsers(e.target.value);
    }

    else {
      setErrorTeamUsers(true)
    }
  }

  const handleSaveData = () => {

    endUsers.bcFullUsers = bcFullUsers;
    endUsers.bcTeamUsers = bcTeamUsers;

    dispatch(saveEndUsers(endUsers));
  }
  const handleNext = () => {
    dispatch(setSubStepStatus({ euCentral: { active: false, completed: true } }));

    endUsers.bcFullUsers = bcFullUsers;
    endUsers.bcTeamUsers = bcTeamUsers;

    dispatch(saveEndUsers(endUsers));

    if (selectedProducts.length > 2 && selectedProducts.indexOf('2') === -1) {
      navigate(`/end-users/customer-service`);
    } else if (selectedProducts.length >= 2 && selectedProducts.indexOf('2') === 1) {
      navigate(`/end-users/sales`);
    } else if (selectedProducts.length === 2 && selectedProducts.indexOf('4') === 1) {
      navigate(`/end-users/customer-service`);
    } else {
      navigate(`/support`);
    }

    if (selectedProducts[selectedProducts.length - 1] === '1') {
      const updateStep = stepsStatus;
      updateStep[2].endUsers = { 'active': false, 'completed': true }
      dispatch(setStepsStatus(updateStep))
    }
  };

  const reduxData = useSelector((s) => s);

  const handleCancel = () => {
    navigate('/pricing');
  }

  const handleSave = () => {
    setLoading(true)
    const payloadData = {
      "bcFullUsers": bcFullUsers,
      "bcTeamUsers": bcTeamUsers,
      "salesFullUsers": reduxData?.endUsers?.salesFullUsers,
      "salesTeamUsers": reduxData?.endUsers?.salesTeamUsers,
      "serviceFullUsers": reduxData?.endUsers?.serviceFullUsers,
      "serviceTeamUsers": reduxData?.endUsers?.serviceTeamUsers
    }
    const profileData = {
      "firstName": reduxData?.profile?.profile?.firstName,
      "lastName": reduxData?.profile?.profile?.lastName,
      "email": reduxData?.profile?.profile?.email,
      "countryCode": reduxData?.profile?.profile?.countryCode,
      "phoneNo": reduxData?.profile?.profile?.phoneNo,
      "companyName": reduxData?.profile?.profile?.companyName,
      "followUp": reduxData?.profile?.profile?.followUp
    }
    const body = {
      selectedProducts: modifyProductsByCategory(reduxData.product.selectedProducts),
      businessCentral: reduxData?.businessCentral?.businessCentral,
      sales: reduxData.sales.sales,
      marketing: reduxData.marketing.marketing,
      customerService: reduxData.customerService.customerService,
      endUsers: payloadData,
      profileDetails: profileData,
      supportPackage: reduxData.saveSupportPackage.supportPackage,
      userLocation: reduxData.saveGeoLocation.geoLocation,
      // userCode: reduxData.saveLater.code,
      analyticsPayload: {
        clientId: id
      }
    }
    if (saveForLaterCode) {
      body.userCode = saveForLaterCode
    }
    else if (code) {
      body.userCode = code
    }
    else {
      body.userCode = ""
    }
    axios.post(`${process.env.REACT_APP_BASE_URL}/api/calculatePricing`, body)
      .then(res => {
        if (res.data.message === 'Pricing calculated successfully') {
          setLoading(false)
          const response = res.data;
          dispatch(saveEndUsers(payloadData));
          dispatch(saveSupport(response))
          navigate('/pricing');
        }
      })
      .catch((error) => {
        setLoading(false)
      });
  }

  document.documentElement.style.setProperty('--animate-duration', '3s');

  const [navbar, setNavbar] = useState(false);

  const changePosition = () => {
    if (window.scrollY >= 400) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener('scroll', changePosition);

  const scrollToRef = (ref) => {
    if (isSmallMobile) {
      window.scrollTo(0, ref.current.offsetTop - 270);
    }
    else {
      window.scrollTo(0, ref.current.offsetTop - 130);
    }
  }

  useEffect(() => {
    if (state?.focus) {
      switch (state?.focus) {
        case 'businessCentral':
          scrollToRef(endUserBusinessCentralDivRef)
          break;

        default:
          break;
      }
    }
    else if (isSmallMobile) {
      scrollToRef(endUserBusinessCentralDivRef);
    }
    else {
      scrollToRef(endUserBusinessCentralDivRef);
    }
  }, [state?.focus])

  useEffect(() => {
    ReactGA.initialize('UA-74792081-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
    console.log(window.location.pathname, "window.location.pathname")
    console.log(window.location.search, "window.location.search")
  }, [])

  useEffect(() => {

    if (bcFullUsers > 50 || bcFullUsers < 1 || bcTeamUsers > 100 || bcTeamUsers < 0 || bcTeamUsers === ""
    ) {
      setShow(false);
    } else {
      setShow(true);
    }
  }, [bcFullUsers, bcTeamUsers,
  ]);

  return (
    <div>
      <Link to="navbar" spy smooth>
        <section
          style={{
            backgroundImage: `linear-gradient(90deg, #61C250, #4EC35D, #00C37C, #00C0A4, #00BBC7, #00B3DA, #00ACE0, #00A9E0)`,
            height: isMobile ? '80vh' : "100%",
            // padding: 2,
          }}
        >
          <div
            style={{
              backgroundImage: `url(${BannerImage})`,
              height: '100%',
              backgroundPosition: 'top',
              backgroundRepeat: 'no-repeat',
              display: "flex",
              alignItems: "center"
            }}
          >
            {location.pathname === "/pricing" || location.pathname === "/product" ?
              (
                <img src={isMobile ? catapultLogo : exit}
                  style={{
                    cursor: "pointer",
                    height: isMobile ? '47px' : "68px",
                    width: isMobile ? '100%' : "230px",
                    zIndex: 999,
                    position: "fixed",
                    right: isMobile ? '' : "0px",
                    // left: isMobile ? "-1px" : '',
                    top: "-5px"
                  }}
                  onClick={() => window.location.replace('https://www.catapulterp.com/')}
                />
              ) : (
                <img src={isMobile ? catapultLogo : exit}
                  style={{
                    cursor: "pointer",
                    height: isMobile ? '47px' : "68px",
                    width: isMobile ? '100%' : "230px",
                    zIndex: 999,
                    position: "fixed",
                    right: isMobile ? '' : "0px",
                    // left: isMobile ? "-1px" : '',
                    top: "-5px"
                  }}
                  onClick={() => {
                    modalRef.current()
                    setCheck(true)
                    // handleSaveData()
                  }}
                />
              )
            }
            <Container fixed maxWidth="md" className="animate__animated animate__fadeIn"
              sx={{
                height: "396px",
              }}
            >
              <Grid item xs={12}
                sx={{
                  textAlign: 'center',
                  height: "100%",
                  display: "flex",
                  justifyContent: "center"
                }}>
                <Stack direction="column"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: isMobile ? '47px' : ''
                  }}>
                  <Typography
                    variant="string"
                    gutterBottom
                    component="div"
                    sx={{
                      fontFamily: 'DinDIsplayProRegular',
                      fontStyle: 'italic',
                      color: '#FFFFFF',
                    }}
                  >
                    Pricing
                  </Typography>
                  <Typography
                    variant="h3"
                    gutterBottom
                    component="div"
                    className="font-face-prelo-black"
                    sx={{
                      fontFamily: 'PreloSlabW04Black',
                      color: '#FFFFFF',
                      fontSize: isMobile ? '33px' : '48px',
                      marginBottom: isMobile ? "40px" : ''
                    }}
                  >
                    Take the guesswork out of your ERP & CRM solution budgeting.
                  </Typography>
                  <Typography
                    variant="caption"
                    gutterBottom
                    component="div"
                    mb={5}
                    sx={{
                      fontFamily: 'PreloSlabW04Light',
                      color: '#FFFFFF',
                      fontSize: isMobile ? '18px' : '20px',
                      lineHeight: 1.5,
                      paddingX: isMobile ? 2 : 10,
                      marginBottom: isMobile ? "60px" : '40px'
                    }}
                  >
                    Customize and price your Dynamics 365 solution using our powerful online
                    scoping and budgeting tools.
                  </Typography>
                </Stack>
              </Grid>
            </Container>
          </div>
        </section>
      </Link>

      <div id="navbar">
        <Navbar navbar={navbar} />
        <ProductBarEndUsers navbar={navbar} />
      </div>
      <Container sx={{ py: 4, height: 'auto', display: "inherit", minHeight: "800px" }}>
        <Grid container spacing={isMobile ? 3 : 2} justifyContent="start" md={12} sx={{ paddingTop: "10 px" }}>
          <Grid item xs={12} md={9} className="animate__animated animate__fadeIn" ref={endUserBusinessCentralDivRef}>
            <Typography
              mb={3}
              variant="body1"
              sx={{
                color: '#00313E',
                fontFamily: 'PreloSlabW04SemiBold',
                fontSize: isMobile ? '19px' : '22px',
                marginBottom: isMobile ? "40px" : '20px'
              }}
              gutterBottom
              component="div"
            >
              {`Now let's talk about your team and the people who will use the Dynamics 365 Business Central solution.`}
            </Typography>
            <Typography
              mb={isMobile || isIpad ? 2 : 3}
              variant="body1"
              sx={{
                color: '#00313E',
                fontFamily: 'PreloSlabW04SemiBold',
                fontSize: isMobile ? '17px' : '20px',
                marginBottom: isMobile ? "30px" : '20px'
              }}
              gutterBottom
              component="div"
            >
              Full users: How many people in your company will use Business Central as a core part of their
              day-to-day work?
            </Typography>
            <Typography
              mb={isMobile || isIpad ? 2 : 4}
              variant="body1"
              sx={{
                color: '#00313E',
                fontFamily: 'DinDIsplayProRegular',
                fontSize: '14px',
                marginBottom: '30px'
              }}
              gutterBottom
              component="div"
            >
              These users are people who will enter data, create new records and
              transactions, and use the system to perform critical business processes.
            </Typography>

            {isMobile ? (
              <TextField
                value={bcFullUsers}
                fullWidth
                type='number'
                label="Enter a value between 1 to 50"
                error={errorFullUsers}
                size="small"
                onChange={errorHandleFullUsers}
              />
            ) : (
              <CustomizedSlider type="50" bcFullUsers={bcFullUsers} getValue={getValue} />
            )}
            <Typography
              my={isMobile || isIpad ? 2 : 4}
              variant="body1"
              sx={{
                color: '#00313E',
                fontFamily: 'PreloSlabW04SemiBold',
                fontSize: isMobile ? '17px' : '20px',
                marginBottom: isMobile ? "30px" : '20px',
              }}
              gutterBottom
              component="div"
            >
              Team users: How many people will use Business Central to look up data and information, run reports
              or act as reviewer-approvers only?
            </Typography>
            <Typography
              mb={isMobile || isIpad ? 2 : 4}
              variant="body1"
              sx={{
                color: '#00313E',
                fontFamily: 'DinDIsplayProRegular',
                fontSize: '14px',
                marginBottom: '30px'
              }}
              gutterBottom
              component="div"
            >
              Team user licenses allow updates to existing records and read-only access to data, subject to roles and permissions.
            </Typography>

            {isMobile ? (
              <TextField
                value={bcTeamUsers}
                fullWidth
                type='number'
                label="Enter a value between 0 to 100"
                error={errorTeamUsers}
                size="small"
                onChange={errorHandleTeamUsers}
              />
            ) : (
              <CustomizedSlider type="100" bcTeamUsers={bcTeamUsers} getValueTwo={getValueTwo} />
            )}
          </Grid>

          {state?.focus ? (

            <Grid
              item
              xs={12}
              md={3}
              direction="row"
              pb={4}
              sx={{
                display: "flex",
                justifyContent: isMobile ? "center" : "flex-end",
                alignItems: "center",
                position: isMobile || isIpad ? '' : 'fixed',
                right: '25px',
                bottom: '25px',
                width: '100%',
                paddingLeft: 0,
              }}
            >
              <Button
                variant="primary"
                sx={{
                  borderRadius: 1,
                  border: '1px solid #CFCFCF',
                  height: "60px",
                  width: "180px",
                  marginRight: "15px",

                }}
                onClick={handleCancel}
              >
                <Typography
                  variant="button"
                  sx={{ fontFamily: 'DinDIsplayProBold', color: '#00313E' }}
                >
                  Cancel
                </Typography>
              </Button>

              <LoadingButton
                variant="contained"
                color="neutral"
                // disabled={loading}
                loading={loading}
                loadingPosition="end"
                sx={{
                  borderRadius: 1,
                  backgroundColor: '#00313E',
                  height: "60px",
                  width: "130px"
                }}
                onClick={() => handleSave()}
              >
                <Typography variant="button" sx={{ fontFamily: 'DinDIsplayProBold' }}>
                  Save
                </Typography>
              </LoadingButton>
            </Grid>) : (
            <Grid
              item
              xs={12}
              md={3}
              direction="row"
              pb={4}
              sx={{
                display: "flex",
                justifyContent: isMobile ? "center" : "flex-end",
                alignItems: "center",
                position: isMobile || isIpad ? '' : 'fixed',
                right: '25px',
                bottom: '25px',
                width: '100%',
                paddingLeft: 0,
              }}
            >
              <Button
                variant="primary"
                sx={{
                  borderRadius: 1,
                  border: '1px solid #CFCFCF',
                  height: "60px",
                  width: "180px",
                  marginRight: "15px",

                }}
                onClick={() => {
                  modalRef.current()
                  handleSaveData()
                }}
              >
                <Typography
                  variant="button"
                  sx={{ fontFamily: 'DinDIsplayProBold', color: '#00313E' }}
                >
                  Save for later
                </Typography>
              </Button>

              <Button
                variant="contained"
                color="neutral"
                disabled={!show}
                sx={{
                  borderRadius: 1,
                  backgroundColor: '#00313E',
                  height: "60px",
                  width: "130px"
                }}
                onClick={() => handleNext()}
              >
                <Typography variant="button" sx={{ fontFamily: 'DinDIsplayProBold' }}>
                  Next
                </Typography>
              </Button>
            </Grid>
          )}

        </Grid>

      </Container>
      {/* <div
        style={{
          background: isMobile ? "white" : "",
          height: isMobile ? "100px" : "70px",
          position: isMobile ? "fixed" : '',
          bottom: "0px",
          width: "100%"
        }}>


      </div> */}

      <SaveForLater check={check} modalRef={modalRef} />
    </div >
  );
};

const mapStateToProps = (state) => {
  return {
    endUsers: state.endUsers,
    stepsStatus: state.home.stepsStatus,
    code: state?.pricingCode?.code,
    saveForLaterCode: state?.saveLater?.code,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveEndUsers: (payload) => dispatch(saveEndUsers(payload)),
    setStepsStatus: (payload) => dispatch(setStepsStatus(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EndUserBusinessCentral);
