import SAVELATER from './saveForLater.types';

const saveLater = (payload) => {
    // console.log('payload',payload)
    return {

        type: SAVELATER,
        payload
    };

};

export default saveLater
