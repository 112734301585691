import SAVEMARKETING from './marketing.types';

const saveMarketing = (payload) => {
  // console.log('payload', payload);
  return {
    type: SAVEMARKETING,
    payload,
  };
};

export default saveMarketing;
