import SAVESUPPORT, { RESET_SAVESUPPORT } from './support.types';

const INITIAL_STATE = {

};

const reducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case SAVESUPPORT:
      // console.log('action.payload', action.payload);
      return {
        ...state,
        support: action.payload,
      };

      case RESET_SAVESUPPORT:
        return INITIAL_STATE

    default:
      return state;
  }
};

export default reducer;
