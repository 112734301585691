import SAVESALES from './sales.types';

const saveSales = (payload) => {
    // console.log('payload',payload)
    return {

        type: SAVESALES,
        payload
    };

};

export default saveSales
