import SAVEENDUSERS, { RESET_SAVEENDUSERS } from './endUsers.types';

const INITIAL_STATE = {

    "bcFullUsers": 0,
    "bcTeamUsers": 0,
    "salesFullUsers": 0,
    "salesTeamUsers": 0,
    "serviceFullUsers": 0,
    "serviceTeamUsers": 0
 
};

const reducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case SAVEENDUSERS:
      // console.log('action.payload', action.payload);
      return {
        ...state,
        bcFullUsers: parseInt(action.payload.bcFullUsers,10),
        bcTeamUsers: parseInt(action.payload.bcTeamUsers,10),
        salesFullUsers: parseInt(action.payload.salesFullUsers,10),
        salesTeamUsers: parseInt(action.payload.salesTeamUsers,10),
        serviceFullUsers: parseInt(action.payload.serviceFullUsers,10),
        serviceTeamUsers: parseInt(action.payload.serviceTeamUsers,10),
      };

      case RESET_SAVEENDUSERS :
        return INITIAL_STATE

    default:
      return state;
  }
};

export default reducer;
