import SAVEREQUESTDEMO from './requestDemo.types';

const requestDemo = (payload) => {
    // console.log('payload',payload)
    return {

        type: SAVEREQUESTDEMO,
        payload
    };

};

export default requestDemo
