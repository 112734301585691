import SAVEGEOLOCATION, { RESET_SAVEGEOLOCATION } from './geoLocation.types';

const INITIAL_STATE = {
  geoLocation: ''
};

const reducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case SAVEGEOLOCATION:
      // console.log('action.payload', action.payload);
      return {
        ...state,
        geoLocation: action.payload
      };

      case RESET_SAVEGEOLOCATION:
        return INITIAL_STATE
    default:
      return state;
  }
};

export default reducer;
