/* eslint-disable no-unsafe-optional-chaining */
import { Grid, Typography, Stack } from '@mui/material';
import { useMediaQuery } from 'react-responsive';
import { connect } from 'react-redux';
import saveSupport from '../redux/support/support.actions';
import { setStepsStatus } from '../redux/home/home.actions';

const SupportPricing = ({ support, location }) => {
    const text = [
        { content: "*Implementation fees include detailed requirements validation and solution design, configuration, testing, training, deployment, and solution stabilization" },
        { content: "**12-month support and software licensing term begin at Go-Live" }
    ]

    const isMobile = useMediaQuery({ query: `(max-width: 640px)` });

    const milestone = [
        {
            name: "Project Initiation",
            payment: `${support?.finalPaymentsPlans?.fixedFeeImplentation?.milestones?.contractSigned}`
        },
        {
            name: "Solution Design Approval",
            payment: `${support?.finalPaymentsPlans?.fixedFeeImplentation?.milestones?.designComplete}`
        },
        {
            name: "Configuration Complete",
            payment: `${support?.finalPaymentsPlans?.fixedFeeImplentation?.milestones?.configurationComplete}`
        },
        {
            name: "Go Live",
            payment: `${support?.finalPaymentsPlans?.fixedFeeImplentation?.milestones?.deployment}`
        },
    ]

    const payments = [
        {
            name: "Monthly Payment",
            payment: `${support?.finalPaymentsPlans?.fixedFeeImplentation?.monthlyPayments?.total}`
        },
        {
            name: "Annual Payment",
            payment: `${support?.finalPaymentsPlans?.fixedFeeImplentation?.yearlyPayments?.total}`
        },
    ]

    return (
        <div>
            <Grid container justifyContent="start" md={12}>
                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                    <Typography
                        variant="body2"
                        component="div"
                        sx={{
                            fontFamily: 'PreloSlabW04Medium',
                            color: '#00313E',
                            fontSize: isMobile ? "14px" : '20px',
                            textAlign: "center",
                            marginTop: "20px",
                            marginBottom: "20px"
                        }}
                    >
                        Budget with confidence. This plan features a fixed-fee implementation project with milestone payment schedules, and monthly or annual software licensing and support options.
                    </Typography>
                    <Stack mt={2}>
                        <Typography
                            variant="caption"
                            gutterBottom
                            component="div"
                            sx={{
                                fontFamily: 'PreloSlabW04Medium',
                                color: '#00313E',
                                fontSize: '16px',
                                textAlign: "center"
                            }}
                        >
                            {location === 'Canada' ? (
                                "All figures given in CAD"
                            ) : "All figures given in USD"}

                        </Typography>
                    </Stack>
                </Grid>

                <Grid item xs={12} sx={{ textAlign: 'center', marginTop: "20px" }}>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={1}>
                        <Typography
                            variant="body2"

                            component="div"
                            sx={{
                                fontFamily: 'PreloSlabW04Bold',
                                color: '#00313E',
                                fontSize: '18px',
                                textAlign: "center"
                            }}
                        >
                            Milestone
                        </Typography>

                        <Typography
                            variant="body2"

                            component="div"
                            sx={{
                                fontFamily: 'PreloSlabW04Bold',
                                color: '#00313E',
                                fontSize: '18px',
                                textAlign: "center"
                            }}
                        >
                            Payment
                        </Typography>

                    </Stack>
                    {milestone.map((item) => (
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            my={2}
                            spacing={1}>
                            <Typography
                                variant="body2"

                                component="div"
                                sx={{
                                    fontFamily: 'PreloSlabW04Light',
                                    color: '#00313E',
                                    fontSize: '18px',
                                    textAlign: "center"
                                }}
                            >
                                {item?.name || "NA"}
                            </Typography>

                            <Typography
                                variant="body2"

                                component="div"
                                sx={{
                                    fontFamily: 'PreloSlabW04Light',
                                    color: '#00313E',
                                    fontSize: '18px',
                                    textAlign: "center"
                                }}
                            >
                                {` $${(new Intl.NumberFormat('en-US').format(Math.round(item?.payment)))}`}
                            </Typography>

                        </Stack>
                    ))}

                </Grid>

                <Grid item xs={12} sx={{ textAlign: 'center', marginTop: "10px", borderTop: "1px solid  #F4F4F4", borderBottom: "1px solid #F4F4F4", borderOpacity: 0.1 }}>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        py={2}
                        spacing={1}>
                        <Typography
                            variant="body2"

                            component="div"
                            sx={{
                                fontFamily: 'PreloSlabW04Bold',
                                color: '#00313E',
                                fontSize: '18px',
                                textAlign: "center"
                            }}
                        >
                            Total Implementation Fee*
                        </Typography>

                        <Typography
                            variant="body2"

                            component="div"
                            sx={{
                                fontFamily: 'PreloSlabW04Bold',
                                color: '#00313E',
                                fontSize: '18px',
                                textAlign: "center"
                            }}
                        >
                            {`$${(new Intl.NumberFormat('en-US').format(Math.round(support?.finalPaymentsPlans?.fixedFeeImplentation?.totalImplementationFee)))}`}
                        </Typography>

                    </Stack>
                </Grid>

                <Grid item xs={12} sx={{ textAlign: 'center', marginTop: "20px" }}>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        // mx={4}
                        spacing={1}>
                        <Typography
                            variant="body2"

                            component="div"
                            sx={{
                                fontFamily: 'PreloSlabW04Bold',
                                color: '#00313E',
                                fontSize: '18px',
                                textAlign: "center"
                            }}
                        >
                            12-Months Licensing + Support**
                        </Typography>
                    </Stack>
                    {payments.map((item) => (
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            // mx={4}
                            my={2}
                            spacing={1}>
                            <Typography
                                variant="body2"

                                component="div"
                                sx={{
                                    fontFamily: 'PreloSlabW04Light',
                                    color: '#00313E',
                                    fontSize: '18px',
                                    textAlign: "center"
                                }}
                            >
                                {item?.name || "NA"}
                            </Typography>
                            <Typography
                                variant="body2"

                                component="div"
                                sx={{
                                    fontFamily: 'PreloSlabW04Light',
                                    color: '#00313E',
                                    fontSize: '18px',
                                    textAlign: "center"
                                }}
                            >
                                {` $${(new Intl.NumberFormat('en-US').format(Math.round(item?.payment)))}`}
                            </Typography>
                        </Stack>
                    ))}
                </Grid>

                <Grid item xs={12} sx={{ textAlign: "left", marginTop: "20px", marginBottom: "20px" }}>
                    <Stack
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="center"
                        // mx={4}
                        spacing={1}>
                        {text?.map((item) => (
                            <Typography
                                variant="body2"
                                component="div"
                                my={1}
                                sx={{
                                    fontFamily: 'DinDIsplayProRegular',
                                    color: '#00313E',
                                    fontSize: '12px',
                                    width: "100%"
                                }}
                            >
                                {item.content}
                            </Typography>
                        ))}
                    </Stack>
                </Grid>

            </Grid>
        </div >
    )
}

const mapStateToProps = (state) => {
    return {
        stepsStatus: state?.home?.stepsStatus,
        support: state?.support?.support?.data,
        location: state?.saveGeoLocation?.geoLocation,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        saveSupport: (payload) => dispatch(saveSupport(payload)),
        setStepsStatus: (payload) => dispatch(setStepsStatus(payload)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SupportPricing);
