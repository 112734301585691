import './App.css';
import { Routes, Route } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Home from './Pages/Home';
import Product from './Pages/Product';
import BusinessCentral from './Pages/BusinessCentral';
import Sales from './Pages/Sales';
import Marketing from './Pages/Marketing';
import CustomerService from './Pages/CustomerService';
import EndUserBusinessCentral from './Pages/EndUserBusinessCentral';
import EndUsersSales from './Pages/EndUsersSales';
import EndUserCustomerService from './Pages/EndUserCustomerService';
import Support from './Pages/Support';
import Pricing from './Pages/Pricing';
import Faq from './Pages/Faq';

const theme = createTheme({
  palette: {
    neutral: {
      main: '#00313E',
      contrastText: '#fff',
    },
    neutralButton: {
      main: '#FFFFFF',
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path=":userCode" element={<Home />} />
          <Route path="product" element={<Product />} />
          <Route path="/business-details/business-central" element={<BusinessCentral />} />
          <Route path="/business-details/sales" element={<Sales />} />
          <Route path="/business-details/marketing" element={<Marketing />} />
          <Route path="/business-details/customer-service" element={<CustomerService />} />
          <Route path="/end-users/business-central" element={<EndUserBusinessCentral />} />
          <Route path="/end-users/sales" element={<EndUsersSales />} />
          <Route path="/end-users/customer-service" element={<EndUserCustomerService />} />
          <Route path="/support" element={<Support />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="*" element={<Home />} />
        </Routes>
      </div>
    </ThemeProvider>
  );
}

export default App;
