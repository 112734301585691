/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-shadow */
/* eslint-disable prefer-const */
/* eslint-disable no-plusplus */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/alt-text */
import axios from 'axios';
import { useState, useEffect } from 'react';
import { Button, Grid, Typography, TextField, Checkbox, Paper, Alert, Snackbar, Backdrop, CircularProgress, } from '@mui/material';
import 'animate.css';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate, useLocation } from 'react-router-dom';
import { makeStyles, withStyles } from '@mui/styles';
import { Formik } from 'formik';
import * as yup from 'yup';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import LoadingButton from '@mui/lab/LoadingButton';
import saveLater from '../redux/saveForLater/saveForLater.actions';
import iconlight from '../assets/iconlight.svg';
import icondark from '../assets/icondark.svg';
import saveProfileInfo from '../redux/profile/profile.actions'
import union from '../assets/union.svg';
import { modifyProductsByCategory } from '../helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  MuiAccordionroot: {
    '&.MuiAccordion-root': {
      boxShadow: 'none',
    },
  },
}));

const CssTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#CCCCCC",
        fontFamily: "DinDIsplayProRegular"
      },
      "&:hover fieldset": {
        borderColor: "#CCCCCC"
      },
      "&.Mui-focused fieldset": {
        borderColor: "#CCCCCC"
      },
      "& input": {
        fontFamily: "DinDIsplayProRegular",
        fontSize: "15px",
        color: "#00313E",
        height: "15px",
      }
    }
  }
})(TextField);

const icon = {
  position: 'fixed',
  cursor: "pointer",
  right: '20px',
  top: '15px',
  color: '#323232',
  opacity: '50%',
};

const SaveForLater = ({ modalRef, selectPlan, saveLaterData, support, check }) => {

  const id = localStorage.getItem("clientId");

  const location = useLocation();

  const isMobile = useMediaQuery({ query: `(max-width: 560px)` });

  const dispatch = useDispatch();

  const classes = useStyles();

  const navigate = useNavigate();

  const [responseMessage, setResponseMessage] = useState('')

  const [saveLaterModal, setSaveLaterModal] = useState(false)

  const [open, setOpen] = useState(false);

  const [errorShow, setErrorShow] = useState(false)

  const [loading, setLoading] = useState(false);

  const reduxData = useSelector((s) => s);

  const [isChecked, setIsChecked] = useState(false);

  const handleChangeDemo = (e) => {
    setIsChecked(!isChecked);
  };

  const initialValues = {
    email: support?.saveForLaterData?.email
  }

  const validationSchema = yup.object().shape({
    email: yup.string().trim().required('Please enter an email.').email('Please enter a valid email'),
  })

  const handleSaveForLater = () => {
    setSaveLaterModal(true);
  }

  const handleSaveForLaterClose = () => {
    localStorage.removeItem('persist:root');
    setSaveLaterModal(false);
    if (check === true) {
      window.location.replace('https://www.catapulterp.com', "_blank")
    }
    else {
      window.open("https://www.catapulterp.com/", "_blank")
      window.location.replace('/')
    }
  }

  const handleSkip = () => {
    if (check === true) {
      window.open("https://www.catapulterp.com/", "_blank")
      window.location.replace('/')
    }
    else {
      setSaveLaterModal(false);
      window.open("https://www.catapulterp.com/", "_blank")
      window.location.replace('/')
    }

    setIsChecked(false)
  }

  const handleCloseIcon = () => {
    setSaveLaterModal(false);
    setIsChecked(false)
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleErrorClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setErrorShow(false);
  };

  const profileData = {
    "firstName": reduxData?.profile?.profile?.firstName,
    "lastName": reduxData?.profile?.profile?.lastName,
    "email": reduxData?.profile?.profile?.email,
    "countryCode": reduxData?.profile?.profile?.countryCode,
    "phoneNo": reduxData?.profile?.profile?.phoneNo,
    "companyName": reduxData?.profile?.profile?.companyName,
    "followUp": reduxData?.profile?.profile?.followUp,
  }

  const addInfo = async (values) => {
    setLoading(true)
    const body = {
      selectedProducts: modifyProductsByCategory(reduxData.product.selectedProducts),
      businessCentral: reduxData.businessCentral.businessCentral,
      sales: reduxData.sales.sales,
      marketing: reduxData.marketing.marketing,
      customerService: reduxData.customerService.customerService,
      endUsers: reduxData.endUsers,
      supportPackage: selectPlan || 0,
      email: values.email,
      followUp: isChecked,
      lastVistedRoute: {
        "stepsStatus": reduxData.home.stepsStatus,
        "subStepsStatus": reduxData.home.subStepsStatus,
        "route": location.pathname
      },
      analyticsPayload: {
        clientId: id
      }
    }
    const updateBody = {
      selectedProducts: modifyProductsByCategory(reduxData.product.selectedProducts),
      businessCentral: reduxData.businessCentral.businessCentral,
      sales: reduxData.sales.sales,
      marketing: reduxData.marketing.marketing,
      customerService: reduxData.customerService.customerService,
      endUsers: reduxData.endUsers,
      supportPackage: selectPlan || 0,
      email: values.email,
      followUp: isChecked,
      userCode: saveLaterData?.code,
      lastVistedRoute: {
        "stepsStatus": reduxData.home.stepsStatus,
        "subStepsStatus": reduxData.home.subStepsStatus,
        "route": location.pathname
      },
      analyticsPayload: {
        clientId: id
      }
    }
    let res = ''
    if (saveLaterData?.code !== "") {
      res = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/updateSaveForLaterData`, updateBody)
    }
    else {
      res = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/insertSaveForLater`, body)
    }
    try {
      if (res?.status === 200) {
        if (res?.data?.data?.userCode) {
          setResponseMessage('Selections saved for later successfully')
          setLoading(false)
          dispatch(saveLater(res?.data?.data?.userCode))
          dispatch(saveProfileInfo(res?.data?.data?.profileDetails))
          localStorage.removeItem('persist:root');
        } else {
          setResponseMessage('Selections saved for later successfully')
          setLoading(false)
          dispatch(saveLater(res?.data?.data?.code))
          dispatch(saveProfileInfo(res?.data?.data?.profileDetails))
          localStorage.removeItem('persist:root');
        }
      }
    } catch (error) {
      setLoading(false)
      setErrorShow(true)

    }
  }
  useEffect(() => {
    // eslint-disable-next-line no-param-reassign
    modalRef.current = handleSaveForLater
  }, [])
  return (
    <div>
      <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Code copied sucessfully!
        </Alert>
      </Snackbar>
      <Snackbar open={errorShow} autoHideDuration={2000} onClose={handleErrorClose}>
        <Alert onClose={handleErrorClose} severity="error" sx={{ width: '100%' }}>
          Oops! Something went wrong!
        </Alert>
      </Snackbar>
      <Modal open={saveLaterModal} >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          overflow: 'auto',
          transform: 'translate(-50%, -50%)',
          width: isMobile ? '300px' : '446px',
          height: isMobile ? '350px' : '310px',
          bgcolor: 'background.paper',
          boxShadow: 24,
          borderRadius: "5px",
          p: 4,
        }}>
          <CloseIcon sx={icon} onClick={() => handleCloseIcon()} />
          {
            responseMessage === 'Selections saved for later successfully' ? (
              <Grid Container display="flex" flexDirection="column" mt={1}>
                <Typography
                  sx={{
                    fontFamily: 'PreloSlabW04Bold',
                    color: '#00313E',
                    fontSize: '26px',
                    textAlign: 'center',
                  }}
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                >
                  Thank You!
                </Typography>
                <div className={classes.root} >
                  <Grid item mt={2} sx={{
                    width: "100%",
                    textAlign: 'center',
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center"
                  }}>
                    <Typography
                      variant="body"
                      sx={{

                        fontFamily: 'PreloSlabW04SemiBold',
                        color: '#00313E',
                        textTransform: 'none',
                        fontSize: '18px',
                        marginBottom: "10px"
                      }}
                    >
                      Your Catapult Code
                    </Typography>
                    <Paper variant="outlined"
                      sx={{
                        height: "60px",
                        width: "225px",
                        fontFamily: 'DinDIsplayProRegular',
                        color: '#00313E',
                        textTransform: 'none',
                        fontSize: '40px',
                        display: "flex",
                        alignItems: "center"
                      }}>
                      <Typography
                        variant="body"
                        sx={{
                          fontFamily: 'DinDIsplayProRegular',
                          marginLeft: "30px",
                          marginRight: "10px"
                        }}
                      >
                        {saveLaterData.code}
                      </Typography>
                      <img src={union}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigator.clipboard.writeText(saveLaterData.code);
                          setOpen(true);
                        }} />

                    </Paper>
                  </Grid>
                  <Grid item mt={4} sx={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "center" }}>
                    <Typography
                      variant="body"
                      sx={{
                        fontFamily: 'DinDIsplayProRegular',
                        color: '#00313E',
                        textTransform: 'none',
                        fontSize: '14px',
                        textAlign: 'center',
                        marginLeft: "10px"
                      }}
                    >
                      A copy of this code, along with a unique URl has been emailed to you.
                    </Typography>
                  </Grid>
                  <Grid item mt={3} sx={{ display: "flex", alignItems: "center", width: "100%" }}>
                    <Button
                      variant="contained"
                      color="neutral"
                      sx={{
                        borderRadius: 1,
                        backgroundColor: '#00313E',
                        height: "60px",
                        width: "100%",
                      }}
                      onClick={() => {
                        handleSaveForLaterClose()
                      }
                      }
                    >
                      <Typography variant="button" sx={{ fontFamily: 'DinDIsplayProBold' }}>
                        Done
                      </Typography>
                    </Button>
                  </Grid>
                </div>
              </Grid>
            ) : <Grid Container display="flex" flexDirection="column" mt={1}>
              <Typography
                sx={{
                  fontFamily: 'PreloSlabW04Bold',
                  color: '#00313E',
                  fontSize: '26px',
                  textAlign: 'center',
                }}
                id="modal-modal-title"
                variant="h6"
                component="h2"
              >
                Save for later
              </Typography>
              <Formik initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={addInfo}>
                {({ values, handleChange, handleSubmit, errors, touched }) => (
                  <div className={classes.root} >
                    <Grid item mt={2} sx={{ width: "100%", height: "60px" }}>
                      <CssTextField
                        className={classes.margin}
                        variant="outlined"
                        fullWidth
                        id="custom-css-outlined-input"
                        placeholder='Enter your Email Address'
                        error={errors.email}
                        defaultValue={values.email}
                        value={values.email}
                        onChange={handleChange('email')}
                        helperText={touched.email && errors.email}
                      />
                    </Grid>
                    <Grid item sx={{
                      width: "100%",
                      paddingTop: "8px"
                    }}>
                      <Typography
                        variant="body"
                        sx={{

                          fontFamily: 'DinDIsplayProRegular',
                          color: '#00313E',
                          textTransform: 'none',
                          fontSize: '14px',
                        }}
                      >
                        You'll receive an email with a link, along with a code to retrieve this quote.
                      </Typography>
                    </Grid>
                    <Grid item mt={6}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        justifyContent: "flex-start"
                      }}>
                      <>
                        <Checkbox
                          size="small"
                          style={{ margin: 0, padding: 0 }}
                          onChange={(e) => handleChangeDemo(e.target.value)}
                          checked={isChecked}
                          icon={<img src={iconlight} />}
                          checkedIcon={<img src={icondark} />}
                        />
                        <Typography
                          variant="body"
                          sx={{
                            fontFamily: 'PreloSlabW04Light',
                            color: '#00313E',
                            textTransform: 'none',
                            fontSize: '14px',
                            marginLeft: "10px"
                          }}
                        >
                          Please follow up with me to arrange a tailored solution demo.
                        </Typography>
                      </>
                    </Grid>
                    <Grid item mt={3} sx={{ display: "flex", alignItems: "center", width: "100%" }}>
                      <Button
                        variant="primary"
                        sx={{
                          borderRadius: 1,
                          border: '1px solid #CFCFCF',
                          height: "60px",
                          width: "215px",
                          marginRight: "15px",
                        }}
                        onClick={() => handleSkip()}
                      >
                        <Typography
                          variant="button"
                          sx={{ fontFamily: 'DinDIsplayProBold', color: '#00313E' }}
                        >
                          Skip for Now
                        </Typography>
                      </Button>

                      <LoadingButton
                        variant="contained"
                        color="neutral"
                        loading={loading}
                        loadingPosition="end"
                        sx={{
                          borderRadius: 1,
                          backgroundColor: '#00313E',
                          height: "60px",
                          width: "215px",
                        }}
                        onClick={() => handleSubmit()}
                      >
                        <Typography variant="button" sx={{ fontFamily: 'DinDIsplayProBold' }}>
                          Save
                        </Typography>
                      </LoadingButton>
                    </Grid>
                  </div>
                )}
              </Formik>
            </Grid>
          }
        </Box>
      </Modal >
    </div >
  )
}
const mapStateToProps = (state) => {
  return {
    saveLaterData: state?.saveLater,
    support: state?.support?.support?.data
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    saveLater: (payload) => dispatch(saveLater(payload)),

  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SaveForLater);
