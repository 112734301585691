import PropTypes from 'prop-types';
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';

function ValueLabelComponent(props) {
  const { children, value } = props;

  return (
    <Tooltip enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  value: PropTypes.number.isRequired,
};

const PrettoSlider = styled(Slider)({
  height: 8,
  color: '#CCD5D7',
  width: '100%',

  '& .MuiSlider-track': {
    border: 'none',
    backgroundImage: 'linear-gradient(270deg, #34B233 0%, #00A9E0 100%)',
  },

  '& .MuiSlider-thumb': {
    height: 18,
    width: 18,
    backgroundColor: '#fff',
    border: '4px solid #00313E',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 80,
    height: 30,
    backgroundColor: '#00313E',
    transformOrigin: 'bottom left',
  },
});
export default function CustomizedSlider({
  type,
  getValue,
  getValueTwo,
  bcFullUsers,
  bcTeamUsers,
  salesFullUsers,
  salesTeamUsers,
  serviceFullUsers,
  serviceTeamUsers,
}) {
  return (
    <Box>
      {type === '50' ? (
        <>
          <PrettoSlider
            valueLabelDisplay={bcFullUsers || salesFullUsers || serviceFullUsers ? "on" : "off"}
            aria-label="pretto slider"
            max={50}
            onChange={getValue}
            value={bcFullUsers || salesFullUsers || serviceFullUsers}
          />
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              color: '#00313E',
              fontFamily: 'PreloSlabW04SemiBold',
            }}
          >
            <p>0</p>
            <p>50</p>
          </div>
        </>
      ) : null}
      {type === '100' ? (
        <>
          <PrettoSlider
            valueLabelDisplay={bcTeamUsers || serviceTeamUsers || salesTeamUsers ? "on" : "off"}
            aria-label="pretto slider"
            max={100}
            onChange={getValueTwo}
            value={bcTeamUsers || serviceTeamUsers || salesTeamUsers}
          />
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              color: '#00313E',
              fontFamily: 'PreloSlabW04SemiBold',
            }}
          >
            <p>0</p>
            <p>100</p>
          </div>
        </>
      ) : null}
    </Box>
  );
}
