import SAVECUSTOMERSERVICE, { RESET_SAVECUSTOMERSERVICE } from './customerService.types';

const INITIAL_STATE = {
  "customerService": {
    "customerServiceData": {
        "uniqueServiceProcesses": {
            "label": "uniqueServiceProcesses",
            "value": null
        },
        "customerSelfServePortal": {
            "label": "customerSelfServePortal",
            "value": null
        },
        "workOrders": {
            "label": "workOrders",
            "value": null
        },
        "workOrderRouting": {
            "label": "workOrderRouting",
            "value": null
        },
        "supportContracts": {
            "label": "supportContracts",
            "value": null
        },
        "selfserveKBs": {
            "label": "selfserveKBs",
            "value": null
        }
    }
},
};

const reducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case SAVECUSTOMERSERVICE:
      // console.log('action.payload', action.payload);
      return {
        ...state,
        customerService: action.payload,
      };

      case RESET_SAVECUSTOMERSERVICE:
        return INITIAL_STATE

    default:
      return state;
  }
};

export default reducer;
