import SAVESALES, { RESET_SAVESALES } from './sales.types';

const INITIAL_STATE = {

  "sales": {
    "salesManagement": {
        "websitesLeads": {
            "label": "No",
            "value": null
        },
        "emailLeads": {
            "label": "No",
            "value": null
        },
        "leadLists": {
            "label": "No",
            "value": null
        },

        "other": {
            "label": "No",
            "value": null
        },

        "otherDescription": {
            "label": "otherDescription",
            "value": null
        },

        "leadRouting": {
            "label": "leadRouting",
            "value": null
        },
        "leadScoring": {
            "label": "leadScoring",
            "value": null
        },
        "buSalesFunnels": {
            "label": "buSalesFunnels",
            "value": null
        },
        "salesProcesses": {
            "label": "salesProcesses",
            "value": null
        },
        "collabrativeSales": {
            "label": "collabrativeSales",
            "value": null
        },
        "salesTargetTracking": {
            "label": "salesTargetTracking",
            "value": null
        }
    },
    "salesQuote": {
        "quotes": {
            "label": "quotes",
            "value": null
        },
              
        "eSignatureIntegration": {
            "label": "eSignatureIntegration",
            "value": null
          },
        "quoteType": {
            "label": "quoteType",
            "value": null
        },
        "noCatalogPriceLists": {
            "label": "noCatalogPriceLists",
            "value": null
        },
        "productBundles": {
            "label": "productBundles",
            "value": null
        },

    }
},

};

const reducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case SAVESALES:
    //   console.log('action.payload', action.payload);
      return {
        ...state,
        sales: action.payload,
      };

      case RESET_SAVESALES:
        return INITIAL_STATE
    default:
      return state;
  }
};

export default reducer;
