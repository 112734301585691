/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-plusplus */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable import/no-unresolved */
import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { Button, Container, Grid, Typography, Stack, Card, CardContent, Link, CardMedia } from '@mui/material';
import { Link as Scroll } from 'react-scroll';
import { useMediaQuery } from 'react-responsive';
import { connect, useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';
import { setStepsStatus } from '../redux/home/home.actions';
import saveSupport from '../redux/support/support.actions';
import saveProfileInfo from '../redux/profile/profile.actions'
import saveSupportPackage from '../redux/supportPackage/supportPackage.actions';
import saveRequestDemo from '../redux/requestDemo/requestDemo.actions';
import BannerImage from '../assets/logo-overlay.png';
import Navbar from '../Components/Navbar/NavbarTab'
import SupportPricing from './SupportPricing';
import Configuration from './Configuration';
import SubscriptionPricing from './SubscriptionPricing';
import CaseStudy1 from '../assets/CaseStudy1.jpg'
import CaseStudy2 from '../assets/CaseStudy2.jpeg'
import CaseStudy3 from '../assets/CaseStudy3.jpg'
import exit from '../assets/exit.svg'
import catapultLogo from '../assets/catapult-mobile.svg'
import { modifyProductsByCategory } from '../helpers';

const Pricing = ({ profileInfo, stepsStatus, selectedPackage, saveForLaterCode }) => {

    const isIpad = useMediaQuery({ query: `(max-width: 1024px)` });

    const isSmallMobile = useMediaQuery({ query: `(max-width: 768px)` });

    const isMobile = useMediaQuery({ query: `(max-width: 812px)` });

    const location = useLocation();

    const dispatch = useDispatch();

    const reduxData = useSelector((s) => s);

    const [changeFrame, setChangeFrame] = useState(false)

    const [text, setText] = useState("Request a solution demo")

    // state for navbar responsiveness
    const [navbar, setNavbar] = useState(false);

    const [requestDemo, setRequestDemo] = useState(false)

    const [loading, setLoading] = useState(false);

    const [selectPlan, setSelectPlan] = useState(selectedPackage?.supportPackage);

    const pricingDivRef = useRef()

    const frame = (value) => {
        setChangeFrame(value)
    }

    const changePosition = () => {
        if (window.scrollY >= 400) {
            setNavbar(true);
        } else {
            setNavbar(false);
        }
    };

    window.addEventListener('scroll', changePosition);

    const button = [
        { label: 'Bundled Subscription Plan', value: false },
        { label: 'Project + Support Plan', value: true },
    ];

    const caseStudy = [
        {
            heading: "The Catapult Success Framework",
            img: CaseStudy1,
            content: "Learn more how your business can achieve out of this world results using our specially designed Success Framework",
            link: "https://www.catapulterp.com/success-framework"
        },
        {
            heading: "Our Dynamics 365 Solutions",
            img: CaseStudy2,
            content: "Discover the full suite of D365 solutions offered for SMBs and how they can be implemented to transform your business",
            link: "https://www.catapulterp.com/dynamics-365"
        },
        {
            heading: "Our Latest Insights",
            img: CaseStudy3,
            content: "Explore our most recent articles, eBooks, and webinars along with case studies that highlight our clients’ success",
            link: "https://www.catapulterp.com/insights"
        },
    ]

    const demo = (values) => {
        setLoading(true)
        setRequestDemo(true)
        setText("Thanks for your request!")

        const body = {
            selectedProducts: modifyProductsByCategory(reduxData.product.selectedProducts),
            businessCentral: reduxData.businessCentral.businessCentral,
            sales: reduxData.sales.sales,
            marketing: reduxData.marketing.marketing,
            customerService: reduxData.customerService.customerService,
            endUsers: reduxData.endUsers,
            profileDetails: {
                companyName: profileInfo.companyName,
                countryCode: profileInfo.countryCode,
                email: profileInfo.email,
                firstName: profileInfo.firstName,
                followUp: true,
                lastName: profileInfo.lastName,
                phoneNo: profileInfo.phoneNo,
            },
            supportPackage: selectPlan,
            userLocation: reduxData.saveGeoLocation.geoLocation,
            lastVistedRoute: {
                stepsStatus: reduxData.home.stepsStatus,
                subStepsStatus: reduxData.home.subStepsStatus,
                route: location.pathname
            },
            userCode: saveForLaterCode,
        }
        saveProfileInfo(values)
        axios.post(`${process.env.REACT_APP_BASE_URL}/api/calculatePricing`, body)

            .then(res => {
                if (res?.data?.message === 'Pricing calculated successfully') {
                    const response = res?.data;
                    dispatch(saveSupport(response))
                    dispatch(saveProfileInfo(response.data.profileDetails))
                    dispatch(saveSupportPackage(selectPlan))
                    dispatch(saveRequestDemo(requestDemo))
                    const updateStep = stepsStatus;
                    updateStep[3].support = { 'active': false, 'completed': true }
                    dispatch(setStepsStatus(updateStep))
                    setLoading(false)
                }
            })
            .catch((error) => {
                setLoading(false)
            });
    }

    const scrollToRef = (ref) => {
        if (isSmallMobile) {
            window.scrollTo(0, ref.current.offsetTop - 150);
        }
        else {
            window.scrollTo(0, ref.current.offsetTop - 100);
        }
    }

    // window.scrollTo(0, ref.current.offsetTop - 150);

    useEffect(() => {
        ReactGA.initialize('UA-74792081-1');
        ReactGA.pageview(window.location.pathname + window.location.search);
        console.log(window.location.pathname, "window.location.pathname")
        console.log(window.location.search, "window.location.search")
        scrollToRef(pricingDivRef)
    }, [])

    return (
        <div>
            <Scroll to="navbar" spy smooth>
                <section
                    style={{
                        backgroundImage: `linear-gradient(90deg, #61C250, #4EC35D, #00C37C, #00C0A4, #00BBC7, #00B3DA, #00ACE0, #00A9E0)`,
                        height: isMobile ? '80vh' : "100%",
                    }}
                >
                    <div
                        style={{
                            backgroundImage: `url(${BannerImage})`,
                            height: '100%',
                            backgroundPosition: 'top',
                            backgroundRepeat: 'no-repeat',
                            display: "flex",
                            alignItems: "center"
                        }}
                    >

                        <img src={isMobile ? catapultLogo : exit}
                            style={{
                                cursor: "pointer",
                                height: isMobile ? '47px' : "67px",
                                width: isMobile ? '100%' : "230px",
                                zIndex: 999,
                                position: "fixed",
                                right: isMobile ? '' : "0px",
                                // left: isMobile ? "-1px" : '',
                                top: "-5px",
                            }}
                            onClick={() =>
                                window.open("https://www.catapulterp.com/", "_blank")}
                        />

                        <Container fixed maxWidth="md" className="animate__animated animate__fadeIn"
                            sx={{
                                height: "450px",
                            }}
                        >
                            <Grid item xs={12}
                                sx={{
                                    textAlign: 'center',
                                    height: "100%",
                                    display: "flex",
                                    justifyContent: "center"
                                }}>
                                <Stack direction="column"
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        paddingTop: isMobile ? '47px' : ''
                                    }}>
                                    <Typography
                                        variant="string"
                                        gutterBottom
                                        component="div"
                                        sx={{
                                            fontFamily: 'DinDIsplayProRegular',
                                            fontStyle: 'italic',
                                            color: '#FFFFFF',
                                        }}
                                    >
                                        Pricing
                                    </Typography>
                                    <Typography
                                        variant="h3"
                                        gutterBottom
                                        component="div"
                                        className="font-face-prelo-black"
                                        sx={{
                                            fontFamily: 'PreloSlabW04Black',
                                            color: '#FFFFFF',
                                            fontSize: isMobile ? '33px' : '48px',
                                            marginBottom: isMobile ? "40px" : ''
                                        }}
                                    >
                                        Take the guesswork out of your ERP & CRM solution budgeting.
                                    </Typography>
                                    <Typography
                                        variant="caption"
                                        gutterBottom
                                        component="div"

                                        mb={5}
                                        sx={{
                                            fontFamily: 'PreloSlabW04Light',
                                            color: '#FFFFFF',
                                            fontSize: isMobile ? '18px' : '20px',
                                            lineHeight: 1.5,
                                            paddingX: isMobile ? 2 : 10,
                                            marginBottom: isMobile ? "60px" : '40px'
                                        }}
                                    >
                                        Customize and price your Dynamics 365 solution using our powerful online
                                        scoping and budgeting tools.
                                    </Typography>
                                </Stack>
                            </Grid>
                        </Container >
                    </div>
                </section>
            </Scroll>
            <div id="navbar"  >
                <Navbar navbar={navbar} />
            </div>
            <Container sx={{ py: 5, height: '100%', display: "flex", justifyContent: "center" }} ref={pricingDivRef} >
                <Grid container spacing={2} justifyContent="center" md={10}>
                    <Grid item xs={12} sx={{ textAlign: 'center' }}>
                        <Stack direction="column">
                            <Typography
                                variant="h6"

                                component="div"
                                sx={{
                                    fontFamily: 'PreloSlabW04Bold',
                                    color: '#00313E',
                                    fontSize: isMobile ? "32px" : '40px',
                                    textAlign: "center"
                                }}
                            >
                                Your Solution Quote
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sx={{ textAlign: 'center' }}>
                        {button?.map((item) => (
                            <Button
                                variant="myPrimary"
                                sx={{
                                    backgroundColor: changeFrame !== item.value ? 'white' : '#00313E',
                                    width: isMobile ? "170px" : '250px',
                                    height: '70px',
                                    padding: "0px",
                                    borderRadius: '15px',
                                    border: '1px solid #00313E',
                                    marginRight: isMobile ? "5px" : '40px',
                                    marginBottom: '10px',
                                    marginTop: '10px',

                                    '&:hover': {
                                        backgroundColor: changeFrame !== item.value ? 'white' : '#00313E',
                                    },
                                }}
                                value={item.value}
                                onClick={() => {
                                    frame(item.value);
                                }}
                            >
                                <Typography
                                    variant="button"
                                    display="block"
                                    sx={{
                                        color: changeFrame !== item.value ? '#00313E' : '#FFFFFF',
                                        fontFamily: 'DinDIsplayProBold',
                                        textTransform: 'none',
                                        fontSize: isMobile ? '14px' : '18px',
                                    }}
                                >
                                    {item.label}
                                </Typography>
                            </Button>
                        ))}

                        {changeFrame === false ? (
                            <SubscriptionPricing />
                        ) : (
                            <SupportPricing />
                        )}
                        <Configuration />

                    </Grid>
                    {profileInfo?.followUp === true ? (
                        ''
                    ) : (
                        <>
                            <Grid item xs={12} sx={{ textAlign: 'center' }}>
                                <Button
                                    variant="myPrimary"
                                    sx={{
                                        backgroundColor: 'white',
                                        width: '250px',
                                        height: '70px',
                                        borderRadius: '5px',
                                        border: '1px solid #00313E',
                                        marginBottom: '10px',
                                        marginTop: '10px',
                                        '&:hover': {
                                            backgroundColor: 'white',
                                        },
                                    }}
                                    onClick={() => { demo() }}
                                >
                                    <Typography
                                        variant="button"
                                        display="block"
                                        sx={{
                                            color: '#00313E',
                                            fontFamily: 'DinDIsplayProBold',
                                            textTransform: 'none',
                                            fontSize: '18px',
                                        }}
                                    >
                                        {text}
                                    </Typography>

                                </Button>
                            </Grid>
                            {requestDemo === true ? (
                                <Grid item xs={12} md={6} sx={{ textAlign: 'center' }}>
                                    <Typography
                                        variant="body1"
                                        gutterBottom
                                        component="div"
                                        sx={{
                                            fontFamily: 'PreloSlabW04Light',
                                            color: '#00313E',
                                            fontSize: '16px',
                                            textAlign: "center",
                                        }}
                                    >
                                        Our team will be in touch shortly to schedule a solution demo tailored to your busineses needs.
                                    </Typography>
                                </Grid>
                            ) : (
                                ""
                            )}
                        </>
                    )
                    }

                </Grid>
            </Container>
            <div style={{
                height: isMobile ? "100%" : '650px',
                width: "100%",
                backgroundImage: `linear-gradient(90deg, #61C250, #4EC35D, #00C37C, #00C0A4, #00BBC7, #00B3DA, #00ACE0, #00A9E0)`,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center"
            }}>
                <Grid item py={2}>
                    <Typography
                        variant="body1"
                        gutterBottom
                        component="div"
                        sx={{
                            fontFamily: 'PreloSlabW04Bold',
                            color: '#FFFFFF',
                            fontSize: isMobile ? "20px" : '35px',
                            textAlign: "center",
                            marginLeft: isMobile ? "5px" : "0px",
                            marginRight: isMobile ? "5px" : "0px",
                        }}
                    >
                        Learn more about partnering with Catapult
                    </Typography>
                </Grid>
                <Grid item sx={{
                    marginTop: "20px",
                    display: "flex",
                    flexDirection: isMobile ? "column" : "row",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                    {caseStudy?.map((item) => (
                        <Card
                            sx={{
                                width: "350px",
                                height: "400px",
                                marginBottom: "15px",
                                marginRight: "10px",
                                marginLeft: "10px",
                                overflow: isMobile ? "auto" : ' '
                            }}>
                            <CardMedia
                                component="img"
                                height="180"
                                image={item.img}
                                alt="case study"
                            />
                            <CardContent>
                                <Typography
                                    variant="body1"
                                    gutterBottom
                                    component="div"
                                    sx={{
                                        fontFamily: 'DinDIsplayProRegular',
                                        color: '#00A9E0',
                                        fontSize: isMobile || isIpad ? "16px" : '22px',
                                        height: "100%",
                                    }}
                                >
                                    {item.heading}
                                </Typography>
                                <Typography
                                    variant="body1"
                                    gutterBottom
                                    component="div"
                                    sx={{
                                        fontFamily: 'PreloSlabW04Medium',
                                        color: '#00313E',
                                        fontSize: isMobile || isIpad ? "14px" : '15px',
                                        height: "80px"
                                    }}>
                                    {item.content}
                                </Typography>
                                <Grid item
                                    sx={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        height: "100%"
                                    }}>
                                    <Button
                                        variant="myPrimary"
                                        sx={{
                                            backgroundColor: '#00A9E0',
                                            width: '121px',
                                            height: '40px',
                                            borderRadius: '30px',
                                            border: '1px solid #00313E',
                                            marginBottom: '10px',
                                            marginTop: '10px',
                                            '&:hover': {
                                                backgroundColor: '#00A9E0',
                                            },
                                        }}

                                        onClick={() => window.location.replace(item.link)}
                                    >
                                        <Typography
                                            variant="button"
                                            display="block"
                                            sx={{
                                                color: '#FFFFFF',
                                                fontFamily: 'DinDIsplayProBold',
                                                textTransform: 'none',
                                                fontSize: '14xpx',
                                            }}
                                        >
                                            LEARN MORE
                                        </Typography>
                                    </Button>
                                </Grid>
                            </CardContent>
                        </Card>
                    ))}
                </Grid>

            </div>

        </div >
    )
}
const mapStateToProps = (state) => {
    return {
        code: state.pricingCode.code,
        profileInfo: state.profile.profile,
        stepsStatus: state?.home?.stepsStatus,
        selectedPackage: state?.saveSupportPackage,
        demo: state?.requestDemo,
        saveForLaterCode: state?.saveLater?.code,
    };
};


const mapDispatchToProps = (dispatch) => {
    return {
        saveSupport: (payload) => dispatch(saveSupport(payload)),
        setStepsStatus: (payload) => dispatch(setStepsStatus(payload)),
        saveProfileInfo: (payload) => dispatch(saveProfileInfo(payload)),
        saveSupportPackage: (payload) => dispatch(saveSupportPackage(payload)),
        saveRequestDemo: (payload) => dispatch(saveRequestDemo(payload)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Pricing);
