import {
  INCREMENT,
  DECREMENT,
  QUOTETYPE,
  STEPSOFMODULES,
  UPDATE_SUB_STEPS_STATUS,
  RESET_UPDATE_SUB_STEPS_STATUS,
  RESET_STEPSOFMODULES,
} from './home.types';

const INITIAL_STATE = {
  count: 0,
  quoteType: 0, // if new quote then 0 else 1
  stepsStatus: [
    {
      product: { active: false, completed: false },
    },
    {
      businessDetails: { active: false, completed: false },
    },
    {
      endUsers: { active: false, completed: false },
    },
    {
      support: { active: false, completed: false },
    },
    {
      pricing: { active: false, completed: false },
    },
  ],
  
  subStepsStatus: {

    bdCentral: { active: false, completed: false },

    bdSales: { active: false, completed: false },

    bdMarketing: { active: false, completed: false },

    bdCustomerService: { active: false, completed: false },

    euCentral: { active: false, completed: false },

    euSales: { active: false, completed: false },

    euCustomerService: { active: false, completed: false },
    
  },
};

const reducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case INCREMENT:
      // console.log('action.payload', action.payload);
      return {
        ...state,
        count: state.count + 1,
        savedData: action.payload,
      };

    case DECREMENT:
      return {
        ...state,
        count: state.count - 1,
      };

    case QUOTETYPE:
      // console.log('action.payload', action.payload);
      return {
        ...state,
        quoteType: action.payload,
      };

    case STEPSOFMODULES:
      // console.log('action.payload', action.payload);
      return {
        ...state,
        stepsStatus: action.payload,
      };

    case UPDATE_SUB_STEPS_STATUS:
      // console.log('action.payload', action.payload);
      return {...state, subStepsStatus:{...state.subStepsStatus,...action.payload}}

      case RESET_STEPSOFMODULES:
        return INITIAL_STATE

        case RESET_UPDATE_SUB_STEPS_STATUS:
          return INITIAL_STATE
    default:
      return state;
  }
};

export default reducer;
