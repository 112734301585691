import SET_PROFILE from './profile.type';

const saveProfileInfo = (payload) => {
    // console.log('payload',payload)
    return {
        type: SET_PROFILE,
        payload
    };

}
export default saveProfileInfo