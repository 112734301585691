import { SELECTPRODUCTS } from './product.type';

export const setSelectedProducts = (payload) => {
    console.log('payload',payload)
    return {

        type: SELECTPRODUCTS,
        payload
    };

};

export const setSelectedProductsConfigs = (payload) => {
    // console.log('payload',payload)
    return {

        type: SELECTPRODUCTS,
        payload
    };

};