import SET_PROFILE, { RESET_SET_PROFILE } from './profile.type';

const INITIAL_STATE = {
};

const profileReducer = (state = INITIAL_STATE, action = {}) => {

    switch (action.type) {

        case SET_PROFILE:
            // console.log('action.payload',action.payload)
            return {

                ...state, 
                profile: action.payload

            };
            case RESET_SET_PROFILE:
                return INITIAL_STATE

            default: return state;

    }

};

export default profileReducer;