import {
  INCREMENT,
  DECREMENT,
  QUOTETYPE,
  STEPSOFMODULES,
  UPDATE_SUB_STEPS_STATUS,
} from './home.types';

export const increaseCounter = (payload) => {
  // console.log('payload', payload);
  return {
    type: INCREMENT,
    payload,
  };
};

export const decreaseCounter = () => {
  return {
    type: DECREMENT,
  };
};

export const setQuoteType = (payload) => {
  return {
    type: QUOTETYPE,
    payload,
  };
};

export const setStepsStatus = (payload) => {
  return {
    type: STEPSOFMODULES,
    payload,
  };
};

export const setSubStepStatus = (payload) => {
  return {
    type: UPDATE_SUB_STEPS_STATUS,
    payload,
  };
};
