import SAVEREQUESTDEMO, { RESET_SAVEREQUESTDEMO } from './requestDemo.types';

const INITIAL_STATE = {
  requestDemo: false
};

const reducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case SAVEREQUESTDEMO:
      // console.log('action.payload', action.payload);
      return {
        ...state,
        requestDemo: action.payload
      };

      case RESET_SAVEREQUESTDEMO:
        return INITIAL_STATE
    default:
      return state;
  }
};

export default reducer;
