/* eslint-disable no-constant-condition */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable jsx-a11y/alt-text */
// / eslint-disable jsx-a11y/alt-text /
// / eslint-disable jsx-a11y/click-events-have-key-events /
// / eslint-disable jsx-a11y/no-static-element-interactions /
// / The <div> element has a child <button> element that allows keyboard interaction /
// / eslint-disable-next-line jsx-a11y/no-static-element-interactions /
import { useEffect, useRef, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { setStepsStatus } from '../../redux/home/home.actions';
import conditionalRoute from '../../Routes/ConditionalRoutes';
import conditionalRouteEndUser from '../../Routes/ConditionalRouteEndUser';
import Tick from './tick.svg';
import './Navbar.css';

const Navbar = ({ navbar, stepsStatus }) => {

  const location = useLocation();

  const { home, product } = useSelector((s) => s);

  const isMobile = useMediaQuery({ query: `(max-width: 640px)` });

  const dispatch = useDispatch();

  const updateStepStatus = () => {
    const updateStep = stepsStatus;
    updateStep[2].endUsers = { 'active': false, 'completed': true }
    dispatch(setStepsStatus(updateStep))
  }

  useEffect(() => {
    if (product.selectedProducts.length === 1 && product.selectedProducts[0] === '3' && stepsStatus[1].businessDetails.completed === true) {
      updateStepStatus()
    }
  }, [])

  useEffect(() => {

    if (location.pathname === "/product") {
      const element = document.getElementById('products')
      if (element === null) {
        console.log("null")
      }
      else {
        element.scrollIntoView({ block: "start" });

      }
    }
    else if (location.pathname === 'end-users/business-central'
      || location.pathname === 'end-users/customer-service' ||
      location.pathname === 'end-users/sales') {
      const element = document.getElementById('pricingDiv')
      if (element === null) {
        console.log("null")
      }
      else {
        element.scrollIntoView({ block: "end" });
      }

    }

    else if (location.pathname === "/pricing" && home?.stepsStatus[1].businessDetails?.completed === true) {
      const element = document.getElementById('pricingDiv')
      if (element === null) {
        console.log("null")
      }
      else {
        element.scrollIntoView({ block: "end" });
      }
    }

    else if (location.pathname === "/support") {
      const element = document.getElementById('pricingDiv')
      if (element === null) {
        console.log("null")
      }
      else {
        element.scrollIntoView({ block: "end" });
      }
    }
    else if (location.pathname === "/pricing" && home?.stepsStatus[2].endUsers?.completed === true) {
      const element = document.getElementById('pricingDiv')
      if (element === null) {
        console.log("null")
      }
      else {
        element.scrollIntoView({ block: "end" });
      }
    }
  }, [])

  return (
    < div className={navbar ? 'web-navbar-fixed' : 'web-navbar'}>
      <ul className="navbar-list">

        <li className="navbar-list-tab" id="products">
          {home?.stepsStatus[0].product?.completed === true ? <img style={{ height: isMobile ? "20px" : "" }} src={Tick} /> : ''}
          <NavLink
            exact
            to="/product"
            className={location.pathname.startsWith('product') && 'active'}
          >
            DYNAMICS 365 PRODUCTS
          </NavLink>
        </li>

        <li className="navbar-list-tab" id="bdetails">

          {home?.stepsStatus[1].businessDetails?.completed === true ? <img src={Tick} style={{ height: isMobile ? "20px" : "" }} /> : ''}

          {home?.stepsStatus[0].product?.completed === false || location.pathname === "/pricing" || location.pathname === "/product" ? (
            <li className="disabled-list">BUSINESS DETAILS</li>
          ) : (
            <NavLink
              exact
              to={`/business-details/${conditionalRoute(product.selectedProducts.at(0))}`}
              className={location.pathname.startsWith('/business-details') && 'active'}
            >
              BUSINESS DETAILS
            </NavLink>
          )}
        </li>

        <li className="navbar-list-tab" id='endUsersDiv'>
          {home?.stepsStatus[2].endUsers?.completed === true ? <img src={Tick} style={{ height: isMobile ? "20px" : "" }} /> : ''}

          {product.selectedProducts.length === 1 && product.selectedProducts[0] === '3' || location.pathname === "/pricing" || location.pathname === "/product" ?
            (
              <li className="disabled-list"> END USERS</li>
            ) : (
              <>
                {home?.stepsStatus[1].businessDetails?.completed === true ? (
                  <NavLink
                    exact
                    to={`/end-users/${conditionalRouteEndUser(product.selectedProducts.at(0))}`}
                    className={location.pathname.startsWith('/end-users') && 'active'}
                  >
                    END USERS
                  </NavLink>
                ) : (
                  <li className="disabled-list"> END USERS</li>
                )}
              </>
            )}

        </li>

        <li className="navbar-list-tab">
          {home?.stepsStatus[3].support?.completed === true ? <img src={Tick} style={{ height: isMobile ? "20px" : "" }} /> : ''}

          {home?.stepsStatus[2].endUsers?.completed === false || location.pathname === "/pricing" || location.pathname === "/product" ? (
            <li className="disabled-list"> SUPPORT</li>
          ) : (
            <NavLink
              exact
              to="/support"
              className={location.pathname.startsWith('/support') && 'active'}
            >
              SUPPORT
            </NavLink>
          )}
        </li>

        <li className="navbar-list-tab" >
          {home?.stepsStatus[4].product?.completed === true ? <img src={Tick} /> : ''}

          {home?.stepsStatus[3].support?.completed === true ? (
            <NavLink
              exact
              to="/pricing"
              className={location.pathname.startsWith('/pricing') && 'active'}
            >
              PRICING
            </NavLink>
          ) : (
            <li className="disabled-list"> PRICING</li>
          )}
        </li>
      </ul>
      {isMobile ? <div id="pricingDiv" style={{ width: "10px" }} /> : ''}
      {isMobile ? '' : <div style={{ width: "240px" }} />}

    </div >

  );
};

const mapStateToProps = (state) => {
  return {
    stepsStatus: state.home.stepsStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);

