/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-shadow */
/* eslint-disable prefer-const */
/* eslint-disable no-plusplus */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/alt-text */
import { makeStyles } from "@mui/styles";
import parser from "react-html-parser";
import {
    Container, Grid, Typography, Stack, Accordion, AccordionDetails, AccordionSummary,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from 'react-scroll';
import { useMediaQuery } from 'react-responsive';
import BannerImage from '../assets/logo-overlay.png';
import 'animate.css';
import exit from '../assets/exit.svg';
import catapultLogo from '../assets/catapult-mobile.svg'

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap"
    },
    MuiAccordionroot: {
        '&.MuiAccordion-root': {
            boxShadow: 'none',
            borderTop: "1px solid #F4F4F4"
        },
    },
}));

const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 170,
        },
    },
};

const faq = () => {

    const classes = useStyles();

    const isMobile = useMediaQuery({ query: `(max-width: 590px)` });

    const isIpad = useMediaQuery({ query: `(max-width: 900px)` });

    document.documentElement.style.setProperty('--animate-duration', '3s');

    const faqData = [
        {
            index: 1,
            name: 'How long does it take to build a quote?',
            desc: 'We’re excited that you’re interested in taking your business to the next level with a Microsoft Dynamics 365 solution. Building a Dynamics 365 solution quote will take between 5-10 minutes for most people depending on the scope of business processes you’re seeking to improve. Don’t worry, you can save your work at any time and return to complete it later.',
        },
        {
            index: 2,
            name: 'How is the pricing built?',
            desc: "The pricing tools on our website have evolved through many scoping conversations over the years with people just like you who needed to understand the necessary investments in software and services. We are firm believers that software and services pricing should be standardized and transparent. Our pricing algorithms are built on hundreds of successful implementations completed by our team and use insight about your business to determine your unique solution’s quote. Every single input you provide as you go through the questionnaire has an impact, so take your time and if you need to check details before answering a question, simply hit Save for Later.",
        },
        {
            index: 3,
            name: 'What is included in the price quote?',
            desc: "Our quotes cover all the investments and ongoing costs associated with implementation and support for the Dynamics 365 solution you specify. Your quote will include software licensing for the number of users you specify, our fees to implement the software according to your requirements, training, deployment, and ongoing support.",
        },
        {
            index: 4,
            name: 'What is not included in the price quote?',
            desc: "Sometimes, customers have unique requirements that require third-party software made to work with Dynamics 365, existing systems and software that may require integration, custom reporting and dashboards, or custom development. Before we engage or sign an agreement, our team will validate the scope and requirements for your Dynamics 365 solution and discuss options for any capabilities not considered “out-of-the-box” and present refined pricing on a case-by-case basis."
        },
        {
            index: 5,
            name: 'What payment options does Catapult offer?',
            desc: "Catapult offers payment flexibility to suit organizations with different needs. Regardless of the preferred approach, we believe in shared accountability with fixed-fee implementations and support. We know that you can’t afford to take risks when it comes to ERP and CRM and our objective is to eliminate the guesswork. Customers can opt for a fixed-fee implementation with a milestone-driven payment structure plus a 1-year renewable support and licensing agreement, or a unique fully bundled subscription where the implementation project fee is amortized over a 36-month period, significantly reducing upfront costs.",
        },
        {
            index: 6,
            name: 'How does Catapult ensure a successful Dynamics 365 adoption?',
            desc: "Catapult has been helping customers perform at their best with ERP and CRM solutions from Microsoft since 2007. We are experts in the platform and our focus on long-term support and evolving solutions to keep pace with changing business needs is ideal for fast-paced midsized business, non-profits and public sector organisations. As is the case with any implementation and adoption process, a clearly laid out plan is always the best way to get where you need to go. That’s why we’ve laid out our easy-to-navigate <a href='https://www.catapulterp.com/success-framework/'> Success Framework</a> that guides our clients on their journey to a successful business adoption and ensures that their needs are met and expectations exceeded at every stage.",
        },
        {
            index: 7,
            name: 'What Dynamics 365 solutions do you offer services for? ',
            desc: "Our services support the full suite of Dynamics 365 ERP and CRM solutions for small to medium sized businesses. This includes Dynamics 365 Business Central, Dynamics 365 Sales, Marketing, and Customer Service, as well as the complete Microsoft Power Platform. We also support a wide range of partner integrations that allow our team to more effectively tailor a solution to your business’ specific industry or processes. You can read more each of our solutions and the capabilities they provide <a href='https://www.catapulterp.com/dynamics-365/'>here.</a>"
        },
        {
            index: 8,
            name: 'Can you integrate Dynamics 365 with other existing business intelligence tools?',
            desc: "Absolutely. Our team of solution architects and consultants are committed to tailoring solutions that meet our clients’ specific needs. We’ll work closely with your teams to understand your current processes and design a Dynamics 365 solution that aligns with your workflows and take your business to the next level of success. This includes understanding existing solutions, connecting data, and building custom integrations wherever possible to ensure that your business achieves a successful adoption. ",
        },
        {
            index: 9,
            name: 'Can I get free trial of the Microsoft Dynamics 365 Software?',
            desc: "Catapult does not provide free trials of Microsoft Dynamics 365 solutions. We are always happy to meet with your team to deliver a demonstration tailored to your specific business scenarios. In our experience, during the <a href='https://www.catapulterp.com/success-framework/#stage-1'> Learn phase</a> an open two-way dialogue is critical to ensure that we understand your desired business outcomes and operational needs, so we can assist you to validate the technology fit, scope the implementation, and budget accordingly.",
        },
    ];

    return (

        <div>
            <Link to="faq" spy smooth>
                <section
                    style={{
                        backgroundImage: `linear-gradient(90deg, #61C250, #4EC35D, #00C37C, #00C0A4, #00BBC7, #00B3DA, #00ACE0, #00A9E0)`,
                        height: '100%',
                    }}
                >
                    <div
                        style={{
                            backgroundImage: `url(${BannerImage})`,
                            height: '100%',
                            backgroundPosition: 'top',
                            backgroundRepeat: 'no-repeat',
                        }}
                    >
                        <img src={isMobile ? catapultLogo : exit}
                            style={{
                                cursor: "pointer",
                                position: "fixed",
                                width: isMobile ? '100%' : "",
                                height: isMobile ? '47px' : "62px",
                                right: isMobile ? '' : "0px",
                                top: "-3px",
                                zIndex: "999"
                            }}
                            onClick={() =>
                                window.open("https://www.catapulterp.com/", "_blank")
                            }
                        />
                        <Container fixed maxWidth="md" className="animate__animated animate__fadeIn">
                            <Grid container spacing={1}>
                                <Grid item xs={12} mt={10} sx={{ textAlign: 'center', height: "350px", display: "flex", alignItems: "center" }}>
                                    <Stack direction="column">
                                        <Typography
                                            variant="h3"
                                            gutterBottom
                                            component="div"
                                            className="font-face-prelo-black"
                                            sx={{
                                                fontFamily: 'PreloSlabW04Black',
                                                color: '#FFFFFF',
                                                fontSize: isMobile ? '34px' : '63px',
                                                marginTop: "20px"
                                            }}
                                        >
                                            Frequently Asked Questions
                                        </Typography>
                                        <Typography
                                            variant="caption"
                                            gutterBottom
                                            component="div"
                                            mb={5}
                                            sx={{
                                                fontFamily: 'PreloSlabW04Light',
                                                color: '#FFFFFF',
                                                fontSize: isMobile ? '25px' : '32px',
                                                lineHeight: 1.5,
                                                paddingX: isMobile ? 2 : 10,
                                            }}
                                        >
                                            Have questions? We’re here to help.
                                        </Typography>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Container>
                    </div>
                </section>
            </Link>
            <div style={{ backgroundColor: "#FFFFFF", width: "100%" }}>
                <Container sx={{ py: 5, height: '100%', display: "flex", justifyContent: "center" }}>
                    <Grid container spacing={3} justifyContent="center" md={12} p={0} m={0}>
                        <div style={{ display: 'flex', width: "900px" }} id="faq">
                            <Grid item xs={12} mt={3} sx={{ borderBottom: "1px solid #F4F4F4", borderTop: "1px solid #F4F4F4" }}>

                                {faqData.map((item) => (
                                    <Accordion classes={{
                                        root: classes.MuiAccordionroot
                                    }}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon sx={{ color: '#00313E' }} />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography
                                                className={classes.heading}
                                                variant="body2"
                                                component="div"
                                                my={1}
                                                sx={{
                                                    fontFamily: 'PreloSlabW04Medium',
                                                    color: '#00313E',
                                                    fontSize: '18px',
                                                    width: "100%",
                                                    textAlign: "left"
                                                }}
                                            >
                                                {item.name}
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails
                                            sx={{
                                                fontFamily: 'PreloSlabW04Light',
                                                color: '#00313E',
                                                fontSize: '18px',
                                                lineHeight: "40px"
                                            }}
                                        >
                                            {parser(item.desc)}
                                        </AccordionDetails>
                                    </Accordion>
                                ))}

                            </Grid>
                        </div>
                        {/* -------------------------------------END------------ */}
                    </Grid>
                </Container>
            </div>
        </div >
    );
};

export default faq

