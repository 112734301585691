/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { Button, Container, Grid, Typography, Stack } from '@mui/material';
import { Link } from 'react-scroll';
import LoadingButton from '@mui/lab/LoadingButton';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import ReactGA from 'react-ga';
import { useMediaQuery } from 'react-responsive';
import { connect, useDispatch, useSelector } from 'react-redux';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { useLocation, useNavigate } from 'react-router-dom';
import { setStepsStatus, setSubStepStatus } from '../redux/home/home.actions';
import conditionalRoute from '../Routes/ConditionalRoutes';
import conditionalRouteEndUser from '../Routes/ConditionalRouteEndUser';
import BannerImage from '../assets/logo-overlay.png';
import saveMarketing from '../redux/marketing/marketing.actions';
import saveSupport from '../redux/support/support.actions';
import 'animate.css';
import Navbar from '../Components/Navbar/NavbarTab';
import ProductBar from '../Components/ProductBar/ProductBar';
import SaveForLater from '../Components/SaveForLater';
import exit from '../assets/exit.svg'
import catapultLogo from '../assets/catapult-mobile.svg'
import { modifyProductsByCategory } from '../helpers';

const Marketing = ({ marketing, stepsStatus, code, hyperPackage, saveForLaterCode }) => {

  const isMobile = useMediaQuery({ query: `(max-width: 640px)` });

  const isSmallMobile = useMediaQuery({ query: `(max-width: 768px)` });

  const isSmallDevice = useMediaQuery({ query: `(max-width: 390px)` });

  const isXtraSmallDevice = useMediaQuery({ query: `(max-height: 667px)` });

  const isDesktopOrLaptop = useMediaQuery({ query: `(min-width: 1244px)` });

  const isIpad = useMediaQuery({ query: `(max-width: 900px)` });

  document.documentElement.style.setProperty('--animate-duration', '3s');

  const id = localStorage.getItem("clientId");

  const dispatch = useDispatch();

  const reduxData = useSelector((s) => s);

  const { product } = useSelector((s) => s);

  const marketingDivRef = useRef()

  const modalRef = useRef(null)

  const navigate = useNavigate();

  const { state } = useLocation();

  const location = useLocation();

  // state for navbar responsiveness
  const [navbar, setNavbar] = useState(false);

  const ifProductsExist = product.selectedProducts.length > 0 ? product.selectedProducts : [];
  const [selectedProducts, setProducts] = useState(ifProductsExist);

  // enable and disable//
  const [show, setShow] = useState(false);
  const [data, setData] = useState(marketing?.marketingData);
  const [loading, setLoading] = useState(false);
  const [check, setCheck] = useState(false);


  // -------------------------------functions----------------------------------------------------------------///

  const handleChange = (field, label, value) => {
    setTimeout(() => {
      window.scrollBy({
        top: isMobile || isXtraSmallDevice || isSmallDevice ? 250 : 200,
        behavior: 'smooth',
      });
    }, 800);
    setData({ ...data, [field]: { "label": label, "value": parseInt(value, 10) } });
  };

  const handleSaveData = () => {
    const PayloadData = {
      "marketingData": {
        "uniqueTemplates": {
          "label": data?.uniqueTemplates?.label,
          "value": data?.uniqueTemplates?.value
        },
        "nurtureCampaigns": {
          "label": data?.nurtureCampaigns?.label,
          "value": data?.nurtureCampaigns?.value
        },
        "campaignSpecificLandingPages": {
          "label": data?.campaignSpecificLandingPages?.label,
          "value": data?.campaignSpecificLandingPages?.value
        },
        "landingPages": {
          "label": data?.landingPages?.label,
          "value": data?.landingPages?.value
        },
        "surveys": {
          "label": data?.surveys?.label,
          "value": data?.surveys?.value
        },
        "eventRegistration": {
          "label": data?.eventRegistration?.label,
          "value": data?.eventRegistration?.value
        }
      }
    }

    dispatch(saveMarketing(PayloadData));
  }

  const handleNext = () => {

    const PayloadData = {
      "marketingData": {
        "uniqueTemplates": {
          "label": data?.uniqueTemplates?.label,
          "value": data?.uniqueTemplates?.value
        },
        "nurtureCampaigns": {
          "label": data?.nurtureCampaigns?.label,
          "value": data?.nurtureCampaigns?.value
        },
        "campaignSpecificLandingPages": {
          "label": data?.campaignSpecificLandingPages?.label,
          "value": data?.campaignSpecificLandingPages?.value
        },
        "landingPages": {
          "label": data?.landingPages?.label,
          "value": data?.landingPages?.value
        },
        "surveys": {
          "label": data?.surveys?.label,
          "value": data?.surveys?.value
        },
        "eventRegistration": {
          "label": data?.eventRegistration?.label,
          "value": data?.eventRegistration?.value
        }
      }
    }

    if (selectedProducts[selectedProducts.length - 1] === '3') {
      const updateStep = stepsStatus;
      updateStep[1].businessDetails = { 'active': false, 'completed': true }
      dispatch(setStepsStatus(updateStep))
    }
    dispatch(setSubStepStatus({ bdMarketing: { active: false, completed: true } }));
    dispatch(saveMarketing(PayloadData));
    const nextIndex = selectedProducts.indexOf('3') + 1 || Infinity;

    if (selectedProducts.at(nextIndex)) {
      navigate(`/business-details/${conditionalRoute(selectedProducts.at(nextIndex))}`);
    }
    else if (selectedProducts.length === 1) {
      navigate('/support');
    }
    else {
      navigate(`/end-users/${conditionalRouteEndUser(selectedProducts.at(0))}`);
    }
  };

  const handleSave = () => {
    setLoading(true)
    const PayloadData = {
      "marketingData": {
        "uniqueTemplates": {
          "label": data?.uniqueTemplates?.label,
          "value": data?.uniqueTemplates?.value === null ? 0 : data?.uniqueTemplates?.value
        },
        "nurtureCampaigns": {
          "label": data?.nurtureCampaigns?.label,
          "value": data?.nurtureCampaigns?.value === null ? 0 : data?.nurtureCampaigns?.value
        },
        "campaignSpecificLandingPages": {
          "label": data?.campaignSpecificLandingPages?.label,
          "value": data?.campaignSpecificLandingPages?.value === null || data?.nurtureCampaigns?.value === 0 ? 0 : data?.campaignSpecificLandingPages?.value
        },
        "landingPages": {
          "label": data?.landingPages?.label,
          "value": data?.landingPages?.value === null || data?.nurtureCampaigns?.value === 0 ? 0 : data?.landingPages?.value
        },
        "surveys": {
          "label": data?.surveys?.label,
          "value": data?.surveys?.value === null ? 0 : data?.surveys?.value
        },
        "eventRegistration": {
          "label": data?.eventRegistration?.label,
          "value": data?.eventRegistration?.value === null ? 0 : data?.eventRegistration?.value
        }
      }
    }
    const profileData = {
      "firstName": reduxData?.profile?.profile?.firstName,
      "lastName": reduxData?.profile?.profile?.lastName,
      "email": reduxData?.profile?.profile?.email,
      "countryCode": reduxData?.profile?.profile?.countryCode,
      "phoneNo": reduxData?.profile?.profile?.phoneNo,
      "companyName": reduxData?.profile?.profile?.companyName,
      "followUp": reduxData?.profile?.profile?.followUp
    }
    const body = {
      selectedProducts: modifyProductsByCategory(reduxData.product.selectedProducts),
      businessCentral: reduxData.businessCentral.businessCentral,
      sales: reduxData.sales.sales,
      marketing: PayloadData,
      customerService: reduxData.customerService.customerService,
      endUsers: reduxData.endUsers,
      profileDetails: profileData,
      supportPackage: hyperPackage,
      userLocation: reduxData.saveGeoLocation.geoLocation,
      // userCode: saveForLaterCode,
      analyticsPayload: {
        clientId: id
      }
    }
    if (saveForLaterCode) {
      body.userCode = saveForLaterCode
    }
    else if (code) {
      body.userCode = code
    }
    else {
      body.userCode = ""
    }
    axios.post(`${process.env.REACT_APP_BASE_URL}/api/calculatePricing`, body)
      .then(res => {
        if (res.data.message === 'Pricing calculated successfully') {
          const response = res.data;
          setLoading(false)
          dispatch(saveMarketing(PayloadData));
          dispatch(saveSupport(response))
          navigate('/pricing');
        }
      })
      .catch((error) => {
        setLoading(false)
      });
  }
  const handleCancel = () => {
    navigate('/pricing');
  }

  const buttonOne = [
    { label: 'Up to 3', value: 1 },
    { label: '4 to 5', value: 2 },
    { label: '6+', value: 3 },
  ];

  const changePosition = () => {
    if (window.scrollY >= 400) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener('scroll', changePosition);

  useEffect(() => {
    ReactGA.initialize('UA-74792081-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
    console.log(window.location.pathname, "window.location.pathname")
    console.log(window.location.search, "window.location.search")
  }, [])

  useEffect(() => {
    if (data?.eventRegistration.value !== null) {
      setShow(true);
    }
    else {
      setShow(false)
    }
  }, [data])

  const scrollToRef = (ref) => {
    if (isSmallMobile) {
      window.scrollTo(0, ref.current.offsetTop - 260);
    }
    else {
      window.scrollTo(0, ref.current.offsetTop - 150);
    }
  }

  useEffect(() => {

    if (isSmallMobile) {
      scrollToRef(marketingDivRef);
    }
    else {
      scrollToRef(marketingDivRef);
    }
  }, [])

  return (
    <div>

      <Link to="navbar" spy smooth>
        <section
          style={{
            backgroundImage: `linear-gradient(90deg, #61C250, #4EC35D, #00C37C, #00C0A4, #00BBC7, #00B3DA, #00ACE0, #00A9E0)`,
            height: isMobile ? '80vh' : "100%",
          }}
        >
          <div
            style={{
              backgroundImage: `url(${BannerImage})`,
              height: '100%',
              backgroundPosition: 'top',
              backgroundRepeat: 'no-repeat',
              display: "flex",
              alignItems: "center"
            }}
          >
            {location.pathname === "/pricing" || location.pathname === "/product" ?
              (
                <img src={isMobile ? catapultLogo : exit}
                  style={{
                    cursor: "pointer",
                    height: isMobile ? '47px' : "68px",
                    width: isMobile ? '100%' : "230px",
                    zIndex: 999,
                    position: "fixed",
                    right: isMobile ? '' : "0px",
                    // left: isMobile ? "-1px" : '',
                    top: "-5px"
                  }}
                  onClick={() => window.location.replace('https://www.catapulterp.com/')}
                />
              ) : (
                <img src={isMobile ? catapultLogo : exit}
                  style={{
                    cursor: "pointer",
                    height: isMobile ? '47px' : "68px",
                    width: isMobile ? '100%' : "230px",
                    zIndex: 999,
                    position: "fixed",
                    right: isMobile ? '' : "0px",
                    // left: isMobile ? "-1px" : '',
                    top: "-5px"
                  }}
                  onClick={() => {
                    modalRef.current()
                    setCheck(true)
                    // handleSaveData()
                  }}
                />
              )
            }
            <Container fixed maxWidth="md" className="animate__animated animate__fadeIn"
              sx={{
                height: "450px",
              }}
            >
              <Grid item xs={12}
                sx={{
                  textAlign: 'center',
                  height: "100%",
                  display: "flex",
                  justifyContent: "center"
                }}>
                <Stack direction="column"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: isMobile ? '47px' : ''
                  }}>
                  <Typography
                    variant="string"
                    gutterBottom
                    component="div"
                    sx={{
                      fontFamily: 'DinDIsplayProRegular',
                      fontStyle: 'italic',
                      color: '#FFFFFF',
                    }}
                  >
                    Pricing
                  </Typography>
                  <Typography
                    variant="h3"
                    gutterBottom
                    component="div"
                    className="font-face-prelo-black"
                    sx={{
                      fontFamily: 'PreloSlabW04Black',
                      color: '#FFFFFF',
                      fontSize: isMobile ? '33px' : '48px',
                      marginBottom: isMobile ? "40px" : ''
                    }}
                  >
                    Take the guesswork out of your ERP & CRM solution budgeting.
                  </Typography>
                  <Typography
                    variant="caption"
                    gutterBottom
                    component="div"
                    mb={5}
                    sx={{
                      fontFamily: 'PreloSlabW04Light',
                      color: '#FFFFFF',
                      fontSize: isMobile ? '18px' : '20px',
                      lineHeight: 1.5,
                      paddingX: isMobile ? 2 : 10,
                      marginBottom: isMobile ? "60px" : '40px'
                    }}
                  >
                    Customize and price your Dynamics 365 solution using our powerful online
                    scoping and budgeting tools.
                  </Typography>
                </Stack>
              </Grid>
            </Container>
          </div>
        </section>
      </Link>
      <div
        style={{ height: isMobile ? '' : '120px' }}
        id="navbar">
        <Navbar navbar={navbar} />
        <ProductBar navbar={navbar} />
      </div>

      <Container sx={{ py: 4, height: 'auto', display: "inherit", marginBottom: isMobile ? "100px" : '', minHeight: isDesktopOrLaptop ? "500px" : "800px" }}>
        <Grid container spacing={isMobile ? 3 : 2} justifyContent="start" md={12} sx={{ paddingBottom: isMobile ? "100px" : "", paddingTop: "10px" }}>
          {/* -------------------------Question 1 ----------------------------------------------------*/}
          <Grid item xs={12} md={9} className="animate__animated animate__fadeIn" ref={marketingDivRef}>
            <Typography
              variant="body1"
              sx={{
                color: '#00313E',
                fontFamily: 'PreloSlabW04SemiBold',
                fontSize: isMobile ? '19px' : '22px',
              }}
              gutterBottom
              component="div"
            >
              How many unique email templates would you like to have?
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            mb={1}
            sx={{
              display: 'flex',
              flexWrap: isMobile ? 'wrap' : ''
            }}
            className="animate__animated animate__fadeIn"
          >
            {buttonOne?.map((item) => (
              <Button
                variant="myPrimary"
                sx={{
                  backgroundColor: data?.uniqueTemplates?.value !== item.value ? 'white' : '#00313E',
                  width: isMobile ? '150px' : '180px',
                  height: '60px',
                  borderRadius: '15px',
                  border: '1px solid #00313E',
                  marginRight: isMobile ? '18px' : '40px',
                  marginBottom: '10px',
                  marginTop: '10px',

                  '&:hover': {
                    backgroundColor: data?.uniqueTemplates?.value !== item.value ? 'white' : '#00313E',
                  },
                }}
                value={item.label}
                onClick={() => {
                  handleChange('uniqueTemplates', item.label, item.value);
                }}
              >
                <Typography
                  variant="button"
                  display="block"
                  sx={{
                    color: data?.uniqueTemplates?.value !== item.value ? '#00313E' : '#FFFFFF',
                    fontFamily: 'DinDIsplayProBold',
                    textTransform: 'none',
                    fontSize: isMobile ? '14px' : '18px',
                  }}
                >
                  {item.label}
                </Typography>
              </Button>
            ))}
          </Grid>
          {/* -------------------------Question 2 ----------------------------------------------------*/}

          {data?.uniqueTemplates?.value ? (
            <>
              <Grid item xs={12} md={9} className="animate__animated animate__fadeIn">
                <Typography
                  variant="body1"
                  sx={{
                    color: '#00313E',
                    fontFamily: 'PreloSlabW04SemiBold',
                    fontSize: isMobile ? '19px' : '22px',
                    // paddingTop: "60px"
                  }}
                  gutterBottom
                  component="div"
                >
                  Do you run nurture or drip campaigns?
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={9}
                mb={2}
                sx={{ display: 'flex' }}
                className="animate__animated animate__fadeIn"
              >
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    sx={{
                      color: '#00313E',
                    }}
                    onChange={(event) =>
                      handleChange('nurtureCampaigns', event.target.value === "0" ? "No" : "Yes", event.target.value)
                    }
                  >
                    <FormControlLabel
                      value={1}
                      control={
                        <Radio
                          checked={data?.nurtureCampaigns?.value === 1}
                          size="large"
                          sx={{
                            color: '#00313E',
                            '&.Mui-checked': {
                              color: '#00313E',
                            },
                          }}

                        />
                      }
                      label="Yes"
                    />
                    <FormControlLabel
                      value={0}
                      control={
                        <Radio
                          size="large"
                          checked={data?.nurtureCampaigns?.value === 0}
                          sx={{
                            color: '#00313E',
                            '&.Mui-checked': {
                              color: '#00313E',
                            },
                          }}
                        />
                      }
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </>
          ) : (
            ''
          )}

          {data?.nurtureCampaigns?.value === 1 ? (
            <>
              {/* -------------------------Question 3 ----------------------------------------------------*/}
              {data?.nurtureCampaigns?.value === 1 ? (
                <>
                  <Grid item xs={12} md={9} className="animate__animated animate__fadeIn">
                    <Typography
                      variant="body1"
                      sx={{
                        color: '#00313E',
                        fontFamily: 'PreloSlabW04SemiBold',
                        fontSize: isMobile ? '17px' : '22px',
                      }}
                      gutterBottom
                      component="div"
                    >
                      Are there campaign-specific landing pages?
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={9}
                    mb={2}
                    sx={{ display: 'flex' }}
                    className="animate__animated animate__fadeIn"
                  >
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                        onChange={(event) =>
                          handleChange('campaignSpecificLandingPages', event.target.value === "0" ? "No" : "Yes", event.target.value)
                        }
                        sx={{
                          color: '#00313E',
                        }}
                      >
                        <FormControlLabel
                          value={1}
                          control={
                            <Radio
                              size="large"
                              checked={data?.campaignSpecificLandingPages?.value === 1}
                              sx={{
                                color: '#00313E',
                                '&.Mui-checked': {
                                  color: '#00313E',
                                },
                              }}
                            />
                          }
                          label="Yes"
                        />
                        <FormControlLabel
                          value={0}
                          checked={data?.campaignSpecificLandingPages?.value === 0}
                          control={
                            <Radio
                              size="large"
                              sx={{
                                color: '#00313E',
                                '&.Mui-checked': {
                                  color: '#00313E',
                                },
                              }}
                            />
                          }
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </>
              ) : (
                ''
              )}
              {/* -------------------------Question 4 ----------------------------------------------------*/}
              {data?.campaignSpecificLandingPages?.value === 1 || data?.nurtureCampaigns?.value === 0 ? (
                <>
                  <Grid item xs={12} md={9} className="animate__animated animate__fadeIn">
                    <Typography
                      variant="body1"
                      sx={{
                        color: '#00313E',
                        fontFamily: 'PreloSlabW04SemiBold',
                        fontSize: isMobile ? '17px' : '22px',
                      }}
                      gutterBottom
                      component="div"
                    >
                      How many?
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    mb={1}
                    sx={{
                      display: 'flex',
                      flexWrap: isMobile ? 'wrap' : ''
                    }}
                    className="animate__animated animate__fadeIn"
                  >
                    {buttonOne?.map((item) => (
                      <Button
                        variant="myPrimary"
                        sx={{
                          backgroundColor: data?.landingPages?.value !== item.value ? 'white' : '#00313E',
                          width: isMobile ? '150px' : '180px',
                          height: '60px',
                          borderRadius: '15px',
                          border: '1px solid #00313E',
                          marginRight: isMobile ? '18px' : '40px',
                          marginBottom: '10px',
                          marginTop: '10px',

                          '&:hover': {
                            backgroundColor: data?.landingPages?.value !== item.value ? 'white' : '#00313E',
                          },
                        }}
                        value={item.label}
                        onClick={() => {
                          handleChange('landingPages', item.label, item.value);
                        }}
                      >
                        <Typography
                          variant="button"
                          display="block"
                          sx={{
                            color: data?.landingPages?.value !== item.value ? '#00313E' : '#FFFFFF',
                            fontFamily: 'DinDIsplayProBold',
                            textTransform: 'none',
                            fontSize: isMobile ? '14px' : '18px',
                          }}
                        >
                          {item.label}
                        </Typography>
                      </Button>
                    ))}
                  </Grid>
                </>
              ) : (
                ''
              )}
            </>
          ) : (
            ''
          )}

          {/* -------------------------Question 5 ----------------------------------------------------*/}

          {data?.landingPages?.value || data?.nurtureCampaigns?.value === 0 || data?.campaignSpecificLandingPages?.value === 0 ? (
            <>
              <Grid item xs={12} md={9} className="animate__animated animate__fadeIn">
                <Typography
                  variant="body1"
                  sx={{
                    color: '#00313E',
                    fontFamily: 'PreloSlabW04SemiBold',
                    fontSize: isMobile ? '19px' : '22px',
                    // paddingTop: "60px"
                  }}
                  gutterBottom
                  component="div"
                >
                  Do you want to run customer surveys?
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={9}
                mb={2}
                sx={{ display: 'flex' }}
                className="animate__animated animate__fadeIn"
              >
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    onChange={(event) => handleChange('surveys', event.target.value === "0" ? "No" : "Yes", event.target.value)}
                    sx={{
                      color: '#00313E',
                    }}
                  >
                    <FormControlLabel
                      value={1}
                      control={
                        <Radio
                          size="large"
                          checked={data?.surveys?.value === 1}
                          sx={{
                            color: '#00313E',
                            '&.Mui-checked': {
                              color: '#00313E',
                            },
                          }}
                        />
                      }
                      label="Yes"
                    />
                    <FormControlLabel
                      value={0}
                      checked={data?.surveys?.value === 0}
                      control={
                        <Radio
                          size="large"
                          sx={{
                            color: '#00313E',
                            '&.Mui-checked': {
                              color: '#00313E',
                            },
                          }}
                        />
                      }
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </>
          ) : (
            ''
          )}

          {/* -------------------------Question 6 ----------------------------------------------------*/}
          {data?.surveys.value === 1 || data?.surveys.value === 0 ? (
            <>
              <Grid item xs={12} md={9} className="animate__animated animate__fadeIn">
                <Typography
                  variant="body1"
                  sx={{
                    color: '#00313E',
                    fontFamily: 'PreloSlabW04SemiBold',
                    fontSize: isMobile ? '19px' : '22px',
                    // paddingTop: "60px"
                  }}
                  gutterBottom
                  component="div"
                >
                  Do you require event registration functionality?
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={9}
                mb={2}
                sx={{ display: 'flex' }}
                className="animate__animated animate__fadeIn"
              >
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    onChange={(event) => handleChange('eventRegistration', event.target.value === "0" ? "No" : "Yes", event.target.value)}
                    sx={{
                      color: '#00313E',
                    }}
                  >
                    <FormControlLabel
                      value={1}
                      control={
                        <Radio
                          size="large"
                          checked={data?.eventRegistration?.value === 1}
                          sx={{
                            color: '#00313E',
                            '&.Mui-checked': {
                              color: '#00313E',
                            },
                          }}
                        />
                      }
                      label="Yes"
                    />
                    <FormControlLabel
                      value={0}
                      checked={data?.eventRegistration?.value === 0}
                      control={
                        <Radio
                          size="large"
                          sx={{
                            color: '#00313E',
                            '&.Mui-checked': {
                              color: '#00313E',
                            },
                          }}
                        />
                      }
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </>
          ) : (
            ''
          )}


          {/* ------------------------ Grid End------------------------------------------ */}
        </Grid>
      </Container>

      <div
        style={{
          background: isMobile ? "white" : "",
          height: isMobile ? "100px" : "70px",
          position: isMobile ? "fixed" : '',
          bottom: "0px",
          width: "100%"
        }}>
        {state?.focus ? (
          <Grid
            item
            xs={12}
            md={3}
            direction="row"
            sx={{
              display: "flex",
              justifyContent: isMobile ? "center" : "flex-end",
              alignItems: "center",
              position: isMobile || isIpad ? '' : 'fixed',
              right: '25px',
              bottom: '25px',
              width: '100%',
              paddingLeft: 0,
              height: isMobile ? "100%" : '120px'
            }}
          >
            <Button
              variant="primary"
              sx={{
                borderRadius: 1,
                border: '1px solid #CFCFCF',
                height: "60px",
                width: "180px",
                marginRight: "15px",

              }}
              onClick={handleCancel}
            >
              <Typography
                variant="button"
                sx={{ fontFamily: 'DinDIsplayProBold', color: '#00313E' }}
              >
                Cancel
              </Typography>
            </Button>

            <LoadingButton
              variant="contained"
              color="neutral"
              loading={loading}
              loadingPosition="end"
              // disabled={loading}
              sx={{
                borderRadius: 1,
                backgroundColor: '#00313E',
                height: "60px",
                width: "130px"
              }}
              onClick={() => handleSave()}
            >
              <Typography variant="button" sx={{ fontFamily: 'DinDIsplayProBold' }}>
                Save
              </Typography>
            </LoadingButton>
          </Grid>
        ) : <Grid
          item
          xs={12}
          md={3}
          direction="row"
          sx={{
            display: "flex",
            justifyContent: isMobile ? "center" : "flex-end",
            alignItems: "center",
            position: isMobile || isIpad ? '' : 'fixed',
            right: '25px',
            bottom: '25px',
            width: '100%',
            paddingLeft: 0,
            height: isMobile ? "100%" : '120px'
          }}
        >
          <Button
            variant="primary"
            sx={{
              borderRadius: 1,
              border: '1px solid #CFCFCF',
              height: "60px",
              width: "180px",
              marginRight: "15px",

            }}
            onClick={() => {
              modalRef.current()
              handleSaveData()
            }}
          >
            <Typography
              variant="button"
              sx={{
                fontFamily: 'DinDIsplayProBold',
                color: '#00313E'
              }}
            >
              Save for later
            </Typography>
          </Button>

          <Button
            variant="contained"
            color="neutral"
            disabled={!show}
            sx={{
              borderRadius: 1,
              backgroundColor: '#00313E',
              height: "60px",
              width: "130px"
            }}
            onClick={() => handleNext()}
          >
            <Typography variant="button" sx={{ fontFamily: 'DinDIsplayProBold' }}>
              Next
            </Typography>
          </Button>
        </Grid>}

      </div>
      <SaveForLater check={check} modalRef={modalRef} />
    </div >
  );
};

const mapStateToProps = (state) => {
  return {
    marketing: state.marketing.marketing,
    stepsStatus: state.home.stepsStatus,
    code: state.pricingCode.code,
    hyperPackage: state.saveSupportPackage.supportPackage,
    saveForLaterCode: state?.saveLater?.code,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveMarketing: (payload) => dispatch(saveMarketing(payload)),
    saveSupport: (payload) => dispatch(saveSupport(payload)),
    setStepsStatus: (payload) => dispatch(setStepsStatus(payload)),
  };
};

// export default Marketing;
export default connect(mapStateToProps, mapDispatchToProps)(Marketing);
