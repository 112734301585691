/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable consistent-return */
/* eslint-disable no-lone-blocks */
/* eslint-disable default-case */
/* eslint-disable no-undef */
import { useEffect, useRef, useState } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { useLocation, useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { Button, Container, Grid, Typography, Stack } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Link } from 'react-scroll';
import { useMediaQuery } from 'react-responsive';
import axios from 'axios';
// redux
import { connect, useDispatch, useSelector } from 'react-redux';
import { setStepsStatus, setSubStepStatus } from '../redux/home/home.actions';
import conditionalRoute from '../Routes/ConditionalRoutes';
import conditionalRouteEndUser from '../Routes/ConditionalRouteEndUser';
import saveBusinessCentral from '../redux/businessCentral/businessCentral.actions';
import saveSupport from '../redux/support/support.actions';
import BannerImage from '../assets/logo-overlay.png';
import 'animate.css';
import Navbar from '../Components/Navbar/NavbarTab';
import ProductBar from '../Components/ProductBar/ProductBar';
import exit from '../assets/exit.svg';
import SaveForLater from '../Components/SaveForLater';
import catapultLogo from '../assets/catapult-mobile.svg';
import { modifyProductsByCategory } from '../helpers';

const BusinessCentral = ({
  businessCentral,
  stepsStatus,
  code,
  hyperPackage,
  saveForLaterCode,
}) => {
  const isDesktopOrLaptop = useMediaQuery({ query: `(min-width: 1244px)` });

  const isSmallMobile = useMediaQuery({ query: `(max-width: 768px)` });

  const isIpad = useMediaQuery({ query: `(max-width: 1024px)` });

  const isSmallDevice = useMediaQuery({ query: `(max-width: 390px)` });

  const isMediumDevice = useMediaQuery({ query: `(max-width: 430px)` });

  const isMobile = useMediaQuery({ query: `(max-width: 640px)` });

  const id = localStorage.getItem('clientId');

  const dispatch = useDispatch();

  const reduxData = useSelector((s) => s);

  const { product } = useSelector((s) => s);

  const modalRef = useRef(null);

  const navigate = useNavigate();

  const { state } = useLocation();

  const location = useLocation();

  // state for navbar responsiveness
  const [navbar, setNavbar] = useState(false);

  const [loading, setLoading] = useState(false);

  const [check, setCheck] = useState(false);

  const [show, setShow] = useState(false);

  const [bcFinanceFoundation, setBcFinanceFoundation] = useState(
    businessCentral?.bcFinanceFoundation
  );

  const [bcMulticompany, setBcMulticompany] = useState(businessCentral?.bcMulticompany);

  const [bcFixedAssets, setBcFixedAssets] = useState(businessCentral?.bcFixedAssets);

  const [bcSalesOrder, setBcSalesOrder] = useState(businessCentral?.bcSalesOrder);

  const [bcJobs, setBcJobs] = useState(businessCentral?.bcJobs);

  const [bcInventory, setBcInventory] = useState(businessCentral?.bcInventory);

  const [bcAssemblyManagement, setBcAssemblyManagement] = useState(
    businessCentral?.bcAssemblyManagement
  );

  const [bcManufacturing, setBcManufacturing] = useState(businessCentral?.bcManufacturing);

  // ----------------------Functions---------------//////////

  const handleChangeFinanceFoundation = (field, label, value) => {
    setTimeout(() => {
      window.scrollBy({
        top: isMobile || isMediumDevice || isSmallDevice ? 240 : 200,
        behavior: 'smooth',
      });
    }, 800);
    setBcFinanceFoundation({
      ...bcFinanceFoundation,
      [field]: { label, value: parseInt(value, 10) },
    });
  };

  const handleChangeMultiCompany = (field, label, value) => {
    setTimeout(() => {
      if (state?.focus === 'multiCompany') {
        window.scrollBy({
          top: isMobile || isMediumDevice || isSmallDevice ? 220 : 125,
          behavior: 'smooth',
        });
      } else {
        window.scrollBy({
          top: isMobile || isMediumDevice || isSmallDevice ? 250 : 500,
          behavior: 'smooth',
        });
      }
    }, 800);
    setBcMulticompany({ ...bcMulticompany, [field]: { label, value: parseInt(value, 10) } });
  };

  const handleChangeFixedAssets = (field, label, value) => {
    setTimeout(() => {
      if (state?.focus === 'fixedAssets') {
        window.scrollBy({
          top: isMobile || isMediumDevice || isSmallDevice ? 220 : 500,
          behavior: 'smooth',
        });
      } else {
        window.scrollBy({
          top: isMobile || isMediumDevice || isSmallDevice ? 300 : 500,
          behavior: 'smooth',
        });
      }
    }, 800);
    setBcFixedAssets({ ...bcFixedAssets, [field]: { label, value: parseInt(value, 10) } });
  };

  const handleChangeSalesOrder = (field, label, value) => {
    setTimeout(() => {
      if (state?.focus === 'salesOrders') {
        window.scrollBy({
          top: isMobile || isMediumDevice || isSmallDevice ? 220 : 500,
          behavior: 'smooth',
        });
      } else {
        window.scrollBy({
          top: isMobile || isMediumDevice || isSmallDevice ? 300 : 500,
          behavior: 'smooth',
        });
      }
    }, 800);
    setBcSalesOrder({ ...bcSalesOrder, [field]: { label, value: parseInt(value, 10) } });
  };

  const handleChangeJobs = (field, label, value) => {
    setTimeout(() => {
      window.scrollBy({
        top: isMobile || isMediumDevice || isSmallDevice ? 300 : 500,
        behavior: 'smooth',
      });
    }, 800);
    setBcJobs({ ...bcJobs, [field]: { label, value: parseInt(value, 10) } });
  };

  const handleAssemblyManufacturingNull = () => {
    setBcAssemblyManagement({
      ...bcAssemblyManagement,
      assemblyMgmt: {
        label: 'assemblyMgmt',
        value: null,
      },
      noAboms: {
        label: 'noAboms',
        value: null,
      },
      edi: {
        label: 'edi',
        value: null,
      },
    });
    setBcManufacturing({
      ...bcManufacturing,
      manufacturing: {
        label: 'manufacturing',
        value: null,
      },
      routings: {
        label: 'routings',
        value: null,
      },
      workCenters: {
        label: 'workCenters',
        value: null,
      },
      prodLocations: {
        label: 'prodLocations',
        value: null,
      },
      mBoms: {
        label: 'mBoms',
        value: null,
      },
      ediM: {
        label: 'ediM',
        value: null,
      },
    });
  };

  const handleChangeInventory = (field, label, value) => {
    if (field === 'inventoryItems' && value === '1') {
      handleAssemblyManufacturingNull();
    }
    setTimeout(() => {
      window.scrollBy({
        top: isMobile ? 300 : 500,
        behavior: 'smooth',
      });
    }, 800);
    setBcInventory({ ...bcInventory, [field]: { label, value: parseInt(value, 10) } });
  };

  const handleChangeAssembly = (field, label, value) => {
    setTimeout(() => {
      window.scrollBy({
        top: isMobile ? 300 : 500,
        behavior: 'smooth',
      });
    }, 800);
    setBcAssemblyManagement({
      ...bcAssemblyManagement,
      [field]: { label, value: parseInt(value, 10) },
    });
  };

  const handleChangeManufacturing = (field, label, value) => {
    setTimeout(() => {
      window.scrollBy({
        top: isMobile ? 300 : 500,
        behavior: 'smooth',
      });
    }, 800);
    setBcManufacturing({
      ...bcManufacturing,
      [field]: { label, value: parseInt(value, 10) },
    });
  };

  const financialAccountingDivRef = useRef();
  const multiCompanyDivRef = useRef();
  const fixedAssetsDivRef = useRef();
  const salesOrdersDivRef = useRef();
  const jobsDivRef = useRef();
  const inventoryDivRef = useRef();
  const assemblyDivRef = useRef();
  const manufacturingDivRef = useRef();

  const ifProductsExist = product.selectedProducts.length > 0 ? product.selectedProducts : [];
  const [selectedProducts, setProducts] = useState(ifProductsExist);

  document.documentElement.style.setProperty('--animate-duration', '3s');

  const changePosition = () => {
    if (window.scrollY >= 400) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  const handleSaveData = () => {
    const data = {
      bcFinanceFoundation: {
        bankAccounts: {
          label: bcFinanceFoundation?.bankAccounts?.label,
          value: bcFinanceFoundation?.bankAccounts?.value,
        },
        functionalCurrencies: {
          label: bcFinanceFoundation?.functionalCurrencies?.label,
          value: bcFinanceFoundation?.functionalCurrencies?.value,
        },
        customerCount: {
          label: bcFinanceFoundation?.customerCount?.label,
          value: bcFinanceFoundation?.customerCount?.value,
        },
        vendorCount: {
          label: bcFinanceFoundation?.vendorCount?.label,
          value: bcFinanceFoundation?.vendorCount?.value,
        },
        poCount: {
          label: bcFinanceFoundation?.poCount?.label,
          value: bcFinanceFoundation?.poCount?.value,
        },
      },
      bcMulticompany: {
        multicompany: {
          label: bcMulticompany?.multicompany?.label,
          value: bcMulticompany?.multicompany?.value,
        },
        entityCount: {
          label: bcMulticompany?.entityCount?.label,
          value: bcMulticompany?.entityCount?.value,
        },
        uniqueCOA: {
          label: bcMulticompany?.uniqueCOA?.label,
          value: bcMulticompany?.uniqueCOA?.value,
        },
        intercompanyRequired: {
          label: bcMulticompany?.intercompanyRequired?.label,
          value: bcMulticompany?.intercompanyRequired?.value,
        },
        consolidationRequired: {
          label: bcMulticompany?.consolidationRequired?.label,
          value: bcMulticompany?.consolidationRequired?.value,
        },
        noConsolidations: {
          label: bcMulticompany?.noConsolidations?.label,
          value: bcMulticompany?.noConsolidations?.value,
        },
      },
      bcFixedAssets: {
        fixedAssets: {
          label: bcFixedAssets?.fixedAssets?.label,
          value: bcFixedAssets?.fixedAssets?.value,
        },
        noFixedAssets: {
          label: bcFixedAssets?.noFixedAssets?.label,
          value: bcFixedAssets?.noFixedAssets?.value,
        },
        faComponents: {
          label: bcFixedAssets?.faComponents?.label,
          value: bcFixedAssets?.faComponents?.value,
        },
      },
      bcSalesOrder: {
        salesOrders: {
          label: bcSalesOrder?.salesOrders?.label,
          value: bcSalesOrder?.salesOrders?.value,
        },
        soCount: {
          label: bcSalesOrder?.soCount?.label,
          value: bcSalesOrder?.soCount?.value,
        },
      },
      bcJobs: {
        jobs: {
          label: bcJobs?.jobs?.label,
          value: bcJobs?.jobs?.value,
        },
        billableJobs: {
          label: bcJobs?.billableJobs?.label,
          value: bcJobs?.billableJobs?.value,
        },
      },
      bcInventory: {
        inventoryItems: {
          label: bcInventory?.inventoryItems?.label,
          value: bcInventory?.inventoryItems?.value,
        },
        noSku: {
          label: bcInventory?.noSku?.label,
          value: bcInventory?.noSku?.value,
        },
        lotTracking: {
          label: bcInventory?.lotTracking?.label,
          value: bcInventory?.lotTracking?.value,
        },
        noWarehouses: {
          label: bcInventory?.noWarehouses?.label,
          value: bcInventory?.noWarehouses?.value,
        },
        warehouseLogistics: {
          label: bcInventory?.warehouseLogistics?.label,
          value: bcInventory?.warehouseLogistics?.value,
        },
      },
      bcAssemblyManagement: {
        assemblyMgmt: {
          label: bcAssemblyManagement?.assemblyMgmt?.label,
          value: bcAssemblyManagement?.assemblyMgmt?.value,
        },
        noAboms: {
          label: bcAssemblyManagement?.noAboms?.label,
          value: bcAssemblyManagement?.noAboms?.value,
        },
        edi: {
          label: bcAssemblyManagement?.edi?.label,
          value: bcAssemblyManagement?.edi?.value,
        },
      },
      bcManufacturing: {
        manufacturing: {
          label: bcManufacturing?.manufacturing?.label,
          value: bcManufacturing?.manufacturing?.value,
        },
        routings: {
          label: bcManufacturing?.routings?.label,
          value: bcManufacturing?.routings?.value,
        },
        workCenters: {
          label: bcManufacturing?.workCenters?.label,
          value: bcManufacturing?.workCenters?.value,
        },
        prodLocations: {
          label: bcManufacturing?.prodLocations?.label,
          value: bcManufacturing?.prodLocations?.value,
        },
        mBoms: {
          label: bcManufacturing?.mBoms?.label,
          value: bcManufacturing?.mBoms?.value,
        },
        ediM: {
          label: bcManufacturing?.ediM?.label,
          value: bcManufacturing?.ediM?.value,
        },
      },
    };
    dispatch(saveBusinessCentral(data));
  };

  const handleNext = () => {
    const data = {
      bcFinanceFoundation: {
        bankAccounts: {
          label: bcFinanceFoundation?.bankAccounts?.label,
          value: bcFinanceFoundation?.bankAccounts?.value,
        },
        functionalCurrencies: {
          label: bcFinanceFoundation?.functionalCurrencies?.label,
          value: bcFinanceFoundation?.functionalCurrencies?.value,
        },
        customerCount: {
          label: bcFinanceFoundation?.customerCount?.label,
          value: bcFinanceFoundation?.customerCount?.value,
        },
        vendorCount: {
          label: bcFinanceFoundation?.vendorCount?.label,
          value: bcFinanceFoundation?.vendorCount?.value,
        },
        poCount: {
          label: bcFinanceFoundation?.poCount?.label,
          value: bcFinanceFoundation?.poCount?.value,
        },
      },
      bcMulticompany: {
        multicompany: {
          label: bcMulticompany?.multicompany?.label,
          value: bcMulticompany?.multicompany?.value,
        },
        entityCount: {
          label: bcMulticompany?.entityCount?.label,
          value: bcMulticompany?.entityCount?.value,
        },
        uniqueCOA: {
          label: bcMulticompany?.uniqueCOA?.label,
          value: bcMulticompany?.uniqueCOA?.value,
        },
        intercompanyRequired: {
          label: bcMulticompany?.intercompanyRequired?.label,
          value: bcMulticompany?.intercompanyRequired?.value,
        },
        consolidationRequired: {
          label: bcMulticompany?.consolidationRequired?.label,
          value: bcMulticompany?.consolidationRequired?.value,
        },
        noConsolidations: {
          label: bcMulticompany?.noConsolidations?.label,
          value: bcMulticompany?.noConsolidations?.value,
        },
      },
      bcFixedAssets: {
        fixedAssets: {
          label: bcFixedAssets?.fixedAssets?.label,
          value: bcFixedAssets?.fixedAssets?.value,
        },
        noFixedAssets: {
          label: bcFixedAssets?.noFixedAssets?.label,
          value: bcFixedAssets?.noFixedAssets?.value,
        },
        faComponents: {
          label: bcFixedAssets?.faComponents?.label,
          value: bcFixedAssets?.faComponents?.value,
        },
      },
      bcSalesOrder: {
        salesOrders: {
          label: bcSalesOrder?.salesOrders?.label,
          value: bcSalesOrder?.salesOrders?.value,
        },
        soCount: {
          label: bcSalesOrder?.soCount?.label,
          value: bcSalesOrder?.soCount?.value,
        },
      },
      bcJobs: {
        jobs: {
          label: bcJobs?.jobs?.label,
          value: bcJobs?.jobs?.value,
        },
        billableJobs: {
          label: bcJobs?.billableJobs?.label,
          value: bcJobs?.billableJobs?.value,
        },
      },
      bcInventory: {
        inventoryItems: {
          label: bcInventory?.inventoryItems?.label,
          value:
            bcInventory?.inventoryItems?.value === null ? 0 : bcInventory?.inventoryItems?.value,
          // === null ? 0 : bcInventory?.inventoryItems?.value
        },
        noSku: {
          label: bcInventory?.noSku?.label,
          value: bcInventory?.noSku?.value,
          // === null ? 0 : bcInventory?.noSku?.value
        },
        lotTracking: {
          label: bcInventory?.lotTracking?.label,
          value: bcInventory?.lotTracking?.value,
          //  === null ? 0 : bcInventory?.lotTracking?.value
        },
        noWarehouses: {
          label: bcInventory?.noWarehouses?.label,
          value: bcInventory?.noWarehouses?.value,
          // === null ? 0 : bcInventory?.noWarehouses?.value
        },
        warehouseLogistics: {
          label: bcInventory?.warehouseLogistics?.label,
          value: bcInventory?.warehouseLogistics?.value,
          // === null
          // ? 0 : bcInventory?.warehouseLogistics?.value
        },
      },
      bcAssemblyManagement: {
        assemblyMgmt: {
          label:
            bcAssemblyManagement?.assemblyMgmt?.label === 'assemblyMgmt'
              ? 'No'
              : bcAssemblyManagement?.assemblyMgmt?.label,
          value: bcAssemblyManagement?.assemblyMgmt?.value,
          //  === null ? 0 : bcAssemblyManagement?.assemblyMgmt?.value
        },
        noAboms: {
          label: bcAssemblyManagement?.noAboms?.label,
          value: bcAssemblyManagement?.noAboms?.value,
          // === null ? 0 : bcAssemblyManagement?.noAboms?.value
        },
        edi: {
          label: bcAssemblyManagement?.edi?.label,
          value: bcAssemblyManagement?.edi?.value,
          // === null ? 0 : bcAssemblyManagement?.edi?.value
        },
      },
      bcManufacturing: {
        manufacturing: {
          label:
            bcManufacturing?.manufacturing?.label === 'manufacturing'
              ? 'No'
              : bcManufacturing?.manufacturing?.label,
          value: bcManufacturing?.manufacturing?.value,
          //  === null ? 0 : bcManufacturing?.manufacturing?.value
        },
        routings: {
          label: bcManufacturing?.routings?.label,
          value: bcManufacturing?.routings?.value,
          // === null ? 0 : bcManufacturing?.routings?.value
        },
        workCenters: {
          label: bcManufacturing?.workCenters?.label,
          value: bcManufacturing?.workCenters?.value,
          //  === null ? 0 : bcManufacturing?.workCenters?.value
        },
        prodLocations: {
          label: bcManufacturing?.prodLocations?.label,
          value: bcManufacturing?.prodLocations?.value,
          // === null ? 0 : bcManufacturing?.prodLocations?.value
        },
        mBoms: {
          label: bcManufacturing?.mBoms?.label,
          value: bcManufacturing?.mBoms?.value,
          // === null ? 0 : bcManufacturing?.mBoms?.value
        },
        ediM: {
          label: bcManufacturing?.ediM?.label,
          value: bcManufacturing?.ediM?.value,
          //  === null ? 0 : bcManufacturing?.ediM?.value
        },
      },
    };

    dispatch(setSubStepStatus({ bdCentral: { active: false, completed: true } }));
    dispatch(saveBusinessCentral(data));

    if (selectedProducts[selectedProducts.length - 1] === '1') {
      const updateStep = stepsStatus;
      updateStep[1].businessDetails = { active: false, completed: true };
      dispatch(setStepsStatus(updateStep));
    }

    const nextIndex = selectedProducts.indexOf('1') + 1 || Infinity;
    if (selectedProducts.at(nextIndex)) {
      navigate(`/business-details/${conditionalRoute(selectedProducts.at(nextIndex))}`);
    } else {
      navigate(`/end-users/${conditionalRouteEndUser(selectedProducts.at(0))}`);
    }
  };

  const handleSave = () => {
    setLoading(true);
    const payloadData = {
      bcFinanceFoundation: {
        bankAccounts: {
          label: bcFinanceFoundation?.bankAccounts?.label,
          value:
            bcFinanceFoundation?.bankAccounts?.value === null
              ? 0
              : bcFinanceFoundation?.bankAccounts?.value,
        },
        functionalCurrencies: {
          label: bcFinanceFoundation?.functionalCurrencies?.label,
          value:
            bcFinanceFoundation?.functionalCurrencies?.value === null
              ? 0
              : bcFinanceFoundation?.functionalCurrencies?.value,
        },
        customerCount: {
          label: bcFinanceFoundation?.customerCount?.label,
          value:
            bcFinanceFoundation?.customerCount?.value === null
              ? 0
              : bcFinanceFoundation?.customerCount?.value,
        },
        vendorCount: {
          label: bcFinanceFoundation?.vendorCount?.label,
          value:
            bcFinanceFoundation?.vendorCount?.value === null
              ? 0
              : bcFinanceFoundation?.vendorCount?.value,
        },
        poCount: {
          label: bcFinanceFoundation?.poCount?.label,
          value:
            bcFinanceFoundation?.poCount?.value === null ? 0 : bcFinanceFoundation?.poCount?.value,
        },
      },
      bcMulticompany: {
        multicompany: {
          label: bcMulticompany?.multicompany?.label,
          value:
            bcMulticompany?.multicompany?.value === null ? 0 : bcMulticompany?.multicompany?.value,
        },
        entityCount: {
          label: bcMulticompany?.entityCount?.label,
          value:
            bcMulticompany?.entityCount?.value === null ? 0 : bcMulticompany?.entityCount?.value,
        },
        uniqueCOA: {
          label: bcMulticompany?.uniqueCOA?.label,
          value: bcMulticompany?.uniqueCOA?.value === null ? 0 : bcMulticompany?.uniqueCOA?.value,
        },
        intercompanyRequired: {
          label: bcMulticompany?.intercompanyRequired?.label,
          value:
            bcMulticompany?.intercompanyRequired?.value === null
              ? 0
              : bcMulticompany?.intercompanyRequired?.value,
        },
        consolidationRequired: {
          label: bcMulticompany?.consolidationRequired?.label,
          value:
            bcMulticompany?.consolidationRequired?.value === null
              ? 0
              : bcMulticompany?.consolidationRequired?.value,
        },
        noConsolidations: {
          label: bcMulticompany?.noConsolidations?.label,
          value:
            bcMulticompany?.noConsolidations?.value === null
              ? 0
              : bcMulticompany?.noConsolidations?.value,
        },
      },
      bcFixedAssets: {
        fixedAssets: {
          label: bcFixedAssets?.fixedAssets?.label,
          value: bcFixedAssets?.fixedAssets?.value === null ? 0 : bcFixedAssets?.fixedAssets?.value,
        },
        noFixedAssets: {
          label: bcFixedAssets?.noFixedAssets?.label,
          value:
            bcFixedAssets?.noFixedAssets?.value === null ? 0 : bcFixedAssets?.noFixedAssets?.value,
        },
        faComponents: {
          label: bcFixedAssets?.faComponents?.label,
          value:
            bcFixedAssets?.faComponents?.value === null ? 0 : bcFixedAssets?.faComponents?.value,
        },
      },
      bcSalesOrder: {
        salesOrders: {
          label: bcSalesOrder?.salesOrders?.label,
          value: bcSalesOrder?.salesOrders?.value === null ? 0 : bcSalesOrder?.salesOrders?.value,
        },
        soCount: {
          label: bcSalesOrder?.soCount?.label,
          value: bcSalesOrder?.soCount?.value,
        },
      },
      bcJobs: {
        jobs: {
          label: bcJobs?.jobs?.label,
          value: bcJobs?.jobs?.value === null ? 0 : bcJobs?.jobs?.value,
        },
        billableJobs: {
          label: bcJobs?.billableJobs?.label,
          value: bcJobs?.billableJobs?.value === null ? 0 : bcJobs?.billableJobs?.value,
        },
      },
      bcInventory: {
        inventoryItems: {
          label: bcInventory?.inventoryItems?.label,
          value:
            bcInventory?.inventoryItems?.value === null ? 0 : bcInventory?.inventoryItems?.value,
          // === null ? 0 : bcInventory?.inventoryItems?.value
        },
        noSku: {
          label: bcInventory?.noSku?.label,
          value: bcInventory?.noSku?.value,
          // === null ? 0 : bcInventory?.noSku?.value
        },
        lotTracking: {
          label: bcInventory?.lotTracking?.label,
          value: bcInventory?.lotTracking?.value,
          //  === null ? 0 : bcInventory?.lotTracking?.value
        },
        noWarehouses: {
          label: bcInventory?.noWarehouses?.label,
          value: bcInventory?.noWarehouses?.value,
          // === null ? 0 : bcInventory?.noWarehouses?.value
        },
        warehouseLogistics: {
          label: bcInventory?.warehouseLogistics?.label,
          value: bcInventory?.warehouseLogistics?.value,
          // === null
          // ? 0 : bcInventory?.warehouseLogistics?.value
        },
      },
      bcAssemblyManagement: {
        assemblyMgmt: {
          label:
            bcAssemblyManagement?.assemblyMgmt?.label === 'assemblyMgmt'
              ? 'No'
              : bcAssemblyManagement?.assemblyMgmt?.label,
          value: bcAssemblyManagement?.assemblyMgmt?.value,
          //  === null ? 0 : bcAssemblyManagement?.assemblyMgmt?.value
        },
        noAboms: {
          label: bcAssemblyManagement?.noAboms?.label,
          value: bcAssemblyManagement?.noAboms?.value,
          // === null ? 0 : bcAssemblyManagement?.noAboms?.value
        },
        edi: {
          label: bcAssemblyManagement?.edi?.label,
          value: bcAssemblyManagement?.edi?.value,
          // === null ? 0 : bcAssemblyManagement?.edi?.value
        },
      },
      bcManufacturing: {
        manufacturing: {
          label:
            bcManufacturing?.manufacturing?.label === 'manufacturing'
              ? 'No'
              : bcManufacturing?.manufacturing?.label,
          value: bcManufacturing?.manufacturing?.value,
          //  === null ? 0 : bcManufacturing?.manufacturing?.value
        },
        routings: {
          label: bcManufacturing?.routings?.label,
          value: bcManufacturing?.routings?.value,
          // === null ? 0 : bcManufacturing?.routings?.value
        },
        workCenters: {
          label: bcManufacturing?.workCenters?.label,
          value: bcManufacturing?.workCenters?.value,
          //  === null ? 0 : bcManufacturing?.workCenters?.value
        },
        prodLocations: {
          label: bcManufacturing?.prodLocations?.label,
          value: bcManufacturing?.prodLocations?.value,
          // === null ? 0 : bcManufacturing?.prodLocations?.value
        },
        mBoms: {
          label: bcManufacturing?.mBoms?.label,
          value: bcManufacturing?.mBoms?.value,
          // === null ? 0 : bcManufacturing?.mBoms?.value
        },
        ediM: {
          label: bcManufacturing?.ediM?.label,
          value: bcManufacturing?.ediM?.value,
          //  === null ? 0 : bcManufacturing?.ediM?.value
        },
      },
    };
    const profileData = {
      firstName: reduxData?.profile?.profile?.firstName,
      lastName: reduxData?.profile?.profile?.lastName,
      email: reduxData?.profile?.profile?.email,
      countryCode: reduxData?.profile?.profile?.countryCode,
      phoneNo: reduxData?.profile?.profile?.phoneNo,
      companyName: reduxData?.profile?.profile?.companyName,
      followUp: reduxData?.profile?.profile?.followUp,
    };
    const body = {
      selectedProducts: modifyProductsByCategory(reduxData.product.selectedProducts),
      businessCentral: payloadData,
      sales: reduxData.sales.sales,
      marketing: reduxData.marketing.marketing,
      customerService: reduxData.customerService.customerService,
      endUsers: reduxData.endUsers,
      profileDetails: profileData,
      supportPackage: hyperPackage,
      // userCode: saveForLaterCode ? saveForLaterCode : code,
      userLocation: reduxData.saveGeoLocation.geoLocation,
      analyticsPayload: {
        clientId: id,
      },
    };
    if (saveForLaterCode) {
      body.userCode = saveForLaterCode;
    } else if (code) {
      body.userCode = code;
    } else {
      body.userCode = '';
    }
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/api/calculatePricing`, body)
      .then((res) => {
        if (res.data.message === 'Pricing calculated successfully') {
          setLoading(false);
          const response = res.data;
          dispatch(saveBusinessCentral(payloadData));
          dispatch(saveSupport(response));
          navigate('/pricing');
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleCancel = () => {
    navigate('/pricing');
  };

  window.addEventListener('scroll', changePosition);

  const buttonOne = [
    { label: 'Up to 5', value: 1 },
    { label: 'More than 5', value: 2 },
  ];

  const buttonTwo = [
    { label: 'Up to 3', value: 1 },
    { label: 'More than 3', value: 2 },
  ];

  const buttonThree = [
    { label: 'Under 500', value: 1 },
    { label: '500 to 2500', value: 2 },
    { label: 'More than 2500', value: 3 },
  ];

  const buttonFour = [
    { label: 'Under 250', value: 1 },
    { label: '250 to 500', value: 2 },
    { label: 'More than 500', value: 3 },
  ];

  const buttonFive = [
    { label: 'Under 50', value: 1 },
    { label: '50 to 100', value: 2 },
    { label: 'More than 100', value: 3 },
  ];

  const buttonSix = [
    { label: 'One company or legal entity', value: 0 },
    { label: 'More than one company or legal entity', value: 1 },
  ];

  const buttonSeven = [
    { label: 'Between 2 to 4', value: 1 },
    { label: 'Between 5 to 10', value: 2 },
    { label: 'More than 10', value: 3 },
  ];
  const buttonEight = [
    { label: 'Just 1', value: 1 },
    { label: 'Between 2 to 4', value: 2 },
    { label: 'Between 5 to 10', value: 3 },
    { label: 'More than 10', value: 4 },
  ];

  const buttonEleven = [
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    { label: '3', value: 3 },
    { label: '4', value: 4 },
    { label: '5', value: 5 },
  ];

  const buttonThirteen = [
    { label: 'Under 200', value: 1 },
    { label: '200 to 500', value: 2 },
    { label: 'More than 500', value: 3 },
  ];

  const buttonSixteen = [
    { label: 'Under 100', value: 1 },
    { label: '100 to 250', value: 2 },
    { label: 'More than 250', value: 3 },
  ];

  const buttonTwenty = [
    { label: ' Under 1500', value: 1 },
    { label: '1500 to 3000', value: 2 },
    { label: 'More than 3000', value: 3 },
  ];

  const buttonTwentyFive = [
    { label: '1 to 25', value: 1 },
    { label: '26 to 50', value: 2 },
    { label: '50+', value: 3 },
  ];

  const buttonTwentyEight = [
    { label: '1 to 10', value: 1 },
    { label: '11 to 25', value: 2 },
    { label: '26+', value: 3 },
  ];

  const buttonTwentyNine = [
    { label: '1 to 5', value: 1 },
    { label: '6 to 10', value: 2 },
    { label: '10+', value: 3 },
  ];

  const buttonThirty = [
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    { label: '3+', value: 3 },
  ];

  useEffect(() => {
    if (bcMulticompany?.multicompany?.value === 1) {
      if (
        bcMulticompany?.uniqueCOA?.value === null ||
        bcMulticompany?.intercompanyRequired?.value === null ||
        bcMulticompany?.consolidationRequired?.value === null ||
        bcMulticompany?.entityCount?.value === null ||
        (bcMulticompany?.consolidationRequired?.value &&
          bcMulticompany?.noConsolidations?.value === null)
      ) {
        setShow(false);
        return null;
      }
    } else if (bcMulticompany?.multicompany?.value === null) {
      setShow(false);
      return null;
    }

    if (bcFixedAssets?.fixedAssets?.value === 1) {
      if (
        bcFixedAssets?.noFixedAssets?.value === null ||
        bcFixedAssets?.faComponents?.value === null
      ) {
        setShow(false);
        return null;
      }
    } else if (bcFixedAssets?.fixedAssets?.value === null) {
      setShow(false);
      return null;
    }

    if (bcSalesOrder?.salesOrders?.value === 1) {
      if (bcSalesOrder?.soCount?.value === null) {
        setShow(false);
        return null;
      }
    } else if (bcSalesOrder?.salesOrders?.value === null) {
      setShow(false);
      return null;
    }

    if (bcJobs?.jobs?.value === 1) {
      if (bcJobs?.billableJobs?.value === null) {
        setShow(false);
        return null;
      }
    } else if (bcJobs?.jobs?.value === null) {
      setShow(false);
      return null;
    }

    if (bcInventory?.inventoryItems?.value === 1) {
      if (
        bcInventory?.noSku?.value === null ||
        bcInventory?.lotTracking?.value === null ||
        bcInventory?.noWarehouses?.value === null ||
        bcInventory?.warehouseLogistics?.value === null
        // (bcAssemblyManagement?.assemblyMgmt?.value && bcMulticompany?.noConsolidations?.value === null)
      ) {
        setShow(false);
        return null;
      }
    } else if (bcInventory?.inventoryItems?.value === null) {
      setShow(false);
      return null;
    } else if (bcInventory?.inventoryItems?.value === 0) {
      setShow(true);
      return null;
    }

    if (bcAssemblyManagement?.assemblyMgmt?.value === 1) {
      if (
        bcAssemblyManagement?.noAboms?.value === null ||
        bcAssemblyManagement?.edi?.value === null
        // (bcAssemblyManagement?.assemblyMgmt?.value && bcMulticompany?.noConsolidations?.value === null)
      ) {
        setShow(false);
        return null;
      }
    } else if (bcAssemblyManagement?.assemblyMgmt?.value === null) {
      setShow(false);
      return null;
    }

    if (bcManufacturing?.manufacturing?.value === 1) {
      if (
        bcManufacturing?.routings?.value === null ||
        bcManufacturing?.workCenters?.value === null ||
        bcManufacturing?.prodLocations?.value === null ||
        bcManufacturing?.mBoms?.value === null ||
        bcManufacturing?.ediM?.value === null
      ) {
        setShow(false);
        return null;
      }
    } else if (bcManufacturing?.manufacturing?.value === 0) {
      setShow(true);
      return null;
    } else if (bcManufacturing?.manufacturing?.value === null) {
      setShow(false);
      return null;
    }
    setShow(true);
  }, [
    bcMulticompany,
    bcInventory,
    bcManufacturing,
    bcFixedAssets,
    bcSalesOrder,
    bcJobs,
    bcAssemblyManagement,
  ]);

  const scrollToRef = (ref) => {
    if (ref.current === undefined) {
      window.scrollTo(0, 2749);
    } else if (isSmallMobile) {
      window.scrollTo(0, ref.current.offsetTop - 260);
    } else if (isDesktopOrLaptop) {
      window.scrollTo(0, ref.current.offsetTop - 150);
    } else {
      window.scrollTo(0, ref.current.offsetTop - 100);
    }
  };

  useEffect(() => {
    if (state?.focus) {
      switch (state?.focus) {
        case 'financialAccounting':
          scrollToRef(financialAccountingDivRef);
          break;

        case 'multiCompany':
          scrollToRef(multiCompanyDivRef);
          break;

        case 'fixedAssets':
          scrollToRef(fixedAssetsDivRef);
          break;

        case 'salesOrders':
          scrollToRef(salesOrdersDivRef);
          break;

        case 'jobs':
          scrollToRef(jobsDivRef);
          break;

        case 'inventory':
          scrollToRef(inventoryDivRef);
          break;

        case 'assembly':
          scrollToRef(assemblyDivRef);
          break;

        case 'manufacturing':
          scrollToRef(manufacturingDivRef);
          break;

        default:
          break;
      }
    } else if (isSmallMobile) {
      scrollToRef(financialAccountingDivRef);
    } else {
      scrollToRef(financialAccountingDivRef);
    }
  }, [state?.focus]);

  useEffect(() => {
    ReactGA.initialize('UA-74792081-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
    console.log(window.location.pathname, 'window.location.pathname');
    console.log(window.location.search, 'window.location.search');
  }, []);

  return (
    <div>
      <Link to="navbar" spy smooth>
        <section
          style={{
            backgroundImage: `linear-gradient(90deg, #61C250, #4EC35D, #00C37C, #00C0A4, #00BBC7, #00B3DA, #00ACE0, #00A9E0)`,
            height: isMobile ? '80vh' : '100%',
          }}
        >
          <div
            style={{
              backgroundImage: `url(${BannerImage})`,
              height: '100%',
              backgroundPosition: 'top',
              backgroundRepeat: 'no-repeat',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {location.pathname === '/pricing' || location.pathname === '/product' ? (
              <img
                src={isMobile ? catapultLogo : exit}
                style={{
                  cursor: 'pointer',
                  height: isMobile ? '47px' : '68px',
                  width: isMobile ? '100%' : '230px',
                  zIndex: 999,
                  position: 'fixed',
                  right: isMobile ? '' : '0px',
                  top: '-5px',
                }}
                onClick={() => window.location.replace('https://www.catapulterp.com/')}
              />
            ) : (
              <img
                src={isMobile ? catapultLogo : exit}
                style={{
                  cursor: 'pointer',
                  height: isMobile ? '47px' : '68px',
                  width: isMobile ? '100%' : '230px',
                  zIndex: 999,
                  position: 'fixed',
                  right: isMobile ? '' : '0px',
                  top: '-5px',
                }}
                onClick={() => {
                  modalRef.current();
                  setCheck(true);
                }}
              />
            )}
            <Container
              fixed
              maxWidth="md"
              className="animate__animated animate__fadeIn"
              sx={{
                height: '450px',
              }}
            >
              <Grid
                item
                xs={12}
                sx={{
                  textAlign: 'center',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Stack
                  direction="column"
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    paddingTop: isMobile ? '47px' : '',
                  }}
                >
                  <Typography
                    variant="string"
                    gutterBottom
                    component="div"
                    sx={{
                      fontFamily: 'DinDIsplayProRegular',
                      fontStyle: 'italic',
                      color: '#FFFFFF',
                    }}
                  >
                    Pricing
                  </Typography>
                  <Typography
                    variant="h3"
                    gutterBottom
                    component="div"
                    className="font-face-prelo-black"
                    sx={{
                      fontFamily: 'PreloSlabW04Black',
                      color: '#FFFFFF',
                      fontSize: isMobile ? '33px' : '48px',
                      marginBottom: isMobile ? '40px' : '',
                    }}
                  >
                    Take the guesswork out of your ERP & CRM solution budgeting.
                  </Typography>
                  <Typography
                    variant="caption"
                    gutterBottom
                    component="div"
                    mb={5}
                    sx={{
                      fontFamily: 'PreloSlabW04Light',
                      color: '#FFFFFF',
                      fontSize: isMobile ? '18px' : '20px',
                      lineHeight: 1.5,
                      paddingX: isMobile ? 2 : 10,
                      marginBottom: isMobile ? '60px' : '40px',
                    }}
                  >
                    Customize and price your Dynamics 365 solution using our powerful online scoping
                    and budgeting tools.
                  </Typography>
                </Stack>
              </Grid>
            </Container>
          </div>
        </section>
      </Link>

      <div style={{ height: isMobile ? '' : '120px' }} id="navbar">
        <Navbar navbar={navbar} />
        <ProductBar navbar={navbar} />
      </div>

      <Container
        sx={{
          py: 4,
          height: 'auto',
          display: 'inherit',
          marginBottom: isMobile ? '100px' : '',
          minHeight: '800px',
        }}
      >
        <Grid
          container
          spacing={isMobile ? 3 : 2}
          justifyContent="start"
          md={12}
          sx={{ paddingBottom: isMobile ? '100px' : '', paddingTop: '10px' }}
          ref={financialAccountingDivRef}
        >
          <Grid item xs={12} md={9} className="animate__animated animate__fadeIn">
            <Typography
              variant="body1"
              sx={{
                color: '#00313E',
                fontFamily: 'PreloSlabW04SemiBold',
                fontSize: isMobile ? '19px' : '22px',
              }}
              gutterBottom
              component="div"
            >
              First, which of the following options best describes the number of bank accounts your
              organization uses?
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={9}
            mb={1}
            sx={{
              display: 'flex',
            }}
            className="animate__animated animate__fadeIn"
          >
            {buttonOne?.map((item) => (
              <Button
                variant="myPrimary"
                sx={{
                  backgroundColor:
                    bcFinanceFoundation?.bankAccounts?.value !== item.value ? 'white' : '#00313E',
                  width: isMobile ? '150px' : '180px',
                  height: '60px',
                  borderRadius: '15px',
                  border: '1px solid #00313E',
                  marginRight: isMobile ? '18px' : '40px',
                  marginBottom: '10px',
                  marginTop: '10px',

                  '&:hover': {
                    backgroundColor:
                      bcFinanceFoundation?.bankAccounts?.value !== item.value ? 'white' : '#00313E',
                  },
                }}
                value={item.label}
                onClick={() => {
                  handleChangeFinanceFoundation('bankAccounts', item.label, item.value);
                }}
              >
                <Typography
                  variant="button"
                  display="block"
                  sx={{
                    color:
                      bcFinanceFoundation?.bankAccounts?.value !== item.value
                        ? '#00313E'
                        : '#FFFFFF',
                    fontFamily: 'DinDIsplayProBold',
                    textTransform: 'none',
                    fontSize: isMobile ? '14px' : '18px',
                  }}
                >
                  {item.label}
                </Typography>
              </Button>
            ))}
          </Grid>
          {/* -------------------------Question 2 ----------------------------------------------------*/}
          {bcFinanceFoundation?.bankAccounts?.value ? (
            <>
              <Grid item xs={12} md={9} className="animate__animated animate__fadeIn">
                <Typography
                  variant="body1"
                  sx={{
                    color: '#00313E',
                    fontFamily: 'PreloSlabW04SemiBold',
                    fontSize: isMobile ? '19px' : '22px',
                  }}
                  gutterBottom
                  component="div"
                >
                  Next, which of the options below best describes the number of functional
                  currencies your organization transacts in?
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={9}
                mb={1}
                sx={{
                  display: 'flex',
                }}
                className="animate__animated animate__fadeIn"
              >
                {buttonTwo?.map((item) => (
                  <Button
                    variant="myPrimary"
                    sx={{
                      backgroundColor:
                        bcFinanceFoundation?.functionalCurrencies?.value !== item.value
                          ? 'white'
                          : '#00313E',
                      width: isMobile ? '150px' : '180px',
                      height: '60px',
                      borderRadius: '15px',
                      border: '1px solid #00313E',
                      marginRight: isMobile ? '18px' : '40px',
                      marginBottom: '10px',
                      marginTop: '10px',

                      '&:hover': {
                        backgroundColor:
                          bcFinanceFoundation?.functionalCurrencies?.value !== item.value
                            ? 'white'
                            : '#00313E',
                      },
                    }}
                    value={item.label}
                    onClick={() => {
                      handleChangeFinanceFoundation('functionalCurrencies', item.label, item.value);
                    }}
                  >
                    <Typography
                      variant="button"
                      display="block"
                      sx={{
                        color:
                          bcFinanceFoundation?.functionalCurrencies?.value !== item.value
                            ? '#00313E'
                            : '#FFFFFF',
                        fontFamily: 'DinDIsplayProBold',
                        textTransform: 'none',
                        fontSize: isMobile ? '14px' : '18px',
                      }}
                    >
                      {item.label}
                    </Typography>
                  </Button>
                ))}
              </Grid>
            </>
          ) : (
            ''
          )}

          {/* -------------------------Question 3, 4, 5 ----------------------------------------------------*/}

          {bcFinanceFoundation?.functionalCurrencies?.value ? (
            <>
              <Grid item xs={12} md={10} className="animate__animated animate__fadeIn">
                <Typography
                  variant="body1"
                  sx={{
                    color: '#00313E',
                    fontFamily: 'PreloSlabW04SemiBold',
                    fontSize: isMobile ? '19px' : '22px',
                    marginRight: isMobile ? '0px' : '45px',
                  }}
                  gutterBottom
                  component="div"
                >
                  Great! Now we'll ask you to estimate your monthly business volume. Please select
                  the options below that best describe the number of customers billed, vendors
                  invoices and purchase orders processed each month:
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={3}
                sx={{ display: 'flex' }}
                className="animate__animated animate__fadeIn"
              >
                <Typography
                  variant="body1"
                  sx={{
                    color: '#00313E',
                    fontFamily: 'PreloSlabW04Medium',
                    fontSize: isMobile ? '19px' : '22px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  gutterBottom
                  component="div"
                >
                  Customers
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={9}
                sx={{
                  display: 'flex',
                  flexWrap: isMobile ? 'wrap' : '',
                }}
                className="animate__animated animate__fadeIn"
              >
                {buttonThree?.map((item) => (
                  <Button
                    variant="myPrimary"
                    sx={{
                      backgroundColor:
                        bcFinanceFoundation?.customerCount?.value !== item.value
                          ? 'white'
                          : '#00313E',
                      width: isMobile ? '140px' : '180px',
                      height: '60px',
                      borderRadius: '15px',
                      border: '1px solid #00313E',
                      marginRight: isMobile ? '20px' : '18px',
                      marginBottom: '10px',
                      marginTop: '10px',

                      '&:hover': {
                        backgroundColor:
                          bcFinanceFoundation?.customerCount?.value !== item.value
                            ? 'white'
                            : '#00313E',
                      },
                    }}
                    value={item.label}
                    onClick={() => {
                      handleChangeFinanceFoundation('customerCount', item.label, item.value);
                    }}
                  >
                    <Typography
                      variant="button"
                      display="block"
                      sx={{
                        color:
                          bcFinanceFoundation?.customerCount?.value !== item.value
                            ? '#00313E'
                            : '#FFFFFF',
                        fontFamily: 'DinDIsplayProBold',
                        textTransform: 'none',
                        fontSize: isMobile ? '14px' : '18px',
                      }}
                    >
                      {item.label}
                    </Typography>
                  </Button>
                ))}
              </Grid>
              <Grid
                item
                xs={12}
                md={3}
                sx={{ display: 'flex' }}
                className="animate__animated animate__fadeIn"
              >
                <Typography
                  variant="body1"
                  sx={{
                    color: '#00313E',
                    fontFamily: 'PreloSlabW04Medium',
                    fontSize: isMobile ? '19px' : '22px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  gutterBottom
                  component="div"
                >
                  Vendors
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={9}
                sx={{
                  display: 'flex',
                  flexWrap: isMobile ? 'wrap' : '',
                }}
                className="animate__animated animate__fadeIn"
              >
                {buttonFour?.map((item) => (
                  <Button
                    variant="myPrimary"
                    sx={{
                      backgroundColor:
                        bcFinanceFoundation?.vendorCount?.value !== item.value
                          ? 'white'
                          : '#00313E',
                      width: isMobile ? '140px' : '180px',
                      height: '60px',
                      borderRadius: '15px',
                      border: '1px solid #00313E',
                      marginRight: isMobile ? '20px' : '18px',
                      marginBottom: '10px',
                      marginTop: '10px',

                      '&:hover': {
                        backgroundColor:
                          bcFinanceFoundation?.vendorCount?.value !== item.value
                            ? 'white'
                            : '#00313E',
                      },
                    }}
                    value={item.label}
                    onClick={() => {
                      handleChangeFinanceFoundation('vendorCount', item.label, item.value);
                    }}
                  >
                    <Typography
                      variant="button"
                      display="block"
                      sx={{
                        color:
                          bcFinanceFoundation?.vendorCount?.value !== item.value
                            ? '#00313E'
                            : '#FFFFFF',
                        fontFamily: 'DinDIsplayProBold',
                        textTransform: 'none',
                        fontSize: isMobile ? '14px' : '18px',
                      }}
                    >
                      {item.label}
                    </Typography>
                  </Button>
                ))}
              </Grid>
              <Grid
                item
                xs={12}
                md={3}
                sx={{ display: 'flex' }}
                className="animate__animated animate__fadeIn"
              >
                <Typography
                  variant="body1"
                  sx={{
                    color: '#00313E',
                    fontFamily: 'PreloSlabW04Medium',
                    fontSize: isMobile ? '19px' : '22px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  gutterBottom
                  component="div"
                >
                  Monthly Purchase Orders
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={9}
                mb={2}
                sx={{
                  display: 'flex',
                  flexWrap: isMobile ? 'wrap' : '',
                }}
                className="animate__animated animate__fadeIn"
              >
                {buttonFive?.map((item) => (
                  <Button
                    variant="myPrimary"
                    sx={{
                      backgroundColor:
                        bcFinanceFoundation?.poCount?.value !== item.value ? 'white' : '#00313E',
                      width: isMobile ? '140px' : '180px',
                      height: '60px',
                      borderRadius: '15px',
                      border: '1px solid #00313E',
                      marginRight: isMobile ? '20px' : '18px',
                      marginBottom: '10px',
                      marginTop: '10px',

                      '&:hover': {
                        backgroundColor:
                          bcFinanceFoundation?.poCount?.value !== item.value ? 'white' : '#00313E',
                      },
                    }}
                    value={item.label}
                    onClick={() => {
                      handleChangeFinanceFoundation('poCount', item.label, item.value);
                    }}
                  >
                    <Typography
                      variant="button"
                      display="block"
                      sx={{
                        color:
                          bcFinanceFoundation?.poCount?.value !== item.value
                            ? '#00313E'
                            : '#FFFFFF',
                        fontFamily: 'DinDIsplayProBold',
                        textTransform: 'none',
                        fontSize: isMobile ? '14px' : '18px',
                      }}
                    >
                      {item.label}
                    </Typography>
                  </Button>
                ))}
              </Grid>
            </>
          ) : (
            ''
          )}
          {/* -------------------------Question 6 ----------------------------------------------------*/}

          {bcFinanceFoundation?.poCount?.value &&
          bcFinanceFoundation?.customerCount?.value &&
          bcFinanceFoundation?.vendorCount?.value ? (
            <>
              <Grid
                item
                xs={12}
                md={9}
                className="animate__animated animate__fadeIn"
                id="multicompany"
                ref={multiCompanyDivRef}
              >
                <Typography
                  variant="body1"
                  sx={{
                    color: '#00313E',
                    fontFamily: 'PreloSlabW04SemiBold',
                    fontSize: isMobile ? '19px' : '22px',
                  }}
                  gutterBottom
                  component="div"
                >
                  Please choose the option below that best describes the number of individual legal
                  entities in your corporate structure:
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={9}
                mb={2}
                sx={{
                  display: 'flex',
                  flexWrap: isMobile ? 'wrap' : '',
                }}
                className="animate__animated animate__fadeIn"
              >
                {buttonSix?.map((item) => (
                  <Button
                    variant="myPrimary"
                    sx={{
                      backgroundColor:
                        bcMulticompany?.multicompany?.value !== item.value ? 'white' : '#00313E',
                      width: '360px',
                      height: '60px',
                      borderRadius: '15px',
                      border: '1px solid #00313E',
                      marginRight: isMobile ? '20px' : '18px',
                      marginBottom: '10px',
                      marginTop: '10px',

                      '&:hover': {
                        backgroundColor:
                          bcMulticompany?.multicompany?.value !== item.value ? 'white' : '#00313E',
                      },
                    }}
                    value={item.label}
                    onClick={() => {
                      handleChangeMultiCompany('multicompany', item.label, item.value);
                    }}
                  >
                    <Typography
                      variant="button"
                      display="block"
                      sx={{
                        color:
                          bcMulticompany?.multicompany?.value !== item.value
                            ? '#00313E'
                            : '#FFFFFF',
                        fontFamily: 'DinDIsplayProBold',
                        textTransform: 'none',
                        fontSize: isMobile ? '14px' : '18px',
                      }}
                    >
                      {item.label}
                    </Typography>
                  </Button>
                ))}
              </Grid>
            </>
          ) : (
            ''
          )}

          {bcMulticompany?.multicompany?.value === 1 ? (
            <>
              {/* -------------------------Question 7 ----------------------------------------------------*/}

              {bcMulticompany?.multicompany?.value === 1 ? (
                <>
                  <Grid item xs={12} md={9} className="animate__animated animate__fadeIn">
                    <Typography
                      variant="body1"
                      sx={{
                        color: '#00313E',
                        fontFamily: 'PreloSlabW04SemiBold',
                        fontSize: isMobile ? '17px' : '20px',
                      }}
                      gutterBottom
                      component="div"
                    >
                      How many legal entities does your business include?
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={9}
                    mb={2}
                    sx={{
                      display: 'flex',
                      flexWrap: isMobile ? 'wrap' : '',
                    }}
                    className="animate__animated animate__fadeIn"
                  >
                    {buttonSeven?.map((item) => (
                      <Button
                        variant="myPrimary"
                        sx={{
                          backgroundColor:
                            bcMulticompany?.entityCount?.value !== item.value ? 'white' : '#00313E',
                          width: isMobile ? '150px' : '180px',
                          height: '60px',
                          borderRadius: '15px',
                          border: '1px solid #00313E',
                          marginRight: isMobile ? '20px' : '18px',
                          marginBottom: '10px',
                          marginTop: '10px',

                          '&:hover': {
                            backgroundColor:
                              bcMulticompany?.entityCount?.value !== item.value
                                ? 'white'
                                : '#00313E',
                          },
                        }}
                        onClick={() => {
                          handleChangeMultiCompany('entityCount', item.label, item.value);
                        }}
                      >
                        <Typography
                          variant="button"
                          display="block"
                          sx={{
                            color:
                              bcMulticompany?.entityCount?.value !== item.value
                                ? '#00313E'
                                : '#FFFFFF',
                            fontFamily: 'DinDIsplayProBold',
                            textTransform: 'none',
                            fontSize: isMobile ? '14px' : '18px',
                          }}
                        >
                          {item.label}
                        </Typography>
                      </Button>
                    ))}
                  </Grid>
                </>
              ) : (
                ''
              )}

              {/* -------------------------Question 8 ----------------------------------------------------*/}

              {bcMulticompany?.entityCount?.value && bcMulticompany?.multicompany?.value === 1 ? (
                <>
                  <Grid item xs={12} md={9} className="animate__animated animate__fadeIn">
                    <Typography
                      variant="body1"
                      sx={{
                        color: '#00313E',
                        fontFamily: 'PreloSlabW04SemiBold',
                        fontSize: isMobile ? '17px' : '20px',
                      }}
                      gutterBottom
                      component="div"
                    >
                      How many unique business types or Chart of Accounts are there across the
                      different legal entities?
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={9}
                    mb={2}
                    sx={{
                      display: 'flex',
                      flexWrap: isMobile ? 'wrap' : '',
                    }}
                    className="animate__animated animate__fadeIn"
                  >
                    {buttonEight?.map((item) => (
                      <Button
                        variant="myPrimary"
                        sx={{
                          backgroundColor:
                            bcMulticompany?.uniqueCOA?.value !== item.value ? 'white' : '#00313E',
                          width: isMobile ? '150px' : '180px',
                          height: '60px',
                          borderRadius: '15px',
                          border: '1px solid #00313E',
                          marginRight: isMobile ? '20px' : '18px',
                          marginBottom: '10px',
                          marginTop: '10px',

                          '&:hover': {
                            backgroundColor:
                              bcMulticompany?.uniqueCOA?.value !== item.value ? 'white' : '#00313E',
                          },
                        }}
                        value={item.label}
                        onClick={() => {
                          handleChangeMultiCompany('uniqueCOA', item.label, item.value);
                        }}
                      >
                        <Typography
                          variant="button"
                          display="block"
                          sx={{
                            color:
                              bcMulticompany?.uniqueCOA?.value !== item.value
                                ? '#00313E'
                                : '#FFFFFF',
                            fontFamily: 'DinDIsplayProBold',
                            textTransform: 'none',
                            fontSize: isMobile ? '14px' : '18px',
                          }}
                        >
                          {item.label}
                        </Typography>
                      </Button>
                    ))}
                  </Grid>
                </>
              ) : (
                ''
              )}

              {/* -------------------------Question 9 ----------------------------------------------------*/}

              {bcMulticompany?.uniqueCOA?.value && bcMulticompany?.multicompany?.value === 1 ? (
                <>
                  <Grid item xs={12} md={9} className="animate__animated animate__fadeIn">
                    <Typography
                      variant="body1"
                      sx={{
                        color: '#00313E',
                        fontFamily: 'PreloSlabW04SemiBold',
                        fontSize: isMobile ? '17px' : '20px',
                      }}
                      gutterBottom
                      component="div"
                    >
                      Are there intercompany transactions?
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={9}
                    mb={2}
                    sx={{ display: 'flex' }}
                    className="animate__animated animate__fadeIn"
                  >
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                        onChange={(event) =>
                          handleChangeMultiCompany(
                            'intercompanyRequired',
                            event.target.value === '0' ? 'No' : 'Yes',
                            event.target.value
                          )
                        }
                        sx={{
                          color: '#00313E',
                        }}
                      >
                        <FormControlLabel
                          value={1}
                          control={
                            <Radio
                              checked={bcMulticompany?.intercompanyRequired?.value === 1}
                              size="large"
                              sx={{
                                color: '#00313E',
                                '&.Mui-checked': {
                                  color: '#00313E',
                                },
                              }}
                            />
                          }
                          label="Yes"
                        />
                        <FormControlLabel
                          value={0}
                          control={
                            <Radio
                              checked={bcMulticompany?.intercompanyRequired?.value === 0}
                              size="large"
                              sx={{
                                color: '#00313E',
                                '&.Mui-checked': {
                                  color: '#00313E',
                                },
                              }}
                            />
                          }
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </>
              ) : (
                ''
              )}

              {/* -------------------------Question 10 ----------------------------------------------------*/}
              {bcMulticompany?.intercompanyRequired.value === 1 ||
              (bcMulticompany?.intercompanyRequired.value === 0 &&
                bcMulticompany?.multicompany?.value === 1) ? (
                <>
                  <Grid item xs={12} md={9} className="animate__animated animate__fadeIn">
                    <Typography
                      variant="body1"
                      sx={{
                        color: '#00313E',
                        fontFamily: 'PreloSlabW04SemiBold',
                        fontSize: isMobile ? '17px' : '20px',
                      }}
                      gutterBottom
                      component="div"
                    >
                      Do you need consolidated financial reporting?
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={9}
                    mb={2}
                    sx={{ display: 'flex' }}
                    className="animate__animated animate__fadeIn"
                  >
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                        onChange={(event) =>
                          handleChangeMultiCompany(
                            'consolidationRequired',
                            event.target.value === '0' ? 'No' : 'Yes',
                            event.target.value
                          )
                        }
                        sx={{
                          color: '#00313E',
                        }}
                      >
                        <FormControlLabel
                          value={1}
                          control={
                            <Radio
                              checked={bcMulticompany?.consolidationRequired?.value === 1}
                              size="large"
                              sx={{
                                color: '#00313E',
                                '&.Mui-checked': {
                                  color: '#00313E',
                                },
                              }}
                            />
                          }
                          label="Yes"
                        />
                        <FormControlLabel
                          value={0}
                          control={
                            <Radio
                              checked={bcMulticompany?.consolidationRequired?.value === 0}
                              size="large"
                              sx={{
                                color: '#00313E',
                                '&.Mui-checked': {
                                  color: '#00313E',
                                },
                              }}
                            />
                          }
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </>
              ) : (
                ''
              )}

              {/* -------------------------Question 11 ----------------------------------------------------*/}

              {bcMulticompany?.consolidationRequired?.value === 1 ? (
                <>
                  <Grid item xs={12} md={9} className="animate__animated animate__fadeIn">
                    <Typography
                      variant="body1"
                      sx={{
                        color: '#00313E',
                        fontFamily: 'PreloSlabW04SemiBold',
                        fontSize: isMobile ? '17px' : '20px',
                      }}
                      gutterBottom
                      component="div"
                    >
                      How many consolidations are required?
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={9}
                    mb={2}
                    sx={{
                      display: 'flex',
                      flexWrap: isMobile ? 'wrap' : '',
                    }}
                    className="animate__animated animate__fadeIn"
                  >
                    {buttonEleven?.map((item) => (
                      <Button
                        variant="myPrimary"
                        sx={{
                          backgroundColor:
                            bcMulticompany?.noConsolidations?.value !== item.value
                              ? 'white'
                              : '#00313E',
                          width: '100px',
                          height: '60px',
                          borderRadius: '15px',
                          border: '1px solid #00313E',
                          marginRight: isMobile ? '18px' : '40px',
                          marginBottom: '10px',
                          marginTop: '10px',

                          '&:hover': {
                            backgroundColor:
                              bcMulticompany?.noConsolidations?.value !== item.value
                                ? 'white'
                                : '#00313E',
                          },
                        }}
                        value={item.label}
                        onClick={() => {
                          handleChangeMultiCompany('noConsolidations', item.label, item.value);
                        }}
                      >
                        <Typography
                          variant="button"
                          display="block"
                          sx={{
                            color:
                              bcMulticompany?.noConsolidations?.value !== item.value
                                ? '#00313E'
                                : '#FFFFFF',
                            fontFamily: 'DinDIsplayProBold',
                            textTransform: 'none',
                            fontSize: isMobile ? '14px' : '18px',
                          }}
                        >
                          {item.label}
                        </Typography>
                      </Button>
                    ))}
                  </Grid>
                </>
              ) : (
                ''
              )}
            </>
          ) : (
            ''
          )}

          {/* -------------------------Question 12 ----------------------------------------------------*/}
          {bcMulticompany?.multicompany?.value === 0 ||
          bcMulticompany?.consolidationRequired?.value === 0 ||
          bcMulticompany?.noConsolidations?.value ? (
            <>
              <Grid
                item
                xs={12}
                md={9}
                className="animate__animated animate__fadeIn"
                ref={fixedAssetsDivRef}
              >
                <Typography
                  variant="body1"
                  sx={{
                    color: '#00313E',
                    fontFamily: 'PreloSlabW04SemiBold',
                    fontSize: isMobile ? '19px' : '22px',
                  }}
                  gutterBottom
                  component="div"
                >
                  Do you require fixed asset tracking in the ERP solution?
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={9}
                mb={2}
                sx={{ display: 'flex' }}
                className="animate__animated animate__fadeIn"
              >
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    onChange={(event) =>
                      handleChangeFixedAssets(
                        'fixedAssets',
                        event.target.value === '0' ? 'No' : 'Yes',
                        event.target.value
                      )
                    }
                    sx={{
                      color: '#00313E',
                    }}
                  >
                    <FormControlLabel
                      value={1}
                      control={
                        <Radio
                          checked={bcFixedAssets?.fixedAssets?.value === 1}
                          size="large"
                          sx={{
                            color: '#00313E',
                            '&.Mui-checked': {
                              color: '#00313E',
                            },
                          }}
                        />
                      }
                      label="Yes"
                    />
                    <FormControlLabel
                      value={0}
                      control={
                        <Radio
                          checked={bcFixedAssets?.fixedAssets?.value === 0}
                          size="large"
                          sx={{
                            color: '#00313E',
                            '&.Mui-checked': {
                              color: '#00313E',
                            },
                          }}
                        />
                      }
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </>
          ) : (
            ''
          )}
          {/* -------------------------Question 13 ----------------------------------------------------*/}
          {bcFixedAssets?.fixedAssets?.value === 1 ? (
            <>
              <Grid item xs={12} md={9} className="animate__animated animate__fadeIn">
                <Typography
                  variant="body1"
                  sx={{
                    color: '#00313E',
                    fontFamily: 'PreloSlabW04SemiBold',
                    fontSize: isMobile ? '17px' : '20px',
                  }}
                  gutterBottom
                  component="div"
                >
                  Please select the option that best describes the number of fixed assets:
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={9}
                mb={2}
                sx={{
                  display: 'flex',
                  flexWrap: isMobile ? 'wrap' : '',
                }}
                className="animate__animated animate__fadeIn"
              >
                {buttonThirteen?.map((item) => (
                  <Button
                    variant="myPrimary"
                    sx={{
                      backgroundColor:
                        bcFixedAssets?.noFixedAssets?.value !== item.value ? 'white' : '#00313E',
                      width: isMobile ? '150px' : '180px',
                      height: '60px',
                      borderRadius: '15px',
                      border: '1px solid #00313E',
                      marginRight: isMobile ? '18px' : '40px',
                      marginBottom: '10px',
                      marginTop: '10px',

                      '&:hover': {
                        backgroundColor:
                          bcFixedAssets?.noFixedAssets?.value !== item.value ? 'white' : '#00313E',
                      },
                    }}
                    value={item.label}
                    onClick={() => {
                      handleChangeFixedAssets('noFixedAssets', item.label, item.value);
                    }}
                  >
                    <Typography
                      variant="button"
                      display="block"
                      sx={{
                        color:
                          bcFixedAssets?.noFixedAssets?.value !== item.value
                            ? '#00313E'
                            : '#FFFFFF',
                        fontFamily: 'DinDIsplayProBold',
                        textTransform: 'none',
                        fontSize: isMobile ? '14px' : '18px',
                      }}
                    >
                      {item.label}
                    </Typography>
                  </Button>
                ))}
              </Grid>
            </>
          ) : (
            ''
          )}

          {/* -------------------------Question 14 ----------------------------------------------------*/}
          {bcFixedAssets?.fixedAssets?.value === 1 && bcFixedAssets?.noFixedAssets?.value ? (
            <>
              <Grid item xs={12} md={9} className="animate__animated animate__fadeIn">
                <Typography
                  variant="body1"
                  sx={{
                    color: '#00313E',
                    fontFamily: 'PreloSlabW04SemiBold',
                    fontSize: isMobile ? '17px' : '20px',
                  }}
                  gutterBottom
                  component="div"
                >
                  Are there fixed asset components?
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={9}
                mb={2}
                sx={{ display: 'flex' }}
                className="animate__animated animate__fadeIn"
              >
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    onChange={(event) =>
                      handleChangeFixedAssets(
                        'faComponents',
                        event.target.value === '0' ? 'No' : 'Yes',
                        event.target.value
                      )
                    }
                    sx={{
                      color: '#00313E',
                    }}
                  >
                    <FormControlLabel
                      value={1}
                      control={
                        <Radio
                          checked={bcFixedAssets?.faComponents?.value === 1}
                          size="large"
                          sx={{
                            color: '#00313E',
                            '&.Mui-checked': {
                              color: '#00313E',
                            },
                          }}
                        />
                      }
                      label="Yes"
                    />
                    <FormControlLabel
                      value={0}
                      control={
                        <Radio
                          checked={bcFixedAssets?.faComponents?.value === 0}
                          size="large"
                          sx={{
                            color: '#00313E',
                            '&.Mui-checked': {
                              color: '#00313E',
                            },
                          }}
                        />
                      }
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </>
          ) : (
            ''
          )}

          {/* -------------------------Question 15 ----------------------------------------------------*/}
          {bcFixedAssets?.faComponents?.value === 1 ||
          bcFixedAssets?.fixedAssets?.value === 0 ||
          bcFixedAssets?.faComponents?.value === 0 ? (
            <>
              <Grid
                item
                xs={12}
                md={9}
                className="animate__animated animate__fadeIn"
                ref={salesOrdersDivRef}
              >
                <Typography
                  variant="body1"
                  sx={{
                    color: '#00313E',
                    fontFamily: 'PreloSlabW04SemiBold',
                    fontSize: isMobile ? '19px' : '22px',
                  }}
                  gutterBottom
                  component="div"
                >
                  Does your business use Sales Orders?
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={9}
                mb={2}
                sx={{ display: 'flex' }}
                className="animate__animated animate__fadeIn"
              >
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    onChange={(event) =>
                      handleChangeSalesOrder(
                        'salesOrders',
                        event.target.value === '0' ? 'No' : 'Yes',
                        event.target.value
                      )
                    }
                    sx={{
                      color: '#00313E',
                    }}
                  >
                    <FormControlLabel
                      value={1}
                      control={
                        <Radio
                          checked={bcSalesOrder?.salesOrders?.value === 1}
                          size="large"
                          sx={{
                            color: '#00313E',
                            '&.Mui-checked': {
                              color: '#00313E',
                            },
                          }}
                        />
                      }
                      label="Yes"
                    />
                    <FormControlLabel
                      value={0}
                      control={
                        <Radio
                          checked={bcSalesOrder?.salesOrders?.value === 0}
                          size="large"
                          sx={{
                            color: '#00313E',
                            '&.Mui-checked': {
                              color: '#00313E',
                            },
                          }}
                        />
                      }
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </>
          ) : (
            ''
          )}

          {/* -------------------------Question 16 ----------------------------------------------------*/}
          {bcSalesOrder?.salesOrders?.value === 1 ? (
            <>
              <Grid item xs={12} md={9} className="animate__animated animate__fadeIn">
                <Typography
                  variant="body1"
                  sx={{
                    color: '#00313E',
                    fontFamily: 'PreloSlabW04SemiBold',
                    fontSize: isMobile ? '17px' : '20px',
                  }}
                  gutterBottom
                  component="div"
                >
                  About how many Sales Orders are processed each month?
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={9}
                mb={2}
                sx={{
                  display: 'flex',
                  flexWrap: isMobile ? 'wrap' : '',
                }}
                className="animate__animated animate__fadeIn"
              >
                {buttonSixteen?.map((item) => (
                  <Button
                    variant="myPrimary"
                    sx={{
                      backgroundColor:
                        bcSalesOrder?.soCount?.value !== item.value ? 'white' : '#00313E',
                      width: isMobile ? '150px' : '180px',
                      height: '60px',
                      borderRadius: '15px',
                      border: '1px solid #00313E',
                      marginRight: isMobile ? '18px' : '40px',
                      marginBottom: '10px',
                      marginTop: '10px',

                      '&:hover': {
                        backgroundColor:
                          bcSalesOrder?.soCount?.value !== item.value ? 'white' : '#00313E',
                      },
                    }}
                    value={item.label}
                    onClick={() => {
                      handleChangeSalesOrder('soCount', item.label, item.value);
                    }}
                  >
                    <Typography
                      variant="button"
                      display="block"
                      sx={{
                        color: bcSalesOrder?.soCount?.value !== item.value ? '#00313E' : '#FFFFFF',
                        fontFamily: 'DinDIsplayProBold',
                        textTransform: 'none',
                        fontSize: isMobile ? '14px' : '18px',
                      }}
                    >
                      {item.label}
                    </Typography>
                  </Button>
                ))}
              </Grid>
            </>
          ) : (
            ''
          )}

          {/* -------------------------Question 17 ----------------------------------------------------*/}

          {bcSalesOrder?.salesOrders?.value === 0 || bcSalesOrder?.soCount?.value ? (
            <>
              <Grid
                item
                xs={12}
                md={9}
                className="animate__animated animate__fadeIn"
                ref={jobsDivRef}
              >
                <Typography
                  variant="body1"
                  sx={{
                    color: '#00313E',
                    fontFamily: 'PreloSlabW04SemiBold',
                    fontSize: isMobile ? '19px' : '22px',
                  }}
                  gutterBottom
                  component="div"
                >
                  Do you require Jobs or Project Accounting capabilities?
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={9}
                mb={2}
                sx={{ display: 'flex' }}
                className="animate__animated animate__fadeIn"
              >
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    onChange={(event) =>
                      handleChangeJobs(
                        'jobs',
                        event.target.value === '0' ? 'No' : 'Yes',
                        event.target.value
                      )
                    }
                    sx={{
                      color: '#00313E',
                    }}
                  >
                    <FormControlLabel
                      value={1}
                      control={
                        <Radio
                          checked={bcJobs?.jobs?.value === 1}
                          size="large"
                          sx={{
                            color: '#00313E',
                            '&.Mui-checked': {
                              color: '#00313E',
                            },
                          }}
                        />
                      }
                      label="Yes"
                    />
                    <FormControlLabel
                      value={0}
                      control={
                        <Radio
                          checked={bcJobs?.jobs?.value === 0}
                          size="large"
                          sx={{
                            color: '#00313E',
                            '&.Mui-checked': {
                              color: '#00313E',
                            },
                          }}
                        />
                      }
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </>
          ) : (
            ''
          )}

          {/* -------------------------Question 18 ----------------------------------------------------*/}

          {bcJobs?.jobs?.value === 1 ? (
            <>
              <Grid item xs={12} md={9} className="animate__animated animate__fadeIn">
                <Typography
                  variant="body1"
                  sx={{
                    color: '#00313E',
                    fontFamily: 'PreloSlabW04SemiBold',
                    fontSize: isMobile ? '17px' : '20px',
                  }}
                  gutterBottom
                  component="div"
                >
                  Do you have billable jobs?
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={9}
                mb={2}
                sx={{ display: 'flex' }}
                className="animate__animated animate__fadeIn"
              >
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    onChange={(event) =>
                      handleChangeJobs(
                        'billableJobs',
                        event.target.value === '0' ? 'No' : 'Yes',
                        event.target.value
                      )
                    }
                    sx={{
                      color: '#00313E',
                    }}
                  >
                    <FormControlLabel
                      value={1}
                      control={
                        <Radio
                          checked={bcJobs?.billableJobs?.value === 1}
                          size="large"
                          sx={{
                            color: '#00313E',
                            '&.Mui-checked': {
                              color: '#00313E',
                            },
                          }}
                        />
                      }
                      label="Yes"
                    />
                    <FormControlLabel
                      value={0}
                      control={
                        <Radio
                          checked={bcJobs?.billableJobs?.value === 0}
                          size="large"
                          sx={{
                            color: '#00313E',
                            '&.Mui-checked': {
                              color: '#00313E',
                            },
                          }}
                        />
                      }
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </>
          ) : (
            ''
          )}

          {/* -------------------------Question 19 ----------------------------------------------------*/}

          {bcJobs?.billableJobs?.value === 1 ||
          bcJobs?.billableJobs?.value === 0 ||
          bcJobs?.jobs?.value === 0 ? (
            <>
              <Grid
                item
                xs={12}
                md={9}
                className="animate__animated animate__fadeIn"
                ref={inventoryDivRef}
              >
                <Typography
                  variant="body1"
                  sx={{
                    color: '#00313E',
                    fontFamily: 'PreloSlabW04SemiBold',
                    fontSize: isMobile ? '19px' : '22px',
                  }}
                  gutterBottom
                  component="div"
                >
                  Do you require Inventory management, Assembly management or Manufacturing
                  capabilities?
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={9}
                mb={2}
                sx={{ display: 'flex' }}
                className="animate__animated animate__fadeIn"
              >
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    onChange={(event) =>
                      handleChangeInventory(
                        'inventoryItems',
                        event.target.value === '0' ? 'No' : 'Yes',
                        event.target.value
                      )
                    }
                    sx={{
                      color: '#00313E',
                    }}
                  >
                    <FormControlLabel
                      value={1}
                      control={
                        <Radio
                          checked={bcInventory?.inventoryItems?.value === 1}
                          size="large"
                          sx={{
                            color: '#00313E',
                            '&.Mui-checked': {
                              color: '#00313E',
                            },
                          }}
                        />
                      }
                      label="Yes"
                    />
                    <FormControlLabel
                      value={0}
                      control={
                        <Radio
                          checked={bcInventory?.inventoryItems?.value === 0}
                          size="large"
                          sx={{
                            color: '#00313E',
                            '&.Mui-checked': {
                              color: '#00313E',
                            },
                          }}
                        />
                      }
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </>
          ) : (
            ''
          )}
          {bcInventory?.inventoryItems?.value === 1 ? (
            <>
              {' '}
              {/* -------------------------Question 20 ----------------------------------------------------*/}
              {bcInventory?.inventoryItems?.value === 1 ? (
                <>
                  <Grid item xs={12} md={9} className="animate__animated animate__fadeIn">
                    <Typography
                      variant="body1"
                      sx={{
                        color: '#00313E',
                        fontFamily: 'DinDIsplayProRegular',
                        fontSize: isMobile ? '17px' : '20px',
                        display: 'flex',
                        alignItems: 'center',
                        opacity: '60%',
                      }}
                      gutterBottom
                      component="div"
                    >
                      Inventory management
                    </Typography>

                    <Typography
                      variant="body1"
                      sx={{
                        color: '#00313E',
                        fontFamily: 'PreloSlabW04SemiBold',
                        fontSize: isMobile ? '17px' : '20px',
                      }}
                      gutterBottom
                      component="div"
                    >
                      Select the option below that best describes the number of inventory items
                      (SKUs)?
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={9}
                    mb={2}
                    sx={{
                      display: 'flex',
                      flexWrap: isMobile ? 'wrap' : '',
                    }}
                    className="animate__animated animate__fadeIn"
                  >
                    {buttonTwenty?.map((item) => (
                      <Button
                        variant="myPrimary"
                        sx={{
                          backgroundColor:
                            bcInventory?.noSku?.value !== item.value ? 'white' : '#00313E',
                          width: isMobile ? '150px' : '180px',
                          height: '60px',
                          borderRadius: '15px',
                          border: '1px solid #00313E',
                          marginRight: isMobile ? '18px' : '40px',
                          marginBottom: '10px',
                          marginTop: '10px',

                          '&:hover': {
                            backgroundColor:
                              bcInventory?.noSku?.value !== item.value ? 'white' : '#00313E',
                          },
                        }}
                        value={item.label}
                        onClick={() => {
                          handleChangeInventory('noSku', item.label, item.value);
                        }}
                      >
                        <Typography
                          variant="button"
                          display="block"
                          sx={{
                            color: bcInventory?.noSku?.value !== item.value ? '#00313E' : '#FFFFFF',
                            fontFamily: 'DinDIsplayProBold',
                            textTransform: 'none',
                            fontSize: isMobile ? '14px' : '18px',
                          }}
                        >
                          {item.label}
                        </Typography>
                      </Button>
                    ))}
                  </Grid>
                </>
              ) : (
                ''
              )}
              {/* -------------------------Question 21----------------------------------------------------*/}
              {bcInventory?.noSku?.value ? (
                <>
                  <Grid item xs={12} md={9} className="animate__animated animate__fadeIn">
                    <Typography
                      variant="body1"
                      sx={{
                        color: '#00313E',
                        fontFamily: 'PreloSlabW04SemiBold',
                        fontSize: isMobile ? '17px' : '20px',
                      }}
                      gutterBottom
                      component="div"
                    >
                      Do you require lot tracking?
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={9}
                    mb={2}
                    sx={{ display: 'flex' }}
                    className="animate__animated animate__fadeIn"
                  >
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                        onChange={(event) =>
                          handleChangeInventory(
                            'lotTracking',
                            event.target.value === '1' ? 'No' : 'Yes',
                            event.target.value
                          )
                        }
                        sx={{
                          color: '#00313E',
                        }}
                      >
                        <FormControlLabel
                          value={2}
                          control={
                            <Radio
                              checked={bcInventory?.lotTracking?.value === 2}
                              size="large"
                              sx={{
                                color: '#00313E',
                                '&.Mui-checked': {
                                  color: '#00313E',
                                },
                              }}
                            />
                          }
                          label="Yes"
                        />
                        <FormControlLabel
                          value={1}
                          control={
                            <Radio
                              checked={bcInventory?.lotTracking?.value === 1}
                              size="large"
                              sx={{
                                color: '#00313E',
                                '&.Mui-checked': {
                                  color: '#00313E',
                                },
                              }}
                            />
                          }
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </>
              ) : (
                ''
              )}
              {/* -------------------------Question 22----------------------------------------------------*/}
              {bcInventory?.lotTracking.value === 2 || bcInventory?.lotTracking.value === 1 ? (
                <>
                  <Grid item xs={12} md={9} className="animate__animated animate__fadeIn">
                    <Typography
                      variant="body1"
                      sx={{
                        color: '#00313E',
                        fontFamily: 'PreloSlabW04SemiBold',
                        fontSize: isMobile ? '17px' : '20px',
                      }}
                      gutterBottom
                      component="div"
                    >
                      How many warehouses are there?
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={9}
                    mb={2}
                    sx={{
                      display: 'flex',
                      flexWrap: isMobile ? 'wrap' : '',
                    }}
                    className="animate__animated animate__fadeIn"
                  >
                    {buttonEleven?.map((item) => (
                      <Button
                        variant="myPrimary"
                        sx={{
                          backgroundColor:
                            bcInventory?.noWarehouses?.value !== item.value ? 'white' : '#00313E',
                          width: '100px',
                          height: '60px',
                          borderRadius: '15px',
                          border: '1px solid #00313E',
                          marginRight: isMobile ? '18px' : '40px',
                          marginBottom: '10px',
                          marginTop: '10px',

                          '&:hover': {
                            backgroundColor:
                              bcInventory?.noWarehouses?.value !== item.value ? 'white' : '#00313E',
                          },
                        }}
                        value={item.label}
                        onClick={() => {
                          handleChangeInventory('noWarehouses', item.label, item.value);
                        }}
                      >
                        <Typography
                          variant="button"
                          display="block"
                          sx={{
                            color:
                              bcInventory?.noWarehouses?.value !== item.value
                                ? '#00313E'
                                : '#FFFFFF',
                            fontFamily: 'DinDIsplayProBold',
                            textTransform: 'none',
                            fontSize: isMobile ? '14px' : '18px',
                          }}
                        >
                          {item.label}
                        </Typography>
                      </Button>
                    ))}
                  </Grid>
                </>
              ) : (
                ''
              )}
              {/* -------------------------Question 23----------------------------------------------------*/}
              {bcInventory?.noWarehouses?.value ? (
                <>
                  <Grid item xs={12} md={9} className="animate__animated animate__fadeIn">
                    <Typography
                      variant="body1"
                      sx={{
                        color: '#00313E',
                        fontFamily: 'PreloSlabW04SemiBold',
                        fontSize: isMobile ? '17px' : '20px',
                      }}
                      gutterBottom
                      component="div"
                    >
                      Do you require warehouse logistics (e.g., pick, pack, and put away)
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={9}
                    mb={2}
                    sx={{ display: 'flex' }}
                    className="animate__animated animate__fadeIn"
                  >
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                        onChange={(event) =>
                          handleChangeInventory(
                            'warehouseLogistics',
                            event.target.value === '1' ? 'No' : 'Yes',
                            event.target.value
                          )
                        }
                        sx={{
                          color: '#00313E',
                        }}
                      >
                        <FormControlLabel
                          value={2}
                          control={
                            <Radio
                              checked={bcInventory?.warehouseLogistics?.value === 2}
                              size="large"
                              sx={{
                                color: '#00313E',
                                '&.Mui-checked': {
                                  color: '#00313E',
                                },
                              }}
                            />
                          }
                          label="Yes"
                        />
                        <FormControlLabel
                          value={1}
                          control={
                            <Radio
                              checked={bcInventory?.warehouseLogistics?.value === 1}
                              size="large"
                              sx={{
                                color: '#00313E',
                                '&.Mui-checked': {
                                  color: '#00313E',
                                },
                              }}
                            />
                          }
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </>
              ) : (
                ''
              )}
              {/* -------------------------Question 24----------------------------------------------------*/}
              <div
                style={{ width: '100%', paddingLeft: '16px', paddingTop: '16px' }}
                ref={assemblyDivRef}
              >
                {bcInventory?.warehouseLogistics.value === 2 ||
                bcInventory?.warehouseLogistics.value === 1 ? (
                  <>
                    <Grid item xs={12} md={9} className="animate__animated animate__fadeIn">
                      <Typography
                        variant="body1"
                        sx={{
                          color: '#00313E',
                          fontFamily: 'PreloSlabW04SemiBold',
                          fontSize: isMobile ? '19px' : '22px',
                        }}
                        gutterBottom
                        component="div"
                      >
                        Do you require assembly management?
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={9}
                      mb={2}
                      mt={2}
                      sx={{ display: 'flex' }}
                      className="animate__animated animate__fadeIn"
                    >
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="demo-radio-buttons-group-label"
                          name="radio-buttons-group"
                          onChange={(event) =>
                            handleChangeAssembly(
                              'assemblyMgmt',
                              event.target.value === '0' ? 'No' : 'Yes',
                              event.target.value
                            )
                          }
                          sx={{
                            color: '#00313E',
                          }}
                        >
                          <FormControlLabel
                            value={1}
                            control={
                              <Radio
                                checked={bcAssemblyManagement?.assemblyMgmt?.value === 1}
                                size="large"
                                sx={{
                                  color: '#00313E',
                                  '&.Mui-checked': {
                                    color: '#00313E',
                                  },
                                }}
                              />
                            }
                            label="Yes"
                          />
                          <FormControlLabel
                            value={0}
                            control={
                              <Radio
                                checked={bcAssemblyManagement?.assemblyMgmt?.value === 0}
                                size="large"
                                sx={{
                                  color: '#00313E',
                                  '&.Mui-checked': {
                                    color: '#00313E',
                                  },
                                }}
                              />
                            }
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </>
                ) : (
                  ''
                )}
              </div>
              {/* -------------------------Question 25 ----------------------------------------------------*/}
              {bcAssemblyManagement?.assemblyMgmt?.value === 1 ? (
                <>
                  <Grid item xs={12} md={9} className="animate__animated animate__fadeIn">
                    <Typography
                      variant="body1"
                      sx={{
                        color: '#00313E',
                        fontFamily: 'DinDIsplayProRegular',
                        fontSize: isMobile ? '17px' : '20px',
                        display: 'flex',
                        alignItems: 'center',
                        opacity: '60%',
                      }}
                      gutterBottom
                      component="div"
                    >
                      Assembly management
                    </Typography>

                    <Typography
                      variant="body1"
                      sx={{
                        color: '#00313E',
                        fontFamily: 'PreloSlabW04SemiBold',
                        fontSize: isMobile ? '17px' : '20px',
                      }}
                      gutterBottom
                      component="div"
                    >
                      About how many assembly BOMs are there?
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={9}
                    mb={2}
                    sx={{
                      display: 'flex',
                      flexWrap: isMobile ? 'wrap' : '',
                    }}
                    className="animate__animated animate__fadeIn"
                  >
                    {buttonTwentyFive?.map((item) => (
                      <Button
                        variant="myPrimary"
                        sx={{
                          backgroundColor:
                            bcAssemblyManagement?.noAboms?.value !== item.value
                              ? 'white'
                              : '#00313E',
                          width: isMobile ? '150px' : '180px',
                          height: '60px',
                          borderRadius: '15px',
                          border: '1px solid #00313E',
                          marginRight: isMobile ? '18px' : '40px',
                          marginBottom: '10px',
                          marginTop: '10px',

                          '&:hover': {
                            backgroundColor:
                              bcAssemblyManagement?.noAboms?.value !== item.value
                                ? 'white'
                                : '#00313E',
                          },
                        }}
                        value={item.label}
                        onClick={() => {
                          handleChangeAssembly('noAboms', item.label, item.value);
                        }}
                      >
                        <Typography
                          variant="button"
                          display="block"
                          sx={{
                            color:
                              bcAssemblyManagement?.noAboms?.value !== item.value
                                ? '#00313E'
                                : '#FFFFFF',
                            fontFamily: 'DinDIsplayProBold',
                            textTransform: 'none',
                            fontSize: isMobile ? '14px' : '18px',
                          }}
                        >
                          {item.label}
                        </Typography>
                      </Button>
                    ))}
                  </Grid>
                </>
              ) : (
                ''
              )}
              {/* -------------------------Question 26----------------------------------------------------*/}
              {bcAssemblyManagement?.noAboms?.value &&
              bcAssemblyManagement?.assemblyMgmt?.value === 1 ? (
                <>
                  <Grid item xs={12} md={9} className="animate__animated animate__fadeIn">
                    <Typography
                      variant="body1"
                      sx={{
                        color: '#00313E',
                        fontFamily: 'PreloSlabW04SemiBold',
                        fontSize: isMobile ? '17px' : '20px',
                      }}
                      gutterBottom
                      component="div"
                    >
                      Do you require EDI functionality to integrate with supplier systems?
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={9}
                    mb={2}
                    sx={{ display: 'flex' }}
                    className="animate__animated animate__fadeIn"
                  >
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                        onChange={(event) =>
                          handleChangeAssembly(
                            'edi',
                            event.target.value === '0' ? 'No' : 'Yes',
                            event.target.value
                          )
                        }
                        sx={{
                          color: '#00313E',
                        }}
                      >
                        <FormControlLabel
                          value={1}
                          control={
                            <Radio
                              checked={bcAssemblyManagement?.edi?.value === 1}
                              size="large"
                              sx={{
                                color: '#00313E',
                                '&.Mui-checked': {
                                  color: '#00313E',
                                },
                              }}
                            />
                          }
                          label="Yes"
                        />
                        <FormControlLabel
                          value={0}
                          control={
                            <Radio
                              checked={bcAssemblyManagement?.edi?.value === 0}
                              size="large"
                              sx={{
                                color: '#00313E',
                                '&.Mui-checked': {
                                  color: '#00313E',
                                },
                              }}
                            />
                          }
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </>
              ) : (
                ''
              )}
              {/* -------------------------Question 27----------------------------------------------------*/}
              {bcAssemblyManagement?.edi?.value === 1 ||
              bcAssemblyManagement?.edi?.value === 0 ||
              bcAssemblyManagement?.assemblyMgmt?.value === 0 ? (
                <>
                  <Grid
                    item
                    xs={12}
                    md={9}
                    className="animate__animated animate__fadeIn"
                    ref={manufacturingDivRef}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        color: '#00313E',
                        fontFamily: 'PreloSlabW04SemiBold',
                        fontSize: isMobile ? '19px' : '22px',
                      }}
                      gutterBottom
                      component="div"
                    >
                      Do you require manufacturing capabilities?
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={9}
                    mb={2}
                    sx={{ display: 'flex' }}
                    className="animate__animated animate__fadeIn"
                  >
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                        onChange={(event) =>
                          handleChangeManufacturing(
                            'manufacturing',
                            event.target.value === '0' ? 'No' : 'Yes',
                            event.target.value
                          )
                        }
                        sx={{
                          color: '#00313E',
                        }}
                      >
                        <FormControlLabel
                          value={1}
                          control={
                            <Radio
                              checked={bcManufacturing?.manufacturing?.value === 1}
                              size="large"
                              sx={{
                                color: '#00313E',
                                '&.Mui-checked': {
                                  color: '#00313E',
                                },
                              }}
                            />
                          }
                          label="Yes"
                        />
                        <FormControlLabel
                          value={0}
                          control={
                            <Radio
                              checked={bcManufacturing?.manufacturing?.value === 0}
                              size="large"
                              sx={{
                                color: '#00313E',
                                '&.Mui-checked': {
                                  color: '#00313E',
                                },
                              }}
                            />
                          }
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </>
              ) : (
                ''
              )}
              {/* -------------------------Question 28 ----------------------------------------------------*/}
              {bcManufacturing?.manufacturing?.value === 1 ? (
                <>
                  <Grid item xs={12} md={9} className="animate__animated animate__fadeIn">
                    <Typography
                      variant="body1"
                      sx={{
                        color: '#00313E',
                        fontFamily: 'PreloSlabW04SemiBold',
                        fontSize: isMobile ? '17px' : '20px',
                      }}
                      gutterBottom
                      component="div"
                    >
                      About how many routings are there?
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={9}
                    mb={2}
                    sx={{
                      display: 'flex',
                      flexWrap: isMobile ? 'wrap' : '',
                    }}
                    className="animate__animated animate__fadeIn"
                  >
                    {buttonTwentyEight?.map((item) => (
                      <Button
                        variant="myPrimary"
                        sx={{
                          backgroundColor:
                            bcManufacturing?.routings?.value !== item.value ? 'white' : '#00313E',
                          width: isMobile ? '150px' : '180px',
                          height: '60px',
                          borderRadius: '15px',
                          border: '1px solid #00313E',
                          marginRight: isMobile ? '18px' : '40px',
                          marginBottom: '10px',
                          marginTop: '10px',

                          '&:hover': {
                            backgroundColor:
                              bcManufacturing?.routings?.value !== item.value ? 'white' : '#00313E',
                          },
                        }}
                        value={item.label}
                        onClick={() => {
                          handleChangeManufacturing('routings', item.label, item.value);
                        }}
                      >
                        <Typography
                          variant="button"
                          display="block"
                          sx={{
                            color:
                              bcManufacturing?.routings?.value !== item.value
                                ? '#00313E'
                                : '#FFFFFF',
                            fontFamily: 'DinDIsplayProBold',
                            textTransform: 'none',
                            fontSize: isMobile ? '14px' : '18px',
                          }}
                        >
                          {item.label}
                        </Typography>
                      </Button>
                    ))}
                  </Grid>
                </>
              ) : (
                ''
              )}
              {/* -------------------------Question 29 ----------------------------------------------------*/}
              {bcManufacturing?.routings?.value && bcManufacturing?.manufacturing?.value === 1 ? (
                <>
                  <Grid item xs={12} md={9} className="animate__animated animate__fadeIn">
                    <Typography
                      variant="body1"
                      sx={{
                        color: '#00313E',
                        fontFamily: 'PreloSlabW04SemiBold',
                        fontSize: isMobile ? '17px' : '20px',
                      }}
                      gutterBottom
                      component="div"
                    >
                      About how many work centers are there?
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={9}
                    mb={2}
                    sx={{
                      display: 'flex',
                      flexWrap: isMobile ? 'wrap' : '',
                    }}
                    className="animate__animated animate__fadeIn"
                  >
                    {buttonTwentyNine?.map((item) => (
                      <Button
                        variant="myPrimary"
                        sx={{
                          backgroundColor:
                            bcManufacturing?.workCenters?.value !== item.value
                              ? 'white'
                              : '#00313E',
                          width: isMobile ? '150px' : '180px',
                          height: '60px',
                          borderRadius: '15px',
                          border: '1px solid #00313E',
                          marginRight: isMobile ? '18px' : '40px',
                          marginBottom: '10px',
                          marginTop: '10px',

                          '&:hover': {
                            backgroundColor:
                              bcManufacturing?.workCenters?.value !== item.value
                                ? 'white'
                                : '#00313E',
                          },
                        }}
                        value={item.label}
                        onClick={() => {
                          handleChangeManufacturing('workCenters', item.label, item.value);
                        }}
                      >
                        <Typography
                          variant="button"
                          display="block"
                          sx={{
                            color:
                              bcManufacturing?.workCenters?.value !== item.value
                                ? '#00313E'
                                : '#FFFFFF',
                            fontFamily: 'DinDIsplayProBold',
                            textTransform: 'none',
                            fontSize: isMobile ? '14px' : '18px',
                          }}
                        >
                          {item.label}
                        </Typography>
                      </Button>
                    ))}
                  </Grid>
                </>
              ) : (
                ''
              )}
              {/* -------------------------Question 30 ----------------------------------------------------*/}
              {bcManufacturing?.workCenters?.value &&
              bcManufacturing?.manufacturing?.value === 1 ? (
                <>
                  <Grid item xs={12} md={9} className="animate__animated animate__fadeIn">
                    <Typography
                      variant="body1"
                      sx={{
                        color: '#00313E',
                        fontFamily: 'PreloSlabW04SemiBold',
                        fontSize: isMobile ? '17px' : '20px',
                      }}
                      gutterBottom
                      component="div"
                    >
                      How many different production locations are there?
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={9}
                    mb={2}
                    sx={{
                      display: 'flex',
                      flexWrap: isMobile ? 'wrap' : '',
                    }}
                    className="animate__animated animate__fadeIn"
                  >
                    {buttonThirty?.map((item) => (
                      <Button
                        variant="myPrimary"
                        sx={{
                          backgroundColor:
                            bcManufacturing?.prodLocations?.value !== item.value
                              ? 'white'
                              : '#00313E',
                          width: isMobile ? '150px' : '180px',
                          height: '60px',
                          borderRadius: '15px',
                          border: '1px solid #00313E',
                          marginRight: isMobile ? '18px' : '40px',
                          marginBottom: '10px',
                          marginTop: '10px',

                          '&:hover': {
                            backgroundColor:
                              bcManufacturing?.prodLocations?.value !== item.value
                                ? 'white'
                                : '#00313E',
                          },
                        }}
                        value={item.label}
                        onClick={() => {
                          handleChangeManufacturing('prodLocations', item.label, item.value);
                        }}
                      >
                        <Typography
                          variant="button"
                          display="block"
                          sx={{
                            color:
                              bcManufacturing?.prodLocations?.value !== item.value
                                ? '#00313E'
                                : '#FFFFFF',
                            fontFamily: 'DinDIsplayProBold',
                            textTransform: 'none',
                            fontSize: isMobile ? '14px' : '18px',
                          }}
                        >
                          {item.label}
                        </Typography>
                      </Button>
                    ))}
                  </Grid>
                </>
              ) : (
                ''
              )}
              {/* -------------------------Question 31 ----------------------------------------------------*/}
              {bcManufacturing?.prodLocations?.value &&
              bcManufacturing?.manufacturing?.value === 1 ? (
                <>
                  <Grid item xs={12} md={9} className="animate__animated animate__fadeIn">
                    <Typography
                      variant="body1"
                      sx={{
                        color: '#00313E',
                        fontFamily: 'PreloSlabW04SemiBold',
                        fontSize: isMobile ? '17px' : '20px',
                      }}
                      gutterBottom
                      component="div"
                    >
                      About how many manufacturing BOMs are there?
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={9}
                    mb={2}
                    sx={{
                      display: 'flex',
                      flexWrap: isMobile ? 'wrap' : '',
                    }}
                    className="animate__animated animate__fadeIn"
                  >
                    {buttonTwentyFive?.map((item) => (
                      <Button
                        variant="myPrimary"
                        sx={{
                          backgroundColor:
                            bcManufacturing?.mBoms?.value !== item.value ? 'white' : '#00313E',
                          width: isMobile ? '150px' : '180px',
                          height: '60px',
                          borderRadius: '15px',
                          border: '1px solid #00313E',
                          marginRight: isMobile ? '18px' : '40px',
                          marginBottom: '10px',
                          marginTop: '10px',

                          '&:hover': {
                            backgroundColor:
                              bcManufacturing?.mBoms?.value !== item.value ? 'white' : '#00313E',
                          },
                        }}
                        value={item.label}
                        onClick={() => {
                          handleChangeManufacturing('mBoms', item.label, item.value);
                        }}
                      >
                        <Typography
                          variant="button"
                          display="block"
                          sx={{
                            color:
                              bcManufacturing?.mBoms?.value !== item.value ? '#00313E' : '#FFFFFF',
                            fontFamily: 'DinDIsplayProBold',
                            textTransform: 'none',
                            fontSize: isMobile ? '14px' : '18px',
                          }}
                        >
                          {item.label}
                        </Typography>
                      </Button>
                    ))}
                  </Grid>
                </>
              ) : (
                ''
              )}
              {/* -------------------------Question 32----------------------------------------------------*/}
              {bcManufacturing?.mBoms?.value && bcManufacturing?.manufacturing?.value === 1 ? (
                <>
                  <Grid item xs={12} md={9} className="animate__animated animate__fadeIn">
                    <Typography
                      variant="body1"
                      sx={{
                        color: '#00313E',
                        fontFamily: 'PreloSlabW04SemiBold',
                        fontSize: isMobile ? '17px' : '20px',
                      }}
                      gutterBottom
                      component="div"
                    >
                      Do you require EDI functionality to integrate with supplier systems?
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={9}
                    mb={2}
                    sx={{ display: 'flex' }}
                    className="animate__animated animate__fadeIn"
                  >
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                        onChange={(event) =>
                          handleChangeManufacturing(
                            'ediM',
                            event.target.value === '0' ? 'No' : 'Yes',
                            event.target.value
                          )
                        }
                        sx={{
                          color: '#00313E',
                        }}
                      >
                        <FormControlLabel
                          value={1}
                          control={
                            <Radio
                              checked={bcManufacturing?.ediM?.value === 1}
                              size="large"
                              sx={{
                                color: '#00313E',
                                '&.Mui-checked': {
                                  color: '#00313E',
                                },
                              }}
                            />
                          }
                          label="Yes"
                        />
                        <FormControlLabel
                          value={0}
                          control={
                            <Radio
                              checked={bcManufacturing?.ediM?.value === 0}
                              size="large"
                              sx={{
                                color: '#00313E',
                                '&.Mui-checked': {
                                  color: '#00313E',
                                },
                              }}
                            />
                          }
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </>
              ) : (
                ''
              )}{' '}
            </>
          ) : (
            ''
          )}

          {/* -------------------------Question Grid End----------------------------------------------------*/}
        </Grid>
      </Container>

      <div
        style={{
          background: isMobile ? 'white' : '',
          height: isMobile ? '100px' : '70px',
          position: isMobile ? 'fixed' : '',
          bottom: '0px',
          width: '100%',
        }}
      >
        {state?.focus ? (
          <Grid
            item
            xs={12}
            md={3}
            direction="row"
            sx={{
              display: 'flex',
              justifyContent: isMobile ? 'center' : 'flex-end',
              alignItems: 'center',
              position: isMobile || isIpad ? '' : 'fixed',
              right: '25px',
              bottom: '25px',
              width: '100%',
              paddingLeft: 0,
              height: isMobile ? '100%' : '120px',
            }}
          >
            <Button
              variant="primary"
              sx={{
                borderRadius: 1,
                border: '1px solid #CFCFCF',
                height: '60px',
                width: '180px',
                marginRight: '15px',
              }}
              onClick={handleCancel}
            >
              <Typography
                variant="button"
                sx={{ fontFamily: 'DinDIsplayProBold', color: '#00313E' }}
              >
                Cancel
              </Typography>
            </Button>

            <LoadingButton
              variant="contained"
              color="neutral"
              loading={loading}
              loadingPosition="end"
              sx={{
                borderRadius: 1,
                backgroundColor: '#00313E',
                height: '60px',
                width: '130px',
              }}
              onClick={() => handleSave()}
            >
              <Typography variant="button" sx={{ fontFamily: 'DinDIsplayProBold' }}>
                Save
              </Typography>
            </LoadingButton>
          </Grid>
        ) : (
          <Grid
            item
            xs={12}
            md={3}
            direction="row"
            sx={{
              display: 'flex',
              justifyContent: isMobile ? 'center' : 'flex-end',
              alignItems: 'center',
              position: isMobile || isIpad ? '' : 'fixed',
              right: '25px',
              bottom: '25px',
              width: '100%',
              paddingLeft: 0,
              height: isMobile ? '100%' : '120px',
            }}
          >
            <Button
              variant="primary"
              sx={{
                borderRadius: 1,
                border: '1px solid #CFCFCF',
                height: '60px',
                width: '180px',
                marginRight: '15px',
              }}
              onClick={() => {
                modalRef.current();
                handleSaveData();
              }}
            >
              <Typography
                variant="button"
                sx={{ fontFamily: 'DinDIsplayProBold', color: '#00313E' }}
              >
                Save for later
              </Typography>
            </Button>

            <Button
              variant="contained"
              color="neutral"
              disabled={!show}
              sx={{
                borderRadius: 1,
                backgroundColor: '#00313E',
                height: '60px',
                width: '130px',
              }}
              onClick={() => handleNext()}
            >
              <Typography variant="button" sx={{ fontFamily: 'DinDIsplayProBold' }}>
                Next
              </Typography>
            </Button>
          </Grid>
        )}
      </div>
      <SaveForLater check={check} modalRef={modalRef} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    businessCentral: state?.businessCentral?.businessCentral,
    stepsStatus: state?.home?.stepsStatus,
    code: state?.pricingCode?.code,
    hyperPackage: state?.saveSupportPackage?.supportPackage,
    saveForLaterCode: state?.saveLater?.code,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveBusinessCentral: (payload) => dispatch(saveBusinessCentral(payload)),
    setStepsStatus: (payload) => dispatch(setStepsStatus(payload)),
    saveSupport: (payload) => dispatch(saveSupport(payload)),
  };
};

// export default BusinessCentral;
export default connect(mapStateToProps, mapDispatchToProps)(BusinessCentral);
