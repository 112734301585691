const conditionalRoute = (value) => {

  switch (value) {
    case '1':
      return 'business-central';
    case '2':
      return 'sales';

    case '3':
      return 'marketing';

    case '4':
      return 'customer-service';

    default:
      return 'business-central';
  }
};

export default conditionalRoute;
