/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable default-case */
/* eslint-disable jsx-a11y/alt-text */
// / eslint-disable jsx-a11y/alt-text /
// / eslint-disable no-plusplus /
import { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import { Stack, Container, Grid, Checkbox, Button } from '@mui/material';
import { connect, useSelector, useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import conditionalRoute from '../Routes/ConditionalRoutes';

// redux
import { setSelectedProducts } from '../redux/product/product.actions';
import { setStepsStatus } from '../redux/home/home.actions';

import circle from '../assets/circle.svg';
import tickblack from '../assets/tickblack.svg';

const useStyles = makeStyles({
  btnMain: {
    '&:hover': {
      backgroundColor: '#055469',
    },
  },
  btnSeconday: {
    border: '1px solid #FFFFFF',
    '&:hover': {
      borderColor: '#F5F5F5',
    },
  },
});

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const NoteCard = (props) => {
  const { product } = useSelector((s) => s);
  const dispatch = useDispatch();
  const classes = useStyles();

  const navigate = useNavigate();

  const isMobile = useMediaQuery({ query: `(max-width: 560px)` });

  const { stepsStatus } = props;
  const [BundleData, setBundleData] = useState([
    {
      id: '1',
      title: 'Dynamics 365 Business Central',
      desc: 'Streamline and bring control to financial accounting, inventory, production, distribution and jobs with a powerful cloud ERP solution.',
    },
    {
      id: '2',
      title: 'Dynamics 365 Sales',
      desc: 'Empower your sales team, accelerate sales, manage opportunities, forecast revenue, and close more deals.',
    },
    {
      id: '3',
      title: 'Dynamics 365 Marketing',
      desc: ' Engage target audiences, create personalized experiences with automated nurture campaigns, and track performance and engagement in real-time.',
    },
    {
      id: '4',
      title: 'Dynamics 365 Customer Service',
      desc: 'Build customer loyalty and exceed expectations. Manage customer requests and cases, create work orders, track interactions, and resolve issues faster.',
    },
  ]);

  const ifProductsExist = product.selectedProducts.length > 0 ? product.selectedProducts : [];
  const [selectedProducts, setProducts] = useState(ifProductsExist);

  const modifyProductsArr = (productName) => {
    const test = selectedProducts.find((el) => el === productName)
      ? setProducts(selectedProducts.filter((el) => el !== productName))
      : setProducts(() => [...selectedProducts, productName]);
  };

  const changeCentralCardColor = (data) => {
    modifyProductsArr(data?.id);
  };

  const handleNext = () => {
    stepsStatus[0].product = { active: false, completed: true };
    props.setStepsStatus(stepsStatus);
    props.setSelectedProducts(selectedProducts);
    const sortProducts = selectedProducts.sort((a, b) => {
      return a - b;
    });
    navigate(`/business-details/${conditionalRoute(sortProducts[0])}`);
  };

  useEffect(() => {
    // eslint-disable-next-line no-plusplus
    stepsStatus[0].product.active = true;
    props.setStepsStatus(stepsStatus);
  }, []);

  return (
    <div className="web web-new">
      <Container sx={{ my: 1, py: 1 }}>
        <Grid item xs={12}>
          <Typography
            variant="h4"
            sx={{
              color: '#00313E',
              fontFamily: 'PreloSlabW04Bold',
              textAlign: 'center',
              fontSize: isMobile ? '28px' : '40px',
              marginTop: isMobile ? '30px' : '10px',
              marginBottom: '25px',
            }}
          >
            Please select the Microsoft Dynamics products to include
          </Typography>
        </Grid>
        <Grid container spacing={3} xs={12} justifyContent="center" className="heading" m={0} >

          {BundleData &&
            BundleData.map((data) => {
              return (
                <div>
                  <Paper
                    elevation={0}
                    sx={{
                      padding: 2,
                      margin: "10px",
                      border: '1px solid #B2B4B3',
                      borderRadius: 5,
                      backgroundColor: selectedProducts.includes(data.id) ? '#00313E' : '#FFFFFF',
                      height: '350px',
                      width: '230px',
                    }}
                  >
                    <Stack direction="row" alignItems="flex-start">
                      <Typography
                        variant="h6"
                        sx={{
                          color: selectedProducts.includes(data.id) ? '#FFFFFF' : '#00313E',
                          fontFamily: 'PreloSlabW04Bold',
                          marginTop: '3px',
                          fontSize: '22px',
                          fontWeight: 400,
                        }}
                      >
                        {data.title}
                      </Typography>

                      <Checkbox
                        checked={selectedProducts.includes(data.id)}
                        inputProps={{ 'aria-label': 'controlled' }}
                        onChange={(e) => changeCentralCardColor(data, e)}
                        icon={<img src={circle} />}
                        checkedIcon={<img src={tickblack} />}
                      />
                    </Stack>

                    <Typography
                      variant="body2"
                      sx={{
                        color: selectedProducts.includes(data.id) ? '#FFFFFF' : '#00313E',
                        fontFamily: 'DinDIsplayProRegular',
                        fontSize: '19px',
                        lineHeight: 2,
                        marginY: 2,
                      }}
                    >
                      {data.desc}
                    </Typography>
                  </Paper>
                </div>
              );
            })}
        </Grid>
      </Container>

      {selectedProducts && selectedProducts.length > 0 ? (
        <Grid
          item
          xs={12}
          sx={{ py: 2 }}
          container
          direction="row"
          justifyContent="end"
          alignItems="center"
        >
          <Button
            variant="contained"
            color="neutral"
            sx={{
              borderRadius: 1,
              position: 'sticky',
              right: 40,
              padding: '15px 40px',
            }}
            onClick={handleNext}
          >
            <Typography variant="button" sx={{ fontFamily: 'DinDIsplayProBold' }}>
              Next
            </Typography>
          </Button>
        </Grid>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    stepsStatus: state.home.stepsStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedProducts: (payload) => dispatch(setSelectedProducts(payload)),
    setStepsStatus: (payload) => dispatch(setStepsStatus(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NoteCard);
