/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
import { useState, useEffect, useRef } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Container, Stack } from '@mui/material';
import { Link } from 'react-scroll';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';
import BannerImage from '../assets/logo-overlay.png';
import NavbarTab from '../Components/Navbar/NavbarTab';
import NoteCard from '../Components/NoteCard';
import exit from '../assets/exit.svg'
import catapultLogo from '../assets/catapult-mobile.svg'
import SaveForLater from '../Components/SaveForLater';

const Product = () => {
  const planSelected = localStorage.getItem('PlanSelected');

  const modalRef = useRef(null)

  const [check, setCheck] = useState(false);

  const location = useLocation();

  // Animation delay code
  document.documentElement.style.setProperty('--animate-duration', '3s');

  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.initialize('UA-74792081-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
    console.log(window.location.pathname, "window.location.pathname")
    console.log(window.location.search, "window.location.search")
  }, [])

  return (
    <>
      <Link to="navbar" spy smooth>
        <section
          style={{
            backgroundImage: `linear-gradient(90deg, #61C250, #4EC35D, #00C37C, #00C0A4, #00BBC7, #00B3DA, #00ACE0, #00A9E0)`,
            height: isMobile ? '80vh' : "100%",
          }}
        >
          <div
            style={{
              backgroundImage: `url(${BannerImage})`,
              height: '100%',
              backgroundPosition: 'top',
              backgroundRepeat: 'no-repeat',
              display: "flex",
              alignItems: "center"
            }}
          >
            {location.pathname === "/pricing" || location.pathname === "/product" ?
              (
                <img src={isMobile ? catapultLogo : exit}
                  style={{
                    cursor: "pointer",
                    height: isMobile ? '47px' : "68px",
                    width: isMobile ? '100%' : "230px",
                    zIndex: 999,
                    position: "fixed",
                    right: isMobile ? '' : "0px",
                    top: "-5px"
                  }}
                  onClick={() => window.open("https://www.catapulterp.com/", "_blank")}
                />
              ) : (
                <img src={isMobile ? catapultLogo : exit}
                  style={{
                    cursor: "pointer",
                    height: isMobile ? '47px' : "68px",
                    width: isMobile ? '100%' : "230px",
                    zIndex: 999,
                    position: "fixed",
                    right: isMobile ? '' : "0px",
                    top: "-5px"
                  }}
                  onClick={() => {
                    modalRef.current()
                    setCheck(true)
                  }}
                />
              )
            }
            <Container fixed maxWidth="md" className="animate__animated animate__fadeIn"
              sx={{
                height: "450px",
              }}
            >
              <Grid item xs={12}
                sx={{
                  textAlign: 'center',
                  height: "100%",
                  display: "flex",
                  justifyContent: "center"
                }}>
                <Stack direction="column"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: isMobile ? '47px' : ''
                  }}>
                  <Typography
                    variant="string"
                    gutterBottom
                    component="div"
                    sx={{
                      fontFamily: 'DinDIsplayProRegular',
                      fontStyle: 'italic',
                      color: '#FFFFFF',
                    }}
                  >
                    Pricing
                  </Typography>
                  <Typography
                    variant="h3"
                    gutterBottom
                    component="div"
                    className="font-face-prelo-black"
                    sx={{
                      fontFamily: 'PreloSlabW04Black',
                      color: '#FFFFFF',
                      fontSize: isMobile ? '33px' : '48px',
                      marginBottom: isMobile ? "40px" : ''
                    }}
                  >

                    Customize and price your Dynamics 365 solution
                  </Typography>
                  <Typography
                    variant="caption"
                    gutterBottom
                    component="div"
                    mb={5}
                    sx={{
                      fontFamily: 'PreloSlabW04Light',
                      color: '#FFFFFF',
                      fontSize: isMobile ? '18px' : '20px',
                      lineHeight: 1.5,
                      paddingX: isMobile ? 2 : 10,
                      marginBottom: isMobile ? "60px" : '40px'
                    }}
                  >
                    Complete our short questionnaire about your business needs to receive a quote for implementation, licensing and support.
                  </Typography>
                </Stack>
              </Grid>
            </Container>
          </div>
        </section>
      </Link>

      <div id="navbar" style={{ height: isMobile ? "100%" : '100vh' }}>
        <NavbarTab PlanSelected={planSelected} />
        <NoteCard />
        <SaveForLater modalRef={modalRef} check={check} />
      </div>
    </>
  );
};

export default Product;
