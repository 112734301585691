import { combineReducers } from 'redux';
import homeReducer from './home/home.reducer';
import productReducer from './product/product.reducer';
import businessCentralReducer from './businessCentral/businessCentral.reducer';
import marketingReducer from './marketing/marketing.reducer';
import endUsersReducer from './endUser/endUsers.reducer';
import customerServiceReducer from './customerService/customerService.reducer';
import salesReducer from './sales/sales.reducer'
import supportReducer from './support/support.reducer'
import profileReducer from './profile/profile.reducer'
import saveForLaterReducer from './saveForLater/saveForLater.reducer'
import saveSupportPackageReducer from './supportPackage/supportPackage.reducer';
import saveGeoLocationReducer from './geoLocation/geoLocation.reducer';
import saveRequestDemoReducer from './requestDemo/requestDemo.reducer';
import pricingCodeReducer from './pricingCode/pricingCode.reducer';

const rootReducer = combineReducers({
  home: homeReducer,
  product: productReducer,
  businessCentral: businessCentralReducer,
  sales: salesReducer,
  marketing: marketingReducer,
  endUsers: endUsersReducer,
  customerService: customerServiceReducer,
  support:supportReducer,
  profile:profileReducer,
  saveLater : saveForLaterReducer,
  pricingCode: pricingCodeReducer,
  saveSupportPackage : saveSupportPackageReducer,
  saveGeoLocation: saveGeoLocationReducer,
  saveRequestDemo: saveRequestDemoReducer
});

export default rootReducer;
