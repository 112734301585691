import SAVEMARKETING, { RESET_SAVEMARKETING } from './marketing.types';

const INITIAL_STATE = {
  "marketing": {
    "marketingData": {
        "uniqueTemplates": {
            "label": "uniqueTemplates",
            "value": null
        },
        "nurtureCampaigns": {
            "label": "nurtureCampaigns",
            "value": null
        },
        "campaignSpecificLandingPages": {
            "label": "No",
            "value": null
        },
        "landingPages": {
            "label": "No",
            "value": null
        },
        "surveys": {
            "label": "surveys",
            "value": null
        },
        "eventRegistration": {
            "label": "eventRegistration",
            "value": null
        }
    }
},
};

const reducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case SAVEMARKETING:
      // console.log('action.payload', action.payload);
      return {
        ...state,
        marketing: action.payload,
      };
      case RESET_SAVEMARKETING:
        return INITIAL_STATE
    default:
      return state;
  }
};

export default reducer;
