/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/self-closing-comp */
/* eslint-disable eqeqeq */
import { useEffect, useRef, useState } from 'react';
import { Button, Container, Grid, Typography, Stack, IconButton, Fade } from '@mui/material';
import { Link } from 'react-scroll';
import LoadingButton from '@mui/lab/LoadingButton';
import { makeStyles } from '@mui/styles';
import axios from 'axios';
import ReactGA from 'react-ga';
import Radio from '@mui/material/Radio';
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import styled from '@emotion/styled';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { useMediaQuery } from 'react-responsive';
import { useLocation, useNavigate } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import SaveForLater from '../Components/SaveForLater';
import saveCustomerService from '../redux/customerService/customerService.actions';
import { setStepsStatus, setSubStepStatus } from '../redux/home/home.actions';
import conditionalRoute from '../Routes/ConditionalRoutes';
import conditionalRouteEndUser from '../Routes/ConditionalRouteEndUser';
import BannerImage from '../assets/logo-overlay.png';
import saveSupport from '../redux/support/support.actions';
import 'animate.css';
import Navbar from '../Components/Navbar/NavbarTab';
import ProductBar from '../Components/ProductBar/ProductBar';
import exit from '../assets/exit.svg'
import catapultLogo from '../assets/catapult-mobile.svg'
import infoicon from '../assets/infoicon.svg';
import { modifyProductsByCategory } from '../helpers';


const useStyles = makeStyles({
  btnMain: {
    '&:hover': {
      backgroundColor: '#055469',
    },
  },
  btnSeconday: {
    border: '1px solid #FFFFFF',
    '&:hover': {
      borderColor: '#F5F5F5',
    },
  },
});

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "260px",
    padding: "10px",
    display: "flex",
    alignItems: "center",
    height: "50px",
    color: "#FFFFFF",
    fontFamily: "DinDIsplayProRegular",
    backgroundColor: "#00313E",
    fontSize: "12px"
  }
});


const CustomerService = ({ customerService, stepsStatus, code, hyperPackage, saveForLaterCode }) => {

  const isMobile = useMediaQuery({ query: `(max-width: 640px)` });

  const isSmallMobile = useMediaQuery({ query: `(max-width: 768px)` });

  const isSmallDevice = useMediaQuery({ query: `(max-width: 390px)` });

  const isXtraSmallDevice = useMediaQuery({ query: `(max-height: 667px)` });

  const isDesktopOrLaptop = useMediaQuery({ query: `(min-width: 1244px)` });

  const isIpad = useMediaQuery({ query: `(max-width: 900px)` });

  document.documentElement.style.setProperty('--animate-duration', '3s');

  const id = localStorage.getItem("clientId");

  const dispatch = useDispatch();

  const reduxData = useSelector((s) => s);

  const { product } = useSelector((s) => s);

  const navigate = useNavigate();

  const { state } = useLocation();

  const customerServiceDivRef = useRef()

  const modalRef = useRef(null)

  const location = useLocation();

  const [openToolTip, setOpenToolTip] = useState(false);
  const handleTooltipOpen = () => {
    setOpenToolTip(!openToolTip);
    setTimeout(() => {
      setOpenToolTip(false);
    }, 3000);

  };
  const ifProductsExist = product.selectedProducts.length > 0 ? product.selectedProducts : [];
  const [selectedProducts, setProducts] = useState(ifProductsExist);
  const [loading, setLoading] = useState(false);
  // state for navbar responsiveness
  const [navbar, setNavbar] = useState(false);

  // enable and disable//
  const [show, setShow] = useState(false);

  const [data, setData] = useState(customerService?.customerServiceData);

  const [check, setCheck] = useState(false);


  // -------------------------------------------Funtions---------------------------------------------------//

  const handleChange = (field, label, value) => {

    setTimeout(() => {
      window.scrollBy({
        top: isMobile || isXtraSmallDevice || isSmallDevice ? 300 : 500,
        behavior: 'smooth',
      });
    }, 800);
    // }

    setData({ ...data, [field]: { "label": label, "value": parseInt(value, 10) } });
  };

  const handleSaveData = () => {
    const payloadData = {
      "customerService": {
        "customerServiceData": {
          "uniqueServiceProcesses": {
            "label": data?.uniqueServiceProcesses?.label,
            "value": data?.uniqueServiceProcesses?.value
          },
          "customerSelfServePortal": {
            "label": data?.customerSelfServePortal?.label,
            "value": data?.customerSelfServePortal?.value
          },
          "workOrders": {
            "label": data?.workOrders?.label,
            "value": data?.workOrders?.value
          },
          "workOrderRouting": {
            "label": data?.workOrderRouting?.label,
            "value": data?.workOrderRouting?.value
          },
          "supportContracts": {
            "label": data?.supportContracts?.label,
            "value": data?.supportContracts?.value
          },
          "selfserveKBs": {
            "label": data?.selfserveKBs?.label,
            "value": data?.selfserveKBs?.value
          }
        }
      }
    }
    dispatch(saveCustomerService(payloadData.customerService));
  }
  const handleNext = () => {

    const payloadData = {
      "customerService": {
        "customerServiceData": {
          "uniqueServiceProcesses": {
            "label": data?.uniqueServiceProcesses?.label,
            "value": data?.uniqueServiceProcesses?.value
          },
          "customerSelfServePortal": {
            "label": data?.customerSelfServePortal?.label,
            "value": data?.customerSelfServePortal?.value
          },
          "workOrders": {
            "label": data?.workOrders?.label,
            "value": data?.workOrders?.value
          },
          "workOrderRouting": {
            "label": data?.workOrderRouting?.label,
            "value": data?.workOrderRouting?.value === null ? 0 : data?.workOrderRouting?.value
          },
          "supportContracts": {
            "label": data?.supportContracts?.label,
            "value": data?.supportContracts?.value
          },
          "selfserveKBs": {
            "label": data?.selfserveKBs?.label,
            "value": data?.selfserveKBs?.value
          }
        }
      }
    }

    if (selectedProducts[selectedProducts.length - 1] === '4') {
      const updateStep = stepsStatus;
      updateStep[1].businessDetails = { 'active': false, 'completed': true }
      dispatch(setStepsStatus(updateStep))
    }
    dispatch(setSubStepStatus({ bdCustomerService: { active: false, completed: true } }));
    dispatch(saveCustomerService(payloadData.customerService));
    const nextIndex = selectedProducts.indexOf('4') + 1 || Infinity;

    if (selectedProducts.at(nextIndex)) {
      navigate(`/business-details/${conditionalRoute(selectedProducts.at(nextIndex))}`);
    }
    else {
      navigate(`/end-users/${conditionalRouteEndUser(selectedProducts.at(0))}`);
    }
  };

  const handleSave = () => {
    setLoading(true)
    const payloadData = {
      "customerServiceData": {
        "uniqueServiceProcesses": {
          "label": data?.uniqueServiceProcesses?.label,
          "value": data?.uniqueServiceProcesses?.value === null ? 0 : data?.uniqueServiceProcesses?.value
        },
        "customerSelfServePortal": {
          "label": data?.customerSelfServePortal?.label,
          "value": data?.customerSelfServePortal?.value === null ? 0 : data?.customerSelfServePortal?.value
        },
        "workOrders": {
          "label": data?.workOrders?.label,
          "value": data?.workOrders?.value === null ? 0 : data?.workOrders?.value
        },
        "workOrderRouting": {
          "label": data?.workOrderRouting?.label,
          "value": data?.workOrderRouting?.value === null ? 0 : data?.workOrderRouting?.value
        },
        "supportContracts": {
          "label": data?.supportContracts?.label,
          "value": data?.supportContracts?.value === null ? 0 : data?.supportContracts?.value
        },
        "selfserveKBs": {
          "label": data?.selfserveKBs?.label,
          "value": data?.selfserveKBs?.value === null ? 0 : data?.selfserveKBs?.value
        }
      }
    }
    const profileData = {
      "firstName": reduxData?.profile?.profile?.firstName,
      "lastName": reduxData?.profile?.profile?.lastName,
      "email": reduxData?.profile?.profile?.email,
      "countryCode": reduxData?.profile?.profile?.countryCode,
      "phoneNo": reduxData?.profile?.profile?.phoneNo,
      "companyName": reduxData?.profile?.profile?.companyName,
      "followUp": reduxData?.profile?.profile?.followUp
    }

    const body = {
      selectedProducts: modifyProductsByCategory(reduxData.product.selectedProducts),
      businessCentral: reduxData.businessCentral.businessCentral,
      sales: reduxData.sales.sales,
      marketing: reduxData.marketing.marketing,
      customerService: payloadData,
      endUsers: reduxData.endUsers,
      profileDetails: profileData,
      supportPackage: hyperPackage,
      userLocation: reduxData.saveGeoLocation.geoLocation,
      // userCode: saveForLaterCode,
      analyticsPayload: {
        clientId: id
      }
    }
    if (saveForLaterCode) {
      body.userCode = saveForLaterCode
    }
    else if (code) {
      body.userCode = code
    }
    else {
      body.userCode = ""
    }
    axios.post(`${process.env.REACT_APP_BASE_URL}/api/calculatePricing`, body)
      .then(res => {
        if (res.data.message === 'Pricing calculated successfully') {
          setLoading(false)
          const response = res.data;
          dispatch(saveCustomerService(payloadData));
          dispatch(saveSupport(response))
          navigate('/pricing');
        }
      })
      .catch((error) => {
        setLoading(false)
      });
  }
  const handleCancel = () => {
    navigate('/pricing');
  }

  const changePosition = () => {
    if (window.scrollY >= 400) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener('scroll', changePosition);

  const buttonOne = [
    { label: '1', value: 1 },
    { label: '2 to 3', value: 2 },
    { label: '3+', value: 3 },
  ];

  useEffect(() => {
    ReactGA.initialize('UA-74792081-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
    console.log(window.location.pathname, "window.location.pathname")
    console.log(window.location.search, "window.location.search")
  }, [])

  useEffect(() => {

    if (data?.selfserveKBs.value !== null) {
      setShow(true);
    }
    else {
      setShow(false)
    }
  }, [data])

  const scrollToRef = (ref) => {
    if (isSmallMobile) {
      window.scrollTo(0, ref.current.offsetTop - 260);
    }
    else if (isDesktopOrLaptop) {
      window.scrollTo(0, ref.current.offsetTop - 170);
    }
    else {
      window.scrollTo(0, ref.current.offsetTop - 150);
    }
  }

  useEffect(() => {
    if (state?.focus) {
      switch (state?.focus) {
        case 'customerService':
          scrollToRef(customerServiceDivRef)
          break;

        default:
          break;
      }
    }
    else if (isSmallMobile) {
      scrollToRef(customerServiceDivRef);
    }
    else {
      scrollToRef(customerServiceDivRef);
    }
  }, [state?.focus])

  return (
    <div>
      <Link to="navbar" spy smooth>
        <section
          style={{
            backgroundImage: `linear-gradient(90deg, #61C250, #4EC35D, #00C37C, #00C0A4, #00BBC7, #00B3DA, #00ACE0, #00A9E0)`,
            height: isMobile ? '80vh' : "100%",
          }}
        >
          <div
            style={{
              backgroundImage: `url(${BannerImage})`,
              height: '100%',
              backgroundPosition: 'top',
              backgroundRepeat: 'no-repeat',
              display: "flex",
              alignItems: "center"
            }}
          >
            {location.pathname === "/pricing" || location.pathname === "/product" ?
              (
                <img src={isMobile ? catapultLogo : exit}
                  style={{
                    cursor: "pointer",
                    height: isMobile ? '47px' : "68px",
                    width: isMobile ? '100%' : "230px",
                    zIndex: 999,
                    position: "fixed",
                    right: isMobile ? '' : "0px",
                    // left: isMobile ? "-1px" : '',
                    top: "-5px"
                  }}
                  onClick={() => window.location.replace('https://www.catapulterp.com/')}
                />
              ) : (
                <img src={isMobile ? catapultLogo : exit}
                  style={{
                    cursor: "pointer",
                    height: isMobile ? '47px' : "68px",
                    width: isMobile ? '100%' : "230px",
                    zIndex: 999,
                    position: "fixed",
                    right: isMobile ? '' : "0px",
                    // left: isMobile ? "-1px" : '',
                    top: "-5px"
                  }}
                  onClick={() => {
                    modalRef.current()
                    setCheck(true)
                    // handleSaveData()
                  }}
                />
              )
            }
            <Container fixed maxWidth="md" className="animate__animated animate__fadeIn"
              sx={{
                height: "450px",

              }}
            >
              <Grid item xs={12}
                sx={{
                  textAlign: 'center',
                  height: "100%",
                  display: "flex",
                  justifyContent: "center"
                }}>
                <Stack direction="column"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: isMobile ? '47px' : ''
                  }}>
                  <Typography
                    variant="string"
                    gutterBottom
                    component="div"
                    sx={{
                      fontFamily: 'DinDIsplayProRegular',
                      fontStyle: 'italic',
                      color: '#FFFFFF',

                    }}
                  >
                    Pricing
                  </Typography>
                  <Typography
                    variant="h3"
                    gutterBottom
                    component="div"
                    className="font-face-prelo-black"
                    sx={{
                      fontFamily: 'PreloSlabW04Black',
                      color: '#FFFFFF',
                      fontSize: isMobile ? '33px' : '48px',
                      marginBottom: isMobile ? "40px" : ''
                    }}
                  >
                    Take the guesswork out of your ERP & CRM solution budgeting.
                  </Typography>
                  <Typography
                    variant="caption"
                    gutterBottom
                    component="div"
                    mb={5}
                    sx={{
                      fontFamily: 'PreloSlabW04Light',
                      color: '#FFFFFF',
                      fontSize: isMobile ? '18px' : '20px',
                      lineHeight: 1.5,
                      paddingX: isMobile ? 2 : 10,
                      marginBottom: isMobile ? "60px" : '40px'
                    }}
                  >
                    Customize and price your Dynamics 365 solution using our powerful online
                    scoping and budgeting tools.
                  </Typography>
                </Stack>
              </Grid>
            </Container>
          </div>
        </section>
      </Link>
      <div
        style={{ height: isMobile ? '' : '120px' }}
        id="navbar">
        <Navbar navbar={navbar} />
        <ProductBar navbar={navbar} />
      </div>


      <Container sx={{ py: 4, height: 'auto', display: "inherit", marginBottom: isMobile ? "100px" : '', minHeight: isDesktopOrLaptop ? "500px" : "700px" }} >
        <Grid container spacing={isMobile ? 3 : 2} justifyContent="start" md={12} sx={{ paddingBottom: isMobile ? "100px" : "", paddingTop: "10px" }} >
          {/* -------------------------Question 1 ----------------------------------------------------*/}
          <Grid item xs={12} md={9} className="animate__animated animate__fadeIn" sx={{ display: "flex" }} ref={customerServiceDivRef}>
            <Typography
              variant="body1"
              sx={{
                color: '#00313E',
                fontFamily: 'PreloSlabW04SemiBold',
                fontSize: isMobile ? '19px' : '22px',
              }}
              gutterBottom
              component="div"
            >
              How many unique customer service processes do you want to manage?
            </Typography>
            {
              isMobile || isIpad ? (
                <CustomTooltip placement="top-end"
                  sx={{ padding: "0px 0px 4px 10px" }}
                  TransitionComponent={Fade}
                  TransitionProps={{ timeout: 1000 }}
                  open={openToolTip}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title=" D365 can be configured to support multiple support proceses for different customer request types. For example, waranty claims, technical support, or billing inquiries.">
                  <IconButton>
                    <img src={infoicon}
                      onClick={handleTooltipOpen}
                    />
                  </IconButton>
                </CustomTooltip>
              ) : (
                <CustomTooltip placement="right"
                  sx={{ padding: "0px 0px 4px 10px" }}
                  TransitionComponent={Fade}
                  TransitionProps={{ timeout: 1000 }}
                  title="D365 can be configured to support multiple support proceses for different customer request types. For example, waranty claims, technical support, or billing inquiries.">
                  <IconButton>
                    <img src={infoicon}
                    />
                  </IconButton>
                </CustomTooltip>
              )
            }
          </Grid>
          <Grid
            item
            xs={12}
            md={9}
            mb={1}
            sx={{
              display: 'flex',
              flexWrap: isMobile ? 'wrap' : ''
            }}
            className="animate__animated animate__fadeIn"
          >
            {buttonOne?.map((item) => (
              <Button
                variant="myPrimary"
                sx={{
                  backgroundColor:
                    data?.uniqueServiceProcesses?.value !== item.value ? 'white' : '#00313E',
                  width: isMobile ? '140px' : '180px',
                  height: '60px',
                  borderRadius: '15px',
                  border: '1px solid #00313E',
                  marginRight: isMobile ? '18px' : '40px',
                  marginBottom: '10px',
                  marginTop: '10px',

                  '&:hover': {
                    backgroundColor:
                      data?.uniqueServiceProcesses?.value !== item.value ? 'white' : '#00313E',
                  },
                }}
                value={item.label}
                onClick={() => {
                  handleChange("uniqueServiceProcesses", item.label, item.value);
                }}
              >
                <Typography
                  variant="button"
                  display="block"
                  sx={{
                    color: data?.uniqueServiceProcesses?.value !== item.value ? '#00313E' : '#FFFFFF',
                    fontFamily: 'DinDIsplayProBold',
                    textTransform: 'none',
                    fontSize: isMobile ? '14px' : '18px',
                  }}
                >
                  {item.label}
                </Typography>
              </Button>
            ))}
          </Grid>

          {/* -------------------------Question 2 ----------------------------------------------------*/}

          {/* {data?.uniqueServiceProcesses?.value ? (
            <>
              <Grid item xs={12} md={9} className="animate__animated animate__fadeIn">
                <Typography
                  variant="body1"
                  sx={{
                    color: '#00313E',
                    fontFamily: 'PreloSlabW04SemiBold',
                    fontSize: '22px',
                    // paddingTop: "60px"
                  }}
                  gutterBottom
                  component="div"
                >
                  Do you want a branded, customer self-serve portal?
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={9}
                mb={2}
                sx={{ display: 'flex' }}
                className="animate__animated animate__fadeIn"
              >
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    sx={{
                      color: '#00313E',
                    }}

                    onChange={(event) => {
                      handleChange('customerSelfServePortal', event.target.value === "0" ? "No" : "Yes", event.target.value)
                    }}
                  >
                    <FormControlLabel
                      value={1}
                      control={
                        <Radio
                          checked={data?.customerSelfServePortal?.value === 1}
                          size="large"
                          sx={{
                            color: '#00313E',
                            '&.Mui-checked': {
                              color: '#00313E',
                            },
                          }}
                        />
                      }
                      label="Yes"
                    />
                    <FormControlLabel
                      value={0}
                      control={
                        <Radio
                          size="large"
                          checked={data?.customerSelfServePortal?.value === 0}
                          sx={{
                            color: '#00313E',
                            '&.Mui-checked': {
                              color: '#00313E',
                            },
                          }}
                        />
                      }
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </>
          ) : (
            ''
          )} */}

          {/* -------------------------Question 3 ----------------------------------------------------*/}

          {
            // data?.customerSelfServePortal.value === 1 || data?.customerSelfServePortal.value === 0 ?
            data?.uniqueServiceProcesses?.value ?
              (
                <>
                  <Grid item xs={12} md={9} className="animate__animated animate__fadeIn">
                    <Typography
                      variant="body1"
                      sx={{
                        color: '#00313E',
                        fontFamily: 'PreloSlabW04SemiBold',
                        fontSize: isMobile ? '19px' : '22px',
                        // paddingTop: "60px"
                      }}
                      gutterBottom
                      component="div"
                    >
                      Do you have Work Orders?
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={9}
                    mb={2}
                    sx={{ display: 'flex' }}
                    className="animate__animated animate__fadeIn"
                  >
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                        sx={{
                          color: '#00313E',
                        }}
                        onChange={(event) => handleChange('workOrders', event.target.value === "0" ? "No" : "Yes", event.target.value)}
                      >
                        <FormControlLabel
                          value={1}
                          control={
                            <Radio
                              checked={data?.workOrders?.value === 1}
                              size="large"
                              sx={{
                                color: '#00313E',
                                '&.Mui-checked': {
                                  color: '#00313E',
                                },
                              }}
                            />
                          }
                          label="Yes"
                        />

                        <FormControlLabel
                          value={0}
                          control={
                            <Radio
                              checked={data?.workOrders?.value === 0}
                              size="large"
                              sx={{
                                color: '#00313E',
                                '&.Mui-checked': {
                                  color: '#00313E',
                                },
                              }}
                            />
                          }
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </>
              ) : (
                ''
              )}

          {/* -------------------------Question 4 ----------------------------------------------------*/}

          {data?.workOrders?.value === 1 ? (
            <>
              <Grid item xs={12} md={9} className="animate__animated animate__fadeIn">
                <Typography
                  variant="body1"
                  sx={{
                    color: '#00313E',
                    fontFamily: 'PreloSlabW04SemiBold',
                    fontSize: isMobile ? '17px' : '20px',
                  }}
                  gutterBottom
                  component="div"
                >
                  Do you require Work Order routing?
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={9}
                mb={2}
                sx={{ display: 'flex' }}
                className="animate__animated animate__fadeIn"
              >
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    onChange={(event) => handleChange('workOrderRouting', event.target.value === "0" ? "No" : "Yes", event.target.value)}
                    sx={{
                      color: '#00313E',
                    }}
                  >
                    <FormControlLabel
                      value={1}
                      control={
                        <Radio
                          checked={data?.workOrderRouting?.value === 1}
                          size="large"
                          sx={{
                            color: '#00313E',
                            '&.Mui-checked': {
                              color: '#00313E',
                            },
                          }}
                        />
                      }
                      label="Yes"
                    />
                    <FormControlLabel
                      value={0}
                      control={
                        <Radio
                          checked={data?.workOrderRouting?.value === 0}
                          size="large"
                          sx={{
                            color: '#00313E',
                            '&.Mui-checked': {
                              color: '#00313E',
                            },
                          }}
                        />
                      }
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </>
          ) : (
            ''
          )}
          {/* -------------------------Question 5----------------------------------------------------*/}

          {data?.workOrderRouting.value === 1 || data?.workOrderRouting.value === 0 || data?.workOrders?.value === 0 ? (
            <>
              <Grid item xs={12} md={9} className="animate__animated animate__fadeIn">
                <Typography
                  variant="body1"
                  sx={{
                    color: '#00313E',
                    fontFamily: 'PreloSlabW04SemiBold',
                    fontSize: isMobile ? '19px' : '22px',
                    // paddingTop: "60px"
                  }}
                  gutterBottom
                  component="div"
                >
                  Do you have support or services contracts, or entitlements?
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={9}
                mb={2}
                sx={{ display: 'flex' }}
                className="animate__animated animate__fadeIn"
              >
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    onChange={(event) => handleChange('supportContracts', event.target.value === "0" ? "No" : "Yes", event.target.value)}
                    sx={{
                      color: '#00313E',
                    }}
                  >
                    <FormControlLabel
                      value={1}
                      control={
                        <Radio
                          checked={data?.supportContracts?.value === 1}
                          size="large"
                          sx={{
                            color: '#00313E',
                            '&.Mui-checked': {
                              color: '#00313E',
                            },
                          }}
                        />
                      }
                      label="Yes"
                    />
                    <FormControlLabel
                      value={0}
                      control={
                        <Radio
                          checked={data?.supportContracts?.value === 0}
                          size="large"
                          sx={{
                            color: '#00313E',
                            '&.Mui-checked': {
                              color: '#00313E',
                            },
                          }}
                        />
                      }
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </>
          ) : (
            ''
          )}


          {/* ----------------------------------QUestion------------------------------ */}

          {data?.supportContracts.value === 0 || data?.supportContracts.value === 1 ? (
            <>
              <Grid item xs={12} md={9} className="animate__animated animate__fadeIn">
                <Typography
                  variant="body1"
                  sx={{
                    color: '#00313E',
                    fontFamily: 'PreloSlabW04SemiBold',
                    fontSize: isMobile ? '19px' : '22px',
                    // paddingTop: "60px"
                  }}
                  gutterBottom
                  component="div"
                >
                  Do you want a branded, customer self-serve portal?
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={9}
                mb={2}
                sx={{ display: 'flex' }}
                className="animate__animated animate__fadeIn"
              >
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    sx={{
                      color: '#00313E',
                    }}

                    onChange={(event) => {
                      handleChange('customerSelfServePortal', event.target.value === "0" ? "No" : "Yes", event.target.value)
                    }}
                  >
                    <FormControlLabel
                      value={1}
                      control={
                        <Radio
                          checked={data?.customerSelfServePortal?.value === 1}
                          size="large"
                          sx={{
                            color: '#00313E',
                            '&.Mui-checked': {
                              color: '#00313E',
                            },
                          }}
                        />
                      }
                      label="Yes"
                    />
                    <FormControlLabel
                      value={0}
                      control={
                        <Radio
                          size="large"
                          checked={data?.customerSelfServePortal?.value === 0}
                          sx={{
                            color: '#00313E',
                            '&.Mui-checked': {
                              color: '#00313E',
                            },
                          }}
                        />
                      }
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </>
          ) : (
            ''
          )}



          {/* -------------------------Question 6 ----------------------------------------------------*/}

          {
            // data?.supportContracts.value === 0 || data?.supportContracts.value === 1 ?

            data?.customerSelfServePortal.value === 1 || data?.customerSelfServePortal.value === 0 ? (
              <>
                <Grid item xs={12} md={9} className="animate__animated animate__fadeIn">
                  <Typography
                    variant="body1"
                    sx={{
                      color: '#00313E',
                      fontFamily: 'PreloSlabW04SemiBold',
                      fontSize: isMobile ? '19px' : '22px',
                      // paddingTop: "60px"
                    }}
                    gutterBottom
                    component="div"
                  >
                    Would you like to have customer self-serve knowledge articles?
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={9}
                  mb={2}
                  sx={{ display: 'flex' }}
                  className="animate__animated animate__fadeIn"
                >
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="radio-buttons-group"
                      onChange={(event) => handleChange('selfserveKBs', event.target.value === "0" ? "No" : "Yes", event.target.value)}
                      sx={{
                        color: '#00313E',
                      }}
                    >
                      <FormControlLabel
                        value={1}
                        control={
                          <Radio
                            checked={data?.selfserveKBs?.value === 1}
                            size="large"
                            sx={{
                              color: '#00313E',
                              '&.Mui-checked': {
                                color: '#00313E',
                              },
                            }}
                          />
                        }
                        label="Yes"
                      />
                      <FormControlLabel
                        value={0}
                        control={
                          <Radio
                            checked={data?.selfserveKBs?.value === 0}
                            size="large"
                            sx={{
                              color: '#00313E',
                              '&.Mui-checked': {
                                color: '#00313E',
                              },
                            }}
                          />
                        }
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </>
            ) : (
              ''
            )}


        </Grid>
      </Container>

      <div
        style={{
          background: isMobile ? "white" : "",
          height: isMobile ? "100px" : "70px",
          position: isMobile ? "fixed" : '',
          bottom: "0px",
          width: "100%"
        }}>
        {state?.focus ? (
          <Grid
            item
            xs={12}
            md={3}
            direction="row"
            sx={{
              display: "flex",
              justifyContent: isMobile ? "center" : "flex-end",
              alignItems: "center",
              position: isMobile || isIpad ? '' : 'fixed',
              right: '25px',
              bottom: '25px',
              width: '100%',
              paddingLeft: 0,
              height: isMobile ? "100%" : '120px'
              // marginBottom: '30px',
              // height: isMobile ? "100px" : '',
            }}
          >
            <Button
              variant="primary"
              sx={{
                borderRadius: 1,
                border: '1px solid #CFCFCF',
                height: "60px",
                width: "180px",
                marginRight: "15px",

              }}
              onClick={handleCancel}
            >
              <Typography
                variant="button"
                sx={{ fontFamily: 'DinDIsplayProBold', color: '#00313E' }}
              >
                Cancel
              </Typography>
            </Button>

            <LoadingButton
              variant="contained"
              color="neutral"
              // disabled={loading}
              loading={loading}
              loadingPosition="end"
              sx={{
                borderRadius: 1,
                backgroundColor: '#00313E',
                height: "60px",
                width: "130px"
              }}
              onClick={() => handleSave()}
            >
              <Typography variant="button" sx={{ fontFamily: 'DinDIsplayProBold' }}>
                Save
              </Typography>
            </LoadingButton>
          </Grid>
        ) : <Grid
          item
          xs={12}
          md={3}
          direction="row"
          sx={{
            display: "flex",
            justifyContent: isMobile ? "center" : "flex-end",
            alignItems: "center",
            position: isMobile || isIpad ? '' : 'fixed',
            right: '25px',
            bottom: '25px',
            width: '100%',
            paddingLeft: 0,
            height: isMobile ? "100%" : '120px'
            // marginBottom: '30px',
            // height: isMobile ? "100px" : '',
          }}
        >
          <Button
            variant="primary"
            sx={{
              borderRadius: 1,
              border: '1px solid #CFCFCF',
              height: "60px",
              width: "180px",
              marginRight: "15px",

            }}
            onClick={() => {
              modalRef.current()
              handleSaveData()
            }}
          >
            <Typography
              variant="button"
              sx={{ fontFamily: 'DinDIsplayProBold', color: '#00313E' }}
            >
              Save for later
            </Typography>
          </Button>

          <Button
            variant="contained"
            color="neutral"
            disabled={!show}
            sx={{
              borderRadius: 1,
              backgroundColor: '#00313E',
              height: "60px",
              width: "130px"
            }}
            onClick={() => handleNext()}
          >
            <Typography variant="button" sx={{ fontFamily: 'DinDIsplayProBold' }}>
              Next
            </Typography>
          </Button>
        </Grid>}

      </div>

      <SaveForLater check={check} modalRef={modalRef} />
    </div >
  );
};

const mapStateToProps = (state) => {
  return {
    customerService: state.customerService.customerService,
    stepsStatus: state.home.stepsStatus,
    code: state.pricingCode.code,
    hyperPackage: state.saveSupportPackage.supportPackage,
    saveForLaterCode: state?.saveLater?.code,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveCustomerService: (payload) => dispatch(saveCustomerService(payload)),
    setStepsStatus: (payload) => dispatch(setStepsStatus(payload)),
    saveSupport: (payload) => dispatch(saveSupport(payload)),

  };
};

// export default CustomerService;
export default connect(mapStateToProps, mapDispatchToProps)(CustomerService);
