/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* The <div> element has a child <button> element that allows keyboard interaction */
/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import './ProductBar.css';

const ProductBar = (props) => {

  const isActiveTab = (value) => {
    let active = false;
    if (props.selectedProducts?.length > 0) {
      active = props.selectedProducts.includes(value);
    }
    return active;
  };

  const products = {
    1: 'bdCentral',
    2: 'bdSales',
    3: 'bdMarketing',
    4: 'bdCustomerService',
  };

  const hasPreviousStepCompleted = (value) => {
    const currentTabIndex = props?.selectedProducts?.indexOf(value);
    const previousTabIndex = currentTabIndex - 1;
    const previousTab = props?.selectedProducts[previousTabIndex];
    const previousTabName = products[previousTab];
    const previousStepStatus = props?.subSteps[previousTabName]?.completed || currentTabIndex === 0;
    return previousStepStatus;
  };

  return (
    <div className={props.navbar ? 'product-bar-new' : 'product-bar'}>
      <ul className="menu-list-product-bar">
        {isActiveTab('1') ? (
          <li>
            <NavLink
              exact
              to="/business-details/business-central"
              activeClassName="active"
              className={hasPreviousStepCompleted('1') ? '' : 'disabled'}
            >
              Dynamics 365 Business Central
            </NavLink>
          </li>
        ) : (
          ''
        )}

        {isActiveTab('2') ? (
          <li>
            <NavLink
              exact
              to="/business-details/sales"
              activeClassName="active"
              className={hasPreviousStepCompleted('2') ? '' : 'disabled'}
            >
              Dynamics 365 for Sales
            </NavLink>
          </li>
        ) : (
          ''
        )}

        {isActiveTab('3') ? (
          <li>
            <NavLink
              exact
              to="/business-details/marketing"
              activeClassName="active"
              className={hasPreviousStepCompleted('3') ? '' : 'disabled'}
            >
              Dynamics 365 for Marketing
            </NavLink>
          </li>
        ) : (
          ''
        )}

        {isActiveTab('4') ? (
          <li>
            <NavLink
              exact
              to="/business-details/customer-service"
              activeClassName="active"
              className={hasPreviousStepCompleted('4') ? '' : 'disabled'}
            >
              Dynamics 365 for Customer Service
            </NavLink>
          </li>
        ) : (
          ''
        )}
      </ul>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedProducts: state?.product?.selectedProducts,
    subSteps: state?.home?.subStepsStatus,
  };
};

export default connect(mapStateToProps)(ProductBar);
