/* eslint-disable no-undef */
/* eslint-disable no-unsafe-optional-chaining */
import { useState } from 'react';
import { Grid, Typography, Stack, Paper } from '@mui/material';
import { connect } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import saveSupport from '../redux/support/support.actions';

const SubscriptionPricing = ({ support, location }) => {

    const [monthlyPrice, setMonthlyPrice] = useState(true);

    const isMobile = useMediaQuery({ query: `(max-width: 640px)` });

    const data = [
        {
            id: 1,
            name: "Software Implementation Services",
            payment: `${support?.finalPaymentsPlans?.threeYearSubsription?.monthlyPayments?.monthlyImplementationFee}`
        },
        {
            id: 2,
            name: "Dynamics 365 Software Licensing",
            payment: `${support?.finalPaymentsPlans?.threeYearSubsription?.monthlyPayments?.monthlySoftwareLicensing}`
        },
        {
            id: 3,
            name: "Support Services",
            payment: `${support?.finalPaymentsPlans?.threeYearSubsription?.monthlyPayments?.supportServices} `
        },
    ]

    const yearlyData = [
        { id: 1, name: "Software Implementation Services", payment: `${support?.finalPaymentsPlans?.threeYearSubsription?.yearlyPayments?.yearlyImplementationFee}` },
        { id: 2, name: "Dynamics 365 Software Licensing", payment: `${support?.finalPaymentsPlans?.threeYearSubsription?.yearlyPayments?.totalYearlySoftwareLicensing}` },
        { id: 3, name: "Support Services", payment: `${support?.finalPaymentsPlans?.threeYearSubsription?.yearlyPayments?.yearlySupportServices}` },
    ]

    const effectiveDiscount = support?.finalPaymentsPlans?.threeYearSubsription?.yearlyPayments?.contractYearlyTotal - support?.finalPaymentsPlans?.threeYearSubsription?.monthlyPayments?.contractMonthlyTotal

    const value = effectiveDiscount / support?.finalPaymentsPlans?.threeYearSubsription?.monthlyPayments?.contractMonthlyTotal * 100

    return (
        <div>
            <Grid container justifyContent="start" md={12}>
                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                    <Typography
                        variant="body2"
                        component="div"
                        sx={{
                            fontFamily: 'PreloSlabW04Medium',
                            color: '#00313E',
                            fontSize: isMobile ? "14px" : '20px',
                            textAlign: "center",
                            marginTop: "20px",
                            marginBottom: "20px"
                        }}
                    >
                        Bundled subscription plans offer simple monthly or annual payment options with no upfront investments in software implementation.
                    </Typography>
                    <Stack mt={2}>
                        <Typography
                            variant="caption"
                            gutterBottom
                            component="div"
                            sx={{
                                fontFamily: 'PreloSlabW04Medium',
                                color: '#00313E',
                                fontSize: '16px',
                                textAlign: "center"
                            }}
                        >
                            {location === 'Canada' ? (
                                "All figures given in CAD"
                            ) : "All figures given in USD"}

                        </Typography>
                    </Stack>
                </Grid>
                <Grid item xs={12} sx={{
                    width: "100%",
                    marginTop: "10px",
                    display: "flex",
                    flexDirection: isMobile ? "column" : "row",
                    justifyContent: "center"
                }}>
                    <Paper
                        sx={{
                            display: "flex",
                            cursor: "pointer",
                            marginRight: isMobile ? "" : "20px",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            backgroundColor: monthlyPrice === true ? "#00313E" : "#F2FAFD",
                            height: "150px",
                            width: "100%",
                            marginTop: "10px",
                        }}
                        elevation={0}
                        onClick={() => setMonthlyPrice(true)}
                    >
                        <Typography
                            variant="body2"
                            component="div"
                            sx={{
                                fontFamily: 'PreloSlabW04Light',
                                color: monthlyPrice === true ? "#FFFFFF" : "#00313E",
                                fontSize: '18px',
                                marginTop: "15px",
                                textAlign: "center"
                            }}
                        >
                            Monthly Payment (36 months)
                        </Typography>
                        <Typography
                            variant="body2"
                            component="div"
                            sx={{
                                fontFamily: 'PreloSlabW04Medium',
                                color: monthlyPrice === true ? "#FFFFFF" : "#00313E",
                                fontSize: '50px',
                                textAlign: "center"
                            }}
                        >
                            {` $${(new Intl.NumberFormat('en-US').format(Math.round(support?.finalPaymentsPlans?.threeYearSubsription?.monthlyPayments?.totalMonthlyPayment)))}`}
                        </Typography>
                        <Typography
                            variant="body2"
                            component="div"
                            sx={{
                                fontFamily: 'PreloSlabW04Light',
                                color: monthlyPrice === true ? "#FFFFFF" : "#00313E",
                                fontSize: '18px',
                                textAlign: "center"
                            }}
                        >
                            {`Contract Total: $${(new Intl.NumberFormat('en-US').format(Math.round(support?.finalPaymentsPlans?.threeYearSubsription?.monthlyPayments?.contractMonthlyTotal)))}`}
                        </Typography>
                    </Paper>
                    <Paper
                        sx={{
                            display: "flex",
                            cursor: "pointer",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            backgroundColor: monthlyPrice === false ? "#00313E" : "#F2FAFD",
                            height: "150px",
                            width: "100%",
                            marginTop: "10px",
                        }}
                        elevation={0}
                        onClick={() => setMonthlyPrice(false)}
                    >
                        <Typography
                            variant="body2"
                            component="div"
                            sx={{
                                fontFamily: 'PreloSlabW04Light',
                                color: monthlyPrice === false ? "#FFFFFF" : "#00313E",
                                fontSize: '18px',
                                textAlign: "center",
                                marginTop: "15px",
                            }}
                        >
                            Annual Payment (3 years)
                        </Typography>
                        <Typography
                            variant="body2"
                            component="div"
                            sx={{
                                fontFamily: 'PreloSlabW04Medium',
                                color: monthlyPrice === false ? "#FFFFFF" : "#00313E",
                                fontSize: '50px',
                                textAlign: "center"
                            }}
                        >
                            {` $${(new Intl.NumberFormat('en-US').format(Math.round(support?.finalPaymentsPlans?.threeYearSubsription?.yearlyPayments?.totalYearlyPayment)))}`}
                        </Typography>
                        <Typography
                            variant="body2"
                            component="div"
                            sx={{
                                fontFamily: 'PreloSlabW04Light',
                                color: monthlyPrice === false ? "#FFFFFF" : "#00313E",
                                fontSize: '18px',
                                textAlign: "center"
                            }}
                        >
                            {`Contract Total: $${(new Intl.NumberFormat('en-US').format(Math.round(support?.finalPaymentsPlans?.threeYearSubsription?.yearlyPayments?.contractYearlyTotal)))}`}
                        </Typography>
                    </Paper>
                </Grid>

                {monthlyPrice === true ?
                    (<Grid item xs={12} sx={{ textAlign: 'center', marginTop: "20px" }}>
                        {data.map((item) => (
                            <Stack
                                key={item.id}
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                // mx={4}
                                my={2}
                                spacing={1}>
                                <Typography
                                    variant="body2"
                                    component="div"
                                    sx={{
                                        display: "flex",
                                        fontFamily: 'PreloSlabW04Light',
                                        color: '#00313E',
                                        fontSize: isMobile ? '14px' : '18px',

                                    }}
                                >
                                    {item.name}
                                </Typography>

                                <Typography
                                    variant="body2"

                                    component="div"
                                    sx={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        fontFamily: 'PreloSlabW04Light',
                                        color: '#00313E',
                                        fontSize: isMobile ? '14px' : '18px',

                                    }}
                                >
                                    {`$${(new Intl.NumberFormat('en-US').format(Math.round(item?.payment)))}`}
                                </Typography>

                            </Stack>))}
                    </Grid>) : (
                        <Grid item xs={12} sx={{ textAlign: 'center', marginTop: "20px" }}>
                            {yearlyData.map((item) => (
                                <Stack
                                    key={item.id}
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    my={2}
                                    spacing={1}>
                                    <Typography
                                        variant="body2"
                                        component="div"
                                        sx={{
                                            display: "flex",
                                            fontFamily: 'PreloSlabW04Light',
                                            color: '#00313E',
                                            fontSize: isMobile ? '14px' : '18px',

                                        }}
                                    >
                                        {item.name}
                                    </Typography>
                                    <Typography
                                        variant="body2"

                                        component="div"
                                        sx={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            fontFamily: 'PreloSlabW04Light',
                                            color: '#00313E',
                                            fontSize: isMobile ? '14px' : '18px',
                                        }}
                                    >
                                        {`$${(new Intl.NumberFormat('en-US').format(Math.round(item?.payment)))}`}
                                    </Typography>

                                </Stack>))}
                        </Grid>
                    )}
            </Grid>
        </div >
    )
}


const mapStateToProps = (state) => {
    return {
        stepsStatus: state?.home?.stepsStatus,
        support: state?.support?.support?.data,
        location: state?.saveGeoLocation?.geoLocation,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        saveSupport: (payload) => dispatch(saveSupport(payload)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionPricing);
