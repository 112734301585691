import SAVEBUSINESSCENTRAL, { RESET_SAVEBUSINESSCENTRAL } from './businessCentral.types';

const INITIAL_STATE = {
  "businessCentral": {
    "bcFinanceFoundation": {
        "bankAccounts": {
            "label": "bankAccounts",
            "value": null
        },
        "functionalCurrencies": {
            "label": "functionalCurrencies",
            "value": null
        },
        "customerCount": {
            "label": "customerCount",
            "value": null
        },
        "vendorCount": {
            "label": "vendorCount",
            "value": null
        },
        "poCount": {
            "label": "poCount",
            "value": null
        }
    },
    "bcMulticompany": {
        "multicompany":  {
          "label": "multicompany",
          "value": null
      },
        "entityCount": {
            "label": "entityCount",
            "value": null
        },
        "uniqueCOA": {
            "label": "uniqueCOA",
            "value": null      
            },
        "intercompanyRequired": {
            "label": "intercompanyRequired",
            "value": null
        },
        "consolidationRequired": {
            "label": "consolidationRequired",
            "value": null
        },
        "noConsolidations": {
            "label": "noConsolidations",
            "value": null
        }
    },
    "bcFixedAssets": {
        "fixedAssets": {
          "label": "fixedAssets",
          "value": null
      },
        "noFixedAssets": {
            "label": "noFixedAssets",
            "value": null
        },
        "faComponents": {
            "label": "faComponents",
            "value": null
        }
    },
    "bcSalesOrder": {
        "salesOrders": {
            "label": "salesOrders",
            "value": null
        },
        "soCount": {
            "label": "soCount",
            "value": null
        }

    },
    "bcJobs": {
        "jobs": {
            "label": "jobs",
            "value": null
        },
        "billableJobs": {
            "label": "billableJobs",
            "value": null
        }
    },
    "bcInventory": {
        "inventoryItems":{
          "label": "inventoryItems",
          "value": null
      },
        "noSku": {
            "label": "noSku",
            "value": null
        },
        "lotTracking": {
            "label": "lotTracking",
            "value": null
        },
        "noWarehouses": {
            "label": "noWarehouses",
            "value": null
        },
        "warehouseLogistics": {
            "label": "warehouseLogistics",
            "value": null
        }
    },
    "bcAssemblyManagement": {
        "assemblyMgmt":{
          "label": "assemblyMgmt",
          "value": null
      },
        "noAboms": {
            "label": "noAboms",
            "value": null
        },
        "edi": {
            "label": "edi",
            "value": null
        }
    },
    "bcManufacturing": {
        "manufacturing":
          {
          "label": "manufacturing",
          "value": null
      },
        "routings": {
            "label": "routings",
            "value": null
        },
        "workCenters": {
            "label": "workCenters",
            "value": null
        },
        "prodLocations": {
            "label": "prodLocations",
            "value": null
        },
        "mBoms": {
            "label": "mBoms",
            "value": null
        },
        "ediM": {
            "label": "ediM",
            "value": null
        }
    }
  },
  
};


const reducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case SAVEBUSINESSCENTRAL:
    //   console.log('action.payload', action.payload);
      return {
        ...state,
        businessCentral: action.payload,
      };
      case RESET_SAVEBUSINESSCENTRAL: 
                return INITIAL_STATE

    default:
      return state;
  }
};

export default reducer;
