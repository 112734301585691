import SAVEGEOLOCATION from './geoLocation.types';

const geoLocation = (payload) => {
    // console.log('payload',payload)
    return {

        type: SAVEGEOLOCATION,
        payload
    };

};

export default geoLocation
