import PRICINGCODE, { RESET_PRICINGCODE } from './pricingCode.types';

const INITIAL_STATE = {
  code: ''
};

const reducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case PRICINGCODE:
      // console.log('action.payload', action.payload);
      return {
        ...state,
        code: action.payload
      };

      case RESET_PRICINGCODE:
        return INITIAL_STATE
    default:
      return state;
  }
};

export default reducer;
